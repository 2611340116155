import { DataGridProProps } from '@mui/x-data-grid-pro'
import { useResourceTypeDetails } from 'api/assets/queries/useResourceTypeDetails'
import { GridRenderCellParams, GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid-premium'
import { Avatar, AvatarSize, Button, Chip, Icon, TableCell, theme } from '@ketch-com/deck'
import { Box, Tooltip, Typography } from '@mui/material'
import {
  ProcessingActivityAssetDTO,
  ProcessingActivityDTO,
  ProcessingActivityDataSystemDTO,
  ProcessingActivityDataSystemProcessingStageDTO,
} from '@ketch-com/figurehead'
import { toNumber } from 'lodash'
import { personalDataTypes } from '../../constants'
import { DataSystemGridAutocomplete } from './DataSystemGridAutocomplete'
import { DataSystemGridStageProcessing } from './DataSystemGridStageProcessing'

export interface DataGridObjectType extends ProcessingActivityDataSystemDTO {
  path: string[]
  handleDataCategoriesClick: (object: ProcessingActivityDataSystemDTO) => void
  rowId: string
}

export interface DataGridAutocompleteObjType {
  path: string[]
  isAutocomplete: boolean
  parentElem?: ProcessingActivityDataSystemDTO
  rowId: string
}

export interface DataGridAssetObjectType extends ProcessingActivityAssetDTO {
  handleDataCategoriesClick: (object: ProcessingActivityAssetDTO) => void
  path: string[]
  logoUrl?: string
  parentElem?: ProcessingActivityDataSystemDTO
  rowId: string
}

export function isDataGridObjectType(object: any): object is DataGridObjectType {
  return 'type' in object
}

export function isDataGridAssetObjectType(object: any): object is DataGridAssetObjectType {
  return 'asset' in object
}

export function isDataGridAutocompleteObjType(object: any): object is DataGridAutocompleteObjType {
  return 'isAutocomplete' in object
}

const DataSystemStepCellContent = ({
  row,
}: {
  row: DataGridObjectType | DataGridAssetObjectType | DataGridAutocompleteObjType
}) => {
  const isDataGridAssetObj = isDataGridAssetObjectType(row)
  const isAutocompleteRow = isDataGridAutocompleteObjType(row)

  const { data: resourceTypeDetails } = useResourceTypeDetails({
    params: {
      providerCode: isDataGridAssetObj
        ? row?.parentElem?.dataSystem?.appCode
        : !isAutocompleteRow
        ? row.dataSystem?.appCode
        : undefined,
      capability: 'processingActivity',
    },
  })

  const datasetType = resourceTypeDetails.details?.[0]?.groupName || ''

  if (isDataGridAssetObj) {
    return (
      <Box display="flex" flexDirection="column" gap={0.25} ml={4}>
        <Box alignSelf="flex-start">
          <Typography variant="label">{row.asset?.asset?.resource?.name || ''}</Typography>
        </Box>
        <Box alignSelf="flex-start" display="flex" alignItems="center" gap={0.5}>
          <img
            width={16}
            height={16}
            src={row.parentElem?.dataSystem?.logoUrl}
            alt={row.parentElem?.dataSystem?.logoUrl || 'asset image'}
          />

          <Typography variant="footnote" color="darkDusk.main" textTransform="capitalize">
            {datasetType}
          </Typography>
        </Box>
      </Box>
    )
  } else if (isAutocompleteRow) {
    return (
      <Box py={1} pl={2}>
        <DataSystemGridAutocomplete system={row.parentElem || {}} />
      </Box>
    )
  } else {
    return (
      <>
        <Avatar
          size={AvatarSize.large}
          isLogo
          variant="rounded"
          sx={{
            backgroundColor: theme => theme.palette.tertiary.main,
          }}
          src={row.dataSystem?.logoUrl}
        />

        <Typography variant="label">{row.dataSystem?.name}</Typography>
      </>
    )
  }
}

export const groupingColDef: DataGridProProps['groupingColDef'] = {
  headerName: 'System',
  sortable: false,
  resizable: false,
  headerAlign: 'left',
  align: 'left',
  flex: 1,
  renderCell: (params: GridRenderCellParams<DataGridObjectType | DataGridAssetObjectType>) => {
    const { row, rowNode } = params
    return (
      <TableCell sx={{ paddingLeft: 2, paddingTop: 0, paddingBottom: 0 }}>
        {rowNode.type === 'group' && <Icon name={rowNode.childrenExpanded ? 'OArrowCDown' : 'OArrowCRight'} />}
        <DataSystemStepCellContent row={row} />
      </TableCell>
    )
  },
}

export const dataSourcesListColumns = ({
  isModelType,
  remove,
  isSubmitting,
}: {
  isModelType: boolean
  remove?: (object: DataGridObjectType | DataGridAssetObjectType) => void
  isSubmitting?: boolean
}): GridColDef<DataGridObjectType | DataGridAssetObjectType>[] => {
  let columns: GridColDef<DataGridObjectType | DataGridAssetObjectType>[] = [
    {
      align: 'left',
      field: 'personalDataType',
      flex: 0.6,
      headerAlign: 'left',
      headerName: 'Personal Data Type',
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams<DataGridObjectType | DataGridAssetObjectType>) => {
        return <TableCell title={personalDataTypes[toNumber(row.personalDataType)]} />
      },
    },
    {
      align: 'left',
      field: 'dataCategories',
      flex: 0.6,
      headerAlign: 'left',
      headerName: 'Data Categories',
      sortable: false,
      renderCell: ({
        row,
      }: GridRenderCellParams<DataGridObjectType | DataGridAssetObjectType | DataGridAutocompleteObjType>) => {
        const isAutocomplete = isDataGridAutocompleteObjType(row)
        if (isAutocomplete) return null

        return (
          <TableCell onClick={e => e.stopPropagation()}>
            {row?.dataCategories?.length ? (
              <Chip
                label={<Typography variant="body">{row?.dataCategories?.length || 'None'} categories</Typography>}
                clickable
                deleteIcon={<Icon name="OArrowCRight" />}
                onDelete={e => {
                  e.stopPropagation()
                  row.handleDataCategoriesClick(row)
                }}
                onClick={e => {
                  e.stopPropagation()
                  row.handleDataCategoriesClick(row)
                }}
              />
            ) : (
              <Typography color={'lightGrey.main'} variant="body">
                None
              </Typography>
            )}
          </TableCell>
        )
      },
    },
  ]

  if (isModelType) {
    columns.push({
      align: 'left',
      field: 'processingStages',
      flex: 0.6,
      maxWidth: 140,
      headerAlign: 'left',
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams<DataGridObjectType>) => {
        return (
          <Box display="flex" alignItems="center" gap={0.5}>
            <Typography variant="smallLabel">Stage of Processing</Typography>
            <Tooltip
              placement="left"
              title={
                <Box display="flex" flexDirection="column" gap={2} px={0.5} py={1}>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Icon name="OInput" iconColor="white" />
                    <Typography variant="label">Input</Typography>
                    <Typography variant="smallBody">
                      Either a source collection point or an input intended for subsequent processing.
                    </Typography>
                  </Box>
                  {!isModelType && (
                    <Box display="flex" flexDirection="column" gap={0.5}>
                      <Icon name="ORefresh" iconColor="white" />
                      <Typography variant="label">Processing</Typography>
                      <Typography variant="smallBody">
                        A system or technology where the processing actually takes place.
                      </Typography>
                    </Box>
                  )}

                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Icon name="OOutput" iconColor="white" />
                    <Typography variant="label">Output</Typography>
                    <Typography variant="smallBody">
                      A destination, storage location, or recipient of the processed output.
                    </Typography>
                  </Box>
                </Box>
              }
            >
              <Box display="flex" alignItems="center">
                <Icon name="FUnknown" fontSize="small" iconColor={theme.palette.darkDuskFaded.main} />
              </Box>
            </Tooltip>
          </Box>
        )
      },
      renderCell: ({
        row,
      }: GridRenderCellParams<DataGridObjectType | DataGridAssetObjectType | DataGridAutocompleteObjType>) => {
        const isAutocomplete = isDataGridAutocompleteObjType(row)
        if (isAutocomplete) return null

        return (
          <TableCell onClick={e => e.stopPropagation()}>
            <DataSystemGridStageProcessing system={row} systemIndex={0} />
          </TableCell>
        )
      },
    })
  }

  const deleteColumn: GridColDef<DataGridObjectType | DataGridAssetObjectType>[] = [
    {
      align: 'right',
      field: 'action',
      flex: 1,
      maxWidth: 100,
      headerAlign: 'left',
      headerName: ' ',
      sortable: false,
      renderCell: ({
        row,
      }: GridRenderCellParams<DataGridObjectType | DataGridAssetObjectType | DataGridAutocompleteObjType>) => {
        const isAutocomplete = isDataGridAutocompleteObjType(row)
        if (isAutocomplete) return null

        return (
          <TableCell>
            <Button
              onClick={() => remove?.(row)}
              variant="iconCustomRounded"
              color="tertiary"
              aria-label="delete data system"
              disabled={isSubmitting}
              disableFocusRipple
              disableRipple
              sx={{ width: 32, height: 32 }}
            >
              <Icon name={'FBin'} width={20} height={20} />
            </Button>
          </TableCell>
        )
      },
    },
  ]

  columns = [...columns, ...deleteColumn]
  return columns
}

export const getStageDefaultState = ({
  system,
  processingActivity,
}: {
  system?: DataGridObjectType | DataGridAssetObjectType
  processingActivity: ProcessingActivityDTO
}) => {
  let isInputStageChecked: boolean = false
  let isOutputStageChecked: boolean = false
  let isProcessStageChecked: boolean = false

  if (isDataGridAssetObjectType(system)) {
    isInputStageChecked =
      system?.processingStages?.includes(
        ProcessingActivityDataSystemProcessingStageDTO.InputProcessingActivityDataSystemProcessingStage,
      ) || false
    isOutputStageChecked =
      system?.processingStages?.includes(
        ProcessingActivityDataSystemProcessingStageDTO.OutputProcessingActivityDataSystemProcessingStage,
      ) || false
    isProcessStageChecked =
      system?.processingStages?.includes(
        ProcessingActivityDataSystemProcessingStageDTO.ProcessProcessingActivityDataSystemProcessingStage,
      ) || false
  } else {
    isInputStageChecked =
      processingActivity.dataSystems
        ?.find(elem => elem.dataSystemId === system?.dataSystemId)
        ?.processingStages?.includes(
          ProcessingActivityDataSystemProcessingStageDTO.InputProcessingActivityDataSystemProcessingStage,
        ) || false

    isOutputStageChecked =
      processingActivity.dataSystems
        ?.find(elem => elem.dataSystemId === system?.dataSystemId)
        ?.processingStages?.includes(
          ProcessingActivityDataSystemProcessingStageDTO.OutputProcessingActivityDataSystemProcessingStage,
        ) || false

    isProcessStageChecked =
      processingActivity.dataSystems
        ?.find(elem => elem.dataSystemId === system?.dataSystemId)
        ?.processingStages?.includes(
          ProcessingActivityDataSystemProcessingStageDTO.ProcessProcessingActivityDataSystemProcessingStage,
        ) || false
  }

  return {
    isInputStageChecked,
    isOutputStageChecked,
    isProcessStageChecked,
  }
}
