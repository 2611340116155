import { useState } from 'react'
import { FormDropdown } from 'components/ui-kit/form/dropdown/FormDropdown'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { DsrAttributeActionsSummaryDTO, ListDsrAttributeActionsSummaryBodyDTO } from '@ketch-com/figurehead'
import { Table } from 'components/ui-layouts/table/Table'
import { Chip } from 'components/ui-kit/chip/Chip'
import { useFormikContext } from 'formik'
import { DsrConfigFormData } from 'pages/assetManager/dsrConfig/interfaces'
import { Box, Typography } from '@mui/material'
import { Badge } from 'components/ui-kit/badge/Badge'
import { DSR_COMMAND_OPTIONS } from 'pages/assetManager/dsrConfig/constants'
import pluralize from 'pluralize'
import { getAssetType } from 'pages/assetManager/utils'
import { styled } from '@mui/material/styles'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { ReactComponent as PersonAvatarSmall } from 'assets/icons/person-avatar-small.svg'
import { ClassificationsModal } from 'pages/assetManager/dsrConfig/upsert/components/DatasetConfiguration/components'
import { LabelDTO } from '@ketch-com/figurehead'
import { MaybeNull } from 'interfaces'

type Props = {
  assetHierarchies: ListDsrAttributeActionsSummaryBodyDTO
  identitySpaces: IdentitySpaceDTO[]
}

const PREFIX = 'AttributeConfiguration'

const classes = {
  pkRelationship: `${PREFIX}-pkRelationship`,
  fkRelationship: `${PREFIX}-fkRelationship`,
  infoIcon: `${PREFIX}-infoIcon`,
}

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`& .${classes.fkRelationship}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: palette.darkChrome.main,
    border: `1px solid ${palette.iron.main}`,
    borderRadius: 5,
    width: 32,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.pkRelationship}`]: {
    fontSize: 12,
    fontWeight: 400,
    color: palette.darkViolet.main,
    backgroundColor: 'rgba(156, 100, 255, 0.25)',
    border: `1px solid rgba(156, 100, 255, 0.25)`,
    borderRadius: 5,
    width: 32,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const getCommandOptions = (assetSummary: DsrAttributeActionsSummaryDTO) => {
  // permittedActions specify which commands apply to this asset for this canonical resource
  return (
    (assetSummary.permittedActions || [])
      .map(index => DSR_COMMAND_OPTIONS.find(opt => opt.id === index))
      // Remove undefined rows in case of bad data
      .filter(a => a) as { id: number; name: string }[]
  )
}

export const AttributeConfiguration: React.FC<Props> = ({ assetHierarchies = {}, identitySpaces }) => {
  const [classifications, setClassifications] = useState<MaybeNull<LabelDTO[]>>(null)
  const { values } = useFormikContext<DsrConfigFormData>()
  const primaryAttributeAsset = assetHierarchies?.hierarchySummaries?.[0].asset
  const primaryAttributeAssetConnection = primaryAttributeAsset?.connection
  const providerCode = primaryAttributeAssetConnection?.providerCode || ''
  const attributeType = getAssetType(providerCode, 3)

  return (
    <StyledBox>
      <Table
        isCapitalizedHeaderText
        rowIdKey={assetAttributeSummary => {
          const { resource } = assetAttributeSummary.asset || {}
          return resource?.id as string
        }}
        items={assetHierarchies?.hierarchySummaries || []}
        cellSettings={{
          columnName: {
            label: `${pluralize(attributeType, assetHierarchies?.hierarchySummaries?.length || 0)}`,
            width: 300,
            cellRenderer: assetAttributeSummary => {
              const {
                resource,
                isForeignKey = false,
                isPrimaryKey = false,
                identitySpace,
              } = assetAttributeSummary?.asset || {}

              if (identitySpace?.code) {
                const matchedIdentitySpace = identitySpaces?.find(space => space.code === identitySpace.code)
                identitySpace.name = matchedIdentitySpace?.name
              }

              return (
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="body">{resource?.name}</Typography>
                  {identitySpace?.code ? (
                    <Chip size="small">
                      <Box display="flex" alignItems="center" gap={1}>
                        <PersonAvatarSmall />
                        <Typography variant="smallBody">{identitySpace?.name}</Typography>
                      </Box>
                    </Chip>
                  ) : null}
                  {isPrimaryKey ? <Box className={classes.pkRelationship}>PK</Box> : null}
                  {isForeignKey ? <Box className={classes.fkRelationship}>FK</Box> : null}
                </Box>
              )
            },
          },
          personalDataType: {
            label: 'Personal Data Type',
            cellRenderer: assetAttributeSummary => {
              const { dataCategory } = assetAttributeSummary.asset || {}
              return dataCategory ? (
                dataCategory.map((data, dataCategoryIndex) => {
                  if (data.value === ('Personal' || 'Sensitive'))
                    return (
                      <Badge key={dataCategoryIndex} variant="neutral">
                        {data.value}
                      </Badge>
                    )
                  return (
                    <Badge key={dataCategoryIndex} variant="neutral">
                      Non-Personal
                    </Badge>
                  )
                })
              ) : (
                <Badge variant="neutral">Non-Personal</Badge>
              )
            },
          },
          classifications: {
            label: 'Classification',
            cellRenderer: assetAttributeSummary => {
              const { inferredLabels = [], assignedLabels = [] } = assetAttributeSummary.asset || {}
              const classificationList = [...assignedLabels, ...inferredLabels]
              const classificationListLength = classificationList?.length
              const firstClassificationItem = classificationList?.shift()
              const classificationName = firstClassificationItem?.name || firstClassificationItem?.value || ''

              if (classificationListLength) {
                return (
                  <Box display="flex" alignItems="center" gap={1}>
                    <Chip size="small">{classificationName}</Chip>
                    {classificationListLength > 1 ? (
                      <Chip
                        size="small"
                        onClick={() => {
                          setClassifications(classificationList)
                        }}
                      >
                        {classificationList?.length} more
                      </Chip>
                    ) : null}
                  </Box>
                )
              }

              return null
            },
          },
          action: {
            label: 'Action',
            cellRenderer: assetAttributeSummary => {
              const { resource } = assetAttributeSummary.asset || {}
              const { id: attributeId = '', hierarchies } = resource || {}
              const { id: datasetId = '' } = hierarchies?.$parent || {}
              const { actions } = values
              const displayUpdateValue = actions?.[datasetId]?.[attributeId]?.command === 6

              return (
                <Box display="flex" alignItems="flex-start" gap={1} width={220}>
                  <FormDropdown
                    size="small"
                    required
                    fullWidth
                    name={`actions.${datasetId}.${attributeId}.command` || ''}
                    placeholder="Select"
                    valueKey="id"
                    items={getCommandOptions(assetAttributeSummary)}
                  />

                  {displayUpdateValue ? (
                    <FormInput
                      size="small"
                      required={displayUpdateValue}
                      name={`actions.${datasetId}.${attributeId}.updateValue` || ''}
                    />
                  ) : null}
                </Box>
              )
            },
          },
        }}
      />

      {classifications ? (
        <ClassificationsModal
          classifications={classifications}
          onCancel={() => {
            setClassifications(null)
          }}
        />
      ) : null}
    </StyledBox>
  )
}
