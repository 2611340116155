import { useNavigate } from 'react-router-dom'
import { usePrivacyProtocols } from 'api/privacyProtocols/queries/usePrivacyProtocols'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useUpdatePrivacyProtocolConfigStatus } from 'api/privacyProtocols/mutations/useUpdatePrivacyProtocolConfigStatus'
import { PatchPrivacyProtocolConfigBody } from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'
import { useIsPermitted } from '../../../../../../utils/hooks'
import { PERMISSIONS } from '../../../../../../interfaces/permissions/permissions'

export const usePrivacyProtocolsUtils = () => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const canWrite = isPermitted(PERMISSIONS.PERMIT_ORCH_WRITE)

  // Fetch data
  const {
    data: protocols,
    isLoading: isProtocolsLoading,
    refetch: refetchProtocols,
  } = usePrivacyProtocols({
    onError: () => {
      showToast({ content: `Failed to fetch protocols`, type: 'error' })
    },
  })
  const isReady = !isProtocolsLoading

  // Update status
  const { mutateAsync: updatePrivacyProtocolConfigStatus } = useUpdatePrivacyProtocolConfigStatus({
    onError: () => {
      showToast({ content: `Failed to update protocol`, type: 'error' })
    },
    onSuccess: () => {
      showToast({ content: `Updated protocol`, type: 'success' })
    },
  })
  const updateStatus = async (id: string, formData: PatchPrivacyProtocolConfigBody): Promise<any> => {
    await updatePrivacyProtocolConfigStatus({
      params: { id, formData },
    })
  }

  // return { navigate, protocolsList, setProtocolsList, updateStatus, isReady }
  return { navigate, protocols, refetchProtocols, updateStatus, isReady, canWrite }
}

export type PrivacyProtocolsUtils = ReturnType<typeof usePrivacyProtocolsUtils>
