import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { GridColDef } from '@mui/x-data-grid-premium'
import { Box, Typography, Tooltip, hexToRgb } from '@mui/material'
import { Button, Chip, ContentGroup, DataGrid, EmptyState, TableCell, Icon, InfoRow, theme } from '@ketch-com/deck'
import { APPLICATION_PLATFORM_NAME } from 'interfaces/applications/applicationPlatform'
import {
  ACCESSIBILITY_BUTTON_POSITION_NAMES,
  ApplicationDTO,
  ApplicationEnvironmentDTO,
} from 'interfaces/applications/application'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ApplicationStatus } from 'interfaces/applications/applicationStatus'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { RoutesManager } from 'utils/routing/routesManager'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { ApplicationManageDeploymentModal } from 'pages/consentAndRights/applications/modals/manageDeployment/ApplicationManageDeploymentModal'
import { DataDeploymentStatus } from 'pages/consentAndRights/applications/list/components'

type Props = {
  isLatest: boolean
  application: ApplicationDTO
  themes: ThemeV3DTO[]
}

export const ApplicationOverview: FC<Props> = ({ application, isLatest, themes }) => {
  const [isManageDeploymentOpen, setIsManageDeploymentOpen] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()

  const isPermittedToCreateApplications = isPermitted([PERMISSIONS.PROPERTY_WRITE])
  const { code, environments = [], latest = false, platform } = application

  const onDeploymentPlanClick = (code: string) => () => {
    navigate(RoutesManager.deployment.deploymentPlansV2.view.root.getURL({ code }))
  }

  const onThemeClick = (id: string) => () => {
    navigate(RoutesManager.deployment.themesV3.view.preview.root.getURL({ id }))
  }

  const onDocumentClick = (code: string) => () => {
    navigate(RoutesManager.policyCenter.policyDocuments.view.root.getURL({ code }))
  }

  const environmentColumns: GridColDef<ApplicationEnvironmentDTO>[] = [
    {
      align: 'left',
      field: 'code',
      flex: 1,
      headerAlign: 'left',
      headerName: 'Name',
      sortable: false,
      renderCell: ({ row }) => <TableCell title={row.code} />,
    },
    {
      align: 'left',
      field: 'pattern',
      flex: 1,
      headerAlign: 'left',
      headerName: 'Pattern',
      sortable: false,
      renderCell: ({ row }) => <TableCell title={row?.pattern ?? 'None'} />,
    },
    {
      align: 'left',
      field: 'domain',
      flex: 1,
      headerAlign: 'left',
      headerName: 'Domain',
      sortable: false,
      renderCell: ({ row }) => <TableCell title={row?.domain ?? 'N/A'} />,
    },
    {
      align: 'left',
      field: 'deployment',
      flex: 1,
      headerAlign: 'left',
      headerName: 'Deployment Plan',
      sortable: false,
      renderCell: ({ row }) =>
        !!row.deployment ? (
          <TableCell>
            <Tooltip title={row.deployment.name}>
              <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                <Chip
                  clickable
                  label={row.deployment.name}
                  deleteIcon={<Icon name="OArrowCRight" />}
                  onClick={onDeploymentPlanClick(row.deployment.code)}
                  onDelete={onDeploymentPlanClick(row.deployment.code)}
                />
              </Box>
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell title="None" />
        ),
    },
    {
      align: 'left',
      field: 'theme',
      flex: 1,
      headerAlign: 'left',
      headerName: 'Theme',
      sortable: false,
      renderCell: ({ row }) => {
        const theme = themes.find(item => item.id === row.themeID)
        return !!theme?.id ? (
          <TableCell>
            <Tooltip title={theme.name}>
              <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                <Chip
                  clickable
                  label={theme.name}
                  deleteIcon={<Icon name="OArrowCRight" />}
                  onClick={onThemeClick(theme.id)}
                  onDelete={onThemeClick(theme.id)}
                />
              </Box>
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell title="None" />
        )
      },
    },
    {
      align: 'left',
      field: 'status',
      flex: 1,
      maxWidth: 150,
      headerAlign: 'left',
      headerName: 'Status',
      sortable: false,
      renderCell: ({ row }) => (
        <TableCell>
          <DataDeploymentStatus
            status={!!row.deployment ? ApplicationStatus.DEPLOYED : ApplicationStatus.INACTIVE}
            environments={[row]}
          />
        </TableCell>
      ),
    },
  ]

  if (!isRelease(Release.PropertyThemeDocuments)) {
    environmentColumns.splice(4, 1)
  }

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup title="Details" titleVariant="h3" variant="inner-page-header" />
        <InfoRow title="Property Type">
          <Chip label={APPLICATION_PLATFORM_NAME[platform]} />
        </InfoRow>
        <InfoRow title="Data Subject Type">
          <Box display="flex" gap={1} flexWrap="wrap">
            {!!application?.dataSubjectTypes?.length ? (
              application?.dataSubjectTypes?.map(dst => {
                return <Chip key={dst.code} label={dst.name} secondaryLabel={dst.code} />
              })
            ) : (
              <Typography color="lightGrey.main">None</Typography>
            )}
          </Box>
        </InfoRow>

        {isRelease(Release.PropertyThemeDocuments) && (
          <InfoRow title="Privacy Policy Document">
            {application?.privacyPolicy?.name && application?.privacyPolicy?.code ? (
              <Chip
                clickable
                label={application.privacyPolicy.name}
                deleteIcon={<Icon name="OArrowCRight" />}
                onClick={onDocumentClick(application.privacyPolicy.code)}
                onDelete={onDocumentClick(application.privacyPolicy.code)}
              />
            ) : (
              <Typography color="lightGrey.main">None</Typography>
            )}
          </InfoRow>
        )}
        {isRelease(Release.PropertyThemeDocuments) && (
          <InfoRow title="Terms of Service Document">
            {application?.termsOfService?.name && application?.termsOfService?.code ? (
              <Chip
                clickable
                label={application.termsOfService.name}
                deleteIcon={<Icon name="OArrowCRight" />}
                onClick={onDocumentClick(application.termsOfService.code)}
                onDelete={onDocumentClick(application.termsOfService.code)}
              />
            ) : (
              <Typography color="lightGrey.main">None</Typography>
            )}
          </InfoRow>
        )}
      </Box>

      {isRelease(Release.FabButton) && (
        <Box display="flex" flexDirection="column" gap={4}>
          <ContentGroup title="Accessibility" titleVariant="h3" variant="inner-page-header" />
          <InfoRow title="Preferences Trigger Button">
            {!!application.accessibilityButton ? (
              <Box display="flex" gap={4}>
                <Box
                  p={1}
                  width={48}
                  height={48}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor={application.accessibilityButton.color}
                  borderRadius="50%"
                  sx={{
                    flex: '0 0 48px',
                    overflow: 'hidden',
                    '& > svg, & > img': {
                      width: 32,
                      height: 32,
                    },
                  }}
                >
                  {!!application.accessibilityButton.icon ? (
                    <img src={application.accessibilityButton.icon} alt="preview icon" />
                  ) : (
                    <Icon name="OFingerprint" iconColor={theme.palette.white.main} fontSize="large" />
                  )}
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography pb={0.5} variant="label">
                    Color
                  </Typography>
                  <Typography noWrap>{hexToRgb(application.accessibilityButton.color)}</Typography>
                  <Typography noWrap>{application.accessibilityButton.color}</Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography pb={0.5} variant="label">
                    Icon
                  </Typography>
                  <Typography>
                    {!!application.accessibilityButton.icon
                      ? application.accessibilityButton.icon.split('/').at(-1)
                      : '-'}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography pb={0.5} variant="label">
                    Position
                  </Typography>
                  <Typography noWrap>
                    {ACCESSIBILITY_BUTTON_POSITION_NAMES[application.accessibilityButton.position]}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Typography>Disabled</Typography>
            )}
          </InfoRow>
        </Box>
      )}

      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup
          title="Environments"
          titleVariant="h3"
          variant="inner-page-header"
          actionRightBlockComponent={
            !!environments.length &&
            isLatest &&
            isPermittedToCreateApplications && (
              <Button
                color="secondary"
                onClick={() => {
                  setIsManageDeploymentOpen(true)
                }}
              >
                Manage Deployment
              </Button>
            )
          }
        />
        {!!environments.length ? (
          <>
            <DataGrid
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnReorder
              disableColumnResize
              disableRowHoverStates
              disableRowSelectionOnClick
              hideFooter
              rows={environments}
              columns={environmentColumns}
              getRowId={(row: ApplicationEnvironmentDTO) => row.ID}
              getRowHeight={() => 'auto'}
              sx={{
                '& .MuiDataGrid-virtualScroller': {
                  overflow: 'hidden',
                },
              }}
            />

            {isManageDeploymentOpen && (
              <ApplicationManageDeploymentModal
                application={application}
                onSuccess={() => {
                  queryClient.refetchQueries([
                    latest ? ApiQueryKeys.application : ApiQueryKeys.applicationVersion,
                    { applicationCode: code },
                  ])

                  setIsManageDeploymentOpen(false)
                }}
                onClose={() => {
                  setIsManageDeploymentOpen(false)
                }}
              />
            )}
          </>
        ) : (
          <EmptyState title="Property is not associated with any environments" />
        )}
      </Box>
    </Box>
  )
}
