import { Formik } from 'formik'
import { useContext } from 'react'
import { ProcessingActivityContext } from '../context/ProcessingActivityContext'
import { Comment } from 'pages/policyCenter/processingActivities/view/Comment'
import {
  getChatInitialValues,
  getChatValidationSchema,
} from 'pages/policyCenter/processingActivities/view/Comment/utils'
import { useAuth } from 'utils/hooks/useAuth'
import { handleSendMessage } from 'pages/policyCenter/processingActivities/view/Comment/utils'
import { Button, Tab, TabGroup, TabProps } from '@ketch-com/deck'
import { Box } from '@mui/material'

type TabsComponentProps = {
  tabItems: Array<TabProps>
  value?: any
  onChange?: (event: React.SyntheticEvent, value: any) => void
  'aria-label'?: string
  'aria-labelledby'?: string
}

const TabsComponent = ({ tabItems, ...tabsProps }: TabsComponentProps) =>
  tabItems && (
    <TabGroup {...tabsProps}>
      {tabItems.map((tabItem, index) => (
        <Tab {...tabItem} key={index} />
      ))}
    </TabGroup>
  )

export const ProcessingActivityTabs = ({ tabsComponentProps }: { tabsComponentProps: TabsComponentProps }) => {
  const { userData } = useAuth()

  const {
    processingActivityChat,
    isProcessingActivityChatEnabled,
    isProcessingActivityChatLoading,
    isProcessingActivityChatUsersLoading,
  } = useContext(ProcessingActivityContext)

  const renderTabsAside = () => {
    if (!isProcessingActivityChatEnabled) {
      return null
    }
    if (isProcessingActivityChatLoading || isProcessingActivityChatUsersLoading) {
      return <Button color="tertiary" pending={true} />
    }
    return (
      <Formik
        initialValues={getChatInitialValues(processingActivityChat, userData)}
        validationSchema={getChatValidationSchema()}
        onSubmit={handleSendMessage}
      >
        {() => {
          return <Comment />
        }}
      </Formik>
    )
  }

  return (
    <Box
      className="DeckLayout-header-content"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {tabsComponentProps && (
        <Box flex="1">
          <TabsComponent {...tabsComponentProps} />
        </Box>
      )}
      <Box>{renderTabsAside()}</Box>
    </Box>
  )
}
