import { OrgAvailablePlans, OrgPlanProductIDs } from 'components/planAndBilling/common/types/planAndBilling'

export const OrgPlanIDs: Record<OrgAvailablePlans, OrgPlanProductIDs> = {
  free: 'plan_v5_plg_free',
  starter: 'plan_v5_plg_30k',
  plus: 'plan_v5_plg_150k',
  pro: 'plan_v5_platform_standard',
}

export const OrgPlanNames: Record<OrgAvailablePlans, string> = {
  free: 'Free',
  starter: 'Starter',
  plus: 'Plus',
  pro: 'Pro',
}

export const OrgPlanCosts: Record<Exclude<OrgAvailablePlans, 'pro'>, number> = {
  free: 0,
  starter: 209,
  plus: 419,
}

export const OrgPlanMaxUniquesText: Record<OrgAvailablePlans, string> = {
  free: '5k Unique Users',
  starter: '30k Unique Users',
  plus: '1.5MM Unique Users',
  pro: 'Unlimited Unique Users',
}

export const OrgPlanMaxUniquesLimit: Record<Exclude<OrgAvailablePlans, 'pro'>, number> = {
  free: 5000,
  starter: 30000,
  plus: 1500000,
}

export enum OrgPlanAndBillingBannerVariants {
  freePlanInGrace = 'freePlanInGrace',
  freePlanSuspended = 'freePlanSuspended',
  starterPlanInGrace = 'starterPlanInGrace',
  starterPlanSuspended = 'starterPlanSuspended',
  billingInGrace = 'billingInGrace',
  billingMissed = 'billingMissed',
}
