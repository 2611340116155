import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { AssetPolicyDTO } from '@ketch-com/figurehead'
import { LogicTab, VersionsTab, OverviewTab } from '.'

type Props = {
  policy: AssetPolicyDTO
}

export const PolicyDetailTabRoutes: React.FC<Props> = ({ policy }) => {
  return (
    <Routes>
      <Route
        path={RoutesManager.policyCenter.policies.detail.overview.pattern}
        element={<OverviewTab policy={policy} />}
      />

      <Route path={RoutesManager.policyCenter.policies.detail.logic.pattern} element={<LogicTab policy={policy} />} />

      <Route
        path={RoutesManager.policyCenter.policies.detail.versions.pattern}
        element={<VersionsTab policy={policy} />}
      />

      {/* <Route to={`${path}/overview`} /> */}
    </Routes>
  )
}
