import {
  OrgPlanIDs,
  OrgPlanNames,
  OrgPlanCosts,
  OrgPlanMaxUniquesText,
} from 'components/planAndBilling/common/constants/planAndBilling'

export const getOrgPlanName = (planProductID: string = '') => {
  switch (planProductID) {
    case OrgPlanIDs.free:
      return OrgPlanNames.free
    case OrgPlanIDs.starter:
      return OrgPlanNames.starter
    case OrgPlanIDs.plus:
      return OrgPlanNames.plus
    case OrgPlanIDs.pro:
      return OrgPlanNames.pro

    default:
      return 'Unknown'
  }
}

export const getOrgPlanCost = (planProductID: string = '') => {
  switch (planProductID) {
    case OrgPlanIDs.free:
      return OrgPlanCosts.free
    case OrgPlanIDs.starter:
      return OrgPlanCosts.starter
    case OrgPlanIDs.plus:
      return OrgPlanCosts.plus

    default:
      return 0
  }
}

export const getOrgPlanUniques = (planProductID: string = '') => {
  switch (planProductID) {
    case OrgPlanIDs.free:
      return OrgPlanMaxUniquesText.free
    case OrgPlanIDs.starter:
      return OrgPlanMaxUniquesText.starter

    default:
      return OrgPlanMaxUniquesText.free
  }
}
