import { InfoRow, InfoRowProps, TextInput } from '@ketch-com/deck'
import { InstalledDataSystemDataMapContactV2DTO } from '@ketch-com/figurehead'
import { useEffect, useState } from 'react'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'

interface ContactEditInfoRowProps extends InfoRowProps {
  value?: InstalledDataSystemDataMapContactV2DTO
  setValue: (value: InstalledDataSystemDataMapContactV2DTO) => void
}

const StaticContact = ({ contact }: { contact: InstalledDataSystemDataMapContactV2DTO }) => (
  <Box>
    <Typography variant="label" color="textPrimary" gutterBottom={false}>
      {contact.firstName} {contact.lastName}
    </Typography>
    <Typography display="block" variant="body" color="textSecondary">
      {contact.title}
    </Typography>
    <Typography variant="body" color="textSecondary">
      {contact.email}
    </Typography>
  </Box>
)

type EditFieldProps = {
  localValue: InstalledDataSystemDataMapContactV2DTO
  setLocalValue: (value: InstalledDataSystemDataMapContactV2DTO) => void
}
const EditField = ({ localValue, setLocalValue }: EditFieldProps) => (
  <Grid container spacing={4}>
    <Grid item xs={6}>
      <TextInput
        label="First Name"
        placeholder="Add first name"
        variant="ghost"
        value={localValue.firstName}
        onChange={e => setLocalValue({ ...localValue, firstName: e.target.value })}
      />
    </Grid>
    <Grid item xs={6}>
      <TextInput
        label="Last Name"
        placeholder="Add last name"
        variant="ghost"
        value={localValue.lastName}
        onChange={e => setLocalValue({ ...localValue, lastName: e.target.value })}
      />
    </Grid>
    <Grid item xs={6}>
      <TextInput
        label="Email"
        placeholder="Add email"
        variant="ghost"
        value={localValue.email}
        onChange={e => setLocalValue({ ...localValue, email: e.target.value })}
      />
    </Grid>
    <Grid item xs={6}>
      <TextInput
        label="Title"
        placeholder="Add title"
        variant="ghost"
        value={localValue.title}
        onChange={e => setLocalValue({ ...localValue, title: e.target.value })}
      />
    </Grid>
  </Grid>
)

export default function ContactEditInfoRow({ value, setValue, ...rest }: ContactEditInfoRowProps) {
  const [localValue, setLocalValue] = useState<InstalledDataSystemDataMapContactV2DTO>({})
  const [isEditing, setIsEditing] = useState(false)

  const hasValue = Object.values(localValue).length > 0
  useEffect(() => {
    if (value) {
      setLocalValue(value)
    }
  }, [value])

  return (
    <InfoRow
      {...rest}
      isEditable
      isEditing={isEditing}
      onEditChange={() => {
        setIsEditing(true)
      }}
      onAcceptChange={() => {
        setIsEditing(false)
        setValue(localValue)
      }}
      onCancelChange={() => {
        setIsEditing(false)
        setLocalValue(value || {})
      }}
      isEmpty={!hasValue}
    >
      {isEditing ? (
        <EditField localValue={localValue} setLocalValue={setLocalValue} />
      ) : hasValue ? (
        <StaticContact contact={localValue} />
      ) : (
        <EmptyValueRenderer />
      )}
    </InfoRow>
  )
}
