import React, { useContext } from 'react'
import { useSetState } from 'react-use'
import { FormMode } from 'interfaces/formModes/formMode'
import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { EditConfirmationModal } from 'components/modals/common/EditConfirmationModal'
import { ExperienceDeleteModal } from 'pages/consentAndRights/experiences-v2/modals/ExperienceDeleteModal'
import { ExperienceViewContext } from '../context/ExperienceViewContext'
import { Button, TooltipButton } from '@ketch-com/deck'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { ExperienceStatus } from 'interfaces/experiences-v2/experienceStatus'
import { Box, Typography } from '@mui/material'

export const ExperienceViewActions: React.FC = () => {
  const { navigate, experience } = useContext(ExperienceViewContext)

  const { isPermitted } = useIsPermitted()
  const isPermittedToManageExperiences = isPermitted(PERMISSIONS.EXP_WRITE)
  const isConsent = experience?.type === ExperienceType.Consent
  const isDeployed = experience?.status === ExperienceStatus.Deployed

  const [{ isDeleteOpen, isEditConfirmationOpen }, setState] = useSetState({
    isDeleteOpen: false,
    isEditConfirmationOpen: false,
  })

  const handleRedirectToEdit = () => {
    navigate(
      RoutesManager.deployment.experiencesV2.upsert.root.getURL({
        id: experience?.id,
        formMode: FormMode.EDIT,
        experienceType: isConsent ? ExperienceType.Consent : ExperienceType.Preference,
      }),
    )
  }

  return (
    <>
      <Box display="flex" alignItems="center" gap="12px">
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="smallBody" color="darkDuskFaded.main">
            Last Updated
          </Typography>
          <Typography color="darkDusk.main" variant="smallBody">
            <FormattedDateRenderer date={experience?.metadata?.createdAt} />
          </Typography>
        </Box>

        {isPermittedToManageExperiences && (
          <>
            <TooltipButton
              title={isDeployed ? 'A deployed Experience cannot be deleted' : undefined}
              disabled={isDeployed}
              color="tertiary"
              size="large"
              onClick={() => {
                setState({ isDeleteOpen: true })
              }}
            >
              Delete
            </TooltipButton>

            <Button
              color="secondary"
              size="large"
              onClick={() => {
                if (isDeployed) {
                  setState({ isEditConfirmationOpen: true })
                } else {
                  handleRedirectToEdit()
                }
              }}
            >
              Edit
            </Button>
          </>
        )}
      </Box>

      {isDeleteOpen && (
        <ExperienceDeleteModal
          experience={experience!}
          onClose={() => {
            setState({ isDeleteOpen: false })
          }}
          onDelete={() => {
            navigate(RoutesManager.deployment.experiencesV2.root.getURL())
          }}
        />
      )}

      {isEditConfirmationOpen && (
        <EditConfirmationModal
          entityType={ApplicationEntity.EXPERIENCE}
          onSubmit={handleRedirectToEdit}
          onCancel={() => {
            setState({ isEditConfirmationOpen: false })
          }}
        />
      )}
    </>
  )
}
