import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { Chip, InfoRow, LabelChip, LabelChipVariant, Icon, ContentGroup } from '@ketch-com/deck'
import { styled } from '@mui/styles'
import { ProcessingActivityProcessingFrequencyDTO, ProcessingActivityStatusDTO } from '@ketch-com/figurehead'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'
import { isUndefined, toNumber } from 'lodash'
import {
  personalDataTypes,
  ProcessingActivityFrequencyLabel,
} from '../../../../../policyCenter/processingActivities/constants'
import { DataMapProcessingActivityContext } from '../context/DataMapProcessingActivityContext'
import { RoutesManager } from 'utils/routing/routesManager'
// TODO: needs replacement with Deck component, when ready
import { DataResidencyTile } from './DataResidencyTile'
import { TeamChip } from 'components/chip/TeamChip'

const InfoRowContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}))

const NoDataPlaceholder = () => (
  <Typography color="lightGrey.main" variant="body">
    None
  </Typography>
)

export const OverviewTab: React.FC = () => {
  const { navigate, processingActivity } = useContext(DataMapProcessingActivityContext)

  const validResidencies =
    processingActivity.residencies?.filter(
      residency => residency.country || residency.security || residency.retentionPeriod,
    ) || []

  const handlePurposeClick = (code: string) => {
    navigate(RoutesManager.insights.dataMap.view.overview.root.getURL({ code }))
  }

  return (
    <>
      <Box pb={4}>
        <ContentGroup
          title="Overview"
          titleVariant="h3"
          variant="inner-page-header"
          isDivider={false}
          subTitle="Basic information about this processing activity."
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={4}>
        {/* Conditionally show approval details */}
        {processingActivity.status === ProcessingActivityStatusDTO.ApprovedProcessingActivityStatus && (
          <InfoRow title="Approved By">
            <InfoRowContent>
              <Typography variant="body">
                {processingActivity?.statusUpdatedByInfo?.firstName} {processingActivity?.statusUpdatedByInfo?.lastName}{' '}
                on {formatDateTimeFromUnix(processingActivity.statusUpdatedAt)}
              </Typography>
            </InfoRowContent>
          </InfoRow>
        )}

        {/* Description */}
        <InfoRow title="Description">
          <InfoRowContent>
            {processingActivity.description ? (
              <Typography variant="body">{processingActivity.description}</Typography>
            ) : (
              <NoDataPlaceholder />
            )}
          </InfoRowContent>
        </InfoRow>

        {/* Processing Frequency */}
        <InfoRow title="Processing Frequency">
          <InfoRowContent>
            {!isUndefined(processingActivity.processingFrequency) &&
            processingActivity.processingFrequency !==
              ProcessingActivityProcessingFrequencyDTO.UnspecifiedProcessingActivityProcessingFrequency ? (
              <Typography variant="body">
                {
                  ProcessingActivityFrequencyLabel[
                    processingActivity.processingFrequency ||
                      ProcessingActivityProcessingFrequencyDTO.UnspecifiedProcessingActivityProcessingFrequency
                  ]
                }
              </Typography>
            ) : (
              <NoDataPlaceholder />
            )}
          </InfoRowContent>
        </InfoRow>

        {/* Clickable chip per purpose, redirecting to view screen for that purpose */}
        <InfoRow title={'Purpose'}>
          <InfoRowContent>
            {processingActivity?.purposes?.length ? (
              processingActivity.purposes.map(purpose => (
                <Chip
                  key={purpose.code}
                  label={<Typography variant="body">{purpose.name}</Typography>}
                  clickable
                  deleteIcon={<Icon name="OArrowCRight" />}
                  onDelete={() => handlePurposeClick(purpose.code || '')}
                  onClick={() => handlePurposeClick(purpose.code || '')}
                />
              ))
            ) : (
              <NoDataPlaceholder />
            )}
          </InfoRowContent>
        </InfoRow>

        <InfoRow title={'Teams'}>
          <InfoRowContent>
            {processingActivity?.teams?.length ? (
              processingActivity?.teams?.map(team => <TeamChip team={team} key={team.id} />)
            ) : (
              <NoDataPlaceholder />
            )}
          </InfoRowContent>
        </InfoRow>

        {/* Personal Data Types */}
        <InfoRow title="Personal Data Types">
          <InfoRowContent>
            {processingActivity?.personalDataTypes?.length ? (
              processingActivity.personalDataTypes.map(dataType => (
                <Chip
                  key={dataType}
                  label={<Typography variant="body">{personalDataTypes[toNumber(dataType)]}</Typography>}
                />
              ))
            ) : (
              <NoDataPlaceholder />
            )}
          </InfoRowContent>
        </InfoRow>

        {/* Categories */}
        <InfoRow title="Data Categories">
          <InfoRowContent>
            {processingActivity?.dataCategories?.length ? (
              processingActivity.dataCategories.map(dataCategory => (
                <LabelChip
                  key={dataCategory.code}
                  title={dataCategory.name || ''}
                  isViewOnly
                  variant={LabelChipVariant.category}
                />
              ))
            ) : (
              <NoDataPlaceholder />
            )}
          </InfoRowContent>
        </InfoRow>

        {/* Data Residencies */}
        <InfoRow title="Data Residencies" sx={{ marginBottom: '32px' }}>
          <InfoRowContent>
            {validResidencies.length ? (
              // Used grid below so we can fill space with exactly two columns of the same size
              // <Box width="100%" display="grid" columnGap={2} rowGap={2.5}>
              <Box width="100%" display="flex" flexWrap="wrap" gap={2} rowGap={2.5}>
                {validResidencies.map(residency => (
                  <DataResidencyTile key={residency.country} residency={residency} />
                ))}
              </Box>
            ) : (
              <NoDataPlaceholder />
            )}
          </InfoRowContent>
        </InfoRow>
      </Box>
    </>
  )
}
