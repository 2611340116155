import { Button, Icon } from '@ketch-com/deck'
import { Tooltip, Typography } from '@mui/material'
import React from 'react'
import { REFER_A_FRIEND_LINK } from 'utils/constants/referAFriend'

/**
 * Refer a friend header button
 */
export const HeaderReferAFriendLink: React.FC = () => {
  const handleButtonClick = () => {
    window.open(REFER_A_FRIEND_LINK, '_blank', 'noreferrer')
  }

  return (
    <Tooltip title={<Typography variant="label">Refer a Friend!</Typography>}>
      <span>
        <Button
          id="settings-button"
          variant={'iconCustomRounded'}
          color={'white'}
          onClick={handleButtonClick}
          sx={{
            width: '28px',
            height: '28px',
            minWidth: '28px',
          }}
        >
          <Icon name="OGift" />
        </Button>
      </span>
    </Tooltip>
  )
}
