export const detailViewInfoRowConfig = [
  'Description',
  'Technology',
  'Personal Data Types',
  'Identifier',
  'Data Preview',
  // 'Attribute Identifier', // TODO:BAC - has been de-scoped per SAM
  // 'Key', // TODO:BAC - has been de-scoped per SAM
  'Data Owners',
  'Deep Classification',
  // 'Data Residencies', // TODO:BAC - has been de-scoped per SAM
  'Last Updated',
] as const
