import React from 'react'
import { Box } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'

import { ACTIONS_EXECUTION_STATUS_MAP } from 'interfaces/executions/ActionsExecutionStatus'
import { Badge } from 'components/ui-kit/badge/Badge'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import {
  DATASET_EXECUTION_STATUS_VARIANT_MAP,
  DATASET_EXECUTION_STATUS_NAMES_MAP,
} from 'interfaces/executions/DatasetExecutionStatus'
import { Dialog } from 'components/ui-kit/dialog/Dialog'
import { DmlHookDTO } from '@ketch-com/figurehead'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { ExpandableRowTable } from './components'
import { getProcessesPayload } from './utils'
import { InfiniteScrollLoadingIndicator } from 'components/ui-layouts/table/components/infiniteScrollLoadingIndicator'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { RoutesManager } from 'utils/routing/routesManager'
import { Status } from 'components/ui-kit/status/Status'
import { Table } from 'components/ui-layouts/table/Table'
import { TechnologyTag } from 'components/ui-kit/tag/TechnologyTag'
import { Text } from 'components/ui-kit/typography/Text'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { useTransponderDsrOverviewModalUtils } from './hooks'
import { Waypoint } from 'react-waypoint'
import { WorkflowExecutionStepDTO } from 'interfaces/workflowExecutions/workflowExecution'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStateCommon.svg'
// TODO:BAC - de-scoped per BE resources
// import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
// import { SegmentTabs } from 'components/ui-kit/segmentTabs/SegmentTabs'
// import { TabFilterType } from './constants'
// import { Tabs } from 'components/ui-kit/tabs/Tabs'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      searchInput: {
        maxWidth: 200,
        marginRight: spacing(4),
      },
      filterRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: spacing(2),
        background: palette.white.main,
        paddingTop: spacing(2),
        position: 'sticky',
        top: 0,
      },
      status: {
        textTransform: 'capitalize',
      },
      expandContent: {
        background: palette.white.main,
        padding: 0,
        boxShadow: '0px 5px 13px rgba(7, 26, 36, 0.1), 0px 22px 32px -24px rgba(7, 26, 36, 0.06)',
      },
      tableAssetTypeIcon: {
        width: 22,
        height: 22,
        marginRight: spacing(1),
      },
      buttonOptions: {
        background: palette.white.main,
      },
      tabsContainer: {
        borderBottom: `1px solid ${palette.iron.main}`,
      },
      technologiesList: {
        display: 'flex',
        marginLeft: spacing(0.25),

        '& > div': {
          marginLeft: spacing(-0.375),
        },
      },
      dialogBaseModalContent: {
        paddingTop: 0,
      },
    }),
  { name: 'TransponderDsrOverviewModal' },
)

type Props = {
  hook?: DmlHookDTO
  onCancel?: () => void
  workflowExecutionStep: WorkflowExecutionStepDTO
}

export const TransponderDsrOverviewModal: React.FC<Props> = ({ onCancel, workflowExecutionStep }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { stepID, workflowExecutionID } = workflowExecutionStep
  const {
    executions,
    fiveItemsFromBottomIndex,
    selectedTab,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading,
    // TODO:BAC - de-scoped per BE resources
    // setSelectedTab,
    // searchString,
    // setSearchString,
    // tabFilter,
    // setTabFilter,
  } = useTransponderDsrOverviewModalUtils({ stepId: stepID, workflowExecutionId: workflowExecutionID })

  // TODO:BAC - de-scoped per BE resources
  // const tabFilterOptions = [
  //   {
  //     id: TabFilterType.ALL,
  //     // TODO:BAC - make count dynamic
  //     name: <SegmentFilterOption label={TabFilterType.ALL} count={6} />,
  //   },
  //   {
  //     id: TabFilterType.DONE,
  //     // TODO:BAC - make count dynamic
  //     name: <SegmentFilterOption label={TabFilterType.DONE} count={8} />,
  //   },
  //   {
  //     id: TabFilterType.SKIP,
  //     // TODO:BAC - make count dynamic
  //     name: <SegmentFilterOption label={TabFilterType.SKIP} count={4} />,
  //   },
  //   {
  //     id: TabFilterType.FAIL,
  //     // TODO:BAC - make count dynamic
  //     name: <SegmentFilterOption label={TabFilterType.FAIL} count={2} />,
  //   },
  // ]

  return (
    <Dialog
      title="Transponder"
      subtitle="A summary of deleted or customized information about the Data Subject."
      contentWidth={1280}
      submitBtnLabel="Delete"
      cancelBtnLabel="Close"
      onCancelBtnClick={onCancel}
      baseModalContentClassName={classes.dialogBaseModalContent}
      isLoading={isLoading}
    >
      {/* // TODO:BAC - Filter row has been de-scoped per BE resources */}
      {/* Filter Row */}
      {/* <Box className={classes.filterRow}>
        <SearchInput
          className={classes.searchInput}
          size="small"
          onChange={nextSearchString => setSearchString(nextSearchString)}
          onSearch={() => {}}
          onClear={() => setSearchString('')}
          value={searchString}
        />

        <SegmentTabs value={tabFilter} tabs={tabFilterOptions} onChange={tab => setTabFilter(tab)} />
      </Box> */}

      <Box sx={{ px: 2, overflowX: 'hidden' }}>
        {executions?.length === 0 ? (
          <EmptyState
            variant="list"
            icon={<EmptyStateIcon />}
            title="No execution results"
            description="Data manipulation results are shown for supported providers. The assets defined in the request policy must have associated identities in the rights request."
          />
        ) : (
          <Table
            variant="page"
            isSmallHeaderText
            items={executions}
            tableRowExpandContentContainerClassName={classes.expandContent}
            isRowExpandable={rowData => true}
            renderExpandableRow={executionResult => {
              const processesPayload = getProcessesPayload(executionResult)
              return (
                <Box py={2} px={3}>
                  {/* // TODO:BAC - hide tabs until "data" tab is implemented */}
                  {/* <Box display="flex" alignItems="center" className={classes.tabsContainer} mb={1.5}>
                  <Tabs
                    config={[
                      {
                        key: 'process',
                        active: selectedTab === 'process',
                        title: 'Process',
                        onClick: () => setSelectedTab('process'),
                      },
                      // TODO:BAC - has been de-scoped
                      // {
                      //   key: 'data',
                      //   active: selectedTab === 'data',
                      //   title: 'Data',
                      //   onClick: () => setSelectedTab('data'),
                      // },
                    ]}
                    size="regular"
                  />
                </Box> */}
                  {selectedTab === 'process' ? (
                    <ExpandableRowTable processes={processesPayload} />
                  ) : (
                    <Text size={22}>Data</Text>
                  )}
                </Box>
              )
            }}
            cellSettings={{
              dsrConfiguration: {
                width: 420,
                label: 'DSR Configuration',
                cellRenderer: executionResult => (
                  <Box display="flex" alignItems="center">
                    <Box className={classes.technologiesList} display="flex" ml={0.25} mr={0.5}>
                      <TechnologyTag technology={executionResult?.technology || ''} />

                      <TechnologyTag technology={executionResult?.provider || ''} />
                    </Box>
                    <NameAndCodeCellRenderer code={executionResult?.parentName} name={executionResult?.name} />
                  </Box>
                ),
              },
              mode: {
                label: 'Mode',
                width: 180,
                cellRenderer: executionResult => (
                  <TextCellRenderer value={executionResult?.groupType === 2 ? 'Custom SQL' : 'Configuration'} />
                ),
              },
              status: {
                label: 'Status',
                width: 145,
                cellRenderer: executionResultHook => {
                  return (
                    <Status
                      className={classes.status}
                      variant={DATASET_EXECUTION_STATUS_VARIANT_MAP[executionResultHook?.status || 0]}
                    >
                      {DATASET_EXECUTION_STATUS_NAMES_MAP[executionResultHook?.status || 0]}
                    </Status>
                  )
                },
              },
              personalDataType: {
                width: 150,
                label: 'Personal Data Type',
                cellRenderer: executionResult =>
                  executionResult?.personalDataType?.value ? (
                    <Badge variant="neutral">{executionResult?.personalDataType?.value}</Badge>
                  ) : (
                    <EmptyValueRenderer />
                  ),
              },
              attributes: {
                width: 140,
                label: 'Attributes',
                cellRenderer: executionResult =>
                  executionResult?.attributeCount ? (
                    <Badge variant="neutral">{executionResult?.attributeCount}</Badge>
                  ) : (
                    <EmptyValueRenderer />
                  ),
              },
              attributeActions: {
                width: 150,
                label: 'Attribute Actions',

                cellRenderer: executionResult => (
                  <TextCellRenderer value={ACTIONS_EXECUTION_STATUS_MAP[executionResult?.actions || 0]} />
                ),
              },
              actions: {
                width: 90,
                cellRenderer: (executionResult, index) => {
                  return (
                    <>
                      <ButtonOptions
                        className={classes.buttonOptions}
                        onOpen={() => {}}
                        onClose={() => {}}
                        items={[
                          {
                            content: 'View',
                            onClick: () =>
                              navigate(
                                RoutesManager.assetManager.assets.detail.root.getURL({
                                  code: executionResult?.id || '',
                                }),
                              ),
                          },
                          {
                            content: 'Edit',
                            onClick: () =>
                              navigate(
                                RoutesManager.assetManager.assets.upsert.getURL({
                                  code: executionResult?.id || '',
                                }),
                              ),
                          },
                        ]}
                      />
                      {fiveItemsFromBottomIndex === index && (
                        <Waypoint
                          onEnter={() => {
                            if (!isFetchingNextPage && hasNextPage) fetchNextPage()
                          }}
                        />
                      )}
                    </>
                  )
                },
              },
            }}
          />
        )}
      </Box>
      <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
    </Dialog>
  )
}
