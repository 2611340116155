import { Chip, InfoRow, InfoRowProps } from '@ketch-com/deck'
import { useEffect, useState } from 'react'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { RadioGroup, RadioOptionValue } from 'components/RadioGroup/RadioGroup'

export type ChipContentRadioEditOptions = {
  [key: string]: RadioOptionValue
}

type EditComponentProps = {
  value?: string
  setValue: (selectedChips?: string) => void
  options: ChipContentRadioEditOptions
  allowDelete?: boolean
}

export interface ChipContentInfoRowProps extends InfoRowProps, EditComponentProps {}

export default function ChipContentRadioEditInfoRow({
  allowDelete = true,
  options,
  setValue,
  value,
  ...rest
}: ChipContentInfoRowProps) {
  const [localValue, setLocalValue] = useState<string>()
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  return (
    <InfoRow
      {...rest}
      isEditable
      isEditing={isEditing}
      onEditChange={() => {
        setIsEditing(true)
      }}
      onAcceptChange={() => {
        setIsEditing(false)
        setValue(localValue)
      }}
      onCancelChange={() => {
        setIsEditing(false)
      }}
      onDeleteChange={
        allowDelete
          ? () => {
              setIsEditing(false)
              setValue()
            }
          : undefined
      }
      isEmpty={localValue?.length === 0}
    >
      {localValue ? (
        !isEditing ? (
          <Chip
            label={options[localValue].title}
            onClick={e => {
              e.stopPropagation()
            }}
          />
        ) : (
          <RadioGroup
            options={Object.values(options)}
            value={localValue}
            onChange={e => {
              setLocalValue(e.currentTarget.value)
            }}
          />
        )
      ) : (
        <EmptyValueRenderer />
      )}
    </InfoRow>
  )
}
