import { Box, Typography } from '@mui/material'
import { Free } from 'components/planAndBilling/components/planDetails/Free'
import { Starter } from 'components/planAndBilling/components/planDetails/Starter'
import { Plus } from 'components/planAndBilling/components/planDetails/Plus'
import { Pro } from 'components/planAndBilling/components/planDetails/Pro'
import { theme, Chip } from '@ketch-com/deck'

export const PlanDetails = () => {
  return (
    <Box
      sx={{
        borderTop: `1px solid ${theme.palette.iron.main}`,
        paddingTop: 6,
      }}
    >
      <Box display="flex" mb={2}>
        <Box flexGrow={3} flexBasis={0}>
          <Chip sx={{ width: '100%' }} label={<Typography variant="label">Growth</Typography>} />
        </Box>
        <Box flexGrow={1} flexBasis={0} display="flex" justifyContent="flex-end">
          <Chip sx={{ width: '96%' }} label={<Typography variant="label">Enterprise</Typography>} />
        </Box>
      </Box>
      <Box display="flex" gap={2}>
        <Free />
        <Starter />
        <Plus />
        <Pro />
      </Box>
    </Box>
  )
}
