import { DocumentOptionType } from 'pages/dataSystems/DataSystem/types'
import { Chip } from '@ketch-com/deck'
import { formatLastUpdatedDate } from 'utils/helpers/dateTime'

type Props = {
  document: DocumentOptionType
}

export default function DocumentChip({ document }: Props) {
  const version = formatLastUpdatedDate(document.version)
  const desc = document.description || 'No Description'
  const caption = `${desc} | ${version}`
  return <Chip label={document.name} secondaryLabel={caption} />
}
