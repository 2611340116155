import React, { useContext, useState, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { TrackerDetailsContext, ApprovedTrackerPropertiesContext } from 'pages/policyCenter/trackers/context'
import {
  Icon,
  IconDictionary,
  Chip,
  InfoRow,
  TextInput,
  DropListButton,
  Avatar,
  AvatarSize,
  theme,
  PopUp,
  Button,
} from '@ketch-com/deck'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useUpsertTrackerDescription } from 'api/trackers/mutations/useUpsertTrackerDescription'
import { useUpdateTrackerDataSystem } from 'api/trackers/mutations/useUpdateTrackerDataSystem'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { ApiQueryKeys } from 'api/common/queryKeys'
import moment from 'moment'
import { ProvenanceType } from 'pages/policyCenter/trackers/interfaces'
import { ProvenanceVariant } from 'pages/policyCenter/trackers/components'
import { DataSystemSummary } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type Props = {}

export const Overview: React.FC<Props> = () => {
  const queryClient = useQueryClient()
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])
  const navigate = useNavigate()
  const {
    trackerDetails,
    encodedTrackerKey = '',
    isLoadingDataSystems,
    dataSystems = [],
    setDataSystemInputValue,
    dataSystemInputValue,
  } = useContext(TrackerDetailsContext)
  const { trackerProperties } = useContext(ApprovedTrackerPropertiesContext)
  const [isEditing, setIsEditing] = useState(false)
  const [textValue, setTextValue] = useState<string>(trackerDetails?.description || '')
  const [previousTextValue, setPreviousTextValue] = useState<string>()
  const [isEditingDataSystem, setIsEditingDataSystem] = useState(false)
  const [dataSystem, setDataSystem] = useState<DataSystemSummary>(
    trackerDetails?.dataSystem || ({} as DataSystemSummary),
  )
  const [shouldUpdateDataSystemToManual, setShouldUpdateDataSystemToManual] = useState<boolean>(false)

  const { mutateAsync: upsertTrackerDescription, isLoading: isUpdatingTrackerDescription } =
    useUpsertTrackerDescription({
      onSuccess: async () => {
        await queryClient.invalidateQueries([ApiQueryKeys.trackerCategories])
        showToast({ content: 'Updated tracker description', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Failed to update tracker description', type: 'error' })
      },
    })

  const { mutateAsync: updateTrackerDataSystem, isLoading: isUpdatingTrackerDataSystem } = useUpdateTrackerDataSystem({
    onSuccess: async () => {
      await queryClient.invalidateQueries([ApiQueryKeys.trackerDetails])
      await queryClient.invalidateQueries([ApiQueryKeys.trackerStatistics])
      await queryClient.invalidateQueries([ApiQueryKeys.approvedTrackersInfinite])

      showToast({ content: 'Updated tracker system', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update tracker system', type: 'error' })
    },
  })

  const dataSystemsSummary: DataSystemSummary[] = useMemo(
    () =>
      dataSystems?.map(
        system =>
          ({
            code: system?.dataSystem?.code || '',
            name: system?.dataSystem?.name || '',
            logoURL: system?.dataSystem?.logoUrl || '',
            isManual: trackerDetails?.dataSystem?.isManual || false,
          }) as DataSystemSummary,
      ),
    [dataSystems, trackerDetails],
  )

  const hasDuration = Number(trackerDetails?.duration)
  let durationFormat = ''

  if (!hasDuration && trackerDetails?.duration === 'Infinity') durationFormat = 'Infinity'

  if (hasDuration) {
    const duration = moment.duration(trackerDetails?.duration)
    const days = Math.floor(duration.asDays())
    switch (true) {
      case days < 1:
        durationFormat = 'Less than a day'
        break
      case days === 1:
        durationFormat = '1 day'
        break
      case days > 1:
        durationFormat = `${days} days`
        break
      default:
        durationFormat = 'Unknown'
        break
    }
  }

  return (
    <Box>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
          { title: 'Trackers', link: RoutesManager.policyCenter.trackers.list.approvedTrackers.root.getURL() },
          { title: trackerDetails?.key },
          { title: 'Overview' },
        ]}
      />
      <Box pb={3}>
        <Typography variant="h3">Overview</Typography>
        <Typography variant="body" color="darkDuskFaded.main">
          Basic information about this tracker.
        </Typography>
      </Box>
      <Box>
        <InfoRow
          title="Description"
          isEditable={isPermittedToWriteCookie ? true : false}
          isEditing={isEditing}
          isLoading={isUpdatingTrackerDescription}
          onEditChange={() => {
            setIsEditing(true)
            setPreviousTextValue(textValue)
          }}
          onAcceptChange={() => {
            upsertTrackerDescription({ params: { requestBody: { description: textValue }, encodedTrackerKey } })
            setIsEditing(false)
          }}
          onCancelChange={() => {
            setIsEditing(false)
            setTextValue(previousTextValue || '')
          }}
          onDeleteChange={() => {
            upsertTrackerDescription({ params: { requestBody: { description: '' }, encodedTrackerKey } })
            setTextValue('')
            setIsEditing(false)
          }}
          isEmpty={!!!textValue?.length}
        >
          <Box mb={4}>
            {isEditing ? (
              <TextInput
                autoFocus
                placeholder="Description"
                multiline={true}
                rows={2}
                fullWidth={true}
                value={textValue}
                onChange={event => setTextValue(event.target.value)}
              />
            ) : textValue ? (
              textValue
            ) : (
              'None'
            )}
          </Box>
        </InfoRow>

        {trackerDetails?.provenance ? (
          <InfoRow title="Origin">
            <Box mb={4}>
              <ProvenanceVariant provenance={trackerDetails?.provenance} type={ProvenanceType.Chip} />
            </Box>
          </InfoRow>
        ) : null}

        {durationFormat ? (
          <InfoRow title="Duration">
            <Box mb={4}>
              <Typography variant="body">{durationFormat}</Typography>
            </Box>
          </InfoRow>
        ) : null}

        {trackerDetails?.categories?.length ? (
          <InfoRow title="Categories">
            <Box mb={4} display="flex" flexWrap="wrap" gap={1}>
              {trackerDetails?.categories?.map((category, i) => {
                return <Chip key={i} label={category?.name} />
              })}
            </Box>
          </InfoRow>
        ) : null}

        {trackerProperties.length ? (
          <InfoRow title="Found On">
            <Box mb={4} display="flex" flexWrap="wrap" gap={1}>
              {trackerProperties?.map((properties, i) => {
                return (
                  <Chip
                    key={i}
                    label={properties?.name}
                    clickable
                    onClick={() => {
                      navigate(
                        RoutesManager.deployment.applications.view.overview.root.getURL({ code: properties?.code }),
                      )
                    }}
                    deleteIcon={<Icon name={IconDictionary.OArrowCRight} />}
                    onDelete={() => {
                      navigate(
                        RoutesManager.deployment.applications.view.overview.root.getURL({ code: properties?.code }),
                      )
                    }}
                    selectable={false}
                  />
                )
              })}
            </Box>
          </InfoRow>
        ) : null}

        <InfoRow
          title="System"
          isEditable={isPermittedToWriteCookie ? true : false}
          isEditing={isEditingDataSystem}
          isLoading={isUpdatingTrackerDataSystem}
          onEditChange={() => {
            setIsEditingDataSystem(true)
          }}
          onAcceptChange={() => {
            if (trackerDetails?.dataSystem?.isManual) {
              updateTrackerDataSystem({
                params: {
                  requestBody: {
                    code: dataSystem?.code,
                    name: dataSystem?.name,
                    logoURL: dataSystem?.logoURL,
                    isManual: true,
                  },
                  encodedTrackerKey,
                },
              })
              setIsEditingDataSystem(false)
            } else {
              setShouldUpdateDataSystemToManual(true)
            }
          }}
          onCancelChange={() => {
            setDataSystem(trackerDetails?.dataSystem || ({} as DataSystemSummary))
            setDataSystemInputValue(trackerDetails?.dataSystem?.name || '')
            setIsEditingDataSystem(false)
          }}
          isEmpty={!dataSystems?.length}
        >
          {isEditingDataSystem ? (
            <Box mb={2}>
              <DropListButton
                value={dataSystem}
                size="small"
                disabled={isUpdatingTrackerDataSystem}
                loading={isLoadingDataSystems}
                filterOptions={x => x}
                noOptionsText="No Systems"
                getOptionDisabled={option => dataSystem?.code === option?.code}
                onChange={(e, selectedDataSystem) => {
                  if (selectedDataSystem)
                    setDataSystem({
                      code: selectedDataSystem?.code || '',
                      name: selectedDataSystem?.name || '',
                      logoURL: selectedDataSystem?.logoURL || '',
                    })
                  else setDataSystem({} as DataSystemSummary)

                  setDataSystemInputValue(selectedDataSystem?.name || '')
                }}
                options={dataSystemsSummary}
                renderInput={params => (
                  <TextInput
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    placeholder="Select System"
                    variant="outlined"
                    value={dataSystemInputValue}
                    onChange={e => {
                      setDataSystemInputValue(e?.currentTarget?.value)
                    }}
                  />
                )}
                renderOption={(props, system) => {
                  return (
                    <Box key={system?.code} component="li" {...props} display="flex" alignItems="center" gap={1.5}>
                      <Avatar
                        isLogo
                        src={system?.logoURL}
                        alt={system?.name}
                        variant="rounded"
                        size={AvatarSize.large}
                        backgroundColor={theme.palette.tertiary.main}
                      />
                      <Typography variant="label">{system?.name}</Typography>
                    </Box>
                  )
                }}
                getOptionLabel={system => system?.name || ''}
                isOptionEqualToValue={(option, value) => option?.code === value?.code}
              />
            </Box>
          ) : null}

          {!isEditingDataSystem && trackerDetails?.dataSystem?.name ? (
            <Box display="flex" flexDirection="column" gap={1}>
              <Chip
                sx={{ cursor: 'default !important', alignSelf: 'flex-start' }}
                label={trackerDetails?.dataSystem?.name}
                icon={
                  trackerDetails?.dataSystem?.logoURL ? (
                    <Box pl={1} pt={0.5}>
                      <img
                        src={trackerDetails?.dataSystem?.logoURL}
                        alt={trackerDetails?.dataSystem?.name}
                        width={14}
                        height={14}
                      />
                    </Box>
                  ) : (
                    <></>
                  )
                }
              />
              {!trackerDetails?.dataSystem?.isManual ? (
                <Box display="flex" alignItems="center" gap={0.5}>
                  <Icon
                    name={IconDictionary.FAutomation}
                    iconColor={theme.palette.darkDuskFaded.main}
                    width={12}
                    height={12}
                  />
                  <Typography variant="label" color="darkDuskFaded.main">
                    Auto-Assigned
                  </Typography>
                </Box>
              ) : null}
            </Box>
          ) : null}

          {!isEditingDataSystem && !trackerDetails?.dataSystem?.name ? <>Unknown</> : null}
        </InfoRow>

        {trackerDetails?.updatedAt ? (
          <InfoRow title="Last Updated">
            <Box mb={4}>
              <Typography variant="body">{moment(trackerDetails?.updatedAt).format('MMM D YYYY, h:mm A')}</Typography>
            </Box>
          </InfoRow>
        ) : null}

        {shouldUpdateDataSystemToManual ? (
          <PopUp
            title="Override automatic system assignment?"
            popUpWidth="550px"
            variant="dialog"
            onClose={() => setShouldUpdateDataSystemToManual(false)}
            popUpActionChildren={
              <>
                <Button color="secondary" size="large" onClick={() => setShouldUpdateDataSystemToManual(false)}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  size="large"
                  onClick={() => {
                    updateTrackerDataSystem({
                      params: {
                        requestBody: {
                          code: dataSystem?.code,
                          name: dataSystem?.name,
                          logoURL: dataSystem?.logoURL,
                          isManual: true,
                        },
                        encodedTrackerKey,
                      },
                    })
                    setIsEditingDataSystem(false)
                    setShouldUpdateDataSystemToManual(false)
                  }}
                >
                  Confirm
                </Button>
              </>
            }
          >
            <Box>
              <Typography variant="body">
                You are about to override the automatic system-to-tracker mapping. If you proceed, new trackers will not
                be automatically updated to a system. Please confirm this action.
              </Typography>
            </Box>
          </PopUp>
        ) : null}
      </Box>
    </Box>
  )
}
