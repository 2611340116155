import React from 'react'
import Box from '@mui/material/Box'
import clsx from 'clsx'
import { Waypoint } from 'react-waypoint'

import { AssetSummaryDTO, ConnectionDTO } from '@ketch-com/figurehead'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { Chip } from 'components/ui-kit/chip/Chip'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { getAssetCloudImgUrl, getAssetTypeHumanReadable } from 'pages/assetManager/utils'
import { getDoesAssetSupportHooks } from 'pages/assetManager/components/EditAssetForm/utils'
import { getHasPrimaryKey } from './utils'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ReactComponent as TransponderIcon } from 'assets/icons/transponder-icon.svg'
import { Text } from 'components/ui-kit/typography/Text'
import { Tooltip } from 'components/ui-kit/tooltip/Tooltip'
import { TooltipContent } from './components'
import { useAssetListItemStyles } from './hooks'
import { useHandleEditOnClick, useHandleViewAssetOnClick } from '../../hooks'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { UseAssetsInfiniteV3Return } from 'api/assets/queries/useAssetsInfiniteV3'
import { Typography } from '@mui/material'

export type HierarchyCountTileConfig = {
  tileTitle: string
  tileValue: number
}[]

type Props = {
  index: number
  isLastCard: boolean
  assetSummary: AssetSummaryDTO
  onAssetClick: (event: React.MouseEvent<HTMLDivElement>) => void
  isSelected: boolean
  isFifthToLastItem: boolean
  connections: ConnectionDTO[]
  isFetchingNextPage?: boolean
  fetchNextPage: UseAssetsInfiniteV3Return['fetchNextPage']
}

export const AssetListItem: React.FC<Props> = ({
  isLastCard,
  assetSummary,
  isSelected,
  connections,
  isFifthToLastItem,
  isFetchingNextPage,
  fetchNextPage,
  /*, onAssetClick */
}) => {
  const { isPermitted } = useIsPermitted()
  const [isFancy, setIsFancy] = React.useState<boolean>(false)
  const classes = useAssetListItemStyles({ isSelected })
  const handleEditOnClick = useHandleEditOnClick(assetSummary)
  const handleViewOnClick = useHandleViewAssetOnClick(assetSummary)

  const hierarchyCountTileConfig: HierarchyCountTileConfig = Object.entries(assetSummary?.hierarchyCounts || {}).map(
    ([key, value]) => {
      const humanReadableKey = connections?.[0]?.resourceTypes?.find(rt => rt.code === key)?.name || key
      return { tileTitle: humanReadableKey, tileValue: value }
    },
  )

  const isTooltipDisabled = Object.entries(assetSummary?.asset?.resource?.hierarchies || {}).length === 0

  const collectedLabels = [
    ...(assetSummary?.asset?.assignedLabels || []),
    ...(assetSummary?.asset?.inferredLabels || []),
  ]

  const doesAssetSupportHooks = getDoesAssetSupportHooks(assetSummary) && isPermitted(PERMISSIONS.HOOK_READ)

  const shouldDisableEditAndViewLinks =
    doesAssetSupportHooks || assetSummary?.asset?.resource?.resourceType?.code?.includes('.schema')
      ? false
      : Boolean(!assetSummary?.asset?.resource?.resourceType?.canonicalResourceType)

  const resourceName = assetSummary?.asset?.resource?.name || assetSummary?.asset?.resource?.id || ''
  const connectionName = assetSummary?.asset?.connection?.name || ''

  return (
    <Box
      id={assetSummary?.asset?.resource?.id || ''}
      mb={isLastCard ? 0 : 2}
      // onClick={onAssetClick} // TODO:BAC - uncomment when bulk select and update is desired
      className={clsx(classes.container, {
        [classes.shouldPreserveHoverStyles]: isFancy,
      })}
    >
      {isFifthToLastItem ? (
        <Waypoint
          onEnter={() => {
            if (!isFetchingNextPage) fetchNextPage()
          }}
        />
      ) : null}
      {/* First Row */}

      <Box mb={1.5} className={classes.firstRowContainer}>
        {/* Asset Icon, Asset Type Display Name, Primary Key */}

        <Box display="flex" alignItems="center">
          <img
            src={getAssetCloudImgUrl(assetSummary?.asset?.connection?.technology || '')}
            className={classes.techImage}
            alt={assetSummary?.asset?.connection?.technology || 'technology image'}
          />
          <Tooltip
            disabled={isTooltipDisabled}
            content={<TooltipContent assetSummary={assetSummary} connections={connections} />}
          >
            <Text size={11} weight={600} lineHeight="unset" mr={1} color="darkDuskFaded">
              {getAssetTypeHumanReadable(assetSummary)}
            </Text>
          </Tooltip>

          <Chip className={classes.transponderChip} icon={<TransponderIcon />} resetPadding>
            {connectionName}
          </Chip>

          {getHasPrimaryKey(assetSummary) && (
            <Text size={11} weight={600} color="bloomDark" className={classes.primaryKeyText}>
              | Primary Key
            </Text>
          )}
        </Box>
        {/* Data Owner, Last Updated */}

        <Box display="flex" alignItems="center">
          <Text size={11} weight={500} color="grey" className={classes.dataOwnerText}>
            {assetSummary?.asset?.owner ? `Data Owner: ${assetSummary?.asset?.owner}` : 'Data Owner: N/A'}
          </Text>

          <Text size={11} weight={500} color="grey">
            Last Updated:{' '}
            <FormattedDateRenderer format="MMM D YYYY, h:mm A" date={assetSummary?.asset?.metadata?.updatedAt} />
          </Text>
        </Box>
      </Box>

      {/* Second Row */}

      <Box mb={4} className={classes.secondRowContainer}>
        {/* Asset Name */}

        <Typography
          onClick={
            assetSummary?.asset?.resource?.resourceType?.canonicalResourceType ||
            assetSummary?.asset?.resource?.resourceType?.code?.includes('.schema')
              ? (e: React.MouseEvent) => {
                  e.stopPropagation()
                  handleViewOnClick()
                }
              : undefined
          }
          className={clsx(classes.capitalCase, classes.breakWord, {
            [classes.assetName]: !shouldDisableEditAndViewLinks,
          })}
          variant="h3"
          id={`view-link-${connectionName}`}
        >
          {resourceName}
        </Typography>

        {/* Policies Count Pill Indicator */}
        <Box
          className={classes.policiesPillIndicator}
          style={{
            display:
              // TODO:BAC - Temporarily hide because API does not yet provide this info on an AssetSummaryDTO
              'none',
          }}
        >
          {/* TODO:BAC - make dynamic, add getPlural func */}
          <Text size={11} weight={500} color="persianBlue">
            2 Policies
          </Text>
        </Box>
      </Box>

      {/* Third Row: Asset Stats Tiles */}

      <Box mb={2} className={classes.thirdRowContainer}>
        {/* Technology Tile */}

        <Box className={classes.assetStatTileContainer}>
          {/* Icon */}

          <img
            className={classes.techTileImage}
            src={getAssetCloudImgUrl(assetSummary?.asset?.connection?.technology || '')}
            alt={getAssetCloudImgUrl(assetSummary?.asset?.connection?.technology || '')}
          />

          {/* Name */}
          <Text color="grey" className={clsx(classes.capitalCase, classes.tileText)} size={12} weight={500}>
            Technology
          </Text>
        </Box>

        {/* Personal Data Type */}

        {assetSummary?.asset?.dataCategory && (
          <Box className={classes.assetStatTileContainer}>
            {/* Value */}

            <Text className={clsx(classes.capitalCase, classes.tileText)} size={16} weight={700} color="grey">
              {assetSummary?.asset?.dataCategory?.[0]?.value}
            </Text>

            {/* Title */}
            <Text color="grey" className={clsx(classes.capitalCase, classes.tileText)} size={12} weight={500}>
              Personal Data Type
            </Text>
          </Box>
        )}

        {/* Classifications */}

        {!!collectedLabels.length && (
          <Box className={classes.assetStatTileContainer}>
            {/* Value */}

            <Text className={clsx(classes.capitalCase, classes.tileText)} size={16} weight={700} color="grey">
              {collectedLabels.length}
            </Text>

            {/* Title */}
            <Text color="grey" className={clsx(classes.capitalCase, classes.tileText)} size={12} weight={500}>
              {collectedLabels.length === 1 ? 'Classification' : 'Classifications'}
            </Text>
          </Box>
        )}

        {/* Hierarchy Count Tiles */}

        {hierarchyCountTileConfig?.length > 0 &&
          hierarchyCountTileConfig?.map(({ tileTitle, tileValue }, index) => {
            return (
              <Box key={tileTitle} className={classes.assetStatTileContainer}>
                {/* Value */}

                <Text className={clsx(classes.capitalCase, classes.tileText)} size={16} weight={700} color="grey">
                  {tileValue}
                </Text>

                {/* Title */}
                <Text color="grey" className={clsx(classes.capitalCase, classes.tileText)} size={12} weight={500}>
                  {tileTitle}
                </Text>
              </Box>
            )
          })}
      </Box>

      {/* Fourth Row: Description and Action Menu Button */}

      <Box className={classes.fourthRowContainer}>
        <Box flex={1} display="flex" justifyContent="flex-end">
          <ButtonOptions
            className={classes.buttonOptions}
            onOpen={() => setIsFancy(true)}
            onClose={() => setIsFancy(false)}
            items={[
              {
                content: 'View',
                hidden: shouldDisableEditAndViewLinks,
                onClick: () => handleViewOnClick(),
              },
              {
                content: 'Edit',
                hidden: shouldDisableEditAndViewLinks,
                onClick: () => handleEditOnClick(),
              },
            ]}
          />
        </Box>
      </Box>
    </Box>
  )
}
