import { ChatData } from 'pages/policyCenter/processingActivities/view/Comment/interfaces'
import { showToast } from 'components/ui-kit/toastr/Toastr'

export const handleSendMessage = async (values: ChatData) => {
  const { twilioActiveConversation, message, currentUser } = values
  if (twilioActiveConversation && message && currentUser) {
    try {
      const newMessageBuilder = twilioActiveConversation
        .prepareMessage()
        .setBody(message.trim())
        .setAttributes({
          firstName: currentUser?.firstName || '',
          lastName: currentUser?.lastName || '',
          orgCode: currentUser?.organizationCode || '',
        })
      await newMessageBuilder.build().send()
    } catch (error) {
      showToast({ content: 'Failed to send message', type: 'error' })
      console.info('Error message', '\n', (error as Error)?.message)
      console.info('Error name', '\n', (error as Error)?.name, '\n', '\n')
      console.info('Error stack', '\n', (error as Error)?.stack, '\n')
    }
  }
}
