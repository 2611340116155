import React, { useMemo } from 'react'

import { MaybeNull } from 'interfaces'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { usePurposes } from 'api/purposes/queries/usePurposes'
import { ActionSheetItem, DropListButton, ListItemText } from '@ketch-com/deck'

type Props = {
  valueKey?: string
  onChange: (value: MaybeNull<PurposeDTO>) => void
  value: string
  pending?: boolean
}

export const PurposesDropList: React.FC<Props> = props => {
  const { value, valueKey = 'code', onChange, pending } = props
  const { data, isLoading } = usePurposes()

  const options = useMemo(() => {
    const _options =
      data?.map(item => ({
        value: valueKey === 'code' ? item.code : item.ID,
        label: item?.name || item?.code,
        item,
      })) || []

    return [{ label: `All Purposes`, value: 'all', item: null }, ..._options]
  }, [data, valueKey])

  const currentValue = useMemo(() => {
    const _value = value || 'all'

    return options.find(elem => elem.value === _value)
  }, [value, options])

  return (
    <DropListButton
      disableClearable
      isOptionEqualToValue={(option, value) => option === value}
      renderOption={(props, option, { selected }) => {
        return (
          <ActionSheetItem selected={selected} {...props}>
            <ListItemText selected={selected}>{option.label}</ListItemText>
          </ActionSheetItem>
        )
      }}
      options={options}
      onChange={(_, value) => value && onChange(value.item)}
      value={currentValue}
      placeholder={isLoading || pending ? 'Loading..' : !value ? 'All Purposes' : ''}
      size="small"
      loading={isLoading || pending}
    />
  )
}
