import React, { useContext, useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { Box, Typography } from '@mui/material'
import { PopUp, Button, Icon, IconDictionary, theme, DropListButton, TextInput } from '@ketch-com/deck'
import { CustomSqlContext } from 'pages/assetManager/scenario/details/components/CustomSql/context'
import { FormInput } from 'components/ui-kit/form/input/FormInput'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { MaybeNull } from 'interfaces'
import { useDebounce } from 'react-use'
import { FormTextArea } from 'components/ui-kit/form/textArea/FormTextArea'
import { CustomSqlFormData } from 'pages/assetManager/scenario/details/components/CustomSql/interfaces'
import { styled } from '@mui/material/styles'
import { useIdentitySpacesPaginated } from 'api/identitySpaces/queries/useIdentitySpacePaginated'
import { useSplitCustomSqls } from 'api/assets/mutations/useSplitCustomSqls'
import { hasMultipleSqlStatement } from '../utils/'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { SCRIPT_TYPE } from 'pages/assetManager/scenario/details/constants'

const InfoBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  padding: spacing(1.5),
  borderRadius: '16px',
  display: 'flex',
  alignItems: 'flex-start',
  gap: spacing(1.5),
  background: palette.fadedHeliotrope.main,
  marginTop: spacing(1.5),
}))

type Props = {}

export const CustomSqlUpsertForm: React.FC<Props> = () => {
  const { values, touched, errors, setFieldValue, setFieldTouched, submitForm, isValid } =
    useFormikContext<CustomSqlFormData>()
  const [inputValue, setInputValue] = useState(values?.identitySpaceCode || '')
  const [identitySpaceQuery, setIdentitySpaceQuery] = useState(values?.identitySpaceCode || '')
  const [selectedIdentitySpace, setSelectedIdentitySpace] = useState<MaybeNull<IdentitySpaceDTO>>(null)
  const { setCustomSqlModalData } = useContext(CustomSqlContext)

  const { mutateAsync: splitCustomSqls } = useSplitCustomSqls({
    onSuccess: () => {
      showToast({ content: 'Successfully split SQL string into multiple statements', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to split SQL', type: 'error' })
    },
  })

  const { data: identitySpaces, isLoading: isLoadingIdentitySpaces } = useIdentitySpacesPaginated({
    params: {
      query: identitySpaceQuery,
      limit: 300,
    },
    onSuccess: ({ data }) => {
      const sel = data?.identitySpaces?.filter(identitySpace => identitySpace?.code === values?.identitySpaceCode)?.[0]

      if (values?.identitySpaceCode && !sel) {
        setFieldValue('identitySpaceCode', '')
        setInputValue('')
        setIdentitySpaceQuery('')
      }

      if (!selectedIdentitySpace && sel) setSelectedIdentitySpace(sel)
    },
  })
  useDebounce(
    () => {
      if (inputValue) {
        setIdentitySpaceQuery(inputValue)
      } else {
        setIdentitySpaceQuery('')
      }
    },
    500,
    [inputValue],
  )

  useEffect(() => {
    const isMultipleSqlStatement = hasMultipleSqlStatement(values.sqlQuery)

    const handleMultipleQueries = async () => {
      const { data } = await splitCustomSqls({
        params: {
          sqlScript: values?.sqlQuery || '',
        },
      })
      const queries = data?.sqlQueries?.filter(query => query)

      if (queries?.length) {
        setFieldValue('sqlQuery', '')
        setFieldValue('scriptType', SCRIPT_TYPE.MULTIPLE)
        setFieldValue('sqlQueries', queries)
      }
    }

    if (isMultipleSqlStatement && !errors?.sqlQuery) {
      handleMultipleQueries()
    }
  }, [values.sqlQuery, errors?.sqlQuery, setFieldValue, splitCustomSqls])

  useEffect(() => {
    if (values?.scriptType === SCRIPT_TYPE.MULTIPLE)
      values?.sqlQueries?.forEach((_, index) => {
        setFieldTouched(`sqlQueries[${index}]`, true)
      })

    if (values?.scriptType === SCRIPT_TYPE.SINGLE) setFieldTouched('sqlQuery', true)
  }, [values?.sqlQueries, values?.scriptType, values?.identitySpaceCode, setFieldTouched])

  const SqlInfo = (
    <Typography variant="smallBody" color="darkDuskFaded.main">
      The SQL query should have
      <Typography variant="label">{` {{${values.identitySpaceCode}}} `}</Typography> around the Identifier code in the
      where filter.{' '}
      {values?.scriptType === SCRIPT_TYPE.SINGLE
        ? 'If you have a script with multiple query statements, please separate each statement with a semicolon.'
        : ''}
    </Typography>
  )

  return (
    <PopUp
      isLoading={isLoadingIdentitySpaces}
      variant="modal"
      title={values?.id ? 'Update Custom SQL' : 'Add Custom SQL'}
      popUpWidth="900px"
      showHeaderDivide
      showFooterDivider
      onClose={() => setCustomSqlModalData(null)}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={() => setCustomSqlModalData(null)}>
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={() => {
              submitForm()
            }}
            disabled={!isValid}
          >
            {values?.id ? 'Update' : 'Add'}
          </Button>
        </>
      }
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <FormInput required name="name" fullWidth label="Name" placeholder="Example: My Script for PII Data" />

        <Box display="flex" alignItems="center" gap={1.5}>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="label">Identifier</Typography>
            <DropListButton
              sx={{ width: 320 }}
              loading={isLoadingIdentitySpaces}
              value={selectedIdentitySpace}
              filterOptions={x => x}
              clearOnBlur
              noOptionsText="No Identities"
              getOptionDisabled={option => option?.code === values?.identitySpaceCode}
              onChange={(e, identity, reason) => {
                if (reason === 'clear') {
                  setFieldValue('identitySpaceCode', '')
                  setSelectedIdentitySpace(null)
                } else {
                  setFieldValue('identitySpaceCode', identity?.code)
                  setSelectedIdentitySpace(identity)
                }
              }}
              onInputChange={(e, inputVal) => {
                setInputValue(inputVal)
              }}
              options={identitySpaces}
              renderInput={params => (
                <TextInput
                  {...params}
                  error={touched?.identitySpaceCode && Boolean(errors?.identitySpaceCode)}
                  errorMessage={errors?.identitySpaceCode}
                  onBlur={() => {
                    setFieldTouched('identitySpaceCode', true)
                  }}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  placeholder="Select Identity"
                  variant="outlined"
                />
              )}
              renderOption={(props, identity) => {
                return (
                  <Box
                    component="li"
                    {...props}
                    key={identity?.code}
                    display="flex"
                    flexDirection="column"
                    gap={0.5}
                    py={2}
                  >
                    <Typography alignSelf="flex-start" variant="body">
                      {identity?.name}
                    </Typography>
                    <Typography alignSelf="flex-start" variant="footnote" color="darkDuskFaded.main">
                      {identity?.code}
                    </Typography>
                  </Box>
                )
              }}
              getOptionLabel={option => option?.name || ''}
              isOptionEqualToValue={(option, value) => option?.code === value?.code}
            />
            <Typography variant="smallBody" color="darkDuskFaded.main">
              {`Code: ${values?.identitySpaceCode || 'None'}`}
            </Typography>
          </Box>

          <InfoBox sx={{ marginTop: 0, flexBasis: '520px' }}>
            <Icon name={IconDictionary.OInfo} iconColor={theme.palette.heliotrope.main} width={24} height={24} />
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="body" color="heliotrope.main">
                Add a code below to the "where" filter of your script:
              </Typography>
              <Typography variant="label" color="heliotrope.main">{` {{${values.identitySpaceCode}}} `}</Typography>
            </Box>
          </InfoBox>
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          {values?.sqlQueries?.length ? (
            <>
              {values?.sqlQueries?.map((_, index) => {
                return (
                  <Box key={`sqlQueries${index}`}>
                    <FormTextArea
                      fullWidth
                      name={`sqlQueries[${index}]`}
                      label={`SQL Script ${index + 1}`}
                      placeholder={`Paste your SQL script here`}
                    />
                    {SqlInfo}
                  </Box>
                )
              })}
            </>
          ) : (
            <Box>
              <FormTextArea fullWidth name="sqlQuery" label="SQL Script" placeholder={`Paste your SQL script here`} />
              {SqlInfo}
            </Box>
          )}
        </Box>
      </Box>
    </PopUp>
  )
}
