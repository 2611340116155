import * as Yup from 'yup'
import { SCRIPT_TYPE } from 'pages/assetManager/scenario/details/constants'

export const getCustomSqlValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Required'),
    identitySpaceCode: Yup.string().required('Required'),
    sqlQuery: Yup.string()
      .test('', 'Required', (value, ctx: any) => {
        const [{ value: formValue }] = ctx?.from
        if (formValue.scriptType === SCRIPT_TYPE.SINGLE) return Boolean(value)
        return true
      })
      .test('', 'Must include identity', (value, ctx: any) => {
        const [{ value: formValue }] = ctx?.from
        if (formValue.scriptType === SCRIPT_TYPE.SINGLE)
          return value?.indexOf(`{{${formValue?.identitySpaceCode}}}`) !== -1
        return true
      }),
    sqlQueries: Yup.array().of(
      Yup.string()
        .test('', 'Required', (value, ctx: any) => {
          const [{ value: formValue }] = ctx?.from
          if (formValue.scriptType === SCRIPT_TYPE.MULTIPLE) return Boolean(value)
          return true
        })
        .test('', 'Must include identity', (value, ctx: any) => {
          const [{ value: formValue }] = ctx?.from
          if (formValue.scriptType === SCRIPT_TYPE.MULTIPLE)
            return value?.indexOf(`{{${formValue?.identitySpaceCode}}}`) !== -1
          return true
        }),
    ),
  })
