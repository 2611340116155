import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'

import { IImportContextParams } from 'pages/orchestration/workflows/edit/interfaces'

interface IGetApiCallInitialValuesParams {
  step: ICanvasStep
}

export const getImportContextInitialValues = (params: IGetApiCallInitialValuesParams): IImportContextParams => {
  const { step } = params

  return {
    code: step?.code || '',
    description: step?.description || '',
    databaseResourceId: step?.workflow?.params?.databaseResourceId || '',
    databaseResourceName: step?.workflow?.params?.databaseResourceName || '',
    databaseResourceTypeCode: step?.workflow?.params?.databaseResourceTypeCode || '',
    databaseResourceTechnology: step?.workflow?.params?.databaseResourceTechnology || '',
    identityType: step?.workflow?.params?.identityType || 1,
    identityFromName: step?.workflow?.params?.identityFromName || '',
    identityFromCode: step?.workflow?.params?.identityFromCode || '',
    identityToName: step?.workflow?.params?.identityToName || '',
    identityToCode: step?.workflow?.params?.identityToCode || '',
    query: step?.workflow?.params?.query || '',
    assignee: step?.workflow?.params?.assignee || '',
    dataSubjectVariableType: step?.workflow?.params?.dataSubjectVariableType || 0,
    dataSubjectVariableEnum: step?.workflow?.params?.dataSubjectVariableEnum || [],
  }
}
