import { InfoRow } from '@ketch-com/deck'
import {
  InstalledDataSystemRelationshipTypeV2DTO,
  InstalledDataSystemV2DTO,
  PutInstalledDataSystemV2ResponseBodyDTO,
} from '@ketch-com/figurehead'
import { useDataMapRegions } from 'api/dataMap/queries/useDataMapRegions'
import { useUsers } from 'api/users/queries/useUsers'
import { useTeams } from 'api/teams/queries/useTeams'
import TextAreaInfoRow from 'components/InfoRow/TextAreaInfoRow'
import { useState } from 'react'
import ChipContentRadioEditInfoRow from 'components/InfoRow/ChipContentRadioEditInfoRow'
import { DocumentOptionType, PersonalDataTypeOptions, TypeOptions } from 'pages/dataSystems/DataSystem/types'
import { useUpdateInstalledDataSystem } from 'api/dataSystems/mutations/useUpdateInstalledDataSystem'
import { showToast } from 'components/modals/AlertComponent'
import { AxiosResponse } from 'axios'
import { getFullNameUserShortDTO } from 'utils/helpers/getFullNameUserShortDTO'
import ChipContentOwnerDropdownEditInfoRow from 'pages/dataSystems/DataSystem/components/ChipContentOwnerDropdownEditInfoRow'
import { camelCaseToTitleWords } from 'utils/text/stringUtils'
import ContactEditInfoRow from 'pages/dataSystems/DataSystem/components/ContactEditInfoRow'
import ChipContentTeamDropdownEditInfoRow from 'pages/dataSystems/DataSystem/components/ChipContentTeamDropdownEditInfoRow'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import ChipContentDocDropdownEditInfoRow from 'pages/dataSystems/DataSystem/components/ChipContentDocDropdownEditInfoRow'
import { usePolicyDocuments } from 'api/policyDocuments/queries/usePolicyDocuments'
import DataResidencyWithEdit from 'pages/dataSystems/DataSystem/components/DataResidencyWithEdit'

type Props = {
  appDescriptor: InstalledDataSystemV2DTO
}

export default function PredeterminedFields({ appDescriptor }: Props) {
  const [updatedDataSystem, setUpdatedDataSystem] = useState<InstalledDataSystemV2DTO>(appDescriptor)

  const regionResponse = useDataMapRegions({})
  const { data: allUsers } = useUsers({ params: { active: true } })
  const { data: teams } = useTeams({})
  const { data: documentsList } = usePolicyDocuments({})

  const userOwnerIds = updatedDataSystem.ownerUserIds || []
  const teamOwnerIds = updatedDataSystem.ownerTeamIds || []
  const allUserOptions = allUsers.map(u => ({
    ...u,
    code: u.ID,
    name: getFullNameUserShortDTO(u),
    value: u.ID,
    id: u.ID,
  }))
  const userOwners = allUserOptions.filter(u => userOwnerIds.includes(u.ID))
  const allTeamOptions = teams?.teams?.map(i => ({ id: i.id || '', name: i.name || '', value: i.id || '' })) || []
  const teamsUsing = allTeamOptions.filter(t => teamOwnerIds.includes(t.id || '')) || []

  const allDocuments: DocumentOptionType[] = documentsList?.map(i => ({ ...i, value: i.code })) || []
  const docsUsed =
    updatedDataSystem.documents?.map(i => {
      const defaultVal = i.code || ''
      const docObj = allDocuments.find(j => defaultVal === j.code)

      if (!docObj) {
        return { name: defaultVal, value: defaultVal }
      } else {
        return docObj
      }
    }) || []

  const { mutateAsync: handleUpdateInstalledDataSystem } = useUpdateInstalledDataSystem({})

  function handleUpdates(updateSubset: Partial<InstalledDataSystemV2DTO>) {
    const updatedObject = { ...updatedDataSystem, ...updateSubset }
    setUpdatedDataSystem(updatedObject)
    const updatingKeys = Object.keys(updateSubset).map(camelCaseToTitleWords).join('; ')
    handleUpdateInstalledDataSystem({
      params: {
        id: updatedObject.id!,
        formData: { installedDataSystem: updatedObject },
      },
    })
      .then((r: AxiosResponse<PutInstalledDataSystemV2ResponseBodyDTO>) => {
        showToast({ content: `Updated ${updatingKeys}`, type: 'success' })
        setUpdatedDataSystem(r.data.installedDataSystem!)
      })
      .catch(e => {
        showToast({ content: `Failed to save updates to ${updatingKeys} `, type: 'error' })
      })
  }

  return (
    <>
      <TextAreaInfoRow
        title={'Description'}
        value={updatedDataSystem.description}
        setValue={t => handleUpdates({ description: t })}
      />
      <ChipContentOwnerDropdownEditInfoRow
        title="System Owner"
        options={allUserOptions}
        values={userOwners}
        setValues={(values: string[] | undefined) => handleUpdates({ ownerUserIds: values })}
      />
      <ChipContentTeamDropdownEditInfoRow
        title="Used By Teams"
        options={allTeamOptions}
        values={teamsUsing}
        setValues={(values: string[] | undefined) => handleUpdates({ ownerTeamIds: values })}
      />
      <ContactEditInfoRow
        title="System Contact"
        value={updatedDataSystem.contact}
        setValue={value => handleUpdates({ contact: value })}
      />
      <ChipContentRadioEditInfoRow
        allowDelete={false}
        title="System Type"
        options={TypeOptions}
        value={TypeOptions[updatedDataSystem.type!].value}
        setValue={value => handleUpdates({ type: value as InstalledDataSystemRelationshipTypeV2DTO })}
      />
      <ChipContentRadioEditInfoRow
        allowDelete={false}
        title="Personal Data Type"
        options={PersonalDataTypeOptions}
        value={PersonalDataTypeOptions[updatedDataSystem.personalDataType!].value}
        setValue={value => handleUpdates({ personalDataType: value as InstalledDataSystemRelationshipTypeV2DTO })}
      />
      <ChipContentDocDropdownEditInfoRow
        title={'Documents'}
        allDocuments={allDocuments}
        setValues={value => handleUpdates({ documents: value })}
        values={docsUsed as DocumentOptionType[]}
      />
      <DataResidencyWithEdit
        title="Data Residencies"
        setValues={values => handleUpdates({ residencies: values })}
        values={updatedDataSystem.residencies || []}
        regionResponse={regionResponse}
      />
      <InfoRow title="Last Updated">
        <FormattedDateRenderer date={updatedDataSystem.updatedAt} />
      </InfoRow>
    </>
  )
}
