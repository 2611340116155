import { styled } from '@mui/styles'
import Box from '@mui/material/Box'
import { Button, InlineEdit, InlineEditSize, TabSegmented, TabSegmentedGroup, theme } from '@ketch-com/deck'
import React, { useContext } from 'react'
import { ExperienceUpsertContext } from '../context/ExperienceUpsertContext'
import { Typography } from '@mui/material'
import { RoutesManager } from 'utils/routing/routesManager'
import { ConsentExperienceTypeLabels } from '../utils/labels'
import { getIn, useFormikContext } from 'formik'
import { experienceUpsertFormKeys } from '../utils/useExperienceValidationSchema'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { ConsentExperienceType } from 'interfaces/experiences-v2/consentExperienceType'
import { ExperienceServerDropdownButton } from './upsertExperienceSidebar/components/ExperienceServerDropdownButton'
import { PreferenceCenterPage } from '@ketch-sdk/ketch-types'
import { getPreferencePageLabel } from '../utils/utils'
import { defaultThemeV3DTO } from 'pages/consentAndRights/themes-v3/upsert/utils/defaults'

type OptionType = { label?: string; value?: string }

const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  minHeight: 62,
  maxHeight: 80,
  padding: '12px 32px',
  marginTop: -24,
  background: theme.palette.white.main,
  boxShadow: '0px 4px 10px 0px rgba(7, 26, 36, 0.10);',
  zIndex: 'auto',
}))

const RightHeaderBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  gap: 32,
})

const ThemeSelectionBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
  marginLeft: 12,
})

const ButtonsBox = styled(Box)({
  display: 'flex',
  gap: 12,
})

export const UpsertExperienceInlineLayoutHeader = () => {
  const { submitForm, setFieldValue, errors, values } = useFormikContext<ExperienceV2DTO>()
  const omitPages = [PreferenceCenterPage.PrivacyPolicy]

  const {
    experienceType,
    consentExperienceType,
    setConsentExperienceType,
    navigate,
    id,
    setExperienceBuilderSection,
    selectedThemeOption,
    setSelectedThemeOption,
    themeOptions,
    setThemeConfig,
    preferencePage,
    setPreferencePage,
    entitledPreferencePages,
  } = useContext(ExperienceUpsertContext)

  const handleConsentExperienceTypeChange = (event: React.MouseEvent<HTMLElement>, newType: ConsentExperienceType) => {
    if (newType !== null) {
      // Update UI
      setConsentExperienceType(newType)
      // Close current sidebar form
      setExperienceBuilderSection(undefined)
    }
  }

  const handleCancelClick = () => {
    if (id) {
      navigate(RoutesManager.deployment.experiencesV2.view.preview.root.getURL({ id }))
    } else {
      navigate(RoutesManager.deployment.experiencesV2.root.getURL())
    }
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(experienceUpsertFormKeys.experienceName, e.target.value)
  }

  const handleThemeOptionChange = (themeOption: OptionType) => {
    setSelectedThemeOption(themeOption)
    if (themeOption.value === 'default') {
      setThemeConfig(defaultThemeV3DTO)
    }
  }

  const handlePreferencePageChange = (page: PreferenceCenterPage) => {
    setPreferencePage(page)
    setExperienceBuilderSection(undefined)
  }

  return (
    <HeaderBox>
      <InlineEdit
        placeholder="Experience Name"
        size={InlineEditSize.large}
        onChange={handleNameChange}
        validationText={getIn(errors, experienceUpsertFormKeys.experienceName)}
        onFocus={() => setExperienceBuilderSection(undefined)}
        value={getIn(values, experienceUpsertFormKeys.experienceName)}
        maxLength={55}
      />
      <RightHeaderBox>
        {/* Theme picker */}
        <ThemeSelectionBox>
          <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
            Preview Theme:
          </Typography>
          <ExperienceServerDropdownButton
            items={themeOptions}
            value={selectedThemeOption}
            renderItem={theme => theme.label}
            onChange={handleThemeOptionChange}
            sx={{ minWidth: '138px' }}
          />
        </ThemeSelectionBox>

        {experienceType === ExperienceType.Consent ? (
          // Consent experience type picker
          <TabSegmentedGroup
            value={consentExperienceType}
            onChange={handleConsentExperienceTypeChange}
            exclusive
            size={'small'}
          >
            {Object.values(ConsentExperienceType).map(expType => (
              <TabSegmented value={expType} key={expType}>
                <Typography
                  // Different text variant for selected theme
                  variant={values.type === expType ? 'smallLabel' : 'smallBody'}
                >
                  {ConsentExperienceTypeLabels[expType]}
                </Typography>
              </TabSegmented>
            ))}
          </TabSegmentedGroup>
        ) : (
          // Preference experience page picker
          <ThemeSelectionBox>
            <Typography variant="label" color={theme.palette.darkDuskFaded.main}>
              Page:
            </Typography>
            <ExperienceServerDropdownButton
              items={entitledPreferencePages.filter(p => !omitPages.includes(p))}
              borderBottomItems={[PreferenceCenterPage.RequestsHome]}
              value={preferencePage}
              renderItem={(page, index) => getPreferencePageLabel(page, index)}
              onChange={handlePreferencePageChange}
            />
          </ThemeSelectionBox>
        )}

        {/* Action buttons */}
        <ButtonsBox>
          <Button color={'secondary'} onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button color={'primary'} onClick={submitForm} sx={{ minWidth: '95px' }}>
            Save & Exit
          </Button>
        </ButtonsBox>
      </RightHeaderBox>
    </HeaderBox>
  )
}
