import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { Button } from '@ketch-com/deck'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { BillingDetails } from 'components/planAndBilling/components/billingDetails/BillingDetails'
import { PlanDetails } from 'components/planAndBilling/components/planDetails/PlanDetails'
import { UniqueUsers } from 'components/planAndBilling/components/uniqueUsers/UniqueUsers'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
import { Spinner } from '@ketch-com/deck'

const breadcrumbs = [
  {
    title: 'Settings',
    link: RoutesManager.settings.root.getURL(),
  },
  { title: 'Plan & Billing', link: RoutesManager.settings.planAndBilling.root.getURL() },
]

export const PlanAndBilling = () => {
  const { isReady } = useContext(PlanAndBillingContext)

  if (!isReady)
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" p={5}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h2">Plan & Billing</Typography>
        <Button size="medium" color="secondary">
          Manage Billing Information
        </Button>
      </Box>
      <Box mb={4}>
        <BillingDetails />
      </Box>
      <Box mb={2}>
        <UniqueUsers />
      </Box>

      <PlanDetails />
    </>
  )
}
