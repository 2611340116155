import { InfoRow, InfoRowProps } from '@ketch-com/deck'
import { useEffect, useState } from 'react'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { OwnerSearchAutocomplete } from 'pages/dataSystems/DataSystem/components/OwnerSearchAutocomplete'
import { UserAndTeamOptionType } from 'pages/dataSystems/DataSystem/types'
import { UserChip } from 'components/chip/UserChip'
import { AvatarEntity } from 'components/avatar/UserAvatar'

interface ChipContentOwnerDropdownEditInfoRowProps extends InfoRowProps {
  setValues: (selectedChips?: string[]) => void
  values: UserAndTeamOptionType[]
  options: UserAndTeamOptionType[]
}

export default function ChipContentOwnerDropdownEditInfoRow({
  options,
  setValues,
  values,
  ...rest
}: ChipContentOwnerDropdownEditInfoRowProps) {
  const [localValues, setLocalValues] = useState<UserAndTeamOptionType[]>([])
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    setLocalValues(values)
  }, [values])

  return (
    <InfoRow
      {...rest}
      isEditable
      isEditing={isEditing}
      onEditChange={() => {
        setIsEditing(true)
      }}
      onAcceptChange={() => {
        setIsEditing(false)
        setValues(localValues.map(i => i.id))
      }}
      onCancelChange={() => {
        setIsEditing(false)
        setLocalValues(values)
      }}
      isEmpty={localValues.length === 0}
    >
      {isEditing ? (
        <OwnerSearchAutocomplete
          options={options}
          values={values}
          updateValues={v => setLocalValues(v as UserAndTeamOptionType[])}
        />
      ) : localValues.length > 0 ? (
        localValues.map(v => <UserChip key={v.id} user={v as AvatarEntity} />)
      ) : (
        <EmptyValueRenderer />
      )}
    </InfoRow>
  )
}
