import { Box } from '@mui/material'
import { Chip } from 'components/ui-kit/chip/Chip'
import { LinkChip } from 'components/ui-kit/chip/LinkChip'
import { GreyBox } from '../../components/layout/GreyBox'
import { Text } from 'components/ui-kit/typography/Text'
import { useState } from 'react'
import { Button } from '@ketch-com/deck'
import { ThirdPartyRisk } from '../../__mocks__/thirdPartyRisk'
import { formatNumberWithCommas } from 'pages/insights/utils'
import { DataRiskDetailLayoutClasses } from '../../components/layout/DataRiskDetailLayout'

type Props = {
  record: ThirdPartyRisk
}

type AboutProps = {
  children?: React.ReactNode
}
const AboutText: React.FC<AboutProps> = ({ children }) => (
  <Text component="div" color="grey" weight={600} size={12} sx={{ mb: 0.75 }}>
    {children}
  </Text>
)

export const ThirdPartyRiskLowerContent: React.FC<Props> = ({ record }) => {
  const { purposes, about, personalDataTypes, dataCategories } = record
  const [showMore, setShowMore] = useState(false)

  const categoriesToShow = (showMore ? dataCategories : dataCategories?.slice(0, 9)) || []

  return (
    <>
      <Box flexBasis="50%">
        <GreyBox heading="Purposes">
          {purposes?.map((purpose, index) => (
            <Box key={index}>
              <LinkChip className={DataRiskDetailLayoutClasses.chip} key={purpose.code} text={purpose.name} />
            </Box>
          ))}
        </GreyBox>
        <GreyBox heading="Data">
          <Text component="div" color="grey" sx={{ mb: 1.5 }}>
            Personal Data Types
          </Text>
          <Box mb={3.5}>
            {personalDataTypes?.map((dataType, index) => (
              <Chip className={DataRiskDetailLayoutClasses.chip} key={index}>
                {dataType}
              </Chip>
            ))}
          </Box>
          <Text component="div" color="grey" sx={{ mb: 1.5 }}>
            Classifications
          </Text>
          <Box>
            {categoriesToShow.map((category, index) => (
              <Chip className={DataRiskDetailLayoutClasses.chip} key={index}>
                {category}
              </Chip>
            ))}
          </Box>
          {(dataCategories?.length || 0) - 10 > 0 && (
            <Text sx={{ mt: 1.5 }} component="div" color="royalBlue" onClick={() => setShowMore(!showMore)}>
              {showMore ? 'Show Less' : `View ${(dataCategories?.length || 0) - 10} More`}
            </Text>
          )}
        </GreyBox>
      </Box>
      <GreyBox flexBasis="50%" heading="About">
        <AboutText>Name</AboutText>
        <Text mb={2.5} component="div">
          {about?.companyName}
        </Text>
        <AboutText>Legal Name</AboutText>
        <Text mb={2.5} component="div">
          {about?.legalName}
        </Text>
        <AboutText>Type</AboutText>
        <Text mb={2.5} component="div">
          {about?.companyType}
        </Text>
        <AboutText>Employees</AboutText>
        <Text mb={2.5} component="div">
          {formatNumberWithCommas(about?.employees)}
        </Text>
        <AboutText>Industry</AboutText>
        <Text mb={2.5} component="div">
          {about?.industry}
        </Text>
        <AboutText>Sector</AboutText>
        <Text mb={2.5} component="div">
          {about?.sector}
        </Text>
        <AboutText>LinkedIn</AboutText>
        <Text mb={3.5} color="royalBlue" component="div" onClick={() => {}}>
          {about?.linkedIn}
        </Text>
        <Button color="tertiary">View More</Button>
      </GreyBox>
    </>
  )
}
