import React, { useMemo, useState, useContext } from 'react'
import { RoutesManager } from 'utils/routing/routesManager'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { useNavigate } from 'react-router-dom'
import { HeaderSectionWrapper } from './HeaderSectionWrapper'
import { ActionSheetItem, ActionSheetWrapper, Button, CustomIconName, Icon, theme } from '@ketch-com/deck'
import { Tooltip, Typography } from '@mui/material'
import { HELP_CENTER_TITLE, HELP_CENTER_URL } from 'pages/settings/Settings'
import { isFunction } from 'lodash'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
import { OrgPlanIDs } from 'components/planAndBilling/common/constants/planAndBilling'

interface SettingsMenuOption {
  id: string
  title?: string
  route?: string
  isSubsectionTitle?: boolean
  isDivider?: boolean
  isHidden?: boolean
  icon?: CustomIconName
  permissions?: PERMISSIONS[]
  onClick?: () => void
}

export const HeaderSettingsLink: React.FC = () => {
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()

  const { orgPlan } = useContext(PlanAndBillingContext)
  const isOrgOnFreePlan = orgPlan?.planProductID === OrgPlanIDs?.free
  const isOrgOnStarterPlan = orgPlan?.planProductID === OrgPlanIDs?.starter
  const isOrgOnPlgPlan = isOrgOnFreePlan || isOrgOnStarterPlan

  // Action sheet state
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const isOnSettingsPage = window.location.pathname.includes('/settings')

  const options: SettingsMenuOption[] = useMemo(
    () => [
      {
        id: 'General Settings',
        title: 'General Settings',
        isSubsectionTitle: true,
      },
      {
        id: 'Account',
        title: 'Account',
        route: RoutesManager.settings.profile.root.getURL(),
        permissions: [],
      },
      {
        id: 'Identifiers',
        title: 'Identifiers',
        route: RoutesManager.settings.identitySpaces.root.getURL(),
        permissions: [PERMISSIONS.IDSP_READ],
      },
      {
        id: 'Languages',
        title: 'Languages',
        route: RoutesManager.deployment.languages.root.getURL(),
        permissions: [PERMISSIONS.TRANSLATIONS_READ],
      },
      {
        id: 'post-languages-divider',
        isDivider: true,
        permissions: [PERMISSIONS.IDSP_READ, PERMISSIONS.TRANSLATIONS_READ],
      },
      {
        id: 'Workspace Settings',
        title: 'Workspace Settings',
        isSubsectionTitle: true,
        permissions: [PERMISSIONS.ORG_ADMIN, PERMISSIONS.USER_ADMIN, PERMISSIONS.TEAM_READ],
      },
      {
        id: 'Organization',
        title: 'Organization',
        route: RoutesManager.settings.organization.root.getURL(),
        permissions: [PERMISSIONS.ORG_ADMIN],
      },
      {
        id: 'Plan & Billing',
        title: 'Plan & Billing',
        route: RoutesManager.settings.planAndBilling.root.getURL(),
        isHidden: !isRelease(Release.PlanAndBilling) || !isOrgOnPlgPlan,
        permissions: [PERMISSIONS.ORG_ADMIN],
      },
      {
        id: 'Users',
        title: 'Users',
        route: RoutesManager.settings.users.root.getURL(),
        permissions: [PERMISSIONS.USER_ADMIN],
      },
      {
        id: 'Teams',
        title: 'Teams',
        route: RoutesManager.settings.teams.root.getURL(),
        permissions: [PERMISSIONS.TEAM_READ],
      },
      {
        id: 'post-teams-divider',
        isDivider: true,
        permissions: [PERMISSIONS.ORG_ADMIN, PERMISSIONS.USER_ADMIN, PERMISSIONS.TEAM_READ],
      },
      {
        id: 'DSR Settings',
        title: 'DSR Settings',
        isSubsectionTitle: true,
        permissions: [
          PERMISSIONS.CUSTOM_NAME_RIGHTS_READ,
          PERMISSIONS.CONTEXT_VARIABLE_READ,
          PERMISSIONS.SUBJECTTYPE_READ,
        ],
      },
      {
        id: 'Right Names',
        title: 'Right Names',
        route: RoutesManager.settings.customRightName.root.getURL(),
        permissions: [PERMISSIONS.CUSTOM_NAME_RIGHTS_READ],
      },
      {
        id: 'Variables',
        title: 'Variables',
        route: RoutesManager.settings.contextVariables.root.getURL(),
        permissions: [PERMISSIONS.CONTEXT_VARIABLE_READ],
      },
      {
        id: 'Subject Types',
        title: 'Subject Types',
        route: RoutesManager.settings.dataSubjectTypes.root.getURL(),
        permissions: [PERMISSIONS.SUBJECTTYPE_READ],
      },
      {
        id: 'post-subject-types-divider',
        isDivider: true,
        permissions: [
          PERMISSIONS.CUSTOM_NAME_RIGHTS_READ,
          PERMISSIONS.CONTEXT_VARIABLE_READ,
          PERMISSIONS.SUBJECTTYPE_READ,
        ],
      },
      {
        id: HELP_CENTER_TITLE,
        title: HELP_CENTER_TITLE,
        icon: 'OShare',
        onClick: () => window.open(HELP_CENTER_URL, '_blank'),
      },
    ],
    [isOrgOnPlgPlan],
  )

  const displayedOptions = useMemo(
    () => options.filter(option => !option.permissions || isPermitted(option.permissions)),
    [isPermitted, options],
  )

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (route?: string, onClick?: () => void) => {
    if (isFunction(onClick)) {
      onClick()
    } else if (route) {
      navigate(route)
      setAnchorEl(null)
    }
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <HeaderSectionWrapper selected={isOnSettingsPage} sx={{ mr: '8px' }}>
      {/* Settings routes menu */}
      <ActionSheetWrapper
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          mt: '20px',
          '& .MuiMenu-paper': {
            width: '210px',
            maxWidth: '210px',
          },
        }}
      >
        {displayedOptions.map(({ id, title, route, isSubsectionTitle, isDivider, isHidden, icon, onClick }) => {
          const selected = !!route && window.location.pathname.includes(route)

          if (isHidden) return null

          return (
            <ActionSheetItem
              key={id}
              subSectionTitle={isSubsectionTitle}
              divider={isDivider}
              onClick={() => handleMenuItemClick(route, onClick)}
              icon={icon}
              selected={selected}
              showSelectedIcon={false}
              sx={{
                '& .MuiSvgIcon-root': {
                  color: theme.palette.Black.o56,
                  fill: theme.palette.Black.o56,
                },
              }}
            >
              {isSubsectionTitle ? <Typography variant={'smallLabel'}>{title}</Typography> : title}
            </ActionSheetItem>
          )
        })}
      </ActionSheetWrapper>

      {/* Settings icon button */}
      <Tooltip title={<Typography variant="label">Settings</Typography>}>
        <span>
          <Button
            id="settings-button"
            variant={'iconCustomRounded'}
            color={'tertiary'}
            onClick={handleButtonClick}
            sx={{
              width: '28px',
              height: '28px',
              minWidth: '28px',
              backgroundColor: isOnSettingsPage ? `${theme.palette.Highlight.PrimaryDark} !important` : undefined,
            }}
          >
            <Icon name={'OCog'} iconColor={isOnSettingsPage ? theme.palette.White.o100 : theme.palette.Black.o100} />
          </Button>
        </span>
      </Tooltip>
    </HeaderSectionWrapper>
  )
}
