import { ApplicationPlatform } from 'interfaces/applications/applicationPlatform'
import { ApplicationEnvironment } from 'interfaces/applications/applicationEnvironment'
import { ApplicationFormValues } from 'interfaces/applications/ApplicationFormValues'

export const getCreateApplicationInitialValues = (): ApplicationFormValues => {
  return {
    code: '',
    name: '',
    platform: ApplicationPlatform.WEB,
    defaultPolicyScopeVariable: '',
    managedIdentity: true,
    dataSubjectTypeCodes: [],
    customIdentitySpaces: false,
    customPolicyScope: false,
    proxy: '',
    supportProxy: false,
    environments: [
      {
        code: ApplicationEnvironment.PRODUCTION,
        domain: '',
        pattern: '',
        themeID: '',
      },
    ],
    identitySpaces: [],
    accessibilityButton: {
      enabled: true,
      color: '#000000',
      icon: '',
      position: 'bottom-right',
    },
    privacyPolicyCode: '',
    termsOfServiceCode: '',
  }
}
