import React, { useState } from 'react'
import { Banner, Button, theme } from '@ketch-com/deck'
import { AlertColor } from '@mui/material'
import { memo } from 'react'
import { OrgPlanAndBillingBannerVariants } from 'components/planAndBilling/common/constants/planAndBilling'
import { Box } from '@mui/material'
import moment from 'moment'
import { Plan } from '@ketch-com/harbormaster/dist/organization/plans.schemas'
import { useIsCurrentUserOrgAdmin } from 'utils/hooks/useIsCurrentUserOrgAdmin'
import { PlanDetailsModal } from 'components/planAndBilling/components/uniqueUsers/PlanDetailsModal'
import { OrgPlanIDs } from 'components/planAndBilling/common/constants/planAndBilling'
import { useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

type PlanAndBillingBannerProps = {
  variant: OrgPlanAndBillingBannerVariants
  orgPlan: Plan
  isGlobal?: boolean
}

type PlanAndBillingBannerDetails = {
  title: string
  message: string
  actionCopy: string
  actionCallback: () => void
  detailsCopy?: string
  detailsCallback?: () => void
  color: string
  icon?: string
}

export const PlanAndBillingBanners: React.FC<PlanAndBillingBannerProps> = memo(({ variant, orgPlan, isGlobal }) => {
  const navigate = useNavigate()
  const [shouldDisplayPlanDetailsModal, setShouldDisplayPlanDetailsModal] = useState(false)
  const isCurrentUserOrgAdmin = useIsCurrentUserOrgAdmin()
  const isOrgOnFreePlan = orgPlan?.planProductID === OrgPlanIDs?.free
  const isOrgOnStarterPlan = orgPlan?.planProductID === OrgPlanIDs?.starter
  const planGraceStartDate = moment.unix(orgPlan?.planGracePeriod?.start || 0)
  const planGraceEndDate = moment.unix(orgPlan?.planGracePeriod?.end || 0)
  const billingPaymentEndDate = moment.unix(orgPlan?.billingPaymentPeriod?.end || 0)
  const currentDate = moment()
  const planGraceDays = planGraceEndDate.diff(planGraceStartDate, 'days') + 1
  const planGraceRemainingDays = planGraceEndDate.diff(currentDate, 'days')
  const billingPaymentRemainingDays = billingPaymentEndDate.diff(currentDate, 'days')

  const freePlanInGrace = {
    title: 'Plan Limit Reached',
    message: `You have reached the unique user limit of your free plan. Upgrade your plan within ${planGraceRemainingDays} days to prevent any service interruptions.`,
    actionCopy: 'Upgrade',
    actionCallback: () => {
      setShouldDisplayPlanDetailsModal(true)
    },
    detailsCopy: 'View Details',
    detailsCallback: () => {
      navigate(RoutesManager.settings.planAndBilling.root.getURL())
    },
    color: 'error',
  }

  const freePlanSuspended = {
    title: 'Upgrade Required to Resume',
    message: `Over ${planGraceDays} days have elapsed since you reached the unique user limit of your free plan. Your implementation is now paused, but will resume once you upgrade.`,
    actionCopy: 'Upgrade',
    actionCallback: () => {
      setShouldDisplayPlanDetailsModal(true)
    },
    detailsCopy: 'View Details',
    detailsCallback: () => {
      navigate(RoutesManager.settings.planAndBilling.root.getURL())
    },
    color: 'error',
  }

  const starterPlanInGrace = {
    title: 'Plan Limit Reached',
    message: `You have reached the unique user limit for all Ketch self-service plans. A member of our sales team will be in touch shortly, or you can book a meeting directly below. Please begin your upgrade within ${planGraceDays} days to prevent any service interruptions.`,
    actionCopy: 'Upgrade',
    actionCallback: () => {
      setShouldDisplayPlanDetailsModal(true)
    },
    detailsCopy: 'View Details',
    detailsCallback: () => {
      navigate(RoutesManager.settings.planAndBilling.root.getURL())
    },
    color: 'error',
  }

  const starterPlanSuspended = {
    title: 'Upgrade Required to Resume',
    message: `Your implementation is now paused as ${planGraceDays} days have elapsed since you reached the unique user limit of Ketch self-service plans. Service will resume immediately after you have upgraded with one of our sales representatives.`,
    actionCopy: 'Upgrade',
    actionCallback: () => {
      setShouldDisplayPlanDetailsModal(true)
    },
    detailsCopy: 'View Details',
    detailsCallback: () => {
      navigate(RoutesManager.settings.planAndBilling.root.getURL())
    },
    color: 'error',
  }

  const billingInGrace = {
    title: 'Payment Failed',
    message: `We were unable to process you last payment. Please update your billing information within ${billingPaymentRemainingDays} days to prevent any service interruptions.`,
    actionCopy: 'Update Billing Details',
    actionCallback: () => {},
    color: 'warning',
    icon: 'FFileError',
  }

  const billingMissed = {
    title: 'Payment Failed',
    message:
      "We couldn't process your payment. Your implementation is paused and will resume once you update your payment information.",
    actionCopy: 'Update Billing Information',
    actionCallback: () => {},
    color: 'error',
    icon: 'FFileError',
  }

  let banner = {} as PlanAndBillingBannerDetails

  switch (variant) {
    case OrgPlanAndBillingBannerVariants.freePlanInGrace:
      banner = freePlanInGrace
      break

    case OrgPlanAndBillingBannerVariants.freePlanSuspended:
      banner = freePlanSuspended
      break

    case OrgPlanAndBillingBannerVariants.starterPlanInGrace:
      banner = starterPlanInGrace
      break

    case OrgPlanAndBillingBannerVariants.starterPlanSuspended:
      banner = starterPlanSuspended
      break

    case OrgPlanAndBillingBannerVariants.billingInGrace:
      banner = billingInGrace
      break

    case OrgPlanAndBillingBannerVariants.billingMissed:
      banner = billingMissed
      break
  }

  return (
    <Box my={2}>
      <Banner
        sx={{
          borderRadius: isGlobal ? 0 : 1.5,
        }}
        icon={banner.icon}
        color={banner.color as AlertColor}
        title={banner.title}
        action={
          <Box display="flex" alignItems="center" gap={2}>
            {isGlobal && isCurrentUserOrgAdmin && banner?.detailsCallback ? (
              <Button
                variant="link"
                style={{ color: theme.palette.Error.PrimaryDark, whiteSpace: 'nowrap' }}
                onClick={banner.detailsCallback}
              >
                {banner.detailsCopy}
              </Button>
            ) : null}

            <Button
              color="primary"
              style={{ color: theme.palette.Text.PrimaryLight, whiteSpace: 'nowrap' }}
              onClick={banner.actionCallback}
            >
              {banner.actionCopy}
            </Button>
          </Box>
        }
      >
        {banner.message}
      </Banner>
      <PlanDetailsModal
        isOrgOnFreePlan={isOrgOnFreePlan}
        isOrgOnStarterPlan={isOrgOnStarterPlan}
        isOpen={shouldDisplayPlanDetailsModal}
        onClose={() => setShouldDisplayPlanDetailsModal(false)}
      />
    </Box>
  )
})
