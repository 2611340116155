import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ViewLayout } from '@ketch-com/deck'
import { RoutesManager } from 'utils/routing/routesManager'
import { withTrackersSettingsContext } from 'pages/policyCenter/trackers/context'
import { CategoriesMapping } from 'pages/policyCenter/trackers/trackersSettings/components'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {}

export const TrackersSettingsWithContext: React.FC<Props> = () => {
  const navigate = useNavigate()

  return (
    <>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          { title: 'Privacy Program', link: RoutesManager.policyCenter.root.getURL() },
          { title: 'Trackers', link: RoutesManager.policyCenter.trackers.list.approvedTrackers.root.getURL() },
          { title: 'Settings' },
        ]}
      />
      <ViewLayout
        contentGroupProps={{
          showBackButton: true,
          title: 'Trackers Settings',
          propsBackButton: {
            onClick: () => {
              navigate(RoutesManager.policyCenter.trackers.list.approvedTrackers.root.getURL())
            },
          },
        }}
      >
        <CategoriesMapping />
      </ViewLayout>
    </>
  )
}

export const TrackersSettings = withTrackersSettingsContext(TrackersSettingsWithContext)
