import { Box } from '@mui/material'
import { InputBase } from '@mui/material'
import { useFormikContext } from 'formik'
import { ChatData } from 'pages/policyCenter/processingActivities/view/Comment/interfaces'
import { getConnectionStateText } from 'pages/policyCenter/processingActivities/view/Comment/utils'
import { Button, Icon, theme } from '@ketch-com/deck'

export const Compose = () => {
  const { isSubmitting, handleSubmit, handleChange, values } = useFormikContext<ChatData>()
  const connectionStateText = getConnectionStateText(values.twilioConnectionState)

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      border={({ palette }) => {
        if (values?.message) return `1px solid ${palette.sphere.main}`
        return `1px solid ${palette.iron.main}`
      }}
      borderRadius="5px"
      p={1}
    >
      <Box width={288} pl={1}>
        <InputBase
          autoFocus
          disabled={isSubmitting || Boolean(connectionStateText) || !values.twilioActiveConversation}
          multiline
          maxRows={3}
          fullWidth
          name="message"
          value={values.message}
          onChange={handleChange}
          placeholder="Add a comment"
          sx={{
            ...theme.typography.body,
          }}
        />
      </Box>
      <Box alignSelf="center">
        <Button
          disabled={
            !values.message?.length || isSubmitting || Boolean(connectionStateText) || !values.twilioActiveConversation
          }
          fullWidth={false}
          pending={isSubmitting}
          size="medium"
          variant="iconSubtle"
          onClick={() => {
            handleSubmit()
          }}
        >
          <Icon name="OSend" iconColor={theme.palette.sphere.main} />
        </Button>
      </Box>
    </Box>
  )
}
