import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { FormattedNumberRenderer } from 'components/renderers/FormattedNumberRenderer'
import { Box, Typography } from '@mui/material'
import { PurposePermitStatsDTO } from '@ketch-com/figurehead'
import { LinearProgress, Chip, TableCell } from '@ketch-com/deck'

import { getConsentRequiredColor, getConsentRequiredText } from '.'

export const allowanceBreakdownPerPurposeColumns = (translate: (a: string) => string): GridColDef[] => [
  {
    field: 'name',
    flex: 1,
    headerName: translate('Purpose'),
    sortable: false,
    renderCell: ({
      row: { name, consentRequiredCount, jurisdictionCount, code },
    }: GridRenderCellParams<PurposePermitStatsDTO>) => {
      const displayName = name?.includes('DISABLED')
        ? name?.replace('DISABLED', '')
        : name?.includes('DELETED')
        ? name?.replace('DELETED', '')
        : name || 'DELETED'
      const isDeleted = name?.includes('DELETED')
      const isDisabled = name?.includes('DISABLED')
      return (
        <TableCell sx={{ py: '5px', height: '52px' }}>
          <Box display="flex" flexDirection="column">
            <Box>
              <Typography variant="label">{displayName}</Typography>
            </Box>

            <Typography
              variant="footnote"
              fontWeight="500"
              color={`${getConsentRequiredColor({ consentRequiredCount, totalCount: jurisdictionCount })}.main`}
            >
              {getConsentRequiredText({
                consentRequiredCount,
                totalCount: jurisdictionCount,
                entity: 'jurisdictions',
                translate: translate,
              })}
            </Typography>
          </Box>
          {isDeleted && (
            <Box display="flex" ml={2}>
              <Chip size="small" label={translate('Deleted')} />
            </Box>
          )}
          {isDisabled && (
            <Box display="flex" ml={2}>
              <Chip size="small" label="Disabled" />
            </Box>
          )}
        </TableCell>
      )
    },
  },

  {
    field: 'allowed',
    headerName: translate('Allowed'),
    width: 150,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<PurposePermitStatsDTO & { allowed: number; allowanceRate: number }>) => (
      <TableCell sx={{ py: '5px', height: '52px' }}>
        <FormattedNumberRenderer value={row.allowed} />
      </TableCell>
    ),
  },
  {
    field: 'allowanceRate',
    headerName: translate('Allowance Rate'),
    width: 180,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<PurposePermitStatsDTO & { allowed: number; allowanceRate: number }>) => {
      return (
        <TableCell sx={{ py: '5px', height: '52px' }}>
          <LinearProgress value={row.allowanceRate} label={`${row.allowanceRate.toFixed(1)} %`} />
        </TableCell>
      )
    },
  },
]
