import { ApplianceWork } from '@ketch-com/windlass/dist/appliance/appliances'
import { getInputSubjectName } from './getInputSubjectName'

export const createInputSubjectList = (data?: ApplianceWork[]) => {
  const memo: Record<string, true> = {}
  const list = ['All Input Subjects']

  if (!data) return list

  data.forEach(item => {
    if (!item.inputSubject) return

    const inputSubject = getInputSubjectName(item.inputSubject)

    if (!memo[inputSubject]) {
      memo[inputSubject] = true
      list.push(inputSubject)
    }
  })
  return list
}
