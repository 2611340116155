import { ConnectionState } from '@twilio/conversations'

export const TWILIO_CLIENT_STATE = {
  INITIALIZED: 'initialized',
  FAILED: 'failed',
} as const

export const TWILIO_CONNECTION_STATE = {
  CONNECTING: 'connecting' as ConnectionState,
  CONNECTED: 'connected' as ConnectionState,
  DENIED: 'denied' as ConnectionState,
  DISCONNECTING: 'disconnecting' as ConnectionState,
  DISCONNECTED: 'disconnected' as ConnectionState,
  ERROR: 'error' as ConnectionState,
  UNKNOWN: 'unknown' as ConnectionState,
} as const

export const TWILIO_CONNECTION_STATE_MESSAGE = {
  [TWILIO_CONNECTION_STATE.CONNECTING]: 'Connecting',
  [TWILIO_CONNECTION_STATE.CONNECTED]: 'Connected',
  [TWILIO_CONNECTION_STATE.DENIED]: 'Denied',
  [TWILIO_CONNECTION_STATE.DISCONNECTING]: 'Disconnecting',
  [TWILIO_CONNECTION_STATE.DISCONNECTED]: 'Disconnected',
  [TWILIO_CONNECTION_STATE.ERROR]: 'Connection Error',
  [TWILIO_CONNECTION_STATE.UNKNOWN]: 'Unknown Connection Status',
} as const
