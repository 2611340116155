import { useContext } from 'react'
import { ImportContextData } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/ImportContext/context'
import { Box, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { IImportContextParams } from 'pages/orchestration/workflows/edit/interfaces'
import { ListItemText, ActionSheetItem } from '@ketch-com/deck'
import { FormDroplistButton } from 'components/form/FormDroplistButton'

interface Props {}

export const SelectFromIdentitySpace: React.FC<Props> = () => {
  const { fromIdentitySpaces } = useContext(ImportContextData)
  const { values, setFieldValue } = useFormikContext<IImportContextParams>()

  return (
    <Box width={214}>
      <FormDroplistButton
        label="Lookup Identifier"
        disableClearable
        required
        formPropertyName="identityFromCode"
        onItemClick={identity => {
          setFieldValue('identityFromName', identity?.name)
          setFieldValue('identityFromCode', identity?.code)
        }}
        size="small"
        valueKey="code"
        placeholder="Select Identity"
        items={fromIdentitySpaces}
        getOptionLabel={option => option?.name || ''}
        isOptionEqualToValue={(option, value) => option?.code === value?.code}
        getOptionDisabled={option => option?.code === values?.code}
        renderOption={(props, option, _, isSelected) => {
          return (
            <ActionSheetItem {...props} key={option.code} selected={isSelected}>
              <ListItemText selected={isSelected}>
                <Box display="flex" flexDirection="column" gap={0.5}>
                  <Typography alignSelf="flex-start" variant="body">
                    {option?.name}
                  </Typography>
                  <Typography alignSelf="flex-start" variant="footnote" color="darkDuskFaded.main">
                    {option?.code}
                  </Typography>
                </Box>
              </ListItemText>
            </ActionSheetItem>
          )
        }}
      />
    </Box>
  )
}
