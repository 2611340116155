import * as Yup from 'yup'

interface IValidationParams {
  usedCodes?: Array<string>
}

export const getImportContextValidationSchema = (params: IValidationParams) =>
  Yup.object().shape({
    code: Yup.string()
      .notOneOf(params?.usedCodes || [], 'This code is already in use')
      .required('Required'),
    description: Yup.string().required('Required'),
    databaseResourceId: Yup.string().required('Required'),
    identityType: Yup.number().required('Required'),
    identityFromCode: Yup.string().required('Required'),
    identityToCode: Yup.string().required('Required'),
    query: Yup.string()
      .test('', 'Required', value => Boolean(value))
      .test(
        '',
        'Must include identities',
        (value, ctx) =>
          value?.indexOf(`{{${ctx?.parent?.identityFromCode}}}`) !== -1 &&
          value?.indexOf(`{{${ctx?.parent?.identityToCode}}}`) !== -1,
      )
      .test(
        '',
        'Must not include INSERT or UPDATE',
        value => value?.toLowerCase()?.indexOf('insert') === -1 && value?.toLowerCase()?.indexOf('update') === -1,
      ),
    assignee: Yup.string().required('Required'),
  })
