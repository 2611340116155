import React from 'react'
import { Formik } from 'formik'
import { compact } from 'lodash'
import { Box, Typography } from '@mui/material'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'
import { formatCodeFromName } from 'utils/formatters'
import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'
import {
  getImportContextInitialValues,
  getImportContextValidationSchema,
} from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/ImportContext/utils'
import { useImportContextUtils } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/ImportContext/hooks'
import { ImportContextData } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/ImportContext/context'
import {
  SelectDatabase,
  SelectFromIdentitySpace,
  SelectToIdentitySpace,
  SelectToContextVariable,
} from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/ImportContext/components'
import { identityType } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/forms/activity/ImportContext/constants'
import { IImportContextParams } from 'pages/orchestration/workflows/edit/interfaces'
import { FormInput } from 'components/form/FormInput'
import { ContentGroup, Icon, theme } from '@ketch-com/deck'
import { FormRadioGroup } from 'components/form/FormRadioGroup'
import { FormAssigneeOrTeamSelector } from 'components/form/FormAssigneeOrTeamSelector'

interface IChildWorkflowFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const ImportContext: React.FC<IChildWorkflowFormProps> = props => {
  const { step, steps, handleClose, handleRemove, handleSave } = props
  const hookValues = useImportContextUtils(step)
  const { isLoading } = hookValues
  const usedCodes = compact(steps.map(({ code }) => code).filter(code => code !== step.code))
  const onSubmit = (values: IImportContextParams) => {
    handleSave({
      step: {
        ...step,
        valid: true,
        code: values.code,
        description: values.description,
        workflow: {
          ...step.workflow,
          params: {
            ...values,
          },
        } as ICanvasStep['workflow'],
      },
    })
  }

  return (
    <SidebarConfigsWrapper isLoading={isLoading} handleClose={handleClose}>
      <Formik
        initialValues={getImportContextInitialValues({ step })}
        validationSchema={getImportContextValidationSchema({ usedCodes })}
        onSubmit={onSubmit}
      >
        {form => {
          const { isValid, submitForm, setFieldValue, values } = form
          const isIdentitySpaceType = values.identityType === 1

          return (
            <>
              <SidebarConfigsHeader
                isValid={isValid}
                step={step}
                steps={steps}
                handleSave={() => submitForm()}
                handleRemove={() => handleRemove({ step })}
                handleClose={handleClose}
                containerSx={{
                  pt: 3,
                  px: 3,
                  pb: 0,
                }}
              />

              <SidebarConfigsBody sx={{ p: 3 }}>
                <ImportContextData.Provider value={hookValues}>
                  <Box display="flex" flexDirection="column" gap={3}>
                    <Box display="flex" flexDirection="column" gap={3}>
                      <Typography variant="h3">Details</Typography>
                      <FormInput
                        required
                        formPropertyName="description"
                        fullWidth
                        label="Description Label"
                        placeholder="Label"
                        onChange={e => setFieldValue('code', formatCodeFromName(e.target.value))}
                        shouldUpdateDebounced
                      />
                      <FormInput
                        formPropertyName="code"
                        fullWidth
                        label="Code"
                        placeholder="Code"
                        required
                        shouldUpdateDebounced
                      />
                    </Box>

                    <Box display="flex" flexDirection="column" gap={3}>
                      <ContentGroup
                        titleVariant="h3"
                        title="Configure"
                        subTitle="Configure a query to run against a specified database to import an Identifier or Data Subject
                        Variable value."
                      />
                      <SelectDatabase />
                      <Box>
                        <FormRadioGroup
                          isInt
                          row
                          formPropertyName="identityType"
                          hideOptionalLabel
                          valueKey="id"
                          renderLabel={({ label }) => label}
                          options={identityType}
                          onChange={() => {
                            setFieldValue('identityToName', '')
                            setFieldValue('identityToCode', '')
                          }}
                        />
                      </Box>
                      <Box display="flex" justifyContent="space-between" alignItems="top">
                        <SelectFromIdentitySpace />
                        <Box mt={2}>
                          <Icon name="OArrowCRight" iconColor={theme.palette.darkDuskFaded.main} />
                        </Box>
                        {isIdentitySpaceType ? <SelectToIdentitySpace /> : <SelectToContextVariable />}
                      </Box>
                      <Box display="flex" flexDirection="column" gap={1}>
                        <FormInput
                          required
                          fullWidth
                          formPropertyName="query"
                          multiline
                          rows={3}
                          label="SQL Query"
                          placeholder={`Example: select ColumnName as {{${values.identityToCode}}} from TableName where member_id = {{${values.identityFromCode}}}`}
                        />
                        <Typography variant="body">
                          This query must return a single column with a single row containing the added Identifier
                          value.
                        </Typography>
                        <Typography variant="body">
                          The SQL query should have{' '}
                          <Typography variant="label">{`{{${values.identityFromCode}}}`}</Typography> around the Lookup
                          Identifier code in the where filter, as well as the{' '}
                          <Typography variant="label">{`{{${values.identityToCode}}}`}</Typography> around the added
                          Identifier code as the returning column name.
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="column" gap={3}>
                      <Typography variant="h3">Assignee</Typography>
                      <Box>
                        <FormAssigneeOrTeamSelector label="" name="assignee" />
                        <Typography variant="body">
                          Select a person for the review process if the Identity Mapping configuration returns an error
                          or requires manual intervention. Admins still can re-assign this activity later in the DSR
                          process.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </ImportContextData.Provider>
              </SidebarConfigsBody>
            </>
          )
        }}
      </Formik>
    </SidebarConfigsWrapper>
  )
}
