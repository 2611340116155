import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FormMode } from 'interfaces/formModes/formMode'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { Pagination } from 'api/common/paginatedQuery'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { RoutesManager } from 'utils/routing/routesManager'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'

import { ReactComponent as EmptyStateExperiencesIcon } from 'assets/icons/emptyState/EmptyStateExperiences.svg'
import {
  ActionSheetItem,
  ActionSheetWrapper,
  Button,
  DataGrid,
  EmptyState,
  Icon,
  ListLayout,
  TooltipButton,
} from '@ketch-com/deck'
import { Box } from '@mui/material'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { GridRowParams } from '@mui/x-data-grid'
import { useExperiencesListColumns } from './experiencesListUtils'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isLoading: boolean
  isFetching: boolean
  pagination: Pagination
  experiences: ExperienceV2DTO[]
}

export const ExperiencesList: React.FC<Props> = ({ experiences, isLoading, isFetching, pagination }) => {
  // Utils
  const navigate = useNavigate()
  const { isPermitted } = useIsPermitted()
  const { isEntitled } = useIsEntitled()

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Experiences', link: RoutesManager.deployment.experiencesV2.root.getURL() },
  ]

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const isPermittedToCreateExperiences = isPermitted(PERMISSIONS.EXP_WRITE)
  const isEntitledToCreateExperience = isEntitled(ENTITLEMENTS.EXPERIENCES)
  const isEntitledToConsentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)
  const isEntitledToRights = isEntitled(ENTITLEMENTS.EXP_SERVER_RIGHTS)

  const canCreateExperiences = isPermittedToCreateExperiences && isEntitledToCreateExperience

  // TODO:JB - Until migration, only show new experiences in this list
  const tableExperiences = experiences.filter(experience => !!experience.summary)

  const handleRedirectToCreateConsent = () => {
    navigate(
      RoutesManager.deployment.experiencesV2.upsert.root.getURL({
        formMode: FormMode.CREATE,
        experienceType: ExperienceType.Consent,
      }),
    )
  }

  const handleRedirectToCreatePreference = () => {
    navigate(
      RoutesManager.deployment.experiencesV2.upsert.root.getURL({
        formMode: FormMode.CREATE,
        experienceType: ExperienceType.Preference,
      }),
    )
  }

  const totalPages = pagination.totalPages
  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  const columns = useExperiencesListColumns()

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <ListLayout
        headerTitle={''}
        headerProps={{
          isDivider: false,
        }}
        customHeaderRightActionComponent={
          isPermittedToCreateExperiences && (
            <>
              {!isEntitledToCreateExperience || !(isEntitledToConsentAndDisclosure || isEntitledToRights) ? (
                <TooltipButton
                  title={
                    !isEntitledToCreateExperience
                      ? 'No experience entitlements remaining, please delete one or contact your admin for more.'
                      : undefined
                  }
                  disabled={!isEntitledToCreateExperience || !(isEntitledToConsentAndDisclosure || isEntitledToRights)}
                  onClick={() => {
                    if (isEntitledToConsentAndDisclosure) {
                      handleRedirectToCreateConsent()
                    } else {
                      handleRedirectToCreatePreference()
                    }
                  }}
                >
                  Create Experience
                </TooltipButton>
              ) : (
                <>
                  <Button
                    size="medium"
                    onClick={handleClickListItem}
                    endIcon={<Icon name={open ? 'OArrowCUp' : 'OArrowCDown'} />}
                  >
                    Create Experience
                  </Button>
                  <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
                    {isEntitledToConsentAndDisclosure && (
                      <ActionSheetItem
                        onClick={() => {
                          handleRedirectToCreateConsent()
                        }}
                      >
                        Consent & Disclosure
                      </ActionSheetItem>
                    )}
                    {isEntitledToConsentAndDisclosure && <ActionSheetItem divider />}

                    <ActionSheetItem
                      onClick={() => {
                        handleRedirectToCreatePreference()
                      }}
                    >
                      Preference Management
                    </ActionSheetItem>
                  </ActionSheetWrapper>
                </>
              )}
            </>
          )
        }
      >
        <Box>
          {!isFetching && !tableExperiences.length ? (
            <Box
              sx={{
                padding: '50px 0',
                borderRadius: '11px',
                backgroundColor: 'white.main',
              }}
            >
              <EmptyState
                title="There are no experiences for this organization"
                subTitle={canCreateExperiences ? 'Create your first one to populate the table.' : undefined}
                primaryButtonTitle={
                  !canCreateExperiences || !isEntitledToConsentAndDisclosure ? undefined : 'Create Consent & Disclosure'
                }
                primaryButtonProps={{
                  size: 'large',
                  onClick: handleRedirectToCreateConsent,
                }}
                secondaryButtonTitle={
                  !canCreateExperiences && !isEntitledToRights && !isEntitledToConsentAndDisclosure
                    ? undefined
                    : 'Create Preference Management'
                }
                secondaryButtonProps={{
                  size: 'large',
                  color: 'secondary',
                  onClick: handleRedirectToCreatePreference,
                }}
                customIcon={<EmptyStateExperiencesIcon />}
              />
            </Box>
          ) : (
            <DataGrid
              sx={{
                ...(isLoading && {
                  '& .MuiDataGrid-virtualScroller': {
                    height: '300px',
                  },
                }),
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                  backgroundImage: 'unset',
                },
                border: 'unset',
              }}
              autosizeOnMount
              autosizeOptions={{
                expand: true,
                includeHeaders: true,
                includeOutliers: false,
              }}
              getRowHeight={() => 'auto'}
              columns={columns}
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnResize
              disableColumnReorder
              disableRowSelectionOnClick
              getRowId={row => row.id}
              rows={tableExperiences || []}
              loading={isLoading}
              noRowsOverlayProps={{
                buttonTitle: '',
              }}
              pagination
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={onPaginationModelChange}
              pageSizeOptions={[rowsPerPage]}
              rowCount={pagination.totalResults}
              hideFooter={pagination.totalPages < 2}
              onRowClick={({ row: { id } }: GridRowParams<ExperienceV2DTO>) => {
                if (id) {
                  navigate(RoutesManager.deployment.experiencesV2.view.preview.root.getURL({ id }))
                }
              }}
            />
          )}
        </Box>
      </ListLayout>
    </>
  )
}
