import { useMemo } from 'react'
import * as Yup from 'yup'

import { ObjectLiteral } from 'interfaces'
import { LanguageWithStatusDTO } from 'interfaces/languages/language'
import { useCheckIsPurposeCodeAvailable } from 'api/purposes/mutations/useCheckIsPurposeCodeAvailable'
import { useCodeValidationSchema } from 'utils/helpers/validators/useCodeValidationSchema'
import { PurposeDataRole } from 'interfaces/purposes/purposeDataRole'

export function mergeSchemas(...schemas: Array<Yup.ObjectSchema<any>>) {
  const [first, ...rest] = schemas
  const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first)
  return merged
}

export const useGetValidationSchemaBasicDetailsStep = (isEditMode?: boolean) => {
  const { mutateAsync: handleCheckIsPurposeCodeAvailable } = useCheckIsPurposeCodeAvailable()
  const codeValidationSchema = useCodeValidationSchema({
    handler: value =>
      handleCheckIsPurposeCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(
    () =>
      Yup.object().shape({
        ...(!isEditMode && {
          code: codeValidationSchema,
          purposeTemplateCode: Yup.string(),
        }),
        name: Yup.string().max(75, 'Name must not exceed 75 characters').required('Required'),
        dataRole: Yup.number().required('Required'),
        canonicalPurposeCodes: Yup.array().of(Yup.string()),
        dataUsageDuration: Yup.number(),
        dataSubjectRole: Yup.number().required('Required'),
      }),
    [isEditMode, codeValidationSchema],
  )
}

export const validationSchemaDataSubjectStep = Yup.object().shape({
  dataSubjectTypeCodes: Yup.array()
    .of(Yup.string())
    .test('Is required', 'At least one Subject Type is required', (value, ctx) =>
      ctx?.parent?.dataRole === PurposeDataRole.CONTROLLER ? !!value?.length : true,
    ),
})

export const validationSchemaDisplayDetailsStep = Yup.object().shape({
  displayDescription: Yup.string(),
  displayName: Yup.string().required('Required'),
})

export const validationSchemaDisplayLegalBasesStep = Yup.object().shape({
  legalBases: Yup.lazy(legalBasesCodesMap => {
    const legalBasesCodes = Object.keys(legalBasesCodesMap as ObjectLiteral)

    const validators = legalBasesCodes.reduce<ObjectLiteral<Yup.StringSchema<string | undefined>>>(
      (acc, code) => ({
        ...acc,
        [code]: Yup.string().required('Required'),
      }),
      {},
    )

    return Yup.object().shape(validators)
  }),
})

export const getValidationSchemaDisplayTranslationsStep = (organizationLanguages: LanguageWithStatusDTO[]) =>
  Yup.object().shape({
    translations: Yup.lazy(translationsCodesMap => {
      const translationsCodes = Object.keys(translationsCodesMap as ObjectLiteral)

      const validators = translationsCodes.reduce((acc, code) => {
        const isRequired = !!organizationLanguages.filter(({ enabled, language }) => enabled && language.code === code)
          .length

        return {
          ...acc,
          ...(isRequired && {
            [code]: Yup.object().shape({
              displayName: Yup.string(),
              displayDescription: Yup.string(),
            }),
          }),
        }
      }, {})

      return Yup.object().shape(validators)
    }),
  })

export const useGetEditPurposeFormValidationSchema = (organizationLanguages: LanguageWithStatusDTO[]) => {
  const validationSchemaBasicDetails = useGetValidationSchemaBasicDetailsStep(true)
  return mergeSchemas(
    validationSchemaBasicDetails,
    validationSchemaDataSubjectStep,
    validationSchemaDisplayDetailsStep,
    getValidationSchemaDisplayTranslationsStep(organizationLanguages),
    validationSchemaDisplayLegalBasesStep,
  )
}
export const useGetCreatePurposeFormValidationSchema = (
  currentFormStep: number,
  organizationLanguages: LanguageWithStatusDTO[],
  dataRole: PurposeDataRole | undefined,
) => {
  const validationSchemaBasicDetails = useGetValidationSchemaBasicDetailsStep(false)
  return useMemo(() => {
    if (currentFormStep === 0) {
      return validationSchemaBasicDetails
    } else if (currentFormStep === 1) {
      if (dataRole !== PurposeDataRole.PROCESSOR) {
        return mergeSchemas(validationSchemaBasicDetails, validationSchemaDataSubjectStep)
      } else {
        return mergeSchemas(validationSchemaBasicDetails, validationSchemaDisplayDetailsStep)
      }
    } else if (currentFormStep === 2) {
      if (dataRole !== PurposeDataRole.PROCESSOR) {
        return mergeSchemas(
          validationSchemaBasicDetails,
          validationSchemaDataSubjectStep,
          validationSchemaDisplayDetailsStep,
        )
      } else {
        return mergeSchemas(
          validationSchemaBasicDetails,
          validationSchemaDisplayDetailsStep,
          getValidationSchemaDisplayTranslationsStep(organizationLanguages),
        )
      }
    } else if (currentFormStep === 3) {
      if (dataRole !== PurposeDataRole.PROCESSOR) {
        return mergeSchemas(
          validationSchemaBasicDetails,
          validationSchemaDataSubjectStep,
          validationSchemaDisplayDetailsStep,
          getValidationSchemaDisplayTranslationsStep(organizationLanguages),
        )
      } else {
        return mergeSchemas(
          validationSchemaBasicDetails,
          validationSchemaDisplayDetailsStep,
          getValidationSchemaDisplayTranslationsStep(organizationLanguages),
          validationSchemaDisplayLegalBasesStep,
        )
      }
    } else if (currentFormStep === 4) {
      return mergeSchemas(
        validationSchemaBasicDetails,
        validationSchemaDataSubjectStep,
        validationSchemaDisplayDetailsStep,
        getValidationSchemaDisplayTranslationsStep(organizationLanguages),
        validationSchemaDisplayLegalBasesStep,
      )
    }
  }, [currentFormStep, dataRole, organizationLanguages, validationSchemaBasicDetails])
}
