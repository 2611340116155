import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { MaybeNull } from 'interfaces/common'
import { WebTagDTO } from 'interfaces/webtags/WebTags'
import { RoutesManager } from 'utils/routing/routesManager'
import { WebTagViewDetails } from 'pages/consentAndRights/consent/webTags/view/components/WebTagViewDetails'
import { WebTagViewActions } from 'pages/consentAndRights/consent/webTags/view/components/WebTagViewActions'
import { WebTagViewRoutes } from 'pages/consentAndRights/consent/webTags/view/WebTagViewRoutes'
import { relativePath } from 'utils/routing/relativePath'
import { Spinner, ViewLayout } from '@ketch-com/deck'
import { NavigationBreadCrumbs } from '../../../../../components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import Box from '@mui/material/Box'

type Props = {
  isReady: boolean
  webTag: MaybeNull<WebTagDTO>
}

export const WebTagView: React.FC<Props> = ({ isReady, webTag }) => {
  const navigate = useNavigate()

  const [currentTab, setCurrentTab] = useState(0)

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
    { title: 'Tags', link: RoutesManager.orchestration.webTags.root.getURL() },
    {
      title: webTag?.name || webTag?.code,
      link: RoutesManager.orchestration.webTags.view.root.getURL({ id: webTag?.ID }),
    },
  ]

  const tabs = useMemo(
    () => [
      {
        label: 'Purposes',
        link: relativePath(RoutesManager.orchestration.webTags.view.purposes.root.pattern),
      },
    ],
    [],
  )

  useEffect(() => {
    navigate(tabs[currentTab].link)
  }, [currentTab, navigate, tabs])

  if (!isReady || !webTag) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }

  return (
    <>
      <NavigationBreadCrumbs type="dark" items={breadcrumbs} />
      <ViewLayout
        contentGroupProps={{
          title: webTag?.name || webTag?.code || '',
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.orchestration.webTags.root.getURL()),
          },
          infoBlockComponent: <WebTagViewDetails webTag={webTag} />,
          actionRightBlockComponent: <WebTagViewActions webTag={webTag} />,
        }}
        tabsComponentProps={{
          tabItems: tabs,
          value: currentTab,
          onChange: (_, value) => {
            setCurrentTab(value)
          },
        }}
      >
        <WebTagViewRoutes webTag={webTag} />
      </ViewLayout>
    </>
  )
}
