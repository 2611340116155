import React from 'react'
import { GetAssetsDataSystemsResponseBodyDTO } from '@ketch-com/figurehead'
import { Text } from 'components/ui-kit/typography/Text'
import { Box } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { TextColorOptions } from 'components/ui-kit/typography/Text'
import { ReactComponent as ConnectIcon } from 'assets/icons/dataSystemActiveConnection.svg'
import { List } from 'components/ui-kit/list/List'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button } from '@ketch-com/deck'
import { useAppDispatch } from 'store/hooks'
import { setFilterState, clearSearchQuery, addTypeFilter } from 'store/megaFilterStateSlice'
import { FILTER_STATES } from 'pages/assetManager/assets/list/components/MegaFilter/constants'
import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'

type Props = {
  assetsDataSystems: GetAssetsDataSystemsResponseBodyDTO
}

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      assetsDataSystemsContainer: {
        marginTop: spacing(3),
        borderTop: `1px solid ${palette.iron.main}`,
      },
      assetsDataSystemsItemsContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        gap: spacing(2),
      },
      assetsDataSystemsItem: {
        backgroundColor: palette.superLightGrey.main,
        borderRadius: 12,
        padding: spacing(2.5),
        width: 236,
      },
      assetsDataSystemsItemHeader: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      assetsDataSystemsLogoContainer: {
        backgroundColor: palette.white.main,
        borderRadius: 8,
        width: 44,
        height: 44,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
          height: 32,
          width: 32,
        },
      },
      assetsDataSystemsConnection: {
        backgroundColor: palette.white.main,
        borderRadius: 5,
        padding: spacing(0, 0.5),
      },
      assetName: {
        textTransform: 'capitalize',
        display: 'inline-block',
        fontSize: 16,
        fontWeight: 700,
        margin: spacing(3, 0, 4),
      },
      assetsList: {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
      },
      assetsListContent: {
        position: 'relative',
        '& span': {
          marginLeft: -8,
        },
        '& svg': {
          position: 'absolute',
          height: 20,
          top: 1,
          left: 150,
        },
      },
    }),
  { name: 'AssetsDataSystems' },
)

export const AssetsDataSystems: React.FC<Props> = ({ assetsDataSystems }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { dataSystems = [] } = assetsDataSystems
  const classes = useStyles()
  const darkDuskFaded = 'darkDuskFaded' as TextColorOptions
  const greenHaze = 'greenHaze' as TextColorOptions

  return (
    <Box className={classes.assetsDataSystemsContainer}>
      <Box display="flex" justifyContent="space-between" mt={3} mb={4}>
        <Text size={20} weight={700} color={darkDuskFaded}>
          Data Systems
        </Text>
        <Button disabled={false} onClick={() => navigate(RoutesManager.systems.create.root.getURL())}>
          Add System
        </Button>
      </Box>

      <Box className={classes.assetsDataSystemsItemsContainer}>
        {dataSystems.map(dataSystem => {
          const { assetCategories = [], connectionsCount, technology = '' } = dataSystem

          return (
            <Box key={technology} className={classes.assetsDataSystemsItem}>
              <Box className={classes.assetsDataSystemsItemHeader}>
                <Box className={classes.assetsDataSystemsLogoContainer}>
                  <img src={getAssetCloudImgUrl(technology)} alt={technology} />
                </Box>
                <Box>
                  <Box className={classes.assetsDataSystemsConnection}>
                    <ConnectIcon />
                    <Text size={11} weight={500} color={greenHaze} pl={0.5}>
                      {connectionsCount}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Text className={classes.assetName}>{technology}</Text>
              <List
                className={classes.assetsList}
                items={assetCategories.map(assetCategory => ({
                  active: false,
                  content: (
                    <Box key={assetCategory.code} className={classes.assetsListContent}>
                      <Text size={14} cursor="pointer">
                        {assetCategory.count}
                        {assetCategory.count === 1
                          ? ` ${assetCategory.code?.split('.')[1]}`
                          : ` ${assetCategory.code?.split('.')[1]}s`}
                      </Text>
                      <ChevronRightIcon />
                    </Box>
                  ),
                  disabled: false,
                  icon: false,
                  onClick: () => {
                    if (assetCategory.code) {
                      dispatch(clearSearchQuery())
                      dispatch(setFilterState(FILTER_STATES.OPEN))
                      dispatch(addTypeFilter(assetCategory.code))
                      navigate(RoutesManager.assetManager.assets.list.getURL())
                    }
                  },
                }))}
                size="small"
              />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
