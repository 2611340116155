import { Pagination } from 'api/common/paginatedQuery'
import { useSyncWebTags } from 'api/webtags/mutations/useSyncWebTags'
import { useWebTagsPaginatedPreservePagination } from 'api/webtags/queries/useWebTagsPaginatedPreservePagination'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { WebTagDTO } from 'interfaces/webtags/WebTags'
import React, { useCallback, useMemo, useState } from 'react'
import { useAppSelector } from 'store/hooks'
import { getWebTagsListFilterState } from 'store/webTagsListFilterSlice/selectors'
import { createCustomContext } from 'utils/hooks/createCustomContext'
import { TagListState } from '../utils/enums'

export interface TagsListContextProps {
  tagListState: TagListState
  setTagListState: React.Dispatch<React.SetStateAction<TagListState>>
  isBusy: boolean
  isLoading: boolean
  isFetching: boolean
  webTags: WebTagDTO[]
  pagination: Pagination
  handleSyncWebTags: () => Promise<void>
  emptyStateMessage: string
}

const { Provider, useCustomContext: useTagsList } = createCustomContext<TagsListContextProps>({
  displayName: 'TagsListContext',
})

export const TagsListProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { appCode, appInstanceId, enabled, purposeId, q: reduxSearchQuery } = useAppSelector(getWebTagsListFilterState)

  // UI States
  const [tagListState, setTagListState] = useState<TagListState>(TagListState.All)

  const [isBusy, setIsBusy] = useState(false)

  const {
    data: webTags,
    isLoading: isWebTagsListLoading,
    isFetching: isWebTagsListFetching,
    pagination,
  } = useWebTagsPaginatedPreservePagination({
    params: {
      includeIssues: true,
      includeMetadata: true,
      q: reduxSearchQuery,
      appCode,
      appInstanceId,
      purposeId,
      enabled,
    },
  })

  const hasFilters = appCode || appInstanceId || enabled || purposeId || reduxSearchQuery
  const emptyStateMessage = hasFilters
    ? 'There are no tags for entered search criteria'
    : 'There are no tags for this organization'

  const { mutateAsync: syncWebTags } = useSyncWebTags({
    onSuccess: () => {
      setIsBusy(false)
      showToast({ content: 'Tag sync initiated', type: 'success' })
    },
    onError: () => {
      setIsBusy(false)
      showToast({ content: 'Failed to initiate tag sync', type: 'error' })
    },
  })

  const handleSyncWebTags = useCallback(async () => {
    setIsBusy(true)
    await syncWebTags({
      params: {
        appCode: appCode,
        appInstanceID: appInstanceId,
      },
    })
  }, [appCode, appInstanceId, syncWebTags])

  const isLoading = isWebTagsListLoading || isBusy

  const isFetching = isWebTagsListFetching

  const value = useMemo(
    () => ({
      tagListState,
      setTagListState,
      isBusy,
      isLoading,
      isFetching,
      webTags,
      pagination,
      handleSyncWebTags,
      emptyStateMessage,
    }),
    [
      tagListState,
      setTagListState,
      isBusy,
      isLoading,
      isFetching,
      webTags,
      pagination,
      handleSyncWebTags,
      emptyStateMessage,
    ],
  )

  return <Provider value={value}>{children}</Provider>
}

export { useTagsList }
