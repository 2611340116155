import { Box } from '@mui/material'
import { useState, useRef } from 'react'
import { ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'
import { Chat } from 'pages/policyCenter/processingActivities/view/Comment/components'
import { useTwilioConversationClient } from 'pages/policyCenter/processingActivities/view/Comment/hooks'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useFormikContext } from 'formik'
import { ChatData } from 'pages/policyCenter/processingActivities/view/Comment/interfaces'
import pluralize from 'pluralize'
import { isTwilioConversationsEnabled } from 'utils/helpers/isTwilioConversationsEnabled'

export const Comment = () => {
  useTwilioConversationClient()
  const { isPermitted } = useIsPermitted()
  const { values } = useFormikContext<ChatData>()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  if (!values.twilioMessages)
    return (
      <Box
        marginBottom="-5px"
        pb={1}
        sx={{
          borderBottom: theme => `1px solid ${theme.palette.iron.main}`,
        }}
      >
        <Button color="tertiary" pending={true} />
      </Box>
    )

  if (!isTwilioConversationsEnabled()) {
    return null
  }

  return (
    <>
      <Box
        ref={anchorRef}
        paddingBottom={1}
        marginBottom="-5px"
        sx={{
          borderBottom: theme => `1px solid ${theme.palette.iron.main}`,
        }}
      >
        {values.twilioMessages?.length ? (
          <Button
            startIcon={values.twilioUnreadMessagesCount ? <Icon name="OChatNotification" /> : <Icon name="OChat" />}
            color={values.twilioUnreadMessagesCount ? 'primary' : 'tertiary'}
            aria-expanded={open ? 'true' : undefined}
            onClick={handleToggle}
          >
            {`${values.twilioMessages?.length} ${pluralize('comment', values.twilioMessages?.length)}`}
          </Button>
        ) : (
          <Button
            startIcon={<Icon name="OChat" />}
            color="tertiary"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleToggle}
          >
            {isPermitted(PERMISSIONS.PROCESSING_ACTIVITY_WRITE) ? `Add Comment` : 'View Comment'}
          </Button>
        )}
      </Box>

      <ActionSheetWrapper anchorEl={anchorRef.current} open={open} onClose={handleClose} transitionDuration={0}>
        <Chat />
      </ActionSheetWrapper>
    </>
  )
}
