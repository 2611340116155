import { useEffect, useState } from 'react'
import { chunk, get, size } from 'lodash'
import { usePrevious } from 'react-use'

import { ObjectLiteral } from 'interfaces'

export interface PaginationInterface {
  page: number
  currentPageItems: any[]
  itemsSize: number
  setPage: (page: number) => void
  itemsPerPage: number
}

/*
 Used when all items are fetched form BE
 */
export function usePagination<T extends ObjectLiteral>(items: T[] = [], itemsPerPage = 10) {
  const [page, setPage] = useState(0)

  const rowsByPage = chunk(items, itemsPerPage)
  const pagesCount: number = size(rowsByPage)
  const prevPagesCount = usePrevious(pagesCount)
  const currentPageItems = get(rowsByPage, page, []) as T[]
  const itemsSize = size(items)
  const isLastPage = pagesCount - 1 === page || pagesCount === page

  // NOTE: move to previous page if page doesn't exist after item deletion
  useEffect(() => {
    if (Number.isInteger(pagesCount) && Number.isInteger(prevPagesCount)) {
      if (pagesCount < (prevPagesCount || 0) && isLastPage) {
        setPage(pagesCount - 1)
      }
    }
  }, [pagesCount, prevPagesCount, isLastPage])

  return {
    isFirstPage: page === 0,
    isLastPage: page === Math.ceil(items.length / itemsPerPage - 1),
    page,
    currentPageItems,
    itemsSize,
    setPage,
    itemsPerPage,
  }
}

export interface BePaginationInterface {
  isFirstPage: boolean
  isLastPage: boolean
  page: number
  setPage: (page: number) => void
  totalItems: number
  totalPages: number
  setTotalItems: (totalItems: number) => void
  itemsPerPage: number
}

type UseBePagination = (itemsPerPage?: number) => BePaginationInterface
/*
 Used when items per page only are fetched form BE
 */
export const useBePagination: UseBePagination = (itemsPerPage = 20) => {
  const [page, setPage] = useState(0)
  const [totalItems, setTotalItems] = useState(0)

  return {
    isFirstPage: page === 0,
    isLastPage: page === Math.ceil(+totalItems / itemsPerPage - 1),
    totalPages: Math.ceil(+totalItems / itemsPerPage),
    page,
    setPage,
    totalItems,
    setTotalItems,
    itemsPerPage,
  }
}
