import { InfoRow, theme, Button, Icon, IconDictionary } from '@ketch-com/deck'
import { Box, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
import { ReferenceLine, Bar, BarChart, Cell, XAxis, YAxis, Tooltip } from 'recharts'
import {
  OrgPlanIDs,
  OrgPlanMaxUniquesLimit,
  OrgPlanAndBillingBannerVariants,
} from 'components/planAndBilling/common/constants/planAndBilling'
import { HowWeCalculateUniquesModal } from 'components/planAndBilling/components/uniqueUsers/HowWeCalculateUniquesModal'
import { MonthlyUniques, PlanStatus, BillingStatus } from '@ketch-com/harbormaster/dist/organization/plans.schemas'
import { PlanAndBillingBanners } from 'components/planAndBilling/components/banners/PlanAndBillingBanners'
import pluralize from 'pluralize'
import { MaybeNull } from 'interfaces/common'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const UniqueUsers: React.FC = () => {
  const [activeFocusBar, setActiveFocusBar] = useState<MaybeNull<number>>(null)
  const [inActiveFocusBar, setInActiveFocusBar] = useState(true)
  const [shouldDisplayUniquesModal, setShouldDisplayUniquesModal] = useState(false)
  const { orgUsage, orgPlan } = useContext(PlanAndBillingContext)
  const isOrgOnFreePlan = orgPlan?.planProductID === OrgPlanIDs?.free
  const isFreePlanInGrace = isOrgOnFreePlan && orgPlan?.planStatus === PlanStatus.in_grace_period
  const isFreePlanSuspended = isOrgOnFreePlan && orgPlan?.planStatus === PlanStatus.suspended
  const isOrgOnStarterPlan = orgPlan?.planProductID === OrgPlanIDs?.starter
  const isStarterPlanInGrace = isOrgOnStarterPlan && orgPlan?.planStatus === PlanStatus.in_grace_period
  const isStarterPlanSuspended = isOrgOnStarterPlan && orgPlan?.planStatus === PlanStatus.suspended
  const isOrgOnProPlan = orgPlan?.planProductID === OrgPlanIDs?.pro
  const isBillingInGrace = orgPlan?.billingStatus === BillingStatus.in_grace_period
  const isBillingMissed = orgPlan?.billingStatus === BillingStatus.missed
  const isPlanSuspended = isFreePlanSuspended || isStarterPlanSuspended
  const isPlanOrBillingSuspended = isPlanSuspended || isBillingMissed
  const isOrgOnPlgPlan = isOrgOnFreePlan || isOrgOnStarterPlan

  const monthlyUniques = orgUsage?.monthlyUniques || []
  let recentUsageData: MonthlyUniques = {}
  let maxUsageData: MonthlyUniques = {}
  const usageData =
    monthlyUniques?.map(d => {
      const month = d?.month || 0
      const uniquesCount = d?.uniquesCount || 0
      const recentMonth = recentUsageData?.month || 0
      if (!recentUsageData?.month) recentUsageData = d
      if (recentMonth < month) recentUsageData = d
      if (!maxUsageData?.uniquesCount) maxUsageData = d
      if ((maxUsageData?.uniquesCount || 0) < uniquesCount) maxUsageData = d
      return {
        month: months?.[month - 1],
        uniques: d?.uniquesCount || 0,
        year: d?.year,
      }
    }) || []
  const recentUniques = recentUsageData?.month ? monthlyUniques[recentUsageData.month - 1] : {}

  let ticks = [
    0,
    1500,
    3500,
    OrgPlanMaxUniquesLimit.free,
    (maxUsageData?.uniquesCount || 0) > OrgPlanMaxUniquesLimit.free ? maxUsageData?.uniquesCount || 0 : 10000,
  ]
  let referenceLine = OrgPlanMaxUniquesLimit.free

  switch (orgPlan?.planProductID) {
    case OrgPlanIDs?.starter:
      ticks = [
        0,
        5000,
        15000,
        OrgPlanMaxUniquesLimit.starter,
        (maxUsageData?.uniquesCount || 0) > OrgPlanMaxUniquesLimit.starter ? maxUsageData?.uniquesCount || 0 : 50000,
      ]
      referenceLine = OrgPlanMaxUniquesLimit.starter
      break

    case OrgPlanIDs?.plus:
      ticks = [
        0,
        50000,
        100000,
        OrgPlanMaxUniquesLimit.plus,
        (maxUsageData?.uniquesCount || 0) > OrgPlanMaxUniquesLimit.starter ? maxUsageData?.uniquesCount || 0 : 1800000,
      ]
      referenceLine = OrgPlanMaxUniquesLimit.plus
      break

    case OrgPlanIDs?.pro:
      ticks = [
        0,
        50000,
        100000,
        OrgPlanMaxUniquesLimit.plus,
        (maxUsageData?.uniquesCount || 0) > OrgPlanMaxUniquesLimit.starter ? maxUsageData?.uniquesCount || 0 : 5000000,
      ]
      break
  }

  return (
    <>
      <InfoRow title="Monthly Unique Users" titleAddendum="(Across All Properties)">
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="label">
              {months[recentUniques.month ? recentUniques.month - 1 : 0]} {recentUniques.year}
            </Typography>
            <Button
              variant="link"
              size="large"
              startIcon={<Icon name={IconDictionary.OArrowCDown} />}
              onClick={() => setShouldDisplayUniquesModal(true)}
            >
              How Do We Calculate Unique Users?
            </Button>
          </Box>
          <Box position="relative">
            {isPlanOrBillingSuspended ? (
              <Box
                position="absolute"
                top={0}
                right={0}
                bottom={0}
                left={0}
                display="flex"
                justifyContent="center"
                alignItems="center"
                border={`1px solid ${theme.palette.Black.o16}`}
                borderRadius={1.5}
                bgcolor={theme.palette.white.main}
                zIndex={1}
                sx={{ opacity: 0.8 }}
              >
                <Box display="flex" alignItems="center" gap={0.5} sx={{ opacity: 1 }}>
                  <Icon name={IconDictionary.FImportant} width={24} height={24} iconColor={theme.palette.Black.o32} />
                  <Typography variant="label" color={theme.palette.Text.Secondary}>
                    Implementation Paused
                  </Typography>
                </Box>
              </Box>
            ) : null}
            <BarChart
              style={{ opacity: isPlanOrBillingSuspended ? 0.2 : 1 }}
              width={700}
              height={300}
              data={usageData}
              onMouseMove={
                !isPlanOrBillingSuspended
                  ? state => {
                      if (state.isTooltipActive && state.activeTooltipIndex !== undefined) {
                        setActiveFocusBar(state.activeTooltipIndex)
                        setInActiveFocusBar(false)
                      } else {
                        setActiveFocusBar(null)
                        setInActiveFocusBar(true)
                      }
                    }
                  : () => {}
              }
              onMouseLeave={() => {
                setActiveFocusBar(null)
                setInActiveFocusBar(true)
              }}
            >
              {!isPlanOrBillingSuspended ? (
                <>
                  <XAxis dataKey="month" tickLine={false} />
                  <YAxis
                    dataKey="uniques"
                    axisLine={false}
                    tickLine={false}
                    ticks={ticks}
                    tickFormatter={number => {
                      if (number >= 1e12) return (number / 1e12).toFixed(number % 1e12 === 0 ? 0 : 1) + 't'
                      if (number >= 1e9) return (number / 1e9).toFixed(number % 1e9 === 0 ? 0 : 1) + 'b'
                      if (number >= 1e6) return (number / 1e6).toFixed(number % 1e6 === 0 ? 0 : 1) + 'm'
                      if (number >= 1e3) return (number / 1e3).toFixed(number % 1e3 === 0 ? 0 : 1) + 'k'
                      return number.toString()
                    }}
                  />

                  <Tooltip
                    cursor={false}
                    wrapperStyle={{ outline: 'none' }}
                    content={({ active, payload }) => {
                      if (active && payload && payload.length) {
                        return (
                          <Box
                            sx={{
                              padding: 1,
                              borderRadius: 1.5,
                              background: theme.palette.Black.o100,
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 0.5,
                            }}
                          >
                            <Typography variant="label" color={theme.palette.white.main}>
                              {`${payload[0].value || 0} Unique  ${pluralize('User', Number(payload[0].value))}`}
                            </Typography>
                            <Typography
                              variant="body"
                              color={theme.palette.white.main}
                            >{`${payload?.[0]?.payload?.month} ${payload?.[0]?.payload?.year}`}</Typography>
                          </Box>
                        )
                      }

                      return null
                    }}
                  />
                </>
              ) : null}

              <Bar dataKey="uniques" radius={10} barSize={10}>
                {usageData.map((d, i) => {
                  const { uniques = 0 } = d
                  let fillColor = theme.palette.Black.o48
                  if (isOrgOnFreePlan) {
                    if (uniques > 0 && uniques <= OrgPlanMaxUniquesLimit.free) {
                      if (activeFocusBar === i || inActiveFocusBar) {
                        fillColor = theme.palette.primary.main
                      } else {
                        fillColor = theme.palette.primary.light
                      }
                    } else {
                      if (activeFocusBar === i || inActiveFocusBar) {
                        fillColor = theme.palette.error.dark
                      } else {
                        fillColor = theme.palette.error.light
                      }
                    }
                  }
                  if (isOrgOnStarterPlan) {
                    if (uniques > 0 && uniques <= OrgPlanMaxUniquesLimit.starter) {
                      if (activeFocusBar === i || inActiveFocusBar) {
                        fillColor = theme.palette.primary.main
                      } else {
                        fillColor = theme.palette.primary.light
                      }
                    } else {
                      if (activeFocusBar === i || inActiveFocusBar) {
                        fillColor = theme.palette.error.dark
                      } else {
                        fillColor = theme.palette.error.light
                      }
                    }
                  }
                  return <Cell key={d?.month} fill={fillColor} />
                })}
              </Bar>
              {!isPlanOrBillingSuspended && !isOrgOnProPlan ? <ReferenceLine y={referenceLine} stroke="black" /> : null}
            </BarChart>
          </Box>
        </Box>

        {isOrgOnPlgPlan ? (
          <>
            {isFreePlanInGrace && !isBillingMissed ? (
              <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.freePlanInGrace} orgPlan={orgPlan} />
            ) : null}
            {isFreePlanSuspended ? (
              <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.freePlanSuspended} orgPlan={orgPlan} />
            ) : null}
            {isStarterPlanInGrace && !isBillingMissed ? (
              <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.starterPlanInGrace} orgPlan={orgPlan} />
            ) : null}
            {isStarterPlanSuspended ? (
              <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.starterPlanSuspended} orgPlan={orgPlan} />
            ) : null}
            {isBillingInGrace && !isPlanSuspended ? (
              <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.billingInGrace} orgPlan={orgPlan} />
            ) : null}
            {isBillingMissed ? (
              <PlanAndBillingBanners variant={OrgPlanAndBillingBannerVariants.billingMissed} orgPlan={orgPlan} />
            ) : null}
          </>
        ) : null}
      </InfoRow>

      <HowWeCalculateUniquesModal
        isOpen={shouldDisplayUniquesModal}
        onClose={() => setShouldDisplayUniquesModal(false)}
      />
    </>
  )
}
