import { MetaData } from 'interfaces/common'
// import { FileInfoDTO } from 'interfaces/files/fileInfo' TODO:BAC - disable until logo file upload implemented

export type OrganizationDTO = {
  auth0OrgID: string
  code: string
  expiresAt: string
  logoURL: string
  metadata: MetaData
  name: string
  organizationStatus: string
  ownerUserID: string
  planProductID: string
  popCode: string
  primaryBrandColor: string
  secondaryBrandColor: string
  stripeCustomerID: string
  trialEndAt: string
  trialStartAt: string
  entitlements: { [capabilityCode: string]: number }
  consumption: { [capabilityCode: string]: number }
  products: { [productCode: string]: number }
}

export type OrganizationFormDTO = {
  // organizationLogoImage: FileInfoDTO[] // TODO:BAC - disable until logo file upload implemented
  primaryBrandColor: string
  secondaryBrandColor: string
  code: string
  name: string
  logoURL: string
}

export enum OrgType {
  PLAN = 0,
  UPGRADE = 1,
}

export const OrgTypeNames = {
  [OrgType.PLAN]: 'Plan',
  [OrgType.UPGRADE]: 'Upgrade',
}

export type OrganizationPortalUrlDTO = {
  PortalURL: string
}

export enum PlanType {
  KETCH_FREE = 'plan_ketch_free',
  KETCH_PLG = 'plan_v5_platform_plg',
}
