import React, { useState } from 'react'

import { useUpdateWebTagPurposes } from 'api/webtags/mutations/useUpdateWebTagPurposes'
import { showToast } from 'components/modals/AlertComponent'
import { Button, PopUp } from '@ketch-com/deck'
import { Typography } from '@mui/material'

type Props = {
  webTagId: string
  onSubmit?: () => void
  onCancel: () => void
}

export const ConfirmNoPurposesNeededDialog: React.FC<Props> = ({ webTagId, onSubmit, onCancel }) => {
  const [isBusy, setIsBusy] = useState(false)

  const { mutateAsync: handleUpdateWebTag } = useUpdateWebTagPurposes({
    onSuccess: () => {
      showToast({ content: 'Purposes mapping updated', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update purposes mapping', type: 'error' })
    },
  })

  const handleSubmit = async () => {
    setIsBusy(true)

    await handleUpdateWebTag({
      params: {
        webTagId,
        formData: {
          tagID: webTagId,
          purposeIDs: [],
        },
      },
    })
    await onSubmit?.()
  }

  return (
    <PopUp
      variant="dialog"
      title="Tag Dependencies"
      popUpWidth="450px"
      onClose={onCancel}
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="primary" size="large" pending={isBusy} onClick={handleSubmit}>
            Confirm
          </Button>
        </>
      }
    >
      <Typography variant="body">
        Please confirm this tag does not have any purpose dependencies. You can always specify dependencies later.
      </Typography>
    </PopUp>
  )
}
