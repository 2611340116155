import React, { useEffect } from 'react'
import { styled } from '@mui/styles'
import { Box } from '@mui/material'

import { SystemsListItem } from './SystemsListItem'
import { useQueryParams } from 'utils/hooks'

import { InstalledDataSystemV2DTO } from '@ketch-com/figurehead'
import { ReactComponent as EmptyStateDataSystemsIcon } from 'assets/icons/emptyState/EmptyStateDataSystems.svg'

import { useNavigate } from 'react-router-dom'
import { useInstalledDataSystemsInfinite } from 'api/dataSystems/queries/useInstalledDataSystemsInfinite'
import { Waypoint } from 'react-waypoint'
import { RoutesManager } from 'utils/routing/routesManager'
import { EmptyState, Spinner } from '@ketch-com/deck'
import { InfiniteScrollLoadingIndicator } from 'components/InfiniteScrollLoadingIndicator'

const AppsListContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gridGap: '1.25rem',
  margin: '0 0 2rem 0',
}))

type Props = {}

export const SystemsList: React.FC<Props> = () => {
  const { queries, removeAllQueryParams } = useQueryParams<{
    q?: string
    capabilities?: string
    connectionStatuses?: string
  }>()

  const {
    data: installedDataSystems,
    isLoading,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInstalledDataSystemsInfinite({
    q: queries.q,
    capabilities: queries?.capabilities ? [queries?.capabilities] : [],
  })

  useEffect(() => {
    refetch()
  }, [queries.q, queries.capabilities, refetch])

  const navigate = useNavigate()

  const isReady = !isRefetching && !isLoading

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  }

  if (isReady && installedDataSystems?.length === 0 && !Object.keys(queries).length) {
    return (
      <Box
        sx={{
          backgroundColor: 'white.main',
          padding: 5,
          borderRadius: '11px',
        }}
      >
        <EmptyState
          title="Add, Connect, Automate"
          subTitle="There are no systems added yet. Click on the button below to add and connect your first system!"
          customIcon={<EmptyStateDataSystemsIcon />}
          primaryButtonTitle="Add System"
          primaryButtonProps={{
            onClick: () => navigate(RoutesManager.systems.create.root.getURL()),
          }}
        />
      </Box>
    )
  }

  return (
    <>
      <Box>
        <>
          {isReady && installedDataSystems?.length === 0 && (queries.q || queries.capabilities) ? (
            <Box
              sx={{
                backgroundColor: 'white.main',
                padding: 5,
                borderRadius: '11px',
              }}
            >
              <EmptyState
                title="No results found"
                subTitle="Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try again."
                iconName="OFilter"
                primaryButtonTitle="Reset to Defaults"
                primaryButtonProps={{
                  onClick: () => removeAllQueryParams(),
                }}
              />
            </Box>
          ) : (
            <AppsListContainer>
              {installedDataSystems?.map((app: InstalledDataSystemV2DTO) => <SystemsListItem app={app} key={app.id} />)}
            </AppsListContainer>
          )}
        </>
      </Box>
      {isReady ? (
        <Waypoint
          onEnter={() => {
            if (!isFetchingNextPage && hasNextPage) fetchNextPage()
          }}
        />
      ) : (
        <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
      )}
    </>
  )
}
