import React, { useState, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { ListLayout, EmptyState, Icon, IconDictionary } from '@ketch-com/deck'
import { GetTrackerStatisticsResponseBody } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { ReactComponent as DuckDuckGo } from 'assets/images/DuckDuckGo.svg'
import { NotFoundTrackers } from 'pages/policyCenter/trackers/list/needsReviewTrackers/notFoundTrackers'
import { TrackersOnNewProperties } from 'pages/policyCenter/trackers/list/needsReviewTrackers/trackersOnNewProperties'

type Props = {
  trackerStatistics: GetTrackerStatisticsResponseBody
}

export const NeedsReviewTrackers: React.FC<Props> = ({ trackerStatistics }) => {
  const needsReviewStatistics =
    (trackerStatistics?.numNotFoundTrackers || 0) + (trackerStatistics?.numTrackersOnNewProperties || 0)

  const [currentTab, setCurrentTab] = useState(0)

  const tabs = useMemo(() => {
    const needsReviewTabs = []
    if (trackerStatistics?.numTrackersOnNewProperties) {
      needsReviewTabs.push({
        label: `Tracker Found on New Property - ${trackerStatistics?.numTrackersOnNewProperties}`,
      })
    }

    if (trackerStatistics?.numNotFoundTrackers) {
      needsReviewTabs.push({
        label: `Tracker Not Found - ${trackerStatistics?.numNotFoundTrackers}`,
      })
    }
    return needsReviewTabs
  }, [trackerStatistics?.numNotFoundTrackers, trackerStatistics?.numTrackersOnNewProperties])

  if (!needsReviewStatistics)
    return (
      <ListLayout headerTitle="Needs Review">
        <Box my={5}>
          <EmptyState
            size="medium"
            customIcon={<Icon name={IconDictionary.OCheckRound2} width={64} height={64} />}
            title="All Caught Up"
            subTitle="No trackers to review at the moment."
          />
        </Box>
      </ListLayout>
    )

  return (
    <ListLayout
      headerTitle="Needs Review"
      tabsComponentProps={{
        tabItems: tabs,
        value: currentTab,
        onChange: (event: React.SyntheticEvent, newValue: number) => {
          setCurrentTab(newValue)
        },
      }}
    >
      <Box>
        {trackerStatistics?.numTrackersOnNewProperties && currentTab === 0 ? (
          <TrackersOnNewProperties trackerStatistics={trackerStatistics} />
        ) : (
          <NotFoundTrackers trackerStatistics={trackerStatistics} />
        )}
      </Box>
      <Box display="flex" alignItems="center" gap={1} my={2} position="absolute" bottom={0} left={32}>
        <Typography variant="smallBody" color="darkDuskFaded.main">
          In partnership with
        </Typography>
        <DuckDuckGo />
      </Box>
    </ListLayout>
  )
}
