import { FC } from 'react'
import { FieldArray, useFormikContext } from 'formik'
import { Box, BoxProps, Typography, Tooltip } from '@mui/material'
import { ActionSheetItem, Banner, Button, FormRow, Icon, ListItemText, TooltipButton, theme } from '@ketch-com/deck'
import { FormDroplistButton } from 'components/form/FormDroplistButton'
import { FormSwitch } from 'components/form/FormSwitch'
import { FormInput } from 'components/form/FormInput'
import { getIdentitySpaceEmptyValue } from 'pages/consentAndRights/applications/upsert/utils'
import { APPLICATION_IDENTITY_SPACE_TYPE_OPTIONS } from 'interfaces/applications/applicationIdentitySpaceType'
import { ApplicationFormValues } from 'interfaces/applications/ApplicationFormValues'
import {
  ApplicationIdentitySpaceFormat,
  APPLICATION_IDENTITY_SPACE_FORMAT_OPTIONS,
} from 'interfaces/applications/ApplicationIdentitySpaceFormat'
import {
  ApplicationIdentitySpaceEncoding,
  APPLICATION_IDENTITY_SPACE_ENCODING_OPTIONS,
} from 'interfaces/applications/ApplicationIdentitySpaceEncoding'
import { ApplicationPlatform } from 'interfaces/applications/applicationPlatform'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'

interface ApplicationDataLayerProps {
  identitySpacesList: IdentitySpaceDTO[]
}

const getBoxFormSwitchProps = (switchValue: boolean): Partial<BoxProps> => ({
  p: '18px',
  py: '11px',
  sx: {
    backgroundColor: switchValue ? theme.palette.Success.Disabled : theme.palette.Black.o8,
    borderRadius: '11px',
    '& label > span': {
      color: switchValue ? theme.palette.Success.PrimaryDark : theme.palette.Text.Primary,
    },
  },
})

export const ApplicationDataLayer: FC<ApplicationDataLayerProps> = ({ identitySpacesList }) => {
  const { values, setFieldValue } = useFormikContext<ApplicationFormValues>()

  const { managedIdentity, identitySpaces, customIdentitySpaces, customPolicyScope, supportProxy } = values
  const isMobileProperty = values.platform === ApplicationPlatform.MOBILE
  const isDeleteIdentifierDisabled = isMobileProperty && identitySpaces.length < 2

  const handleInitializeIdentitySpacesIfNeeded = () => {
    if (!identitySpaces.length) {
      setFieldValue('identitySpaces', [getIdentitySpaceEmptyValue()])
    }
  }

  const handleTurnOnManagedIdentityIfNeeded = () => {
    if (!managedIdentity) {
      setFieldValue('managedIdentity', true)
    }
  }

  return (
    <FormRow hasTopBorder title="Data Layer" contentRowGap="16px">
      <Box mt={-3} mb={3}>
        <Typography color={theme.palette.Text.Secondary}>
          A data layer is an object that contains all of the information that you want to pass to a Tag Management
          System, or any external party that is on your website. For more information on data layers and how to set one
          up, please reference the following documentation:{' '}
          <Typography
            variant="bodyLink"
            color={theme.palette.Action.Primary}
            component="a"
            href="https://support.google.com/tagmanager/answer/6164391?hl=en"
            target="_blank"
          >
            Google Data Layer Overview
          </Typography>{' '}
          and{' '}
          <Typography
            variant="bodyLink"
            color={theme.palette.Action.Primary}
            component="a"
            href="https://developers.google.com/tag-platform/tag-manager/datalayer"
            target="_blank"
          >
            Data Layer Documentation.
          </Typography>
        </Typography>
      </Box>
      {!isMobileProperty && (
        <>
          <Box {...getBoxFormSwitchProps(managedIdentity)}>
            <FormSwitch
              inlineLabel
              label="Ketch Managed Cookie"
              formPropertyName="managedIdentity"
              onChange={(_, checked) => {
                if (!checked && !customIdentitySpaces) {
                  handleInitializeIdentitySpacesIfNeeded()
                  setFieldValue('customIdentitySpaces', true)
                }
              }}
            />
          </Box>
          <Box {...getBoxFormSwitchProps(customIdentitySpaces)}>
            <FormSwitch
              inlineLabel
              label="Custom Identifiers"
              formPropertyName="customIdentitySpaces"
              onChange={(_, checked) => {
                if (checked) {
                  handleInitializeIdentitySpacesIfNeeded()
                } else {
                  setFieldValue('identitySpaces', [])
                  handleTurnOnManagedIdentityIfNeeded()
                }
              }}
            />
          </Box>
        </>
      )}
      {customIdentitySpaces && (
        <Box pb={8} sx={{ position: 'relative' }}>
          {isMobileProperty && (
            <Banner
              title="Please ensure all Identifiers are explicitly provided to the Ketch Mobile software development kit (SDK)."
              severity="info"
              sx={{ mb: 2 }}
            />
          )}
          <Box
            p={1}
            sx={{
              border: `1px solid ${theme.palette.Common.Divider}`,
              borderRadius: '11px',
            }}
          >
            <Box display="flex">
              <Box flex={1.9} px={2} py={0.25}>
                <Typography variant="smallLabel">Identifier</Typography>
              </Box>
              <Box flex={1.9} px={2} py={0.25}>
                <Typography variant="smallLabel">Location</Typography>
              </Box>
              <Box flex={1.9} px={2} py={0.25}>
                <Typography variant="smallLabel">Name</Typography>
              </Box>
              <Box flex={1.9} px={2} py={0.25}>
                <Typography variant="smallLabel">Format</Typography>
              </Box>
              <Box flex={1.9} px={2} py={0.25}>
                <Typography variant="smallLabel">Key</Typography>
              </Box>
              <Box flex={1.9} px={2} py={0.25}>
                <Typography variant="smallLabel">Encoding</Typography>
              </Box>
              <Box flex={0.6} pl={2} py={0.25} />
            </Box>
            <FieldArray
              name="identitySpaces"
              render={({ push, remove }) => (
                <>
                  {identitySpaces.map((_, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      sx={{
                        borderTop: `1px solid ${index > 0 ? theme.palette.Common.Divider : 'transparent'}`,
                        height: 52,
                        overflow: 'hidden',
                        '& p': { display: 'none' },
                      }}
                    >
                      <Box flex={1.9} px={2} py={1.5} height={52}>
                        <FormDroplistButton
                          required
                          fullWidth
                          disableClearable
                          formPropertyName={`identitySpaces[${index}].identitySpaceID`}
                          size="small"
                          placeholder="Select"
                          items={identitySpacesList}
                          valueKey="ID"
                          componentsProps={{
                            paper: {
                              sx: {
                                margin: 0,
                                padding: '4px 8px 8px',
                                width: 'min-content',
                              },
                            },
                          }}
                          renderOption={(props, option, state, isSelected) => (
                            <ActionSheetItem selected={isSelected} {...props}>
                              <ListItemText selected={isSelected}>
                                <Box display="flex" flexDirection="column">
                                  <Typography noWrap variant="body">
                                    {option.name}
                                  </Typography>
                                  <Typography noWrap variant="footnote">
                                    {option.code}
                                  </Typography>
                                </Box>
                              </ListItemText>
                            </ActionSheetItem>
                          )}
                          sx={{
                            '& > div': {
                              minWidth: '100%',
                            },
                          }}
                        />
                      </Box>
                      <Box flex={1.9} px={2} py={1.5} height={52}>
                        {isMobileProperty ? (
                          <Typography lineHeight={1} color={theme.palette.Text.Secondary}>
                            Not Applicable
                          </Typography>
                        ) : (
                          <FormDroplistButton
                            required
                            fullWidth
                            disableClearable
                            formPropertyName={`identitySpaces[${index}].type`}
                            size="small"
                            placeholder="Select"
                            items={APPLICATION_IDENTITY_SPACE_TYPE_OPTIONS}
                            valueKey="id"
                            componentsProps={{
                              paper: {
                                sx: {
                                  margin: 0,
                                  padding: '4px 8px 8px',
                                  width: '180px',
                                },
                              },
                            }}
                            sx={{
                              '& > div': {
                                minWidth: '100%',
                              },
                            }}
                          />
                        )}
                      </Box>
                      <Box
                        flex={1.9}
                        px={2}
                        py={1.5}
                        sx={{
                          '& div, & input': {
                            minWidth: '100% !important',
                          },
                        }}
                      >
                        <FormInput
                          fullWidth
                          required
                          shouldUpdateDebounced
                          debounceWaitTime={1000}
                          size="small"
                          formPropertyName={`identitySpaces[${index}].variable`}
                          placeholder="Enter Name"
                        />
                      </Box>
                      <Box flex={1.9} px={2} py={1.5} height={52}>
                        {isMobileProperty ? (
                          <Typography lineHeight={1} color={theme.palette.Text.Secondary}>
                            Not Applicable
                          </Typography>
                        ) : (
                          <FormDroplistButton
                            required
                            fullWidth
                            disableClearable
                            formPropertyName={`identitySpaces[${index}].format`}
                            size="small"
                            placeholder="Select"
                            items={APPLICATION_IDENTITY_SPACE_FORMAT_OPTIONS}
                            valueKey="id"
                            onItemClick={newValue => {
                              if (newValue.id === ApplicationIdentitySpaceFormat.STRING) {
                                setFieldValue(`identitySpaces[${index}].key`, '')
                              }
                            }}
                            componentsProps={{
                              paper: {
                                sx: {
                                  margin: 0,
                                  padding: '4px 8px 8px',
                                  width: '150px',
                                },
                              },
                            }}
                            sx={{
                              '& > div': {
                                minWidth: '100%',
                              },
                            }}
                          />
                        )}
                      </Box>
                      <Box display="flex" flex={1.9} px={2} py={1.5} height={52}>
                        {identitySpaces?.[index]?.format === ApplicationIdentitySpaceFormat.STRING ? (
                          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            <Typography lineHeight={1} color={theme.palette.Text.Secondary}>
                              Not Applicable
                            </Typography>
                            <Tooltip title="Key is not required for identities in string format">
                              <Box display="inline-flex">
                                <Icon
                                  name="FUnknown"
                                  iconColor={theme.palette.Text.Secondary}
                                  sx={{ fontSize: '14px' }}
                                />
                              </Box>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              '& div, & input': {
                                minWidth: '100% !important',
                              },
                            }}
                          >
                            <FormInput
                              fullWidth
                              required
                              shouldUpdateDebounced
                              debounceWaitTime={1000}
                              size="small"
                              formPropertyName={`identitySpaces[${index}].key`}
                              placeholder="Enter Key"
                            />
                          </Box>
                        )}
                      </Box>
                      <Box flex={1.9} px={2} py={1.5} height={52}>
                        <FormDroplistButton
                          required
                          fullWidth
                          disableClearable
                          formPropertyName={`identitySpaces[${index}].encoding`}
                          size="small"
                          placeholder="Select"
                          items={APPLICATION_IDENTITY_SPACE_ENCODING_OPTIONS}
                          valueKey="id"
                          onItemClick={newValue => {
                            if (newValue.id === ApplicationIdentitySpaceEncoding.NONE) {
                              setFieldValue(`identitySpaces[${index}].key`, '')
                            }
                          }}
                          sx={{
                            '& > div': {
                              minWidth: '100%',
                            },
                          }}
                        />
                      </Box>
                      <Box
                        flex={0.6}
                        pl={2}
                        py={1.5}
                        height={52}
                        sx={{
                          '& button': {
                            marginTop: '2px',
                            minWidth: 24,
                            width: 24,
                            height: 24,
                          },
                        }}
                      >
                        <TooltipButton
                          title={
                            isDeleteIdentifierDisabled ? 'A mobile property must have at least one identifier' : ''
                          }
                          variant="iconCustomRounded"
                          color="tertiary"
                          disabled={isDeleteIdentifierDisabled}
                          onClick={() => {
                            if (identitySpaces.length === 1) {
                              setFieldValue('customIdentitySpaces', false)
                              handleTurnOnManagedIdentityIfNeeded()
                            }
                            remove(index)
                          }}
                        >
                          <Icon name="OBin" sx={{ fontSize: '16px' }} />
                        </TooltipButton>
                      </Box>
                    </Box>
                  ))}
                  <Button
                    color="secondary"
                    startIcon={<Icon name="OPlus" />}
                    onClick={() => {
                      push(getIdentitySpaceEmptyValue(isMobileProperty))
                    }}
                    sx={{
                      position: 'absolute',
                      left: 0,
                      bottom: 24,
                    }}
                  >
                    Add Identifiers
                  </Button>
                </>
              )}
            />
          </Box>
        </Box>
      )}
      {!isMobileProperty && (
        <Box {...getBoxFormSwitchProps(supportProxy)}>
          <FormSwitch inlineLabel label="Proxy Support" formPropertyName="supportProxy" />
        </Box>
      )}
      {supportProxy && !isMobileProperty && (
        <Box mb={3}>
          <FormInput
            fullWidth
            required
            label="Proxy URL"
            formPropertyName="proxy"
            placeholder="Input the full URL including path"
          />
        </Box>
      )}
      {!isMobileProperty && (
        <Box {...getBoxFormSwitchProps(customPolicyScope)}>
          <FormSwitch inlineLabel label="Custom Jurisdiction" formPropertyName="customPolicyScope" />
        </Box>
      )}
      {customPolicyScope && !isMobileProperty && (
        <FormInput
          required
          label="Variable Name"
          formPropertyName="defaultPolicyScopeVariable"
          placeholder="Example: juicebox.edu"
        />
      )}
    </FormRow>
  )
}
