import { ApiQueryKeys } from 'api/common/queryKeys'
import { CustomPaginatedQueryConfig } from 'api/common/paginatedQuery'
import { fetchWebTags } from 'api/webtags/fetchers/fetchWebTags'
import { useAuth } from 'utils/hooks/useAuth'
import { createUsePaginatedQueryPreservedPage } from 'api/common/createUsePaginatedQueryPreservedPage'

const useCustomPaginatedQuery = createUsePaginatedQueryPreservedPage({
  queryKey: ApiQueryKeys.webTagsPaginated,
  queryFn: fetchWebTags,
  select: res => res?.data?.tags || [],
})

type QueryConfig = CustomPaginatedQueryConfig<typeof useCustomPaginatedQuery, 'organizationCode'>

export const useWebTagsPaginatedPreservePagination = (config: QueryConfig) => {
  const { userData } = useAuth()

  return useCustomPaginatedQuery({
    ...config,
    params: {
      ...config!.params,
      organizationCode: userData.organizationCode!,
    },
  })
}
