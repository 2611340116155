import { UserRoleDTO } from 'interfaces/users/userRole'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

// Role group codes
export enum RoleGroup {
  Organization = 'org',
  DataMapAssets = 'data_map_assets',
  CarCapture = 'car_capture',
  CarOrchConfig = 'car_orch_config',
  PrivacyProgramFoundations = 'privacy_program_foundations',
  CarOrchQueue = 'car_orch_queue',
  DataMapProcessingActivities = 'data_map_processing_activities',
  PrivacyProgramRisk = 'privacy_program_risk',
  DataMapSystems = 'data_map_systems',
  DeveloperTools = 'developer_tools',
}

// Mapping of role group to display name
export const roleGroupNames: {
  [group: string]: string
} = {
  [RoleGroup.Organization]: 'Organization',
  [RoleGroup.DataMapAssets]: 'Data Map',
  [RoleGroup.CarCapture]: 'Consent & Rights',
  [RoleGroup.CarOrchConfig]: 'Consent & Rights',
  [RoleGroup.PrivacyProgramFoundations]: 'Privacy Program',
  [RoleGroup.CarOrchQueue]: 'Consent & Rights',
  [RoleGroup.DataMapProcessingActivities]: 'Data Map',
  [RoleGroup.PrivacyProgramRisk]: 'Privacy Program',
  [RoleGroup.DataMapSystems]: 'Data Map',
  [RoleGroup.DeveloperTools]: 'Developer Tools',
}

// Mapping of role group to description
export const roleGroupDescriptions: {
  [group: string]: string
} = {
  [RoleGroup.PrivacyProgramFoundations]: 'Foundations',
  [RoleGroup.PrivacyProgramRisk]: 'Risk',
  [RoleGroup.CarCapture]: 'Capture',
  [RoleGroup.CarOrchConfig]: 'Orchestration, Consent',
  [RoleGroup.CarOrchQueue]: 'Orchestration, Rights Requests',
  [RoleGroup.DataMapSystems]: 'Systems',
  [RoleGroup.DataMapAssets]: 'Assets',
  [RoleGroup.DataMapProcessingActivities]: 'ROPA',
}

// Mapping of role group to priority, which dictates the order in which the groups should display
export const roleGroupPriority: {
  [group: string]: number
} = {
  [RoleGroup.Organization]: 1,
  [RoleGroup.PrivacyProgramFoundations]: 2,
  [RoleGroup.PrivacyProgramRisk]: 3,
  [RoleGroup.CarCapture]: 4,
  [RoleGroup.CarOrchConfig]: 5,
  [RoleGroup.CarOrchQueue]: 6,
  [RoleGroup.DataMapSystems]: 7,
  [RoleGroup.DataMapAssets]: 8,
  [RoleGroup.DataMapProcessingActivities]: 9,
  [RoleGroup.DeveloperTools]: 10,
}

export type RoleTooltipDetails = {
  permLevelKey: string
  permLevelTitle: string
  permLevelDescription: string
}

// Mapping of role group to tooltip
export const roleGroupTooltips: {
  [group: string]: RoleTooltipDetails[]
} = {
  [RoleGroup.Organization]: [
    {
      permLevelKey: 'admin',
      permLevelTitle: 'Admin',
      permLevelDescription: 'Full access to Organization’s settings.',
    },
    {
      permLevelKey: 'user',
      permLevelTitle: 'User',
      permLevelDescription: 'Limited to no access to Organization’s settings.',
    },
    {
      permLevelKey: 'doc',
      permLevelTitle: 'Documentation Only',
      permLevelDescription: 'No access to Ketch Platform, login credentials are used to access platform documentation.',
    },
  ],
  [RoleGroup.PrivacyProgramFoundations]: [
    {
      permLevelKey: 'admin',
      permLevelTitle: 'Admin',
      permLevelDescription:
        'Full access to all Foundations features under Privacy Program like Jurisdictions, Purposes and more.',
    },
    {
      permLevelKey: 'write',
      permLevelTitle: 'Write',
      permLevelDescription: 'User is able to create new items, with a limited access to some of the features.',
    },
    {
      permLevelKey: 'read',
      permLevelTitle: 'Read',
      permLevelDescription: 'View-only access, without ability to edit or create.',
    },
    {
      permLevelKey: 'none',
      permLevelTitle: 'None',
      permLevelDescription: 'No access to features under this section.',
    },
  ],
  [RoleGroup.PrivacyProgramRisk]: [
    {
      permLevelKey: 'admin',
      permLevelTitle: 'Admin',
      permLevelDescription:
        'Full access to all Risk features under Privacy Program like Assessments and Processing Activities.',
    },
    {
      permLevelKey: 'write',
      permLevelTitle: 'Write',
      permLevelDescription: 'User is able to create new items, with a limited access to some of the features.',
    },
    {
      permLevelKey: 'respond',
      permLevelTitle: 'Respond Only',
      permLevelDescription: 'User is able to collaborate on Assessments by answering the assigned questions.',
    },
    {
      permLevelKey: 'read',
      permLevelTitle: 'Read',
      permLevelDescription: 'View-only access, without ability to edit or create.',
    },
    {
      permLevelKey: 'none',
      permLevelTitle: 'None',
      permLevelDescription: 'No access to features under this section.',
    },
  ],
  [RoleGroup.CarCapture]: [
    {
      permLevelKey: 'admin',
      permLevelTitle: 'Admin',
      permLevelDescription:
        'Full access to all Capture features under Consent & Rights like Experiences, Subscriptions and more.',
    },
    {
      permLevelKey: 'write',
      permLevelTitle: 'Write',
      permLevelDescription: 'User is able to create new items, with a limited access to some of the features.',
    },
    {
      permLevelKey: 'read',
      permLevelTitle: 'Read',
      permLevelDescription: 'View-only access, without ability to edit or create.',
    },
    {
      permLevelKey: 'none',
      permLevelTitle: 'None',
      permLevelDescription: 'No access to features under this section.',
    },
  ],
  [RoleGroup.CarOrchConfig]: [
    {
      permLevelKey: 'admin',
      permLevelTitle: 'Admin',
      permLevelDescription: 'Full access to configuring orchestration foundations like creating System connections.',
    },
    {
      permLevelKey: 'write',
      permLevelTitle: 'Write',
      permLevelDescription: 'User is able to edit System’s connections, with a limited access to other features.',
    },
    {
      permLevelKey: 'read',
      permLevelTitle: 'Read',
      permLevelDescription: 'View-only access, without ability to edit or create.',
    },
    {
      permLevelKey: 'none',
      permLevelTitle: 'None',
      permLevelDescription: 'No access to features under this section.',
    },
  ],
  [RoleGroup.CarOrchQueue]: [
    {
      permLevelKey: 'admin',
      permLevelTitle: 'Admin',
      permLevelDescription: 'Full access to Rights Requests management (DSARs).',
    },
    {
      permLevelKey: 'write',
      permLevelTitle: 'Write',
      permLevelDescription: 'User is able to create new requests and manage ongoing requests.',
    },
    {
      permLevelKey: 'read',
      permLevelTitle: 'Read',
      permLevelDescription: 'View-only access, without ability to edit or create.',
    },
    {
      permLevelKey: 'none',
      permLevelTitle: 'None',
      permLevelDescription: 'No access to Rights Requests.',
    },
  ],
  [RoleGroup.DataMapSystems]: [
    {
      permLevelKey: 'admin',
      permLevelTitle: 'Admin',
      permLevelDescription: 'Full access to Systems feature.',
    },
    {
      permLevelKey: 'write',
      permLevelTitle: 'Write',
      permLevelDescription: 'User is able to add new Systems, edit and manage System catalog.',
    },
    {
      permLevelKey: 'read',
      permLevelTitle: 'Read',
      permLevelDescription: 'View-only access, without ability to edit or create.',
    },
    {
      permLevelKey: 'report',
      permLevelTitle: 'Report Only',
      permLevelDescription: 'Only able to generate a systems report.',
    },
    {
      permLevelKey: 'none',
      permLevelTitle: 'None',
      permLevelDescription: 'No access to Systems.',
    },
  ],
  [RoleGroup.DataMapAssets]: [
    {
      permLevelKey: 'admin',
      permLevelTitle: 'Admin',
      permLevelDescription: 'Full access to Assets and Labels feature.',
    },
    {
      permLevelKey: 'write',
      permLevelTitle: 'Write',
      permLevelDescription: 'User is able to manage and edit their Assets catalog.',
    },
    {
      permLevelKey: 'read',
      permLevelTitle: 'Read',
      permLevelDescription: 'View-only access, without ability to edit or create.',
    },
    {
      permLevelKey: 'none',
      permLevelTitle: 'None',
      permLevelDescription: 'No access to Assets and Labels.',
    },
  ],
  [RoleGroup.DataMapProcessingActivities]: [
    {
      permLevelKey: 'admin',
      permLevelTitle: 'Admin',
      permLevelDescription: 'Full access to manage processing activities and the ROPA report.',
    },
    {
      permLevelKey: 'write',
      permLevelTitle: 'Write',
      permLevelDescription: 'User is able to create and edit processing activities and export the ROPA report.',
    },
    {
      permLevelKey: 'read',
      permLevelTitle: 'Read',
      permLevelDescription:
        'View-only access to processing activites and the ROPA report, without ability to edit or create.',
    },
    {
      permLevelKey: 'none',
      permLevelTitle: 'None',
      permLevelDescription: 'No access to this feature.',
    },
  ],
  [RoleGroup.DeveloperTools]: [
    {
      permLevelKey: 'admin',
      permLevelTitle: 'Admin',
      permLevelDescription: 'Full access to Developer tools under this Organization.',
    },
    {
      permLevelKey: 'none',
      permLevelTitle: 'None',
      permLevelDescription: 'No access to this feature.',
    },
  ],
}

// Mapping of role group to the entitlement required for it to display, [] for always display
export const roleGroupEntitlement: {
  [group: string]: ENTITLEMENTS[]
} = {
  [RoleGroup.Organization]: [],
  [RoleGroup.DataMapAssets]: [ENTITLEMENTS.ASSET_MANAGER],
  [RoleGroup.CarCapture]: [ENTITLEMENTS.EXPERIENCES],
  [RoleGroup.CarOrchConfig]: [],
  [RoleGroup.PrivacyProgramFoundations]: [],
  [RoleGroup.CarOrchQueue]: [],
  [RoleGroup.DataMapProcessingActivities]: [],
  [RoleGroup.PrivacyProgramRisk]: [ENTITLEMENTS.ASSESSMENTS, ENTITLEMENTS.PROCESSING_ACTIVITIES],
  [RoleGroup.DataMapSystems]: [ENTITLEMENTS.DATA_SYSTEMS],
  [RoleGroup.DeveloperTools]: [],
}

/**
 * Parses the role display name from the name returned by the backend.
 *
 * The syntax Max is using in the provisioning spreadsheet is such that the role name
 * will have some text followed by a "|" character, and the text after the "|"
 * is to be used as the display name.
 */
export const parseRoleDisplayName = (name?: string) => {
  return name?.split('|').at(-1)?.trim() || 'None'
}

export type UserRolesDisplayData = {
  section: string
  description: string
  tooltips: RoleTooltipDetails[]
  priority: number
  permission: string
  id: number
}

export const getEntitledRoleGroups = (
  isEntitled: (requiredEntitlements: ENTITLEMENTS | ENTITLEMENTS[], requireAll?: boolean) => boolean,
) => {
  return Object.values(RoleGroup).filter(group => isEntitled(roleGroupEntitlement[group], false))
}

export const getUserRoleData = (
  userRoles: UserRoleDTO[],
  isEntitled: (requiredEntitlements: ENTITLEMENTS | ENTITLEMENTS[], requireAll?: boolean) => boolean,
) => {
  // Get all role groups to which the organization is entitled
  const entitledRoleGroups = getEntitledRoleGroups(isEntitled)

  const userRoleData = entitledRoleGroups.map((group, index) => {
    // Determine the users current role for this group, if any
    const userRole = userRoles.find(role => group === role.group)

    return {
      section: roleGroupNames[group],
      description: roleGroupDescriptions[group],
      tooltips: roleGroupTooltips[group],
      priority: roleGroupPriority[group],
      permission: parseRoleDisplayName(userRole?.name),
      id: index,
    }
  })

  return userRoleData
}

export const getCopiedFilteredRoles = (
  roles: UserRoleDTO[],
  isEntitled: (requiredEntitlements: ENTITLEMENTS | ENTITLEMENTS[], requireAll?: boolean) => boolean,
): UserRoleDTO[] => {
  return [...(roles || [])]
    .filter(
      r =>
        roleGroupNames[r.group] &&
        r.code !== 'everyone' &&
        r.code !== 'org_owner' &&
        r.code !== 'ketch_admin' &&
        (r.group !== RoleGroup.CarCapture || isEntitled(ENTITLEMENTS.EXPERIENCES)) &&
        (r.group !== RoleGroup.PrivacyProgramRisk ||
          isEntitled([ENTITLEMENTS.ASSESSMENTS, ENTITLEMENTS.PROCESSING_ACTIVITIES], false)) &&
        (r.group !== RoleGroup.DataMapSystems || isEntitled(ENTITLEMENTS.DATA_SYSTEMS)) &&
        (r.group !== RoleGroup.DataMapAssets || isEntitled(ENTITLEMENTS.ASSET_MANAGER)),
    )
    .sort((a, b) => (a?.priority ?? 0) - (b?.priority ?? 0))
}

// See also getExistingUserRoles at src/pages/settings/users/upsert/utils/getUserFormValues.ts
// See also getApiKeyFormSectionRolesOptions at src/pages/developers/apiKeys/upsert/utils/getApiKeyFormSectionRolesOptions
