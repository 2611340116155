import React, { useState } from 'react'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Message as TwilioMessage } from '@twilio/conversations'
import { showToast } from 'components/modals/AlertComponent'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { InternalMessage } from 'pages/policyCenter/processingActivities/view/Comment/components'
import { useAuth } from 'utils/hooks/useAuth'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { UserShortDTO } from 'interfaces/users/users'
import { ActionSheetItem, ActionSheetWrapper, Button, Icon } from '@ketch-com/deck'

type Props = {
  message: TwilioMessage
  isLast?: boolean
  users?: UserShortDTO[]
}

export const Message: React.FC<Props> = ({ message, isLast, users = [] }) => {
  const { isPermitted } = useIsPermitted()
  const { userData } = useAuth()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRemoveMessage = async () => {
    try {
      await message.remove()
      showToast({ content: 'Message Deleted', type: 'success' })
    } catch (error) {
      showToast({ content: 'Failed to remove message', type: 'error' })
    }
  }

  const internalMessageAuthor = users.find(user => user.ID === message.author)
  const isCurrentUserMessageAuthor = userData?.userId === message?.author
  const messageBody = message?.body?.split?.('\n')?.filter?.(Boolean) || []

  return (
    <Box
      borderRadius={3.5}
      display="flex"
      flexDirection="column"
      key={message?.sid}
      mb={isLast ? 0 : 2}
      bgcolor={!isCurrentUserMessageAuthor ? 'sphereFaded.main' : 'fadedGrey.main'}
      padding="12px 16px"
    >
      {/* Author and Sent Row  */}

      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        {/* Left */}

        <Box display="flex" alignItems="center">
          <InternalMessage message={message} author={internalMessageAuthor} />
        </Box>

        {/* Right: createAt Date, ButtonOptions */}

        <Box display="flex" alignItems="center">
          {isCurrentUserMessageAuthor && isPermitted(PERMISSIONS.PROCESSING_ACTIVITY_WRITE) && (
            <>
              <Button size="medium" variant="iconSubtle" onClick={handleClickListItem} color="tertiary">
                <Icon name="OMore" />
              </Button>
              <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
                <ActionSheetItem
                  onClick={() => {
                    handleClose()
                    handleRemoveMessage()
                  }}
                >
                  Delete
                </ActionSheetItem>
              </ActionSheetWrapper>
            </>
          )}
        </Box>
      </Box>

      {/* Message Body Container */}

      <Box>
        {messageBody?.map?.((line, index) => {
          const isLastLine = index === messageBody.length - 1
          return (
            <Box key={`${line}_${index}`} display="flex" alignItems="center" pl={0.5} mb={!isLastLine ? 1 : 0}>
              <Typography variant="body">{line}</Typography>
            </Box>
          )
        })}
        <Typography variant="smallBody" color="darkDuskFaded.main">
          <FormattedDateRenderer date={(message?.dateCreated?.getTime() || 0) / 1000} />
        </Typography>
      </Box>
    </Box>
  )
}
