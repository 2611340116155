import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { UpdateTrackerPurposesBody, Tracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'

type Params = APIRequestParams<{
  encodedTrackerKey: string
  data: {
    purposeIDs: UpdateTrackerPurposesBody
  }
}>

export const upsertTrackerPurposes = ({ encodedTrackerKey, data }: Params) => {
  return API.put<Tracker>(
    formatRequestString({
      entityUrl: `/api/privacy-configuration/tracker/${encodedTrackerKey}/purposes`,
    }),
    data,
  )
}
