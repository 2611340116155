import React from 'react'
import { useQueryClient } from 'react-query'
import { useSetState } from 'react-use'

import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { WebTagDTO } from 'interfaces/webtags/WebTags'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { ToggleWebTagEnabledStateModal } from 'components/modals/webTags/ToggleWebTagEnabledStateModal'
import { Box, Typography } from '@mui/material'
import { TooltipButton } from '@ketch-com/deck'

type Props = {
  webTag: WebTagDTO
}

export const WebTagViewActions: React.FC<Props> = ({ webTag }) => {
  const queryClient = useQueryClient()
  const { isPermitted } = useIsPermitted()

  const [{ isStatusOpen }, setState] = useSetState({
    isStatusOpen: false,
  })

  const { ID, enabled, metadata, purposes } = webTag
  const isPermittedToManageWebTags = isPermitted(PERMISSIONS.WEB_TAGS_WRITE)

  return (
    <>
      <Box display="flex" gap={3}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="smallBody" color="darkDuskFaded.main">
            Last Updated
          </Typography>
          <Typography variant="smallBody" color="darkDusk.main">
            <FormattedDateRenderer date={metadata?.updatedAt} />
          </Typography>
        </Box>

        {isPermittedToManageWebTags && (
          <TooltipButton
            color="primary"
            disabled={!purposes?.length}
            title={!purposes?.length ? 'Add purposes to enable tag controls.' : ''}
            size="large"
            onClick={() => {
              setState({ isStatusOpen: true })
            }}
          >
            {enabled ? 'Disable' : 'Enable'}
          </TooltipButton>
        )}
      </Box>

      {isStatusOpen && (
        <ToggleWebTagEnabledStateModal
          webTagId={ID}
          onSubmit={async () => {
            await queryClient.refetchQueries([ApiQueryKeys.webTag, { webTagId: ID }])
            setState({ isStatusOpen: false })
          }}
          onCancel={() => {
            setState({ isStatusOpen: false })
          }}
        />
      )}
    </>
  )
}
