import { ResidencyWithCountryDetails } from 'pages/dataSystems/DataSystem/components/DataResidencyWithEdit'
import { DataMapRegionDTO } from '@ketch-com/figurehead'
import { ActionSheetItem, Card, Chip, DropListButton, ListItemText, TextInput } from '@ketch-com/deck'
import { Card as MuiCard, Grid, Typography } from '@mui/material'
import { Logger } from 'utils/Logger'
import {
  RetentionPeriodType,
  RetentionPeriodTypeOptions,
  SecurityMeasureOptions,
  SecurityMeasures,
} from 'pages/dataSystems/DataSystem/types'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

type Props = {
  regions: DataMapRegionDTO[]
  setResidencyDetails: (values: ResidencyWithCountryDetails) => void
  residencyDetails: ResidencyWithCountryDetails
}

export const ResidencyCard = ({ values }: { values: ResidencyWithCountryDetails }) => {
  return (
    <MuiCard>
      <Card
        cardTitle={values.countryDetails.name}
        cardVariant="media"
        cardContent={
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="smallLabel" color="textSecondary">
                Security Measures
              </Typography>
            </Grid>
            {Array.isArray(values?.security) && values.security.length > 0 ? (
              values.security.map(s => (
                <Grid item xs={12} key={s}>
                  <Typography variant="body" color="textSecondary">
                    {SecurityMeasureOptions[s as SecurityMeasures].title}
                  </Typography>
                </Grid>
              ))
            ) : (
              <EmptyValueRenderer />
            )}
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <Typography variant="smallLabel" color="textSecondary">
                Physical Retention
              </Typography>
            </Grid>
            {values.retentionPeriod && values.retentionPeriodType ? (
              <Grid item xs={12}>
                <Typography variant="body" color="textSecondary">
                  {`${values.retentionPeriod} ${
                    RetentionPeriodTypeOptions[parseInt(values.retentionPeriodType) as RetentionPeriodType].title
                  }`}
                </Typography>
              </Grid>
            ) : (
              <EmptyValueRenderer />
            )}
          </Grid>
        }
      />
    </MuiCard>
  )
}

export const EditResidency = ({ regions, residencyDetails, setResidencyDetails }: Props) => {
  function onChange(field: string, newValue: DataMapRegionDTO | any) {
    if (!newValue) {
      Logger.error('[DataMap System Edit] Error selecting country')
      return
    }
    if (field === 'country') {
      setResidencyDetails({ ...residencyDetails, countryDetails: newValue, country: newValue.code })
    } else if (field === 'security') {
      const doesExist = residencyDetails.security?.findIndex(i => i === newValue) || -1
      residencyDetails.security = residencyDetails.security || []
      if (doesExist >= 0) {
        setResidencyDetails({
          ...residencyDetails,
          security: [
            ...residencyDetails.security.slice(0, doesExist),
            ...residencyDetails.security.slice(doesExist + 1),
          ],
        })
      } else {
        setResidencyDetails({ ...residencyDetails, security: [...residencyDetails.security, +newValue] })
      }
    } else if (field === 'retentionPeriod') {
      setResidencyDetails({ ...residencyDetails, retentionPeriod: +newValue })
    }
  }

  return (
    <MuiCard sx={{ padding: '5px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DropListButton
            options={regions}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            renderOption={(props, option, { selected }) => (
              <ActionSheetItem key={option.code} selected={selected} {...props}>
                <ListItemText selected={selected}>{option.name}</ListItemText>
              </ActionSheetItem>
            )}
            getOptionLabel={option => option.name || ''}
            label="Residency"
            placeholder="Select Country"
            size="small"
            width="100%"
            value={residencyDetails.countryDetails}
            onChange={(_, v) => onChange('country', v)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography paragraph variant="smallLabel">
            Security Measures
          </Typography>
          {Object.keys(SecurityMeasureOptions).map((key, index) => (
            <Chip
              key={key}
              clickable
              selectable
              isSelected={residencyDetails.security.some(i => i === +key)}
              size="small"
              onClick={() => onChange('security', key)}
              label={SecurityMeasureOptions[key as unknown as SecurityMeasures].title}
              sx={{ margin: '5px' }}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography paragraph variant="smallLabel">
                Physical Retention
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextInput
                placeholder="Enter Number"
                type="number"
                size="small"
                onChange={e => onChange('retentionPeriod', e.currentTarget.value)}
                value={residencyDetails.retentionPeriod || ''}
              />
            </Grid>
            <Grid item xs={6}>
              <DropListButton
                options={Object.keys(RetentionPeriodTypeOptions)}
                isOptionEqualToValue={(option, selection) => option === selection}
                renderOption={(props, option, { selected }) => (
                  <ActionSheetItem key={option} selected={selected} {...props}>
                    <ListItemText selected={selected}>
                      {RetentionPeriodTypeOptions[option as unknown as RetentionPeriodType].title}
                    </ListItemText>
                  </ActionSheetItem>
                )}
                getOptionLabel={option => RetentionPeriodTypeOptions[option as unknown as RetentionPeriodType].title}
                placeholder="Select Duration"
                size="small"
                width="50%"
                value={residencyDetails.retentionPeriodType}
                onChange={(_, v) => onChange('retentionPeriodType', v)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MuiCard>
  )
}
