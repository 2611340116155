import React from 'react'
import { Box } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { Waypoint } from 'react-waypoint'

import { Text } from 'components/ui-kit/typography/Text'
import { Badge } from 'components/ui-kit/badge/Badge'
import { FILTER_STATES, LIST_DISPLAY_MODES } from './MegaFilter/constants'
import { Table } from 'components/ui-layouts/table/Table'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { getAssetCloudImgUrl } from 'pages/assetManager/utils'
import { AssetTypeIcon } from 'pages/assetManager/components'
import { getAssetTypeDisplayNameByEnum } from './AssetListItem/utils'
import { AssetsListTableActionsCellRenderer } from './AssetsListTableActionsCellRenderer'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { UseAssetsInfiniteV3Return } from 'api/assets/queries/useAssetsInfiniteV3'

/* hacky helper function to required because table/layout is unresponsive */
const getTableColumnWidthMultiplier = (filterState: FILTER_STATES) => {
  switch (filterState) {
    case FILTER_STATES.COLLAPSED:
      return 24 / 19.5
    case FILTER_STATES.SELECTING:
      return 22.25 / 19.5
    default:
      return 1
  }
}

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      tableRowSelected: {
        border: `1px solid ${palette.sphere.main}`,
      },
      assetsTable: {
        backgroundColor: palette.white.main,
        borderRadius: spacing(1),
      },
      tableAssetTypeIcon: {
        width: 22,
        height: 22,
        marginRight: spacing(1),
      },
      dataSetIcon: {
        marginRight: spacing(1),
      },
      labelsCountPill: {
        backgroundColor: palette.marine.main,
        borderRadius: '50%',
        padding: spacing(0.75, 0.75),
      },
      buttonOptions: {
        backgroundColor: palette.white.main,
      },
    }),
  { name: 'AssetsListView' },
)

type Props = {
  displayMode: LIST_DISPLAY_MODES
  filterState: FILTER_STATES
  assets: AssetSummaryDTO[]
  onAssetClick: (event: React.MouseEvent<HTMLDivElement>) => void
  selectedAssets: string[]
  isLoading: boolean
  isFetchingNextPage?: boolean
  fetchNextPage: UseAssetsInfiniteV3Return['fetchNextPage']
}

export const AssetListTable: React.FC<Props> = ({
  displayMode,
  assets,
  filterState,
  onAssetClick,
  selectedAssets,
  isLoading,
  isFetchingNextPage,
  fetchNextPage,
}) => {
  const classes = useStyles()

  if (displayMode === LIST_DISPLAY_MODES.TILES || isLoading) return null

  return (
    <Table
      isSmallHeaderText
      items={assets}
      className={classes.assetsTable}
      rowClassName={rowData => {
        if (selectedAssets.includes(rowData.asset?.resource?.id as string)) {
          return classes.tableRowSelected
        }
        return ''
      }}
      // TODO:BAC = temporarily disabling row click functionality because bulk selection/update is not ready on BE yet.
      // onRowClick={(rowData, rowIndex, event) => {
      //   if (!!event) onAssetClick(event)
      // }}
      rowIdKey={rowData => {
        return rowData?.asset?.resource?.id as string
      }}
      cellSettings={{
        name: {
          width: 350 * getTableColumnWidthMultiplier(filterState),
          label: 'Name',
          cellRenderer: assetSummary => (
            <NameAndCodeCellRenderer
              name={assetSummary?.asset?.resource?.name}
              code={assetSummary?.asset?.resource?.id || ''}
            />
          ),
        },
        type: {
          width: 110 * getTableColumnWidthMultiplier(filterState),
          label: 'Type',
          cellRenderer: (assetSummary, index) => {
            return (
              <Box display="flex" alignItems="center">
                <AssetTypeIcon
                  assetType={assetSummary?.asset?.resource?.resourceType?.canonicalResourceType || 0}
                  className={classes.dataSetIcon}
                />
                <Text size={11} weight={600} color="grey">
                  {getAssetTypeDisplayNameByEnum(
                    assetSummary?.asset?.resource?.resourceType?.canonicalResourceType || 0,
                  )}
                </Text>
                {(assets?.length || 0) - 5 === index ? (
                  <Waypoint
                    onEnter={() => {
                      if (!isFetchingNextPage) fetchNextPage()
                    }}
                  />
                ) : null}
              </Box>
            )
          },
        },

        technologies: {
          width: 110 * getTableColumnWidthMultiplier(filterState),
          label: 'Technologies',
          cellRenderer: assetSummary => {
            return (
              <Box display="flex" alignItems="center">
                <img
                  className={classes.tableAssetTypeIcon}
                  src={getAssetCloudImgUrl(assetSummary?.asset?.connection?.technology || '')}
                  alt={getAssetCloudImgUrl(assetSummary?.asset?.connection?.technology || '')}
                />
              </Box>
            )
          },
        },

        personalDataType: {
          width: 140 * getTableColumnWidthMultiplier(filterState),
          label: 'Personal Data Type',
          cellRenderer: assetSummary =>
            assetSummary?.asset?.dataCategory?.[0]?.value ? (
              <Badge variant="neutral">{assetSummary?.asset?.dataCategory?.[0]?.value}</Badge>
            ) : (
              <EmptyValueRenderer />
            ),
        },

        labels: {
          width: 100 * getTableColumnWidthMultiplier(filterState),
          label: 'Classifications',
          cellRenderer: ({ asset }) => {
            const assignedLabels = asset?.assignedLabels || []
            const inferredLabels = asset?.inferredLabels || []
            const collectedLabels = [...assignedLabels, ...inferredLabels]
            return (
              <Box className={classes.labelsCountPill} display="flex" alignItems="center">
                <Text size={11} weight={500} color="persianBlue">
                  {collectedLabels?.length || 0}
                </Text>
              </Box>
            )
          },
        },
        actions: {
          cellRenderer: assetSummary => {
            const shouldDisableEditAndViewLinks = !assetSummary?.asset?.resource?.resourceType?.canonicalResourceType
            return shouldDisableEditAndViewLinks ? null : (
              <AssetsListTableActionsCellRenderer
                assetSummary={assetSummary}
                buttonOptionsClassName={classes.buttonOptions}
              />
            )
          },
        },
      }}
    />
  )
}
