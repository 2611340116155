import { Button, Icon, InfoRow, InfoRowProps, Spinner } from '@ketch-com/deck'
import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { DataMapRegionDTO, InstalledDataSystemDataMapResidencyV2DTO } from '@ketch-com/figurehead'
import { EditResidency, ResidencyCard } from 'pages/dataSystems/DataSystem/components/EditResidency'

interface Props extends InfoRowProps {
  values: InstalledDataSystemDataMapResidencyV2DTO[]
  setValues: (value: InstalledDataSystemDataMapResidencyV2DTO[]) => void
  regionResponse: { data: DataMapRegionDTO[]; isLoading: boolean }
}

export type ResidencyWithCountryDetails = InstalledDataSystemDataMapResidencyV2DTO & {
  countryDetails: DataMapRegionDTO
  security: InstalledDataSystemDataMapResidencyV2DTO[]
}

const emptyEntry: ResidencyWithCountryDetails = {
  countryDetails: {},
  security: [],
}

function populateWithCountryDetails(values: InstalledDataSystemDataMapResidencyV2DTO[], regions: DataMapRegionDTO[]) {
  const o: ResidencyWithCountryDetails[] = values.map(v => {
    const country = regions.find(r => r.code === v.country) || {}
    return { ...v, countryDetails: country, security: v.security || [] }
  })
  return o
}

export default function DataResidencyWithEdit({ values, setValues, regionResponse, ...rest }: Props) {
  const [localValues, setLocalValues] = useState<ResidencyWithCountryDetails[]>([])
  const [isEditing, setIsEditing] = useState(false)
  const isEmpty = localValues.length === 0
  const { data: regions, isLoading: isRegionsLoading } = regionResponse

  useEffect(() => {
    if (!regions) {
      return
    }
    const o = populateWithCountryDetails(values, regions)
    setLocalValues(o)
  }, [regions, values])

  if (isRegionsLoading) {
    return <Spinner size={14} />
  }

  return (
    <InfoRow
      {...rest}
      isEditable
      isEditing={isEditing}
      onEditChange={() => {
        setIsEditing(true)
      }}
      onAcceptChange={() => {
        setIsEditing(false)
        setValues(localValues)
      }}
      onCancelChange={() => {
        setIsEditing(false)
        setLocalValues(populateWithCountryDetails(values, regions))
      }}
      isEmpty={isEmpty}
    >
      {isEditing ? (
        <Grid container spacing={2}>
          {localValues.map((i, index) => (
            <Grid item xs={12} key={i.country}>
              <EditResidency
                residencyDetails={i}
                setResidencyDetails={v =>
                  setLocalValues([...localValues.slice(0, index), v, ...localValues.slice(index + 1)])
                }
                regions={regions}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              color="primary"
              onClick={() => setLocalValues([...localValues, emptyEntry])}
              disabled={localValues.some(i => i.country === undefined)}
              startIcon={<Icon name={'OArrowDown'} />}
              variant="outlined"
            >
              Add
            </Button>
          </Grid>
        </Grid>
      ) : !isEmpty ? (
        <Grid container spacing={2}>
          {localValues.map(i => (
            <Grid item xs={12} md={6} lg={4} key={i.country}>
              <ResidencyCard values={i} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyValueRenderer />
      )}
    </InfoRow>
  )
}
