import React, { useContext } from 'react'

import { ExperienceUpsertContext, withExperienceUpsertContext } from './context/ExperienceUpsertContext'
import { UpsertInlineLayout } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayout'
import { UpsertExperienceInlineLayoutHeader } from './components/UpsertExperienceInlineLayoutHeader'
import { UpsertInlineLayoutPreview } from 'components/ui-layouts/upsertInlineLayout/UpsertInlineLayoutPreview'
import {
  BuilderMode,
  BuilderTarget,
  BuilderType,
  ExperienceBuilderSection,
  ThemeBuilderSection,
} from './components/builder/utils/enums'
import { Builder } from './components/builder/Builder'
import { RoutesManager } from '../../../../utils/routing/routesManager'
import { UpsertExperienceSidebar } from './components/upsertExperienceSidebar/UpsertExperienceSidebar'
import { getIn } from 'formik'
import { themeUpsertFormKeys } from 'pages/consentAndRights/themes-v3/upsert/utils/useThemeValidationSchema'
import { getRgba } from '../../themes-v3/upsert/utils/utils'
import { ConsentExperienceType } from 'interfaces/experiences-v2/consentExperienceType'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { UpsertExperienceExampleDataBanner } from './components/UpsertExperienceExampleDataBanner'
import { ExperienceFormErrorSectionSwitcher } from './components/ExperienceFormErrorSectionSwitcher'
import { Box, styled } from '@mui/material'
import { logObjectDetails } from 'utils/helpers/logObjectDetails'

const UpsertExperienceBuilderBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  padding: '12px',
})

export const ExperienceUpsertWithoutContext: React.FC = () => {
  const {
    isExperienceLoading,
    isEditMode,
    initialValues,
    validationSchema,
    onSubmit,
    experienceType,
    consentExperienceType,
    experienceBuilderSection,
    setExperienceBuilderSection,
    themeConfig,
    preferencePage,
  } = useContext(ExperienceUpsertContext)

  const experienceTypeBreadcrumb =
    experienceType === ExperienceType.Consent ? 'Consent & Disclosure' : 'Preference Center'
  const breadcrumbs: any[] = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Experiences', link: RoutesManager.deployment.experiencesV2.root.getURL() },
    {
      title: isEditMode
        ? `Edit Experience: ${experienceTypeBreadcrumb}`
        : `Create New Experience: ${experienceTypeBreadcrumb}`,
    },
  ]

  return (
    <UpsertInlineLayout
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      breadcrumbs={breadcrumbs}
      fullWidth
      removePadding
      validateOnMount={false}
      validateOnChange={false}
      isReady={!isExperienceLoading}
    >
      {({ values, errors }) => {
        logObjectDetails(errors, 'ExperienceUpsert.tsx')
        const showBackdrop =
          experienceType === ExperienceType.Consent
            ? getIn(
                themeConfig,
                consentExperienceType === ConsentExperienceType.Banner
                  ? themeUpsertFormKeys.bannerContainerBackdropVisible
                  : themeUpsertFormKeys.modalContainerBackdropVisible,
              )
            : false
        const background = getIn(
          themeConfig,
          consentExperienceType === ConsentExperienceType.Banner
            ? themeUpsertFormKeys.bannerContainerBackdropBackgroundColor
            : themeUpsertFormKeys.modalContainerBackdropBackgroundColor,
        )
        const opacity = getIn(
          themeConfig,
          consentExperienceType === ConsentExperienceType.Banner
            ? themeUpsertFormKeys.bannerContainerBackdropBackgroundOpacity
            : themeUpsertFormKeys.modalContainerBackdropBackgroundOpacity,
        )

        // Determine type of builder
        const builderType =
          experienceType === ExperienceType.Preference
            ? BuilderType.Preference
            : consentExperienceType === ConsentExperienceType.Banner
            ? BuilderType.Banner
            : BuilderType.Modal

        return (
          <>
            <ExperienceFormErrorSectionSwitcher />
            <UpsertExperienceInlineLayoutHeader />
            <UpsertInlineLayoutPreview
              sx={showBackdrop ? { background: getRgba(background, opacity) } : {}}
              onClick={() => {
                setExperienceBuilderSection(undefined)
              }}
            >
              <UpsertExperienceBuilderBox id="builder-box">
                <UpsertExperienceExampleDataBanner preferencePage={preferencePage} builderType={builderType} />
                <Builder
                  builderTarget={BuilderTarget.Experience}
                  builderType={builderType}
                  builderMode={BuilderMode.Edit}
                  themeConfig={themeConfig}
                  experienceConfig={values}
                  activeSection={experienceBuilderSection}
                  setActiveSection={(section: ExperienceBuilderSection | ThemeBuilderSection) =>
                    setExperienceBuilderSection(section as ExperienceBuilderSection)
                  }
                  preferencePage={preferencePage}
                />
              </UpsertExperienceBuilderBox>
              {experienceBuilderSection && <UpsertExperienceSidebar />}
            </UpsertInlineLayoutPreview>
          </>
        )
      }}
    </UpsertInlineLayout>
  )
}

export const ExperienceV2Upsert = withExperienceUpsertContext(ExperienceUpsertWithoutContext)
