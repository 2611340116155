export enum ENTITLEMENTS {
  ASSET_MANAGER = 'asset_manager',
  USERS = 'users',

  // "Feature" type entitlements - exist or not
  API_KEY = 'api_key',
  API_KEYS = 'api_keys',
  ASSESSMENTS = 'assessments',
  CONTROLLERS = 'controllers',
  COOKIES = 'cookies',
  CONSENT_MANAGEMENT = 'consent_management',
  CUSTOM_AUTHENTICATORS = 'custom_authenticators',
  DATA_DISCOVERY = 'data_discovery',
  DATA_SYSTEMS = 'data_systems',
  DATA_MAPPING = 'data_mapping',
  DEMO = 'demo',
  DEPLOYMENT_PLANS = 'deployment_plans',
  DEVELOPER_EXTENSIONS = 'developer_extensions',
  EXP_SERVER_CONSENT_AND_DISCLOSURE = 'exp_server_cd',
  EXP_SERVER_JIT = 'exp_server_jit',
  EXP_SERVER_RIGHTS = 'exp_server_rights',
  EXP_SERVER_UNBRANDED = 'exp_server_unbranded',
  EXPERIENCES = 'experiences',
  HOOKS = 'hooks',
  IDENTITY_MGT = 'identity_mgt',
  JURISDICTIONS = 'jurisdictions',
  MARKETING_PREFERENCES = 'marketing_preferences',
  POLICY_DOCUMENTS = 'policy_documents',
  PROCESSING_ACTIVITIES = 'processing_activities',
  PROCESSOR_BASIC = 'processor_basic', // TODO - need to handle in harbormaster somehow
  PROPERTIES = 'properties',
  PURPOSES = 'purposes',
  RIGHTS_MANAGEMENT = 'rights_management',
  REDACTION = 'redaction',
  THEMES = 'custom_themes',
  WEBHOOKS = 'webhooks',
  WEBTAGS = 'webtags',
  WORKFLOWS = 'workflows',
  AUTHENTICATION = 'authentication',
  SUBSCRIPTIONS = 'subscriptions',
  STATS_CONSENT = 'stats_consent',
  STATS_DATAMAP = 'stats_datamap',
  STATS_RIGHTS = 'stats_rights',
  TRACKERS = 'trackers',
  TRANSPONDERS = 'transponders',
  ORCHESTRATION_CONSENT = 'orch_consent',
  ORCHESTRATION_PROTOCOLS = 'orch_protocols',
  ORCHESTRATION_RIGHTS = 'orch_rights',
  VERIFIED_IDENTITIES = 'verified_identities',

  // "Quantity" type entitlements - count matters
  QTY_API_KEY = 'qty_api_key',
  QTY_APP_CONNECTIONS_PRIVACY = 'qty_app_connections_privacy',
  QTY_CONTROLLER = 'qty_controller',
  QTY_COOKIE = 'qty_tracker',
  QTY_DEPLOYMENT_PLAN = 'qty_deployment_plan',
  QTY_EXPERIENCE = 'qty_experience',
  QTY_IDENTITY_SPACE = 'qty_identifiers',
  QTY_JURISDICTION = 'qty_jurisdiction',
  QTY_POLICY_DOCUMENT = 'qty_policy_document',
  QTY_PROPERTY = 'qty_property',
  QTY_PURPOSE = 'qty_purpose',
  QTY_THEME = 'qty_theme',
  QTY_WEBHOOKS = 'qty_webhooks',
  QTY_WORKFLOW = 'qty_workflow',
  QTY_SYSTEM_INTEGRATIONS_EXTERNAL = 'qty_system_integrations_external',
  QTY_SYSTEM_INTEGRATIONS_INTERNAL = 'qty_system_integrations_internal',
}
