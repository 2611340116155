import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { groupBy, mapValues } from 'lodash'
import { ObjectLiteral } from 'interfaces'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ExperienceDefaultDisplay } from 'interfaces/experiences-v2/experienceDefaultDisplay'
import { DeploymentPlanDTO, PolicyScopeExperienceDTO } from 'interfaces/deploymentPlans-v2/deploymentPlan'
import {
  ExperienceType,
  LegacyExperienceType,
  LegacyExperienceTypeMapping,
} from 'interfaces/experiences-v2/experienceType'
import { EXPERIENCE_DEFAULT_DISPLAY_NAMES } from 'interfaces/experiences-v2/experienceDefaultDisplay'
import { RoutesManager } from 'utils/routing/routesManager'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { DeploymentPlanFormSpecialValues } from 'pages/consentAndRights/deploymentPlans-v2/upsert/utils'
import { usePolicyScopes } from 'api/policyScopes/queries/usePolicyScopes'
import { useExperiences } from 'api/experiences-v2/queries/useExperiences'
import { useThemes } from 'api/themes-v3/queries/useThemes'
import { Text } from 'components/ui-kit/typography/Text'
import { Table } from 'components/ui-layouts/table/Table'
import { Button } from '@ketch-com/deck'
import { Spinner } from 'components/ui-kit/spinner/Spinner'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { SectionTitle } from 'components/ui-kit/typography/compositions/SectionTitle'
import { SubSectionTitle } from 'components/ui-kit/typography/compositions/SubSectionTitle'
import { FormRow } from 'components/ui-layouts/formLayout/FormRow'
import { FormGroup } from 'components/ui-layouts/formLayout/FormGroup'
import { Banner } from '@ketch-com/deck'
import { Box, AlertColor } from '@mui/material'

type Props = {
  deploymentPlan: DeploymentPlanDTO
}

export const DeploymentPlanOverview: React.FC<Props> = ({ deploymentPlan }) => {
  const navigate = useNavigate()
  const { isEntitled } = useIsEntitled()

  const { data: policyScopes, isLoading: isPolicyScopesLoading } = usePolicyScopes()
  const { data: experiences, isLoading: isExperiencesLoading } = useExperiences()
  const { data: themes, isLoading: isThemesLoading } = useThemes()

  const isReady = !isPolicyScopesLoading && !isExperiencesLoading && !isThemesLoading
  const isEntitledToContentAndDisclosure = isEntitled(ENTITLEMENTS.EXP_SERVER_CONSENT_AND_DISCLOSURE)

  const theme = useMemo(() => themes.find(({ id }) => id === deploymentPlan.themeID), [themes, deploymentPlan])

  const existingMappings = useMemo(
    () =>
      mapValues(groupBy(deploymentPlan?.policyScopeExperiences, 'policyScopeCode'), policyScopeExperiences =>
        policyScopeExperiences.reduce<ObjectLiteral<PolicyScopeExperienceDTO>>(
          (acc, policyScopeExperience) => ({
            ...acc,
            [policyScopeExperience.experienceType]: policyScopeExperience,
          }),
          {},
        ),
      ),
    [deploymentPlan],
  )

  const experiencesMap = useMemo(
    () =>
      experiences.reduce<ObjectLiteral<ExperienceV2DTO>>(
        (acc, experience) => ({
          ...acc,
          [experience.id!]: experience,
        }),
        {},
      ),
    [experiences],
  )

  const ExperienceRenderer = useCallback(
    function ExperienceRenderer({
      policyScopeCode,
      experienceType,
    }: {
      policyScopeCode: string
      experienceType: ExperienceType
    }) {
      const existingMapping = existingMappings[policyScopeCode]?.[LegacyExperienceTypeMapping[experienceType]]

      if (existingMapping) {
        const experience = experiencesMap[existingMapping?.experienceID as string]

        return experience ? (
          <NameAndCodeCellRenderer
            name={experience.name}
            shortCutLink={RoutesManager.deployment.experiencesV2.view.root.getURL({ id: experience.id })}
          />
        ) : (
          <Text size={14} lineHeight={1.43} weight={600}>
            {DeploymentPlanFormSpecialValues.NO_EXPERIENCE}
          </Text>
        )
      }

      return <EmptyValueRenderer />
    },
    [existingMappings, experiencesMap],
  )

  if (!isReady) {
    return <Spinner padding={50} />
  }

  return (
    <>
      {!isRelease(Release.PropertyThemeDocuments) && (
        <FormGroup>
          <SectionTitle>Basic Details</SectionTitle>

          <FormRow>
            <SubSectionTitle>Theme</SubSectionTitle>
            <NameAndCodeCellRenderer name={theme?.name} />
          </FormRow>
        </FormGroup>
      )}

      <FormGroup>
        <SectionTitle>Experience Delivery</SectionTitle>
        {deploymentPlan?.isOrchestrationOnly ? (
          <Box mb={2}>
            <Banner isCompact severity={'info' as AlertColor} variant={'filled'} title="Quiet mode is enabled">
              These configurations are not applicable. Quiet mode deployments will not serve experiences or generate
              consent.
            </Banner>
          </Box>
        ) : null}

        <Table
          variant="page"
          items={policyScopes}
          cellSettings={{
            policyScope: {
              label: 'Jurisdiction',
              width: 300,
              cellRenderer: ({ name }) => (
                <Text size={14} lineHeight={1.43} weight={600}>
                  {name}
                </Text>
              ),
            },
            ...(isEntitledToContentAndDisclosure && {
              consentAndDisclosure: {
                label: 'Consent & Disclosure Experience',
                width: 300,
                cellRenderer: ({ code }) => (
                  <ExperienceRenderer policyScopeCode={code} experienceType={ExperienceType.Consent} />
                ),
              },
            }),
            preference: {
              width: 300,
              label: 'Preference Experience',
              cellRenderer: ({ code }) => (
                <ExperienceRenderer policyScopeCode={code} experienceType={ExperienceType.Preference} />
              ),
            },
            ...(isEntitledToContentAndDisclosure && {
              defaultConsentDisplay: {
                label: 'Default Consent Display',
                cellRenderer: ({ code }) => {
                  const existingMapping = existingMappings[code]?.[LegacyExperienceType.CONSENT]

                  return (
                    <>
                      {!!existingMapping?.experienceID &&
                        EXPERIENCE_DEFAULT_DISPLAY_NAMES[
                          existingMapping?.experienceDefault || ExperienceDefaultDisplay.MODAL
                        ]}
                    </>
                  )
                },
              },
            }),
            viewButton: {
              width: 120,
              cellRenderer: ({ code }) => (
                <Button
                  color="tertiary"
                  onClick={() => {
                    navigate(RoutesManager.policyCenter.policyScopes.view.root.getURL({ code }))
                  }}
                >
                  View
                </Button>
              ),
            },
          }}
        />
      </FormGroup>
    </>
  )
}
