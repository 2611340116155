import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { FormMode } from 'interfaces/formModes/formMode'
import { MaybeNull } from 'interfaces'
import { handleMigratedExperience, handleOldQuickLinksExperience, withEntitlementInfo } from './utils'
import { EntitlementLayoutConfig, SubscriptionItemType } from '@ketch-sdk/ketch-types'
import { FormTemplateSummaryDTO, SubscriptionTopicDTO } from '@ketch-com/figurehead'
import { defaultConsentExperienceV2DTO, defaultPreferenceExperienceV2DTO } from './defaults'

export const useExperienceInitialValues = (
  formMode: FormMode,
  experienceType: ExperienceType,
  experience: MaybeNull<ExperienceV2DTO>,
  entitlementInfo: EntitlementLayoutConfig,
  defaultFormTemplate?: FormTemplateSummaryDTO,
  subscriptionTopics?: SubscriptionTopicDTO[],
): ExperienceV2DTO => {
  const defaultExperience =
    experienceType === ExperienceType.Consent ? defaultConsentExperienceV2DTO : defaultPreferenceExperienceV2DTO

  let initialExperience: ExperienceV2DTO

  switch (formMode) {
    case FormMode.CREATE:
      initialExperience = defaultExperience

      // Add the default form template as the initial single form
      if (initialExperience.layout?.preference?.tabs?.requests?.home?.rights?.forms !== undefined) {
        initialExperience.layout.preference.tabs.requests.home.rights.forms.singleFormID = defaultFormTemplate?.id
      }

      // Add the default form template as the initial defaultFormID for each canonical right
      if (initialExperience.layout?.preference?.tabs?.requests?.home?.rights?.forms?.details !== undefined) {
        initialExperience.layout?.preference?.tabs?.requests?.home?.rights?.forms?.details.forEach(detail => {
          detail.defaultFormID = defaultFormTemplate?.id
        })
      }

      // Add all subscription topics
      // NOTE: The default preference experience that gets provisioned for new ketch free orgs will not have any
      // subscriptions in it, but that is ok because ketch free orgs don't currently have the subscriptions SKU anyways.
      if (initialExperience.layout?.preference?.tabs?.subscriptions?.list?.items !== undefined) {
        initialExperience.layout.preference.tabs.subscriptions.list.items =
          subscriptionTopics?.map(topic => ({
            type: SubscriptionItemType.Topic,
            code: topic.code,
          })) ?? []
      }

      break

    case FormMode.EDIT:
      // Handle invalid fields from experience miration
      initialExperience = experience ? handleMigratedExperience(experience) : defaultExperience
      break

    case FormMode.DUPLICATE:
      initialExperience = {
        ...(experience ? handleOldQuickLinksExperience(experience) : defaultExperience),
        name: experience?.name ? `${experience.name} Copy` : '',
      }
      break
  }

  return withEntitlementInfo(initialExperience, entitlementInfo)
}
