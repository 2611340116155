import React from 'react'
import { Box, Typography } from '@mui/material'

import { AllowanceBreakdownPerJurisdiction, AllowanceBreakdownPerPurpose } from './components'
import { useTranslation } from 'react-i18next'
import { COMMON, PERMITS_STATISTICS } from 'i18n/types'

type Props = {}

export const AllowanceBreakdown: React.FC<Props> = () => {
  const { t } = useTranslation([PERMITS_STATISTICS, COMMON])

  return (
    <>
      <Box display="flex" alignItems="center" my={4}>
        <Typography variant="h2">{t('Allowance Breakdown')}</Typography>
      </Box>

      <Box display="flex" alignItems="stretch" mb={3} gap={2} sx={{ minHeight: 480 }}>
        <AllowanceBreakdownPerJurisdiction />
        <AllowanceBreakdownPerPurpose />
      </Box>
    </>
  )
}
