import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { Chip, Status, StatusState, StatusVariant, TableCell, ListItem } from '@ketch-com/deck'

import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { Box, List, Tooltip, Typography, styled } from '@mui/material'
import { ExperienceTypeLabels } from '../upsert/utils/labels'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { ExperienceStatus } from 'interfaces/experiences-v2/experienceStatus'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { ExperiencesListDropdown } from './ExperiencesListDropdown'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'

const TooltipListItem = styled(ListItem)({
  display: 'list-item',
  padding: '0px 0px 0px 8px',
  margin: '0px 0px 0px 16px',
  minHeight: 0,
})

export const useExperiencesListColumns = (): GridColDef<ExperienceV2DTO>[] => [
  {
    align: 'left',
    field: 'experience',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Experience',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<ExperienceV2DTO>) => {
      return <TableCell title={row.name} />
    },
  },

  {
    align: 'left',
    field: 'type',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Type',
    sortable: false,
    renderCell: ({ row: experience }: GridRenderCellParams<ExperienceV2DTO>) => {
      return (
        <TableCell>
          <Chip
            size="small"
            label={
              experience.type === ExperienceType.Consent
                ? ExperienceTypeLabels[ExperienceType.Consent]
                : ExperienceTypeLabels[ExperienceType.Preference]
            }
          />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'status',
    flex: 0.7,
    headerAlign: 'left',
    headerName: 'Status',
    sortable: false,
    renderCell: ({ row: { status } }: GridRenderCellParams<ExperienceV2DTO>) => {
      const isDeployed = status === ExperienceStatus.Deployed
      return (
        <TableCell>
          <Status
            variant={StatusVariant.ghost}
            status={isDeployed ? StatusState.success : StatusState.draft}
            icon="Dot"
            label={isDeployed ? 'Deployed' : 'Undeployed'}
          />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'subscriptions',
    flex: 0.5,
    headerAlign: 'left',
    headerName: 'Subscriptions',
    sortable: false,
    renderCell: ({ row: experience }: GridRenderCellParams<ExperienceV2DTO>) => {
      return (
        <TableCell>
          {experience.summary?.uniqueSubscriptions?.length ? (
            <Tooltip
              title={
                <List sx={{ listStyleType: 'disc', display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {experience.summary?.uniqueSubscriptions?.map(subscriptionsName => (
                    <TooltipListItem>
                      <Typography>{subscriptionsName}</Typography>
                    </TooltipListItem>
                  ))}
                </List>
              }
            >
              <Box>
                <Chip clickable label={experience.summary?.uniqueSubscriptions?.length} size="small" />
              </Box>
            </Tooltip>
          ) : (
            <EmptyValueRenderer />
          )}
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'lastUpdated',
    flex: 1,
    headerAlign: 'left',
    headerName: 'Last Updated',
    sortable: false,
    renderCell: ({ row: { metadata } }: GridRenderCellParams<ExperienceV2DTO>) => {
      return (
        <TableCell>
          <FormattedDateRenderer date={metadata?.updatedAt} />
        </TableCell>
      )
    },
  },
  {
    align: 'left',
    field: 'dropDown',
    flex: 1,
    headerAlign: 'left',
    headerName: ' ',
    sortable: false,
    maxWidth: 60,
    renderCell: ({ row: experience }: GridRenderCellParams<ExperienceV2DTO>) => {
      return (
        <TableCell>
          <ExperiencesListDropdown experience={experience} />
        </TableCell>
      )
    },
  },
]
