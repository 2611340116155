import { usePlan } from 'api/planAndBilling/queries/usePlan'
import { useUsage } from 'api/planAndBilling/queries/useUsage'
import { GetUsageGranularity } from '@ketch-com/harbormaster/dist/organization/plans.schemas'

export const usePlanAndBillingUtils = () => {
  const { data: orgPlan, isLoading: isLoadingOrgPlan } = usePlan({})
  const { data: orgUsage, isLoading: isLoadingOrgUsage } = useUsage({
    params: { granularity: GetUsageGranularity.month },
  })

  const isReady = !isLoadingOrgPlan && !isLoadingOrgUsage

  return {
    isReady,
    orgPlan,
    orgUsage,
  }
}
