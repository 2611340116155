import { FC, ReactNode } from 'react'
import { RightMappingDetail, RightsFormMode } from '@ketch-sdk/ketch-types'
import { Box } from '@mui/material'
import { FieldArray, getIn, useFormikContext } from 'formik'
import { experienceUpsertFormKeys } from '../../../utils/useExperienceValidationSchema'
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { RightMappingDraggable } from './RightMappingDraggable'

type Props = {
  variant: RightsFormMode
  defaultFormDropdownRender?: (formId?: string) => ReactNode
  onPreviewForm?: (formId?: string) => void
  shouldOpened?: boolean
}

export const RightsMappingDragList: FC<Props> = ({
  variant,
  defaultFormDropdownRender,
  onPreviewForm,
  shouldOpened = true,
}) => {
  const { values, setFieldValue } = useFormikContext<ExperienceV2DTO>()

  // "Single" mode uses order, "Custom" uses customFormMappings
  const details = getIn(
    values,
    experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsDetails,
  ) as RightMappingDetail[]

  return (
    <Box>
      <FieldArray
        name={experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsDetails}
        render={arrayHelpers => {
          return (
            <Box>
              <DragDropContext
                onDragEnd={(result: DropResult) => {
                  if (!result.destination) return

                  const startIndex = result.source.index
                  const endIndex = result.destination.index

                  const newDetails = Array.from(details)
                  const [removed] = newDetails.splice(startIndex, 1)
                  newDetails.splice(endIndex, 0, removed)

                  setFieldValue(experienceUpsertFormKeys.preferenceRequestsTabHomeRightsFormsDetails, newDetails)
                }}
              >
                <Droppable droppableId="droppable">
                  {provided => {
                    const { innerRef, droppableProps } = provided
                    return (
                      <Box
                        {...droppableProps}
                        ref={innerRef}
                        sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
                      >
                        {details.map((mapping, index) => {
                          return (
                            <RightMappingDraggable
                              variant={variant}
                              rightMapping={mapping}
                              index={index}
                              defaultFormDropdownRender={defaultFormDropdownRender}
                              onPreviewForm={onPreviewForm}
                              shouldOpened={shouldOpened}
                            />
                          )
                        })}
                        {provided.placeholder}
                      </Box>
                    )
                  }}
                </Droppable>
              </DragDropContext>
            </Box>
          )
        }}
      />
    </Box>
  )
}
