import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'
import { GetAssetsDataSystemsResponseBodyDTO } from '@ketch-com/figurehead'
import { APIListRequestParams } from 'api/common/utils'

type Params = APIListRequestParams<{
  isV2FeatureFlagEnabled?: boolean
}>

export const fetchAssetsDataSystems = ({ isV2FeatureFlagEnabled }: Params) => {
  let entityUrl = `/api/v3/assets-data-systems`
  if (isV2FeatureFlagEnabled) entityUrl = `/api/v2/assets-data-systems`

  return API.get<GetAssetsDataSystemsResponseBodyDTO>(
    formatRequestString({
      entityUrl,
    }),
  )
}
