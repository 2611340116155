import { createUseFilters } from 'utils/hooks/createUseFilters'

export type ApplianceWorkStatus = 'QUEUED' | 'IN-PROGRESS' | 'COMPLETED' | 'FAILED'

export type StatusFilters = ApplianceWorkStatus | 'All Statuses'

export const useTransponderWorkFilters = createUseFilters<{
  status: StatusFilters
  inputSubject: string
}>({
  status: 'All Statuses',
  inputSubject: 'All Input Subjects',
})
