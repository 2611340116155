import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { showToast } from 'components/modals/AlertComponent'
import { useWebTag } from 'api/webtags/queries/useWebTag'
import { useUpdateWebTagStatus } from 'api/webtags/mutations/useUpdateWebTagStatus'
import { Typography } from '@mui/material'
import { Button, PopUp } from '@ketch-com/deck'

type Props = {
  webTagId: string
  onSubmit?: () => void
  onCancel: () => void
}

export const ToggleWebTagEnabledStateModal: React.FC<Props> = ({ webTagId, onSubmit, onCancel }) => {
  const queryClient = useQueryClient()
  const [isBusy, setIsBusy] = useState(false)

  const { data: webTag, isLoading } = useWebTag({
    enabled: !!webTagId,
    params: {
      webTagId,
    },
    onError: () => {
      showToast({ content: 'Failed to fetch Tag', type: 'error' })
      onCancel()
    },
  })

  const { mutate: handleUpdateWebTag } = useUpdateWebTagStatus({
    onMutate: () => {
      setIsBusy(true)
    },
    onSuccess: async () => {
      showToast({ content: 'Tag updated', type: 'success' })
      await queryClient.refetchQueries(ApiQueryKeys.entitlements)
      await onSubmit?.()
    },
    onError: () => {
      showToast({ content: 'Failed to update Tag', type: 'error' })
      setIsBusy(false)
    },
  })

  return (
    <PopUp
      isLoading={isLoading}
      title={webTag?.enabled ? 'Disable Tag' : 'Enable Tag'}
      popUpWidth="550px"
      onClose={onCancel}
      variant="dialog"
      popUpActionChildren={
        <>
          <Button color="secondary" size="large" onClick={onCancel}>
            Close
          </Button>
          <Button
            color="primary"
            size="large"
            pending={isBusy}
            onClick={() => {
              handleUpdateWebTag({
                params: {
                  webTagId: webTag!.ID,
                  formData: {
                    ...webTag,
                    enabled: !webTag?.enabled,
                  },
                },
              })
            }}
          >
            Confirm
          </Button>
        </>
      }
    >
      <Typography variant="body">
        Are you sure you want to {webTag?.enabled ? 'disable' : 'enable'}{' '}
        <strong>{webTag?.name || webTag?.code}</strong> tag?
      </Typography>
    </PopUp>
  )
}
