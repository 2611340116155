import React from 'react'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { Collapse } from '@mui/material'
import { KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material'

import { ApplicationDTO } from 'interfaces/applications/application'
import { APPLICATION_PLATFORM_NAME } from 'interfaces/applications/applicationPlatform'
import { DeploymentPlanDeployFormValues } from 'pages/consentAndRights/deploymentPlans-v2/deploy/utils'
import { Group } from 'components/ui-layouts/group/Group'
import { Table } from 'components/ui-layouts/table/Table'
import { FormCheckbox } from 'components/ui-kit/form/checkbox/FormCheckbox'
import { Badge } from 'components/ui-kit/badge/Badge'
import { NameAndCodeCellRenderer } from 'components/ui-layouts/table/cellRenderers/NameAndCodeCellRenderer'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'

const PREFIX = 'DeploymentPlanApplicationSection'

const classes = {
  root: `${PREFIX}-root`,
  opened: `${PREFIX}-opened`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  icon: `${PREFIX}-icon`,
  envColumn: `${PREFIX}-envColumn`,
}

const Root = styled('div')(({ theme: { typography, palette } }) => ({
  [`&.${classes.root}`]: {
    '& $header': {
      borderBottom: `1px solid ${palette.iron.main}`,
    },

    '&:last-child:not($opened) $header': {
      borderBottom: 0,
    },
  },

  [`&.${classes.opened}`]: {
    boxShadow: '0px 15px 10px -10px rgba(0, 0, 0, 0.1)',
  },

  [`& .${classes.header}`]: {
    padding: '9px 18px',
    cursor: 'pointer',
  },

  [`& .${classes.content}`]: {
    padding: '12px 0',
  },

  [`& .${classes.icon}`]: {
    fontSize: typography.pxToRem(20),
  },

  [`& .${classes.envColumn}`]: {
    paddingLeft: 36,
  },
}))

type Props = {
  application: ApplicationDTO
}

export const DeploymentPlanApplicationSection: React.FC<Props> = ({ application }) => {
  const { values, setFieldValue } = useFormikContext<DeploymentPlanDeployFormValues>()

  const { ID, name, code, platform } = application
  const opened = !!values[ID]?.opened

  const Icon = opened ? KeyboardArrowDown : KeyboardArrowRight

  return (
    <Root className={clsx(classes.root, { [classes.opened]: opened })}>
      <Group
        inline={false}
        gap={15}
        className={classes.header}
        onClick={() => {
          setFieldValue(`${ID}.opened`, !opened)
        }}
      >
        <Icon className={classes.icon} />
        <NameAndCodeCellRenderer name={name} code={code} />
      </Group>

      <Collapse mountOnEnter unmountOnExit in={opened}>
        <div className={classes.content}>
          <Table
            items={application.environments}
            cellSettings={{
              environments: {
                label: 'Environments',
                className: classes.envColumn,
                width: 300,
                cellRenderer: ({ ID: environmentId, code }) => {
                  const name = `${ID}.environments.${environmentId}`

                  return <FormCheckbox id={name} name={name} title={code} />
                },
              },
              pattern: {
                label: 'Pattern',
                cellRenderer: ({ pattern }) => pattern,
              },
              type: {
                label: 'Type',
                cellRenderer: () => <Badge>{APPLICATION_PLATFORM_NAME[platform]}</Badge>,
              },
              currentDeploymentPlan: {
                label: 'Current Deployment Plan',
                cellRenderer: ({ deployment }) =>
                  deployment ? (
                    <NameAndCodeCellRenderer name={deployment.name} code={deployment.code} />
                  ) : (
                    <EmptyValueRenderer />
                  ),
              },
            }}
          />
        </div>
      </Collapse>
    </Root>
  )
}
