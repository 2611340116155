import React, { useMemo, useState, useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { createStyles, makeStyles } from '@mui/styles'

import { ApiQueryKeys } from 'api/common/queryKeys'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { WebTagDTO } from 'interfaces/webtags/WebTags'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PURPOSE_DATA_ROLE_NAMES, PurposeDataRole } from 'interfaces/purposes/purposeDataRole'
import { PURPOSE_DATA_SUBJECT_ROLE_NAMES, PurposeDataSubjectRole } from 'interfaces/purposes/purposeDataSubjectRole'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { Button, Chip, DataGrid, EmptyState, Status, StatusState, StatusVariant, TableCell } from '@ketch-com/deck'
import { MapPurposesToWebTagModal } from 'components/modals/purposes/MapPurposesToWebTagModal'
import { ConfirmNoPurposesNeededDialog } from 'components/modals/purposes/ConfirmNoPurposesNeededDialog'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/emptyState/EmptyStatePurposes.svg'
import { Box, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

const useStyles = makeStyles(
  () =>
    createStyles({
      buttonManagePurposes: {
        marginBottom: 20,
      },
    }),
  { name: 'WebTagPurposesTab' },
)

type Props = {
  webTag: WebTagDTO
}

export const WebTagPurposesTab: React.FC<Props> = ({ webTag }) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const queryClient = useQueryClient()

  const { isPermitted } = useIsPermitted()

  const canMapPurposes = isPermitted(PERMISSIONS.WEB_TAGS_WRITE)
  const { ID, name, code, purposes } = webTag
  const reFetchWebTag = () => queryClient.refetchQueries(ApiQueryKeys.webTag)

  const [isMapPurposeOpen, setIsMapPurposeOpen] = useState(false)
  const [isConfirmNoPurposesDialogOpen, setIsConfirmNoPurposesDialogOpen] = useState(false)

  const handleViewPurpose = useCallback(
    ({ code }: PurposeDTO) => {
      navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code }))
    },
    [navigate],
  )

  const columns = useMemo(
    () => [
      {
        field: 'purpose',
        headerName: 'Purpose',
        width: 200,
        renderCell: (params: GridRenderCellParams) => <TableCell title={params.row.name} subTitle={params.row.code} />,
        sortable: false,
      },
      {
        field: 'description',
        headerName: 'Description',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            {params.row.description ? (
              <Typography variant="body">{params.row.description}</Typography>
            ) : (
              <EmptyValueRenderer color="darkDuskFaded.main" />
            )}
          </TableCell>
        ),
        sortable: false,
        flex: 1,
      },
      {
        field: 'dataRole',
        headerName: 'Data Role',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <Chip label={PURPOSE_DATA_ROLE_NAMES[params.row.dataRole as PurposeDataRole]} size="small" />
          </TableCell>
        ),
        sortable: false,
        width: 125,
      },
      {
        field: 'dataSubjectRole',
        headerName: 'Subject Type',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            {params.row.dataSubjectRole ? (
              <Chip
                label={PURPOSE_DATA_SUBJECT_ROLE_NAMES[params.row.dataSubjectRole as PurposeDataSubjectRole]}
                size="small"
              />
            ) : (
              <EmptyValueRenderer color="darkDuskFaded.main" />
            )}
          </TableCell>
        ),
        sortable: false,
        width: 125,
      },
      {
        field: 'status',
        headerName: 'Status',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            {params.row.enabled ? (
              <Status label="Enabled" variant={StatusVariant.ghost} icon="Dot" status={StatusState.success} />
            ) : (
              <Status label="Disabled" variant={StatusVariant.ghost} icon="Dot" status={StatusState.draft} />
            )}
          </TableCell>
        ),
        sortable: false,
        width: 125,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <Button color="tertiary" onClick={() => handleViewPurpose(params.row)}>
              View
            </Button>
          </TableCell>
        ),
        sortable: false,
        width: 100,
        renderHeader: () => null,
      },
    ],
    [handleViewPurpose],
  )

  return (
    <>
      <NavigationBreadCrumbs
        type="dark"
        items={[
          { title: 'Consent & Rights', link: RoutesManager.orchestration.root.getURL() },
          { title: 'Tags', link: RoutesManager.orchestration.webTags.root.getURL() },
          {
            title: name || code,
            link: RoutesManager.orchestration.webTags.view.root.getURL({ id: ID }),
          },
          { title: 'Purposes' },
        ]}
      />

      <Typography variant="h3" marginBottom="29px">
        Purposes Dependencies
      </Typography>

      {purposes?.length ? (
        <>
          {canMapPurposes && (
            <Button
              className={classes.buttonManagePurposes}
              color="secondary"
              onClick={() => setIsMapPurposeOpen(true)}
            >
              Map Purposes
            </Button>
          )}

          <DataGrid
            rows={purposes}
            columns={columns}
            disableChildrenSorting
            disableColumnMenu
            disableColumnResize
            disableColumnReorder
            disableRowSelectionOnClick
            disableAggregation
            hideFooterRowCount
            disableRowHoverStates
            getRowHeight={() => 'auto'}
            getRowId={row => row.ID}
            hideFooterPagination
            sx={{
              height: 'auto',
              backgroundColor: 'transparent',
            }}
          />
        </>
      ) : (
        <Box
          mt={2.5}
          bgcolor="tertiary.main"
          borderRadius="11px"
          py={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <EmptyState
            customIcon={<EmptyStateIcon />}
            title="No purposes dependencies for this tag"
            subTitle={canMapPurposes ? 'Once purposes are added, they will be displayed here.' : ''}
            primaryButtonTitle={canMapPurposes ? 'Map Purposes' : undefined}
            primaryButtonProps={{
              onClick: () => setIsMapPurposeOpen(true),
            }}
            secondaryButtonTitle={webTag?.hasIssues ? 'No Purposes Needed' : undefined}
            secondaryButtonProps={{
              onClick: () => setIsConfirmNoPurposesDialogOpen(true),
            }}
          />
        </Box>
      )}

      {isMapPurposeOpen && (
        <MapPurposesToWebTagModal
          webTagId={ID}
          onSubmit={() => {
            setIsMapPurposeOpen(false)
            return reFetchWebTag()
          }}
          onCancel={() => setIsMapPurposeOpen(false)}
        />
      )}

      {isConfirmNoPurposesDialogOpen && (
        <ConfirmNoPurposesNeededDialog
          webTagId={ID}
          onSubmit={() => {
            setIsConfirmNoPurposesDialogOpen(false)
            return reFetchWebTag()
          }}
          onCancel={() => setIsConfirmNoPurposesDialogOpen(false)}
        />
      )}
    </>
  )
}
