import React, { useMemo, useState } from 'react'

import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { IssuesCellRenderer } from 'components/renderers/IssuesCellRenderer'
import { WebTagsListFilters } from 'pages/consentAndRights/consent/webTags/list/WebTagsListFilters'
import { HighlightText } from 'components/renderers/HighlightText'
import {
  Button,
  CardProps,
  Chip,
  DataGrid,
  EmptyState,
  Icon,
  ListLayout,
  PopUp,
  Status,
  StatusState,
  StatusVariant,
  TableCell,
  TabSegmented,
  TabSegmentedGroup,
  theme,
} from '@ketch-com/deck'
import { Box, Typography, Tooltip } from '@mui/material'
import { clearSearchQuery } from 'store/webTagsListFilterSlice'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { getWebTagsListFilterState } from '../../../../../store/webTagsListFilterSlice/selectors'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { useTagsList } from './context/TagsContext'
import { TagListState } from './utils/enums'
import { tagListStateLabels } from './utils/labels'

export const TagsListV2: React.FC = () => {
  const { tagListState, setTagListState, isBusy, isLoading, isFetching, webTags, pagination, handleSyncWebTags } =
    useTagsList()

  const { queries } = useQueryParams<{
    q?: string
  }>()
  const { q: reduxSearchQuery } = useAppSelector(getWebTagsListFilterState)
  const [searchString, setSearchString] = useState(() => reduxSearchQuery || '')
  const [isSyncTagsOpen, setIsSyncTagsOpen] = useState(false)

  // TODO:JB - Handle permissions for new actions on this page
  // const canSyncTags = isPermitted(PERMISSIONS.WEB_TAGS_WRITE)
  const dispatch = useAppDispatch()

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Consent', link: RoutesManager.deployment.consentHome.root.getURL() },
    { title: 'Tags', link: RoutesManager.orchestration.webTags.root.getURL() },
  ]

  const columns = useMemo(() => {
    const isIssues = webTags.some(item => !!item.hasIssues)

    return [
      ...(isIssues
        ? [
            {
              field: 'issues',
              headerName: 'Issues',
              width: 35,
              renderCell: (params: GridRenderCellParams) => (
                <TableCell>
                  <IssuesCellRenderer hasIssues={params.row.hasIssues} entityType={ApplicationEntity.WEBTAG} />
                </TableCell>
              ),
              sortable: false,
              renderHeader: () => null,
            },
          ]
        : []),
      {
        field: 'nameAndCode',
        headerName: 'Tag',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <NameAndCodeCellRenderer
              name={<HighlightText parts={queries?.q?.split(' ') || []} text={params.row.name} />}
              code={params.row.code}
            />
          </TableCell>
        ),
        sortable: false,
        flex: 1,
      },
      {
        field: 'source',
        headerName: 'Source Connection',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            {params.row.appName ? (
              <Tooltip title={params.row.appName}>
                <div>
                  <Chip label={params.row.appName} size="small" />
                </div>
              </Tooltip>
            ) : (
              <EmptyValueRenderer />
            )}
          </TableCell>
        ),
        sortable: false,
        flex: 1,
      },
      // TODO:JB - Combine with above column
      // {
      //   field: 'sourceConnection',
      //   headerName: 'Source Connection',
      //   renderCell: (params: GridRenderCellParams) => (
      //     <TableCell>
      //       {params.row.appInstanceName ? (
      //         <Chip label={params.row.appInstanceName} size="small" />
      //       ) : (
      //         <EmptyValueRenderer />
      //       )}
      //     </TableCell>
      //   ),
      //   sortable: false,
      //   flex: 1,
      // },
      {
        field: 'purposeDependencies',
        headerName: 'Purposes',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            {!params.row.purposes || !params.row.purposes?.length ? (
              <Chip label={0} size="small" sx={{ minWidth: '24px' }} clickable />
            ) : (
              <Tooltip
                title={
                  <>{params.row.purposes?.map((purpose: PurposeDTO) => <div key={purpose.ID}>{purpose.name}</div>)}</>
                }
              >
                <div>
                  <Chip label={params.row.purposes?.length} size="small" sx={{ minWidth: '24px' }} clickable />
                </div>
              </Tooltip>
            )}
          </TableCell>
        ),
        sortable: false,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Orchestration Status',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            {params.row.enabled ? (
              <Status icon="Dot" variant={StatusVariant.ghost} status={StatusState.success} label="Enabled" />
            ) : (
              <Status icon="Dot" variant={StatusVariant.ghost} status={StatusState.draft} label="Disabled" />
            )}
          </TableCell>
        ),
        sortable: false,
        flex: 1,
      },
      {
        field: 'updatedAt',
        headerName: 'Last Update',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <FormattedDateRenderer date={params.row?.metadata?.updatedAt} />
          </TableCell>
        ),
        sortable: false,
        flex: 1,
      },
    ]
  }, [queries?.q, webTags])

  const totalPages = pagination.totalPages

  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const tagsSummaryCardProps: CardProps[] = useMemo<CardProps[]>(
    () => [
      {
        cardTitle: '16', // TODO:JB - Replace with non-hardcoded example
        subTitle: 'Tags',
        primaryIcon: 'OFocusCopy',
        cardVariant: 'stretchWidth',
        isNumerical: true,
      },
      {
        cardTitle: '0', // TODO:JB - Replace with non-hardcoded example
        subTitle: 'Consent Enabled',
        primaryIcon: 'OCheckRound2',
        textColor: theme.palette.Success.PrimaryDark,
        backgroundColor: theme.palette.Success.Disabled,
        cardVariant: 'stretchWidth',
        isNumerical: true,
      },
      {
        cardTitle: '16', // TODO:JB - Replace with non-hardcoded example
        subTitle: 'New Tags Found',
        primaryIcon: 'OArrowCRight',
        textColor: theme.palette.Warning.Primary,
        backgroundColor: theme.palette.Warning.Disabled,
        cardVariant: 'stretchWidth',
        isNumerical: true,
      },
    ],
    [],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  // Update list state on segment button change
  const handleListStateChange = (event: React.MouseEvent<HTMLElement>, newState: TagListState) => {
    if (newState !== null) {
      setTagListState(newState)
    }
  }

  return (
    <ListLayout
      cardComponentProps={{
        cardItems: tagsSummaryCardProps,
        cardButtonTitle: 'Update Tags',
      }}
      actionHeaderBottomBlockComponent={
        <Box display="flex" justifyContent="space-between" width="100%" gap={3}>
          <WebTagsListFilters searchString={searchString} setSearchString={setSearchString} isFetching={isFetching} />
          <TabSegmentedGroup
            value={tagListState}
            exclusive
            onChange={handleListStateChange}
            aria-label="Small sizes"
            size="small"
          >
            {Object.values(TagListState).map((listState, index) => (
              <TabSegmented value={listState} key={index}>
                {tagListStateLabels[listState]}
              </TabSegmented>
            ))}
          </TabSegmentedGroup>
        </Box>
      }
      sx={{
        // Override the default gap between tabs and content
        '.DeckLayout-container': {
          '.MuiBox-root:first-child': {
            rowGap: '0px !important',
          },
        },
      }}
    >
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      {!isFetching && !webTags.length ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            backgroundColor: 'white.main',
            padding: 5,
            borderRadius: '11px',
            height: '410px',
          }}
        >
          <EmptyState
            customIcon={<Icon name="OFilter" width="64px" height="64px" />}
            size="large"
            title="No results found"
            subTitle="Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try
        again."
            secondaryButtonTitle="Reset to Defaults"
            secondaryButtonProps={{
              onClick: () => {
                dispatch(clearSearchQuery())
                setSearchString('')
              },
            }}
          />
        </Box>
      ) : (
        <DataGrid
          rows={webTags || []}
          columns={columns}
          disableChildrenSorting
          disableColumnMenu
          disableColumnResize
          disableColumnReorder
          disableBorder
          disableRowSelectionOnClick
          disableAggregation
          hideFooterRowCount
          getRowId={row => row.ID}
          loading={isLoading || pagination.isPending}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={[rowsPerPage]}
          rowCount={pagination.totalResults || rowsPerPage * totalPages}
          hideFooterPagination={totalPages < 2}
          sx={{
            '& .MuiDataGrid-columnHeader[role="columnheader"]:hover': {
              backgroundImage: 'unset',
            },
            height: 'auto',
          }}
        />
      )}

      {isSyncTagsOpen && (
        <PopUp
          title="Sync Tags"
          popUpWidth="550px"
          variant="dialog"
          onClose={() => setIsSyncTagsOpen(false)}
          popUpActionChildren={
            <>
              <Button color="secondary" size="large" onClick={() => setIsSyncTagsOpen(false)}>
                Close
              </Button>
              <Button
                color="primary"
                size="large"
                pending={isBusy}
                onClick={() => {
                  handleSyncWebTags()
                  setIsSyncTagsOpen(false)
                }}
              >
                Confirm
              </Button>
            </>
          }
        >
          <Typography variant="body">
            This action will push your Ketch platform configuration to your tag manager account. Please confirm you
            would like to proceed.
          </Typography>
        </PopUp>
      )}
    </ListLayout>
  )
}
