import { useState } from 'react'
import { useUsers } from 'api/users/queries/useUsers'
import { useDatabasesPaginated } from 'api/assets/queries/useDatabasesPaginated'
import { useIdentitySpacesPaginated } from 'api/identitySpaces/queries/useIdentitySpacePaginated'
import { useContextVariablesPaginated } from 'api/contextVariables/queries/useContextVariablesPaginated'
import { ContextVariableCategoryDTO } from '@ketch-com/figurehead'
import { ICanvasStep } from 'pages/orchestration/workflows/edit/interfaces'

export const useImportContextUtils = (step: ICanvasStep) => {
  const [databaseQuery, setDatabaseQuery] = useState(step?.workflow?.params?.databaseResourceName)
  const [fromIdentitySpaceQuery, setFromIdentitySpaceQuery] = useState(step?.workflow?.params?.identityFromName)
  const [toIdentitySpaceQuery, setToIdentitySpaceQuery] = useState(step?.workflow?.params?.identityToName)
  const [toContextVariableQuery, setToContextVariableQuery] = useState(step?.workflow?.params?.identityToName)
  const { isLoading: isLoadingUsers } = useUsers({ params: { active: true } })
  const { data: databaseResourcesData, isLoading: isLoadingDatabaseResources } = useDatabasesPaginated({
    params: {
      type: 'SQL',
      searchString: databaseQuery,
      limit: 20,
    },
  })

  const { data: fromIdentitySpaces, isLoading: isLoadingFromIdentitySpaces } = useIdentitySpacesPaginated({
    params: {
      query: fromIdentitySpaceQuery,
    },
  })
  const { data: toIdentitySpaces, isLoading: isLoadingToIdentitySpaces } = useIdentitySpacesPaginated({
    params: {
      query: toIdentitySpaceQuery,
    },
  })
  const { data: toContextVariables, isLoading: isLoadingToContextVariables } = useContextVariablesPaginated({
    params: {
      query: toContextVariableQuery,
      category: ContextVariableCategoryDTO.DataSubjectContextVariableCategory,
    },
  })
  const isLoading =
    isLoadingUsers ||
    isLoadingDatabaseResources ||
    isLoadingFromIdentitySpaces ||
    isLoadingToIdentitySpaces ||
    isLoadingToContextVariables
  return {
    isLoadingUsers,
    isLoadingDatabaseResources,
    databaseResources: databaseResourcesData?.databaseResources || [],
    isLoadingFromIdentitySpaces,
    fromIdentitySpaces,
    isLoadingToIdentitySpaces,
    toIdentitySpaces,
    isLoadingToContextVariables,
    toContextVariables,
    setDatabaseQuery,
    setFromIdentitySpaceQuery,
    setToIdentitySpaceQuery,
    setToContextVariableQuery,
    isLoading,
  }
}
