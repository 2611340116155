import React, { useState } from 'react'
import { styled } from '@mui/styles'

import { RoutesManager } from 'utils/routing/routesManager'
import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'
import pluralize from 'pluralize'

import { useIsPermitted } from 'utils/hooks'
import { DeleteAppModal } from 'pages/dataSystems/components/Modals/DeleteAppModal'
import { InstalledDataSystemV2DTO } from '@ketch-com/figurehead'
import { Capabilites } from 'pages/dataSystems/components/Capabilities'

import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { Avatar, AvatarSize, Card, Icon, theme } from '@ketch-com/deck'
import { PersonalDataTypeOptions } from 'pages/dataSystems/DataSystem/types'

const Capability = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5),
}))

type Props = {
  app: InstalledDataSystemV2DTO
}

export const SystemsListItem: React.FC<Props> = ({ app }) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)

  const navigate = useNavigate()

  const handleAppClick = (ID: string) => {
    navigate(RoutesManager.systems.id.overview.root.getURL({ id: ID }))
  }

  const hasApp = app.dataSystem?.appCode

  // TODO - KC: remove menu item till we get the data
  // const { isEntitled } = useIsEntitled()
  const { isPermitted } = useIsPermitted()

  const isDataSystemsWrite = isPermitted([PERMISSIONS.DATA_SYSTEMS_WRITE, PERMISSIONS.INTEGRATIONS_WRITE])

  // TODO - KC: remove menu item till we get the data
  // const isEntitledToCreateConnection = isEntitled(
  //   app?.dataSystem?.systemType === 'Internal'
  //     ? ENTITLEMENTS.QTY_SYSTEM_INTEGRATIONS_INTERNAL
  //     : ENTITLEMENTS.QTY_SYSTEM_INTEGRATIONS_EXTERNAL,
  // )
  // const filteredCapabilities = app?.dataSystem?.filteredCapabilities ?? []
  // const hasCapabilities = !!filteredCapabilities?.length

  const hasConnections = !!app.connections?.length
  const hasCredentialsIssue = app.connections?.some(connection => {
    return connection.hasCredentialsIssue === true
  })

  let items: {
    content: string
    onClick: () => void
    disabled?: boolean
  }[] = [
    {
      content: 'View',
      onClick: () => handleAppClick(app?.id!),
    },
  ]

  // TODO - KC: remove menu item till we get the data
  // if (hasApp && isDataSystemsWrite) {
  //   items.push({
  //     content: 'Add Connection',
  //     onClick: () =>
  //       navigate(RoutesManager.systems.connections.upsert.root.getURL({ id: app?.id, instanceId: NEW_ITEM_ID })),
  //     disabled: !isEntitledToCreateConnection || !hasCapabilities || !hasConnections,
  //   })
  // }

  if (isDataSystemsWrite) {
    items.push({
      content: 'Delete',
      onClick: () => setIsDeleteOpen(true),
      disabled: hasConnections,
    })
  }

  const cardMenuItems: {
    label: string
    onClick: () => void
  }[] = [
    {
      label: 'View',
      onClick: () => handleAppClick(app?.id!),
    },
  ]
  if (isDataSystemsWrite && !hasConnections) {
    cardMenuItems.push({
      label: 'Delete',
      onClick: () => setIsDeleteOpen(true),
    })
  }

  const footerChips = app.personalDataType
    ? [{ label: PersonalDataTypeOptions[app.personalDataType].title }]
    : undefined

  return (
    <>
      <Card
        sx={{
          cursor: 'pointer',
          '& .DeckCardAvatarContent .DeckCardAvatarFooter': {
            marginTop: 'auto !important',
          },
        }}
        onClick={e => {
          handleAppClick(app?.id!)
          e.stopPropagation()
        }}
        cardVariant="avatar"
        cardTitle={app.dataSystem?.name || 'None'}
        avatarComponent={
          <Avatar
            src={app.dataSystem?.logoUrl}
            alt={app.dataSystem?.name}
            variant="rounded"
            size={AvatarSize['2xlarge']}
            borderColor={theme.palette.tertiary.main}
            isLogo
          />
        }
        moreMenuItems={cardMenuItems}
        footerChips={footerChips}
        cardContent={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              mb: 2.25,
            }}
          >
            {hasApp && (
              <Capability>
                {app.connections?.length ? (
                  hasCredentialsIssue ? (
                    <Icon name="OConnectionIssue" iconColor={theme.palette.chileanFire.main} />
                  ) : (
                    <Icon name="OConnectionTrue" iconColor={theme.palette.greenHaze.main} />
                  )
                ) : (
                  <Icon name="OConnectionFalse" iconColor={theme.palette.fadedDarkGrey.main} />
                )}
                <Typography
                  variant="smallLabel"
                  color={
                    hasCredentialsIssue ? 'chileanFire.main' : !app.connections ? 'darkGrey.main' : 'greenHaze.main'
                  }
                  pl={0.5}
                >
                  {app.connections?.length ?? 0} {pluralize('Connection', app.connections?.length)}
                </Typography>
              </Capability>
            )}

            <Capabilites
              hasNoConnections={app.connections?.length ? false : true}
              hasCredentialsIssue={hasCredentialsIssue}
              supportedCapabilites={app.dataSystem?.supportedCapabilities!}
            />
          </Box>
        }
      />
      {isDeleteOpen && (
        <DeleteAppModal
          appId={app?.id || ''}
          appInstances={app.connections!}
          onCancel={() => {
            setIsDeleteOpen(false)
          }}
          onSubmit={() => {
            navigate(RoutesManager.systems.systemsHome.root.getURL())
          }}
        />
      )}
    </>
  )
}
