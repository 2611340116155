import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormikProvider, useFormik } from 'formik'
import { Box } from '@mui/material'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'
import { ContentGroup, UpsertLayout, UpsertLayoutMode, Spinner } from '@ketch-com/deck'
import {
  ApplicationFormStep,
  useApplicationValidationSchema,
  validateApplicationFormStep,
} from 'pages/consentAndRights/applications/upsert/utils'
import { RoutesManager } from 'utils/routing/routesManager'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { ApplicationAccessibility } from 'pages/consentAndRights/applications/upsert/components/ApplicationAccessibility'
import { ApplicationDocuments } from 'pages/consentAndRights/applications/upsert/components/ApplicationDocuments'
import { ApplicationBasicDetails } from 'pages/consentAndRights/applications/upsert/components/ApplicationBasicDetails'
import { ApplicationEnvironments } from 'pages/consentAndRights/applications/upsert/components/ApplicationEnvironments'
import { ApplicationDataLayer } from 'pages/consentAndRights/applications/upsert/components/ApplicationDataLayer'
import { ApplicationFormValues } from 'interfaces/applications/ApplicationFormValues'
import { ThemeV3DTO } from 'interfaces/themes-v3/theme'
import { PolicyDocumentDTO } from 'interfaces/policyDocuments/policyDocument'
import { ApplicationPlatform } from 'interfaces/applications/applicationPlatform'
import { IdentitySpaceDTO } from 'interfaces/identitySpaces/identitySpace'
import { getCreateApplicationInitialValues } from './utils'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isReady: boolean
  dataSubjectTypes: DataSubjectTypeDTO[]
  themes: ThemeV3DTO[]
  policyDocuments: PolicyDocumentDTO[]
  identitySpacesList: IdentitySpaceDTO[]
  onSubmit: (values: ApplicationFormValues) => Promise<void>
}

const breadcrumbs = [
  {
    title: 'Consent & Rights',
    link: RoutesManager.deployment.root.getURL(),
  },
  {
    title: 'Properties',
    link: RoutesManager.deployment.applications.root.getURL(),
  },
  {
    title: 'Create Property',
  },
]

export const ApplicationCreate: FC<Props> = ({
  isReady,
  dataSubjectTypes,
  themes,
  policyDocuments,
  identitySpacesList,
  onSubmit,
}) => {
  const [currentFormStep, setCurrentFormStep] = useState(0)
  const navigate = useNavigate()
  const validationSchema = useApplicationValidationSchema()
  const initialValues = getCreateApplicationInitialValues()

  const formikProps = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  })

  const { isSubmitting, submitForm, values } = formikProps
  const formSteps: ApplicationFormStep[] = [
    ApplicationFormStep.BASIC_DETAILS,
    ApplicationFormStep.ENVIRONMENTS,
    ApplicationFormStep.DATA_LAYER,
  ]

  const renderedChildren = [
    <ApplicationBasicDetails dataSubjectTypes={dataSubjectTypes} />,
    <ApplicationEnvironments themes={themes.filter(theme => !!theme.summary)} />,
    <ApplicationDataLayer identitySpacesList={identitySpacesList} />,
  ]

  if (isRelease(Release.PropertyThemeDocuments)) {
    formSteps.splice(1, 0, ApplicationFormStep.DOCUMENTS)
    renderedChildren.splice(1, 0, <ApplicationDocuments policyDocuments={policyDocuments} />)
  }

  if (isRelease(Release.FabButton) && values.platform !== ApplicationPlatform.CTV) {
    formSteps.splice(2, 0, ApplicationFormStep.ACCESSIBILITY)
    renderedChildren.splice(2, 0, <ApplicationAccessibility />)
  }

  const isLastStep = formSteps.length - 1 === currentFormStep

  const onAccept = async () => {
    const isStepValid = validateApplicationFormStep(formSteps[currentFormStep], formikProps)
    if (!isStepValid) return
    if (isLastStep) return submitForm()
    setCurrentFormStep(prev => prev + 1)
  }

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <FormikProvider value={formikProps}>
        <UpsertLayout
          showStepper
          formSteps={formSteps}
          currentFormStep={currentFormStep}
          mode={UpsertLayoutMode.create}
          renderFormTitle={() => <ContentGroup title="Create Property" titleVariant="h2" isDivider />}
          acceptButtonProps={{ pending: isSubmitting, disabled: isSubmitting || !isReady }}
          cancelButtonProps={{ pending: isSubmitting }}
          acceptActionButtonText={isLastStep ? 'Done' : 'Next'}
          onAccept={onAccept}
          onCancel={() => {
            navigate(RoutesManager.deployment.applications.root.getURL())
          }}
        >
          {isReady ? (
            <Box pt={2} component="form" autoComplete="off">
              {renderedChildren.filter((_, i) => currentFormStep >= i)}
            </Box>
          ) : (
            <Box
              sx={{
                margin: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spinner size="32px" thickness={2.5} />
            </Box>
          )}
        </UpsertLayout>
      </FormikProvider>
    </>
  )
}
