import { createUseQuery, CustomQueryConfig } from 'api/common/query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchRightInvocationV2 } from 'api/rightInvocationsV2/fetchers/fetchRightInvocationV2'
import { useMatch } from 'react-router-dom'

const fiveMinutes = 1000 * 60 * 5
const tenSeconds = 1000 * 10
const subjectCommunicationPath = '/orchestration/rights-queue/view/:id/subject-communication'

const useCustomQuery = createUseQuery({
  queryKey: ApiQueryKeys.rightInvocationV2,
  queryFn: fetchRightInvocationV2,
  select: res => res?.data?.rightInvocation || null,
})

type Config = CustomQueryConfig<typeof useCustomQuery>

export const useRightInvocationV2 = (config: Config) => {
  const match = useMatch(subjectCommunicationPath)
  return useCustomQuery({
    ...config,
    /* Increase refetch interval when the user is on subjectCommunicationPath */
    refetchInterval: config?.refetchInterval || !!match ? tenSeconds : fiveMinutes,
    ...(!!match && {
      /* implement exponential rolloff of retry request interval in the event of error */
      retryDelay: attempt => {
        return Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000)
      },
    }),
    params: {
      ...config.params!,
    },
  })
}
