import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import { WebTagDTO } from 'interfaces/webtags/WebTags'
import { RoutesManager } from 'utils/routing/routesManager'
import { WebTagPurposesTab } from 'pages/consentAndRights/consent/webTags/view/tabs/WebTagPurposesTab'

type Props = {
  webTag: WebTagDTO
}

export const WebTagViewRoutes: React.FC<Props> = ({ webTag }) => {
  const location = useLocation()

  return (
    <Routes location={location}>
      <Route path="/" element={<WebTagPurposesTab webTag={webTag} />} />
      <Route
        path={RoutesManager.orchestration.webTags.view.purposes.root.pattern}
        element={<WebTagPurposesTab webTag={webTag} />}
      />
    </Routes>
  )
}
