import React, { useContext } from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
import {
  OrgPlanIDs,
  OrgPlanMaxUniquesText,
  OrgPlanCosts,
  OrgPlanNames,
} from 'components/planAndBilling/common/constants/planAndBilling'
import { Button, Icon, theme, Status, StatusVariant, StatusState, Chip } from '@ketch-com/deck'

const starterPlanOptions = [
  {
    title: OrgPlanMaxUniquesText.starter,
    tooltip:
      'The number of unique visitors who will see the Ketch privacy experience on your website or mobile app each month.',
  },
  {
    title: 'Consent Management Platform (CMP)',
    tooltip:
      'Includes unlimited policy templates for privacy laws, customizable privacy experiences, website tracker scanning and blocking, and consent record storage.',
  },
  {
    title: '2 Integrations',
    tooltip:
      'Pass visitor consent signals to (2) business systems selected from our Integration Library (including Google Analytics, HubSpot, MailChimp, and more).',
  },
  {
    title: 'Limited Support',
    tooltip:
      'Limited assistance from the Ketch Customer Experience Team to configure the platform and understand its core functionality.',
  },
]

export const Starter = () => {
  const { orgPlan } = useContext(PlanAndBillingContext)
  const isStarterPlanActive = orgPlan?.planProductID === OrgPlanIDs?.starter

  return (
    <Box
      flexBasis={0}
      flexGrow={1}
      pt={2}
      pb={4}
      sx={{
        backgroundColor: theme.palette.Blue.Default.o8,
        border: `1px solid ${theme.palette.Action.Primary}`,
        borderRadius: 3,
        position: 'relative',
      }}
    >
      <Chip
        size="small"
        label={<Typography variant="smallLabel">Most Popular</Typography>}
        color="primary"
        sx={{
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
      <Box px={2}>
        <Box mb={2} display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="h3">{OrgPlanNames.starter}</Typography>
          <Box display="flex" gap={0.5}>
            <Typography noWrap variant="h4">
              ${OrgPlanCosts.starter}
            </Typography>
            <Typography variant="h4" color={theme.palette.Text.Secondary}>
              /Month
            </Typography>
          </Box>
          <Typography variant="body" color={theme.palette.Text.Secondary}>
            Per Organization
          </Typography>
        </Box>

        <Box my={2}>
          <Typography variant="body">For moderate traffic websites and apps.</Typography>
        </Box>

        {isStarterPlanActive ? (
          <Box
            sx={{
              '& > div': {
                display: 'flex',
                padding: '9px 8px',
                fontSize: '14px',
                width: '100%',
              },
            }}
          >
            <Status
              icon="FCheckRound"
              label="You are on Starter"
              variant={StatusVariant.outlined}
              status={StatusState.draft}
            />
          </Box>
        ) : (
          <Button
            fullWidth
            size="large"
            onClick={() => {}}
            sx={{
              backgroundColor: `${theme.palette.Success.Primary} !important`,
            }}
          >
            Upgrade
          </Button>
        )}
      </Box>

      <Box
        mt={2.5}
        pt={0.5}
        px={2}
        display="flex"
        flexDirection="column"
        sx={{
          borderTop: `1px solid ${theme.palette.Common.Divider}`,
        }}
      >
        {starterPlanOptions.map((planOption, index) => (
          <Box key={index} py="6px" display="flex" justifyContent="space-between" alignItems="center" gap={0.5}>
            <Typography variant={index === 0 ? 'label' : 'body'} pr={!planOption.tooltip ? 3 : 0}>
              {planOption.title}
            </Typography>
            {planOption.tooltip && (
              <Tooltip title={planOption.tooltip} placement="bottom">
                <Box display="inline-flex">
                  <Icon name="OInfo" iconColor={theme.palette.Black.o32} />
                </Box>
              </Tooltip>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
