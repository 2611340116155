import { Typography } from '@mui/material'
import { TabSegmented, TabSegmentedGroup, Spinner, theme } from '@ketch-com/deck'
import { SidebarFormGroup } from './SidebarFormGroup'
import { styled } from '@mui/styles'
import Box from '@mui/material/Box'
import { FormTextInput } from './FormTextInput'
import { getFormNameForLocale } from '../../../utils/utils'
import { FormEditor, renderDocumentLinkerModal } from 'components/ui-kit/form/editor'
import { useContext, useState } from 'react'
import { ExperienceUpsertContext } from '../../../context/ExperienceUpsertContext'
import { renderDocumentLinkerModalExcludeModal } from 'components/ui-kit/form/editor/components/DocumentLinkerModal'

type Props = {
  title: string
  /** Form field name, only relevant when variant !== 'switchButtons' */
  name?: string
  /** Form field name for ON button, only relevant when variant === 'switchButtons' */
  switchButtonOnName?: string
  /** Form field name for OFF button, only relevant when variant === 'switchButtons' */
  switchButtonOffName?: string
  variant: 'text' | 'richText' | 'switchButtons'
  /** Should the "trigger modal" option be present */
  excludeModalLinks?: boolean
  /** Character limit */
  charLimit?: number
}

enum SwitchButtonType {
  ON = 'on',
  OFF = 'off',
}

const InputsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}))

export const SidebarFormTranslations: React.FC<Props> = ({
  title,
  name = '',
  switchButtonOnName = '',
  switchButtonOffName = '',
  variant,
  excludeModalLinks = false,
  charLimit,
}) => {
  // Languages
  const { languages, isLanguagesLoading } = useContext(ExperienceUpsertContext)
  const [selectedSwitchButton, setSelectedSwitchButton] = useState<SwitchButtonType>(SwitchButtonType.ON)
  const hasLanguages = !isLanguagesLoading && !!languages.length

  // Decide what form field name to use, simply use the name passed in for text and rich text variant. For switch buttons,
  // we must decide to use either the ON button field name or OFF
  const fieldName =
    variant === 'switchButtons'
      ? selectedSwitchButton === SwitchButtonType.ON
        ? switchButtonOnName
        : switchButtonOffName
      : name

  const handleSwitchButtonChange = (event: React.MouseEvent<HTMLElement>, newSwitchButtonType: SwitchButtonType) => {
    if (newSwitchButtonType !== null) {
      setSelectedSwitchButton(newSwitchButtonType)
    }
  }

  return !hasLanguages ? null : (
    <SidebarFormGroup
      title={
        <Typography variant={'h4'} color={theme.palette.darkDuskFaded.main}>
          {title}
        </Typography>
      }
    >
      {isLanguagesLoading ? (
        <Spinner size={'32px'} thickness={2} />
      ) : (
        <InputsBox>
          {/* Switch button picker */}
          {variant === 'switchButtons' && (
            <TabSegmentedGroup
              value={selectedSwitchButton}
              exclusive
              onChange={handleSwitchButtonChange}
              size="small"
              fullWidth={false}
              sx={{ width: 'fit-content' }}
            >
              <TabSegmented value={SwitchButtonType.ON} key="1">
                <Typography variant={selectedSwitchButton === SwitchButtonType.ON ? 'smallLabel' : 'smallBody'}>
                  ON Text Label
                </Typography>
              </TabSegmented>
              <TabSegmented value={SwitchButtonType.OFF} key="2">
                <Typography variant={selectedSwitchButton === SwitchButtonType.OFF ? 'smallLabel' : 'smallBody'}>
                  OFF Text Label
                </Typography>
              </TabSegmented>
            </TabSegmentedGroup>
          )}

          {/* Translation field for each locale, either regular or rich text depending on component variant */}
          {languages.map(({ language }) =>
            variant === 'richText' ? (
              <FormEditor
                label={language.englishName}
                required={false}
                id={getFormNameForLocale(fieldName, language.code).replaceAll('.', '_')}
                name={getFormNameForLocale(fieldName, language.code)}
                linkChangeModal={excludeModalLinks ? renderDocumentLinkerModalExcludeModal : renderDocumentLinkerModal}
                charLimit={charLimit}
              />
            ) : (
              <FormTextInput
                key={language.code}
                label={
                  <Typography variant={'label'}>
                    {language.englishName}{' '}
                    <Typography variant={'label'} color={theme.palette.darkDuskFaded.main}>
                      (Optional)
                    </Typography>
                  </Typography>
                }
                name={getFormNameForLocale(fieldName, language.code)}
                fullWidth
                charLimit={charLimit}
              />
            ),
          )}
        </InputsBox>
      )}
    </SidebarFormGroup>
  )
}
