import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Message, Client, Paginator } from '@twilio/conversations'
import { getAvatarColorMap } from 'pages/policyCenter/processingActivities/view/Comment/utils'
import { ChatData } from 'pages/policyCenter/processingActivities/view/Comment/interfaces'
import { TWILIO_CLIENT_STATE } from 'pages/policyCenter/processingActivities/view/Comment/constants'
import { showToast } from 'components/ui-kit/toastr/Toastr'

export const useTwilioConversationClient = () => {
  const { values, setFieldValue } = useFormikContext<ChatData>()

  const {
    twilioMessages,
    twilioConversationClient,
    twilioConversationId,
    twilioConversationToken,
    twilioConversations,
    twilioActiveConversation,
  } = values

  useEffect(() => {
    let client: Client
    if (!twilioConversationToken) return
    if (!twilioConversationClient) {
      client = new Client(twilioConversationToken)
      client.on('stateChanged', state => {
        switch (state) {
          case TWILIO_CLIENT_STATE.INITIALIZED:
            return setFieldValue('twilioConversationClient', client)
          case TWILIO_CLIENT_STATE.FAILED:
            showToast({ content: 'The chat client failed to initialize', type: 'error' })
            return
        }
      })
    }

    return () => {
      if (client) client.removeAllListeners()
    }
  }, [twilioConversationToken, setFieldValue, twilioConversationClient])

  useEffect(() => {
    if (!twilioConversationClient) return

    twilioConversationClient.on('connectionStateChanged', state => {
      setFieldValue('twilioConnectionState', state)
    })

    twilioConversationClient.on('conversationJoined', conversation => {
      setFieldValue('twilioConversations', [...(twilioConversations || []), conversation])
    })
    twilioConversationClient.on('conversationLeft', conversation => {
      setFieldValue(
        'twilioConversations',
        (twilioConversations || []).filter(c => c !== conversation),
      )
    })

    return () => {
      if (twilioConversationClient) twilioConversationClient.removeAllListeners()
    }
  }, [twilioConversationClient, setFieldValue, twilioMessages, twilioConversations])

  useEffect(() => {
    if (!twilioConversationClient && !twilioConversationId) return

    const conversationInit = async () => {
      try {
        const sdkConversation = await twilioConversationClient?.getConversationBySid(twilioConversationId || '')
        if (sdkConversation) {
          const messagesPaginator: Paginator<Message> = await sdkConversation.getMessages(1000)
          const messages: Message[] = messagesPaginator?.items || []
          setFieldValue('avatarColorMap', getAvatarColorMap({ messages }))
          setFieldValue('twilioMessages', messages)
          setFieldValue('twilioActiveConversation', sdkConversation)
        }
      } catch (error) {
        showToast({ content: 'Unable to get conversation or messages', type: 'error' })
      }
    }

    conversationInit()
  }, [twilioConversationClient, twilioConversationId, setFieldValue])

  useEffect(() => {
    if (!twilioActiveConversation) return

    twilioActiveConversation.on('messageAdded', async (message: Message) => {
      const newMessages = [...(twilioMessages || []), message]
      setFieldValue('avatarColorMap', getAvatarColorMap({ messages: newMessages }))
      setFieldValue('twilioMessages', newMessages)
      if (message?.body?.trim() === values?.message?.trim()) {
        setFieldValue('message', '')
      }
    })
    twilioActiveConversation.on('messageRemoved', message => {
      setFieldValue(
        'twilioMessages',
        (twilioMessages || []).filter(m => m.sid !== message.sid),
      )
    })

    return () => {
      if (twilioActiveConversation) twilioActiveConversation.removeAllListeners()
    }
  }, [setFieldValue, twilioActiveConversation, twilioMessages, values.message])
}
