import { themeUpsertFormKeys } from '../../../../utils/useThemeValidationSchema'
import { Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SidebarFormGroup } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/SidebarFormGroup'
import { FormCheckbox } from '../../../../../../experiences-v2/upsert/components/upsertExperienceSidebar/components/FormCheckbox'

export const BannerThemeFooterForm: React.FC = () => {
  return (
    <SidebarFormGroup sx={{ p: 0, m: 0, gap: 0 }}>
      <FormControlLabel
        control={<FormCheckbox name={themeUpsertFormKeys.bannerFooterKetchBadgeVisible} />}
        label={<Typography variant={'label'}>Show “Powered by Ketch” Badge</Typography>}
      />
    </SidebarFormGroup>
  )
}
