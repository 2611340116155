import { useUserMe } from 'api/users/queries/useUserMe'
import { PlanType } from 'interfaces/organizations/organization'

export const useIsKetchFree = () => {
  const { data: user, isLoading } = useUserMe()
  const isKetchFree =
    user?.organization.planProductID === PlanType.KETCH_FREE || user?.organization.planProductID === PlanType.KETCH_PLG

  return { isKetchFree, isLoading }
}
