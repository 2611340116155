import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'

import { ApplianceDTO } from 'interfaces/appliances'
import { MaybeNull } from 'interfaces'
import { AppliancesInstallationGuide, AppliancesListFilters, AppliancesViewActions } from './components'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { RoutesManager } from 'utils/routing/routesManager'
import { Button, ContentGroup, DataGrid, PopUp } from '@ketch-com/deck'
import { GridRowParams } from '@mui/x-data-grid'
import { transpondersListColumns } from './utils/transpodersDataGridUtils'
import { useAppliancesListFilters } from './hooks'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { AppliancesViewActionsV2 } from './components/AppliancesViewActions'
import { ApplianceCreationPrompt } from './components/AppliancesCreationPrompt'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { getRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'

type Props = {
  isLoading: boolean
  appliances: MaybeNull<ApplianceDTO[]>
}

export const TranspondersList: React.FC<Props> = ({ isLoading, appliances }) => {
  const navigate = useNavigate()

  // remove feature gate when goes live
  const transponderCreateFLowActive = getRelease() === Release.TransponderCreationFlow

  const [isInstallationGuideModalOpen, setIsInstallationGuideModalOpen] = useState(false)
  const [{ status, search }] = useAppliancesListFilters()

  const { isPermitted } = useIsPermitted()
  const hasTransponderWritePermission = isPermitted(PERMISSIONS.TRANSPONDER_WRITE)

  const columns = transpondersListColumns(hasTransponderWritePermission)
  const hasAppliancesWithApprovalData = appliances
    ? appliances.some(appliance => appliance.hasOwnProperty('approvalStatus') && !appliance.approvalStatus)
    : false
  const dataGridColumns = !hasAppliancesWithApprovalData ? columns.slice(1) : columns

  const breadcrumbs = [
    { title: 'Developers', link: RoutesManager.developers.root.getURL() },
    { title: 'Transponders', link: RoutesManager.developers.transponders.list.getURL() },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box display="flex" flexDirection="column" gap={3}>
        <ContentGroup
          isDivider
          title="Transponders"
          actionBottomBlockComponent={<AppliancesListFilters />}
          actionRightBlockComponent={
            transponderCreateFLowActive ? (
              <AppliancesViewActionsV2 />
            ) : (
              <AppliancesViewActions
                handleClick={() => {
                  setIsInstallationGuideModalOpen(true)
                }}
              />
            )
          }
        />
        {!isLoading && !search && status !== 'Status' && (appliances?.length || 0) < 1 ? (
          transponderCreateFLowActive ? (
            <ApplianceCreationPrompt />
          ) : (
            <AppliancesInstallationGuide />
          )
        ) : (
          <Box flexDirection="column" display="flex">
            <DataGrid
              sx={{
                cursor: 'pointer',
                '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                  backgroundImage: 'unset',
                  cursor: 'auto',
                },
                ...(isLoading && {
                  '& .MuiDataGrid-virtualScroller': {
                    height: '300px',
                  },
                }),
              }}
              autosizeOnMount
              autosizeOptions={{
                expand: true,
                includeHeaders: true,
                includeOutliers: false,
              }}
              getRowHeight={() => 'auto'}
              columns={dataGridColumns}
              disableChildrenSorting
              disableColumnMenu
              disableColumnPinning
              disableColumnResize
              disableColumnReorder
              disableRowSelectionOnClick
              getRowId={row => row.applianceID}
              rows={appliances || []}
              hideFooter
              loading={isLoading}
              noRowsOverlayProps={{
                buttonTitle: '',
              }}
              onRowClick={({ row: appliance }: GridRowParams<ApplianceDTO>) => {
                navigate(RoutesManager.developers.transponders.detail.getURL({ id: appliance.applianceID }))
              }}
            />
          </Box>
        )}
      </Box>

      {isInstallationGuideModalOpen && (
        <PopUp
          onClose={() => setIsInstallationGuideModalOpen(false)}
          variant="modal"
          popUpWidth="900px"
          popUpCustomTitle={
            <Typography variant="h3" data-intercom-target="installation_guide_modal_title" component="span">
              Create Transponder
            </Typography>
          }
          popUpActionChildren={
            <Button color="secondary" size="large" onClick={() => setIsInstallationGuideModalOpen(false)}>
              Close
            </Button>
          }
        >
          <AppliancesInstallationGuide />
        </PopUp>
      )}
    </>
  )
}
