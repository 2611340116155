import React from 'react'
import { Box } from '@mui/material'
import { Card, theme } from '@ketch-com/deck'
import { GetTrackerStatisticsResponseBody } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { MaybeNull } from 'interfaces/common'
import { DrawerType } from 'pages/policyCenter/trackers/interfaces'
import pluralize from 'pluralize'

type Props = {
  trackerStatistics: GetTrackerStatisticsResponseBody
  setShowDrawer: React.Dispatch<React.SetStateAction<MaybeNull<DrawerType>>>
}

export const TrackerStatistics: React.FC<Props> = ({ trackerStatistics, setShowDrawer }: Props) => {
  const needsReviewStatistics =
    (trackerStatistics?.numNotFoundTrackers || 0) + (trackerStatistics?.numTrackersOnNewProperties || 0)
  return (
    <Box display="flex" gap={2}>
      <Card
        cardTitle={trackerStatistics?.count ? trackerStatistics?.count?.toString() : '0'}
        subTitle={`${pluralize('Tracker', trackerStatistics?.count)}`}
        isNumerical
        cardVariant="stretchWidth"
      />

      {needsReviewStatistics ? (
        <Card
          cardTitle={needsReviewStatistics?.toString()}
          subTitle={`${pluralize('Need', needsReviewStatistics)} Review`}
          isNumerical
          primaryIcon="OArrowCRight"
          secondaryIcon="OImportant"
          cardVariant="stretchWidth"
          isClickable
          textColor={theme.palette.chileanFire.main}
          backgroundColor={theme.palette.bleachWhite.main}
          onClick={() => setShowDrawer(DrawerType.review)}
        />
      ) : null}

      {trackerStatistics?.unReviewed ? (
        <Card
          cardTitle={trackerStatistics?.unReviewed?.toString()}
          subTitle={`New ${pluralize('Tracker', trackerStatistics?.unReviewed)} Found`}
          isNumerical
          cardVariant="fixedWidth"
          isClickable
          onClick={() => setShowDrawer(DrawerType.unapproved)}
        />
      ) : (
        <Card
          cardTitle="All caught up"
          subTitle="No new trackers discovered."
          primaryIcon="OBinoculars"
          cardVariant="stretchWidth"
          backgroundColor={theme.palette.iron.main}
        />
      )}
    </Box>
  )
}
