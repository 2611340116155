import { GetProcessingActivityChatResponseBodyDTO } from '@ketch-com/figurehead'
import { ChatData } from 'pages/policyCenter/processingActivities/view/Comment/interfaces'
import { AuthContextType } from 'interfaces/auth/authContext'

export const getChatInitialValues = (
  processingActivityChat: GetProcessingActivityChatResponseBodyDTO,
  currentUserData: AuthContextType['userData'],
): ChatData => ({
  twilioConversationClient: null,
  twilioConversations: [],
  twilioActiveConversation: null,
  twilioMessages: null,
  twilioUnreadMessagesCount: null, // TODO: implement twilioUnreadMessagesCount when prioritized
  twilioConnectionState: null,
  twilioConversationId: processingActivityChat?.conversationId || '',
  twilioConversationToken: processingActivityChat?.token || '',
  currentUser: currentUserData || {},
  avatarColorMap: new Map(),
  message: '',
})
