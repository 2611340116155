import React, { CSSProperties, useState } from 'react'
import { Box } from '@mui/material'
import { ConnectionsListItem } from './ConnectionsListItem'
import { DeleteAppInstanceModal } from 'pages/dataSystems/components/Modals'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as EmptyStateConnectionsIcon } from 'assets/icons/emptyState/EmptyStateConnections.svg'
import { useIsEntitled, useIsPermitted, useQueryParams } from 'utils/hooks'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { RoutesManager } from 'utils/routing/routesManager'
import { Filters, ShowFilters } from '../Filters'
import { useConnectionsInfinite } from 'api/dataSystems/queries/useConnectionsInfintie'
import { Waypoint } from 'react-waypoint'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useInstalledDataSystem } from 'api/dataSystems/queries/useInstalledDataSystem'
import { Button, ContentGroup, EmptyState, Spinner } from '@ketch-com/deck'

import { ConnectionV2DTO } from '@ketch-com/figurehead'
import { InfiniteScrollLoadingIndicator } from 'components/InfiniteScrollLoadingIndicator'

interface Props {
  className?: string
  style?: CSSProperties
  listItemBackgroundColor?: string
}

export const ConnectionsList: React.FC<Props> = ({ listItemBackgroundColor }) => {
  const { id: installedDataSystemId } = useParams<{ id: string }>()
  const { queries, removeAllQueryParams } = useQueryParams<{
    q?: string
    capabilities?: string
    connectionStatuses?: string
  }>()

  const {
    data: connections,
    isLoading,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useConnectionsInfinite({
    installedDataSystemId,
    q: queries.q,
    capabilities: queries?.capabilities ? [queries?.capabilities] : [],
  })

  const { data: installedDataSystem, isLoading: isLoadingInstalledDataSystem } = useInstalledDataSystem({
    params: {
      id: installedDataSystemId!,
    },
  })

  const { isPermitted } = useIsPermitted()

  const { isEntitled } = useIsEntitled()

  const [connection, setConnection] = useState<ConnectionV2DTO>({})

  const navigate = useNavigate()

  const isReady = !isLoading && !isRefetching && !isLoadingInstalledDataSystem

  const isIntegrationWrite = isPermitted(PERMISSIONS.INTEGRATIONS_WRITE)

  const isEntitledToCreateConnection = isEntitled(
    installedDataSystem?.dataSystem?.systemType === 'Internal'
      ? ENTITLEMENTS.QTY_SYSTEM_INTEGRATIONS_INTERNAL
      : ENTITLEMENTS.QTY_SYSTEM_INTEGRATIONS_EXTERNAL,
  )

  const filteredCapabilities = installedDataSystem?.dataSystem?.filteredCapabilities ?? []
  const hasCapabilities = !!filteredCapabilities?.length

  if (isReady && connections?.length === 0 && !Object.keys(queries).length) {
    return (
      <Box
        sx={{
          backgroundColor: 'white.main',
          padding: 5,
          borderRadius: '11px',
        }}
      >
        <EmptyState
          title="Make Connections"
          subTitle="By connecting the systems you add to their respective instances, you can automate all privacy signals. All the connections you establish will be displayed in this section."
          customIcon={<EmptyStateConnectionsIcon />}
          primaryButtonTitle="Create New Connection"
          primaryButtonTooltipTitle={
            !isEntitledToCreateConnection
              ? 'No application entitlements remaining, please delete a connection or contact support to license additional.'
              : !hasCapabilities
              ? 'This app requires additional capabilities - please contact support@ketch.com to resolve.'
              : ''
          }
          primaryButtonProps={{
            onClick: () => {
              if (installedDataSystemId) {
                navigate(
                  RoutesManager.systems.connections.upsert.root.getURL({
                    id: installedDataSystemId,
                    instanceId: NEW_ITEM_ID,
                  }),
                )
              } else {
                navigate(RoutesManager.systems.connections.create.getURL())
              }
            },
            disabled: installedDataSystemId ? !isEntitledToCreateConnection || !hasCapabilities : false,
          }}
        />
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column" gap={3} mb={6}>
      {installedDataSystemId && (
        <ContentGroup
          titleVariant="h3"
          title="Connections"
          subTitle={`An overview of connections, capabilities and other system's instances that are not connected yet.`}
        />
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
        }}
      >
        <Box display="flex" alignItems="center">
          <Filters
            show={installedDataSystemId ? [ShowFilters.SEARCH] : [ShowFilters.SEARCH, ShowFilters.CAPABILITIES]}
          />
          {!installedDataSystemId && isIntegrationWrite && (
            <Button
              color="primary"
              size="medium"
              sx={{ marginLeft: 'auto', marginRight: '0' }}
              onClick={() => navigate(RoutesManager.systems.connections.create.getURL())}
            >
              Create New Connection
            </Button>
          )}
        </Box>

        <Box display="flex" flexDirection="column">
          {isLoading ? (
            <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
              <Spinner size="32px" thickness={2.5} />
            </Box>
          ) : isReady && connections?.length === 0 && Object.keys(queries).length ? (
            <Box
              sx={{
                backgroundColor: 'white.main',
                padding: 5,
                borderRadius: '11px',
              }}
            >
              <EmptyState
                title="No results found"
                subTitle="Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try again."
                primaryButtonTitle="Reset to Defaults"
                iconName="OFilter"
                primaryButtonProps={{
                  onClick: () => removeAllQueryParams(),
                }}
              />
            </Box>
          ) : (
            <>
              {connections?.map(connection => {
                return (
                  <ConnectionsListItem
                    backgroundColor={listItemBackgroundColor}
                    connection={connection}
                    handleDelete={() => setConnection(connection)}
                    key={connection.id}
                  />
                )
              })}
            </>
          )}
          {isReady ? (
            <Waypoint
              topOffset={300}
              onEnter={() => {
                if (!isFetchingNextPage && hasNextPage) fetchNextPage()
              }}
            />
          ) : (
            <Box mt={2}>
              <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
            </Box>
          )}
        </Box>
      </Box>

      {connection.id && (
        <DeleteAppInstanceModal
          connection={connection}
          onCancel={() => setConnection({})}
          onSubmit={async () => {
            setConnection({})
          }}
        />
      )}
    </Box>
  )
}
