import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { useWebTag } from 'api/webtags/queries/useWebTag'
import { useWebTagVersion } from 'api/webtags/queries/useWebTagVersion'
import { WebTagView } from 'pages/consentAndRights/consent/webTags/view/WebTagView'

export const WebTagViewContainer: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const { queries } = useQueryParams<{ version?: string }>()
  const { version } = queries

  const handleRedirectToWebTagsList = () => {
    navigate(RoutesManager.orchestration.webTags.root.getURL())
  }

  const { data: webTag, isLoading: isWebTagLoading } = useWebTag({
    enabled: !version,
    params: {
      webTagId: id!,
      includeIssues: true,
      includeMetadata: true,
    },
    onError: handleRedirectToWebTagsList,
  })

  const { data: webTagVersion, isLoading: isWebTagVersionLoading } = useWebTagVersion({
    enabled: !!version,
    params: {
      webTagId: id!,
      version: version!,
    },
    onError: handleRedirectToWebTagsList,
  })

  const isReady = version ? !isWebTagVersionLoading : !isWebTagLoading

  return <WebTagView isReady={isReady} webTag={version ? webTagVersion : webTag} />
}
