import {
  TWILIO_CONNECTION_STATE,
  TWILIO_CONNECTION_STATE_MESSAGE,
} from 'pages/policyCenter/processingActivities/view/Comment/constants'
import { ConnectionState } from '@twilio/conversations'
import { MaybeNull } from 'interfaces'

export const getConnectionStateText = (twilioConnectionState: MaybeNull<ConnectionState> | undefined): String => {
  let connectionState = ''
  switch (twilioConnectionState) {
    case TWILIO_CONNECTION_STATE.CONNECTING:
      connectionState = TWILIO_CONNECTION_STATE_MESSAGE[TWILIO_CONNECTION_STATE.CONNECTING]
      break
    case TWILIO_CONNECTION_STATE.DENIED:
      connectionState = TWILIO_CONNECTION_STATE_MESSAGE[TWILIO_CONNECTION_STATE.DENIED]
      break
    case TWILIO_CONNECTION_STATE.ERROR:
      connectionState = TWILIO_CONNECTION_STATE_MESSAGE[TWILIO_CONNECTION_STATE.ERROR]
      break
    case TWILIO_CONNECTION_STATE.DISCONNECTING:
      connectionState = TWILIO_CONNECTION_STATE_MESSAGE[TWILIO_CONNECTION_STATE.DISCONNECTING]
      break
    case TWILIO_CONNECTION_STATE.DISCONNECTED:
      connectionState = TWILIO_CONNECTION_STATE_MESSAGE[TWILIO_CONNECTION_STATE.DISCONNECTED]
      break
    case TWILIO_CONNECTION_STATE.UNKNOWN:
      connectionState = TWILIO_CONNECTION_STATE_MESSAGE[TWILIO_CONNECTION_STATE.UNKNOWN]
      break
  }

  return connectionState
}
