export const getAssetCloudLongName = (cloud: string) => {
  switch (cloud ? cloud.toLowerCase() : '') {
    case 'azure sql database':
      return 'Azure SQL Database'
    case 'aws':
      return 'Amazon AWS'
    case 'googlecloud':
      return 'Google Cloud'
    case 'google cloud storage':
      return 'Google Cloud Storage'
    case 'google cloud datastore':
      return 'Google Cloud Datastore'
    case 'azure':
      return 'Azure'
    case 'snowflake':
      return 'Snowflake'
    case 'privatecloud':
      return 'Private Cloud'
    case 'oracledb':
      return 'Oracle DB'
    case 'postgresql':
      return 'PostgreSQL'
    case 'sql server':
      return 'SQL Server'
    case 'mysql':
      return 'MySQL'
    case 'redshift':
      return 'Redshift'
    case 'mongodb':
      return 'MongoDB'
    case 'mariadb':
      return 'MariaDB'
    case 'oracle':
      return 'Oracle'
    case 'google bigquery':
      return 'BigQuery'
    case 'databricks':
      return 'Databricks'
    case 'cockroachdb':
      return 'CockroachDB'
    default:
      return 'Unknown'
  }
}
