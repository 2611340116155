import React, { useState } from 'react'
import { Box, Popover } from '@mui/material'
import { Icon, IconDictionary, Button, theme } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { UnapprovedTracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { useUpsertTrackerDisclosure } from 'api/trackers/mutations/useUpsertTrackerDisclosure'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { PropertyDisclosureAndBlocking } from 'pages/policyCenter/trackers/list/unapprovedTrackers/components'

type Props = {
  tracker: UnapprovedTracker
}

export const DisclosureAndBlocking: React.FC<Props> = ({ tracker = {} as UnapprovedTracker }) => {
  const queryClient = useQueryClient()
  const trackerPropertiesItems = tracker?.properties
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { isPermitted: getIsPermitted } = useIsPermitted()

  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])
  const isTrackerDisclosedInSomeProperties = trackerPropertiesItems?.some(item => item?.isDisclosed)
  const isTrackerDisclosedInAllProperties = trackerPropertiesItems?.every(item => item?.isDisclosed)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const { mutateAsync: upsertUnapprovedTrackerDisclosure, isLoading: isUpdatingUnapprovedTrackerDisclosure } =
    useUpsertTrackerDisclosure({
      onSuccess: async () => {
        await queryClient.invalidateQueries([ApiQueryKeys.unapprovedTrackersInfinite])
        showToast({ content: 'Updated disclosure', type: 'success' })
      },
      onError: () => {
        showToast({ content: 'Failed to update disclosure', type: 'error' })
      },
    })

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Button
          sx={{
            width: 32,
            height: 32,
            borderRadius: '100%',
            padding: 0.25,
          }}
          disabled={!isPermittedToWriteCookie || isUpdatingUnapprovedTrackerDisclosure || Boolean(anchorEl)}
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation()
            upsertUnapprovedTrackerDisclosure({
              params: {
                data: {
                  isDisclosed: isTrackerDisclosedInSomeProperties ? false : true,
                },
                encodedTrackerKey: tracker?.encodedKey || '',
              },
            })
          }}
          variant={isTrackerDisclosedInSomeProperties ? 'contained' : 'iconCustomRounded'}
          color={isTrackerDisclosedInSomeProperties ? 'primary' : 'tertiary'}
          children={
            isTrackerDisclosedInSomeProperties ? (
              <Icon name={IconDictionary.OEyeShow} iconColor={theme.palette.white.main} />
            ) : (
              <Icon name={IconDictionary.OEyeHide} />
            )
          }
        />

        {isTrackerDisclosedInSomeProperties && !isTrackerDisclosedInAllProperties ? (
          <Button
            disabled={!isPermittedToWriteCookie || isUpdatingUnapprovedTrackerDisclosure || Boolean(anchorEl)}
            onClick={handleClick}
            children="Custom"
            startIcon={<Icon name={IconDictionary.OMore} />}
            sx={{ borderRadius: 12 }}
          />
        ) : (
          <Button
            disabled={!isPermittedToWriteCookie || isUpdatingUnapprovedTrackerDisclosure || Boolean(anchorEl)}
            onClick={handleClick}
            variant="iconCustomRounded"
            color="tertiary"
            children={<Icon name={IconDictionary.OMore} />}
          />
        )}

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box width={340} display="flex" flexDirection="column" gap={1} p={2}>
            {trackerPropertiesItems?.map((trackerPropertyItem, trackerPropertyItemIndex) => {
              return (
                <PropertyDisclosureAndBlocking
                  key={trackerPropertyItem?.id}
                  tracker={tracker}
                  trackerPropertyItem={trackerPropertyItem}
                  trackerPropertyItemIndex={trackerPropertyItemIndex}
                />
              )
            })}
          </Box>
        </Popover>
      </Box>
    </Box>
  )
}
