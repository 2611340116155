import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { Pagination } from 'api/common/paginatedQuery'
import { WebTagDTO } from 'interfaces/webtags/WebTags'
import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { IssuesCellRenderer } from 'components/renderers/IssuesCellRenderer'
import { WebTagsListFilters } from 'pages/consentAndRights/consent/webTags/list/WebTagsListFilters'
import { HighlightText } from 'components/renderers/HighlightText'
import {
  Button,
  Chip,
  DataGrid,
  EmptyState,
  Icon,
  ListLayout,
  PopUp,
  Status,
  StatusState,
  StatusVariant,
  TableCell,
} from '@ketch-com/deck'
import { Box, Typography, Tooltip } from '@mui/material'
import { clearSearchQuery } from 'store/webTagsListFilterSlice'

import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { PurposeDTO } from 'interfaces/purposes/purpose'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { getWebTagsListFilterState } from '../../../../../store/webTagsListFilterSlice/selectors'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

type Props = {
  isBusy: boolean
  isLoading: boolean
  isFetching: boolean
  webTags: WebTagDTO[]
  pagination: Pagination
  handleSyncWebTags: () => Promise<void>
  emptyStateMessage: string
}

export const WebTagsList: React.FC<Props> = ({
  isBusy,
  isLoading,
  isFetching,
  webTags,
  pagination,
  handleSyncWebTags,
}) => {
  const navigate = useNavigate()
  const { queries } = useQueryParams<{
    q?: string
  }>()
  const { q: reduxSearchQuery } = useAppSelector(getWebTagsListFilterState)
  const [searchString, setSearchString] = useState(() => reduxSearchQuery || '')
  const [isSyncTagsOpen, setIsSyncTagsOpen] = useState(false)

  const { isPermitted } = useIsPermitted()
  const canSyncTags = isPermitted(PERMISSIONS.WEB_TAGS_WRITE)
  const dispatch = useAppDispatch()

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Consent', link: RoutesManager.deployment.consentHome.root.getURL() },
    { title: 'Tags', link: RoutesManager.orchestration.webTags.root.getURL() },
  ]

  const columns = useMemo(() => {
    const isIssues = webTags.some(item => !!item.hasIssues)

    return [
      ...(isIssues
        ? [
            {
              field: 'issues',
              headerName: 'Issues',
              width: 35,
              renderCell: (params: GridRenderCellParams) => (
                <TableCell>
                  <IssuesCellRenderer hasIssues={params.row.hasIssues} entityType={ApplicationEntity.WEBTAG} />
                </TableCell>
              ),
              sortable: false,
              renderHeader: () => null,
            },
          ]
        : []),
      {
        field: 'nameAndCode',
        headerName: 'Tag',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <NameAndCodeCellRenderer
              name={<HighlightText parts={queries?.q?.split(' ') || []} text={params.row.name} />}
              code={params.row.code}
            />
          </TableCell>
        ),
        sortable: false,
        flex: 1,
      },
      {
        field: 'source',
        headerName: 'Source',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            {params.row.appName ? (
              <Tooltip title={params.row.appName}>
                <div>
                  <Chip label={params.row.appName} size="small" />
                </div>
              </Tooltip>
            ) : (
              <EmptyValueRenderer />
            )}
          </TableCell>
        ),
        sortable: false,
        flex: 1,
      },
      {
        field: 'sourceConnection',
        headerName: 'Source Connection',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            {params.row.appInstanceName ? (
              <Chip label={params.row.appInstanceName} size="small" />
            ) : (
              <EmptyValueRenderer />
            )}
          </TableCell>
        ),
        sortable: false,
        flex: 1,
      },
      {
        field: 'purposeDependencies',
        headerName: 'Purpose Dependencies',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            {!params.row.purposes || !params.row.purposes?.length ? (
              <Chip label={0} size="small" sx={{ minWidth: '24px' }} clickable />
            ) : (
              <Tooltip
                title={
                  <>{params.row.purposes?.map((purpose: PurposeDTO) => <div key={purpose.ID}>{purpose.name}</div>)}</>
                }
              >
                <div>
                  <Chip label={params.row.purposes?.length} size="small" sx={{ minWidth: '24px' }} clickable />
                </div>
              </Tooltip>
            )}
          </TableCell>
        ),
        sortable: false,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Orchestration Status',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            {params.row.enabled ? (
              <Status icon="Dot" variant={StatusVariant.ghost} status={StatusState.success} label="Enabled" />
            ) : (
              <Status icon="Dot" variant={StatusVariant.ghost} status={StatusState.draft} label="Disabled" />
            )}
          </TableCell>
        ),
        sortable: false,
        flex: 1,
      },
      {
        field: 'updatedAt',
        headerName: 'Last Updated',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <FormattedDateRenderer date={params.row?.metadata?.updatedAt} />
          </TableCell>
        ),
        sortable: false,
        flex: 1,
      },
    ]
  }, [queries?.q, webTags])

  const totalPages = pagination.totalPages

  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )

  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  return (
    <ListLayout
      headerTitle={''}
      actionHeaderBottomBlockComponent={
        <Box display="flex" justifyContent="space-between" width="100%" gap={3} mt={-4}>
          <WebTagsListFilters searchString={searchString} setSearchString={setSearchString} isFetching={isFetching} />
          {canSyncTags ? (
            <Button pending={isBusy} onClick={() => setIsSyncTagsOpen(true)}>
              Sync Tags
            </Button>
          ) : null}
        </Box>
      }
    >
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      {!isFetching && !webTags.length ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            backgroundColor: 'white.main',
            padding: 5,
            borderRadius: '11px',
            height: '410px',
          }}
        >
          <EmptyState
            customIcon={<Icon name="OFilter" width="64px" height="64px" />}
            size="large"
            title="No results found"
            subTitle="Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try
        again."
            secondaryButtonTitle="Reset to Defaults"
            secondaryButtonProps={{
              onClick: () => {
                dispatch(clearSearchQuery())
                setSearchString('')
              },
            }}
          />
        </Box>
      ) : (
        <DataGrid
          rows={webTags || []}
          columns={columns}
          disableChildrenSorting
          disableColumnMenu
          disableColumnResize
          disableColumnReorder
          disableBorder
          disableRowSelectionOnClick
          disableAggregation
          hideFooterRowCount
          onRowClick={row => {
            if (row.id) {
              navigate(RoutesManager.orchestration.webTags.view.root.getURL({ id: row.id.toString() }))
            }
          }}
          getRowId={row => row.ID}
          loading={isLoading || pagination.isPending}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={[rowsPerPage]}
          rowCount={pagination.totalResults || rowsPerPage * totalPages}
          hideFooterPagination={totalPages < 2}
          sx={{
            '& .MuiDataGrid-columnHeader[role="columnheader"]:hover': {
              backgroundImage: 'unset',
            },
            height: 'auto',
          }}
        />
      )}

      {isSyncTagsOpen && (
        <PopUp
          title="Sync Tags"
          popUpWidth="550px"
          variant="dialog"
          onClose={() => setIsSyncTagsOpen(false)}
          popUpActionChildren={
            <>
              <Button color="secondary" size="large" onClick={() => setIsSyncTagsOpen(false)}>
                Close
              </Button>
              <Button
                color="primary"
                size="large"
                pending={isBusy}
                onClick={() => {
                  handleSyncWebTags()
                  setIsSyncTagsOpen(false)
                }}
              >
                Confirm
              </Button>
            </>
          }
        >
          <Typography variant="body">
            This action will push your Ketch platform configuration to your tag manager account. Please confirm you
            would like to proceed.
          </Typography>
        </PopUp>
      )}
    </ListLayout>
  )
}
