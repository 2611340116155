// layout utils
import { ProtectedRoute } from 'components/ui-routing/ProtectedRoute'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { relativePath } from 'utils/routing/relativePath'
import { RoutesManager } from 'utils/routing/routesManager'

import { ApplicationsListContainer } from 'pages/consentAndRights/applications/list/ApplicationsListContainer'
import { ApplicationUpsertContainer } from 'pages/consentAndRights/applications/upsert/ApplicationUpsertContainer'
import { ApplicationViewContainer } from 'pages/consentAndRights/applications/view/ApplicationViewContainer'

import { ExperiencesListContainer } from 'pages/consentAndRights/experiences/list/ExperiencesListContainer'
import { ExperienceUpsert } from 'pages/consentAndRights/experiences/upsert/ExperienceUpsert'
import { ExperienceViewContainer } from 'pages/consentAndRights/experiences/view/ExperienceViewContainer'
import { ExperiencePreview } from 'pages/consentAndRights/experiences/preview/ExperiencePreview'

import { DeploymentPlansListContainer } from 'pages/consentAndRights/deploymentPlans/list/DeploymentPlansListContainer'
import { DeploymentPlanUpsertContainer } from 'pages/consentAndRights/deploymentPlans/upsert/DeploymentPlanUpsertContainer'
import { DeploymentPlanDeployContainer } from 'pages/consentAndRights/deploymentPlans/deploy/DeploymentPlanDeployContainer'
import { DeploymentPlanViewContainer } from 'pages/consentAndRights/deploymentPlans/view/DeploymentPlanViewContainer'
import { DeploymentPlanPreview } from 'pages/consentAndRights/deploymentPlans/preview/DeploymentPlanPreview'

import { DeploymentPlansListContainer as DeploymentPlansListContainerV2 } from 'pages/consentAndRights/deploymentPlans-v2/list/DeploymentPlansListContainer'
import { DeploymentPlanUpsertContainer as DeploymentPlanUpsertContainerV2 } from 'pages/consentAndRights/deploymentPlans-v2/upsert/DeploymentPlanUpsertContainer'
import { DeploymentPlanDeployContainer as DeploymentPlanDeployContainerV2 } from 'pages/consentAndRights/deploymentPlans-v2/deploy/DeploymentPlanDeployContainer'
import { DeploymentPlanViewContainer as DeploymentPlanViewContainerV2 } from 'pages/consentAndRights/deploymentPlans-v2/view/DeploymentPlanViewContainer'

import { LanguagesListContainer } from 'pages/consentAndRights/languages/list/LanguagesListContainer'

import { ThemesListContainer as ThemesV2ListContainer } from 'pages/consentAndRights/themes/list/ThemesListContainer'
import { ThemesUpsertContainer as ThemesV2UpsertContainer } from 'pages/consentAndRights/themes/upsert/ThemesUpsertContainer'
import { ThemeViewContainer as ThemeV2ViewContainer } from 'pages/consentAndRights/themes/view/ThemeViewContainer'
import { ThemesPreviewContainer as ThemesV2PreviewContainer } from 'pages/consentAndRights/themes/preview/ThemesPreviewContainer'
import { PurposeStacksListContainer } from './purposeStacks/list/PurposeStacksListContainer'
import { PurposeStackViewContainer } from './purposeStacks/view/PurposeStackViewContainer'
import { PurposeStacksUpsertContainer } from './purposeStacks/upsert/PurposeStacksUpsertContainer'

import { Forms } from 'pages/consentAndRights/forms/home/Forms'
import { FieldsTab } from './forms/fields/list/components'
import { FormFieldDetailView } from './forms/fields/view/FormFieldDetailView'
import { FormFieldUpsertContainer } from './forms/fields/upsert/FormFieldUpsertContainer'
import { TemplatesTab } from './forms/templates/list/components'
import { FormTemplateUpsertContainer } from './forms/templates/upsert/FormTemplateUpsertContainer'
import { FormTemplateDetailView } from './forms/templates/view/FormTemplateDetailView'
import { FormFieldDetailViewOverviewTab, FormFieldDetailViewTranslationsTab } from './forms/fields/view/components'
import { FormTemplatePreview } from './forms/templates/preview/FormTemplatePreview'
import { FormFieldDetailViewUsageTab } from './forms/fields/view/components/FormFieldDetailViewUsageTab'
import { ExperiencesV2ListContainer } from './experiences-v2/list/ExperiencesListContainer'
import { ExperienceV2Upsert } from './experiences-v2/upsert/ExperienceUpsert'
import { ThemesV3ListContainer } from './themes-v3/list/ThemesListContainer'
import { ThemeV3Upsert } from './themes-v3/upsert/ThemeUpsert'
import { ThemeV3View } from './themes-v3/view/ThemeView'
import { ExperienceV2View } from './experiences-v2/view/ExperienceView'
import { ConsentRightsOverview } from './overview/ConsentRightsOverview'
import { RouteObject } from 'react-router-dom'
import { ExperiencesSection } from './experiencesSection/ExperiencesSection'
import { SubscriptionsContainer } from 'pages/policyCenter/subscriptions/SubscriptionsContainer'
import { UpsertSubscriptionTopicContainer } from 'pages/policyCenter/subscriptions/subscriptionTopic/upsert/UpsertSubscriptionTopicContainer'
import { ViewSubscriptionTopicContainer } from 'pages/policyCenter/subscriptions/subscriptionTopic/view/ViewSubscriptionTopicContainer'
import { UpsertSubscriptionControlContainer } from 'pages/policyCenter/subscriptions/subscriptionControl/upsert/UpsertSubscriptionControlContainer'
import { ViewSubscriptionControlContainer } from 'pages/policyCenter/subscriptions/subscriptionControl/view/ViewSubscriptionControlContainer'
import { SubscriptionTopicsContainer } from 'pages/policyCenter/subscriptions/subscriptionTopic/list/SubscriptionTopicsContainer'
import { SubscriptionControlsContainer } from 'pages/policyCenter/subscriptions/subscriptionControl/list/SubscriptionControlsContainer'
import { PropertiesSection } from './propertiesSection/PropertiesSection'
import { ApprovedTrackers } from 'pages/policyCenter/trackers/list'
import { TrackersSettings } from 'pages/policyCenter/trackers/trackersSettings'
import { TrackerDetails } from 'pages/policyCenter/trackers/details'
import { RightsSection } from './rightsSection/RightsSection'
import { RightsQueueV2 } from 'pages/orchestration/rightsQueue/listV2/RightsQueueV2'
import { WorkflowStepDetails } from 'pages/orchestration/rightsQueue/stepDetails/WorkflowStepDetailsContainer'
import { RightsQueueViewV2 } from 'pages/orchestration/rightsQueue/viewV2/RightsQueueViewV2'
import { CreateRightInvocationContainer } from 'pages/orchestration/rightsQueue/create/CreateRightInvocationContainer'
import { Redaction } from 'pages/orchestration/rightsQueue/redaction/Redaction'
import { WorkflowViewContainer } from 'pages/orchestration/workflows/view/WorkflowViewContainer'
import { EditWorkflowContainer } from 'pages/orchestration/workflows/edit/EditWorkflowContainer'
import { WorkflowsListContainer } from 'pages/orchestration/workflows/list/WorkflowsListContainer'
import { EventsListV2Container } from 'pages/orchestration/events/listV2/EventsListContainer'

// Importing these as V2 for now so we can still use the old PermitPropagation files temporarily
import { PermitPropagationListContainer } from 'pages/consentAndRights/consent/systems/list/PermitPropagationListContainer'
import { PermitPropagationViewContainer } from 'pages/consentAndRights/consent/systems/view/PermitPropagationViewContainer'
import { PermitPropagationEditContainer } from 'pages/consentAndRights/consent/systems/edit/PermitPropagationEditContainer'
import { PrivacyProtocols } from 'pages/consentAndRights/consent/privacyProtocols/home/PrivacyProtocols'
import { WebTagsListContainer } from 'pages/consentAndRights/consent/webTags/list/WebTagsListContainer'
import { WebTagViewContainer } from 'pages/consentAndRights/consent/webTags/view/WebTagViewContainer'
import { PrivacyProtocolUpsert } from 'pages/consentAndRights/consent/privacyProtocols/upsert/PrivacyProtocolUpsert'
import { PrivacyProtocol } from 'pages/consentAndRights/consent/privacyProtocols/view/PrivacyProtocol'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { TagsListContainerV2 } from 'pages/consentAndRights/consent/webTags/list/TagsListContainerV2'
import { ConsentSection } from './consent/ConsentSection'

export const consentRightsRoutesChildren: RouteObject[] = [
  // NEW

  {
    element: <ProtectedRoute component={ConsentRightsOverview} permissions={[]} />,
    path: '',
  },

  /**
   * Consent & Rights > Experiences
   */

  // Experiences home
  {
    element: <ProtectedRoute component={ExperiencesSection} permissions={PERMISSIONS.EXP_READ} />,
    path: relativePath(RoutesManager.deployment.experiencesHome.root.pattern),
    children: [
      // Experiences list
      {
        element: <ProtectedRoute component={ExperiencesV2ListContainer} permissions={PERMISSIONS.EXP_READ} />,
        path: relativePath(RoutesManager.deployment.experiencesV2.root.pattern),
      },
      // Subscriptions list
      {
        element: <ProtectedRoute component={SubscriptionsContainer} permissions={PERMISSIONS.SUBSCRIPTIONS_READ} />,
        path: relativePath(RoutesManager.policyCenter.subscriptions.root.pattern),
        children: [
          {
            element: (
              <ProtectedRoute component={SubscriptionTopicsContainer} permissions={PERMISSIONS.SUBSCRIPTIONS_READ} />
            ),
            path: relativePath(RoutesManager.policyCenter.subscriptions.list.subscriptionTopics.root.pattern),
          },
          {
            element: (
              <ProtectedRoute component={SubscriptionControlsContainer} permissions={PERMISSIONS.SUBSCRIPTIONS_READ} />
            ),
            path: relativePath(RoutesManager.policyCenter.subscriptions.list.subscriptionControls.root.pattern),
          },
        ],
      },
      // Forms list
      {
        element: <ProtectedRoute component={Forms} permissions={PERMISSIONS.EXP_READ} />,
        path: relativePath(RoutesManager.deployment.forms.root.pattern),
        children: [
          {
            element: <ProtectedRoute component={TemplatesTab} permissions={PERMISSIONS.EXP_READ} />,
            path: relativePath(RoutesManager.deployment.forms.templates.root.pattern),
          },
          {
            element: <ProtectedRoute component={FieldsTab} permissions={PERMISSIONS.EXP_READ} />,
            path: relativePath(RoutesManager.deployment.forms.fields.root.pattern),
          },
        ],
      },
      // Purpose stacks list
      {
        element: <ProtectedRoute component={PurposeStacksListContainer} permissions={PERMISSIONS.EXP_READ} />,
        path: relativePath(RoutesManager.deployment.purposeStacks.root.pattern),
      },
    ],
  },

  // Experiences
  {
    element: <ProtectedRoute component={ExperienceV2Upsert} permissions={PERMISSIONS.EXP_WRITE} />,
    path: relativePath(RoutesManager.deployment.experiencesV2.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={ExperienceV2View} permissions={PERMISSIONS.EXP_READ} />,
    path: `${relativePath(RoutesManager.deployment.experiencesV2.view.root.pattern)}/*`,
  },

  // Subscriptions
  {
    element: (
      <ProtectedRoute component={UpsertSubscriptionTopicContainer} permissions={PERMISSIONS.SUBSCRIPTIONS_WRITE} />
    ),
    path: RoutesManager.policyCenter.subscriptions.upsert.subscriptionTopic.root.pattern,
  },
  {
    element: <ProtectedRoute component={ViewSubscriptionTopicContainer} permissions={PERMISSIONS.SUBSCRIPTIONS_READ} />,
    path: RoutesManager.policyCenter.subscriptions.view.subscriptionTopic.root.pattern,
  },
  {
    element: (
      <ProtectedRoute component={UpsertSubscriptionControlContainer} permissions={PERMISSIONS.SUBSCRIPTIONS_WRITE} />
    ),
    path: RoutesManager.policyCenter.subscriptions.upsert.subscriptionControl.root.pattern,
  },
  {
    element: (
      <ProtectedRoute component={ViewSubscriptionControlContainer} permissions={PERMISSIONS.SUBSCRIPTIONS_READ} />
    ),
    path: RoutesManager.policyCenter.subscriptions.view.subscriptionControl.root.pattern,
  },

  // Forms - Templates
  {
    element: <ProtectedRoute component={FormTemplateUpsertContainer} permissions={PERMISSIONS.EXP_WRITE} />,
    path: relativePath(RoutesManager.deployment.forms.templates.upsert.root.pattern),
  },

  {
    element: <ProtectedRoute component={FormTemplateDetailView} permissions={PERMISSIONS.EXP_READ} />,
    path: relativePath(RoutesManager.deployment.forms.templates.view.root.pattern),
  },

  {
    element: <ProtectedRoute component={FormTemplatePreview} permissions={PERMISSIONS.EXP_READ} />,
    path: relativePath(RoutesManager.deployment.forms.templates.upsert.preview.root.pattern),
  },

  // Forms - Fields
  {
    element: <ProtectedRoute component={FormFieldDetailView} permissions={PERMISSIONS.EXP_READ} />,
    path: relativePath(RoutesManager.deployment.forms.fields.view.root.pattern),
    children: [
      {
        element: <ProtectedRoute component={FormFieldDetailViewOverviewTab} permissions={PERMISSIONS.EXP_READ} />,
        path: relativePath(RoutesManager.deployment.forms.fields.view.overview.root.pattern),
      },
      {
        element: <ProtectedRoute component={FormFieldDetailViewTranslationsTab} permissions={PERMISSIONS.EXP_READ} />,
        path: relativePath(RoutesManager.deployment.forms.fields.view.translations.root.pattern),
      },
      {
        element: <ProtectedRoute component={FormFieldDetailViewUsageTab} permissions={PERMISSIONS.EXP_READ} />,
        path: relativePath(RoutesManager.deployment.forms.fields.view.usage.root.pattern),
      },
    ],
  },
  {
    element: <ProtectedRoute component={FormFieldUpsertContainer} permissions={PERMISSIONS.EXP_WRITE} />,
    path: relativePath(RoutesManager.deployment.forms.fields.upsert.root.pattern),
  },

  /**
   * Consent & Rights > Properties
   */

  // Properties home
  {
    element: <ProtectedRoute component={PropertiesSection} permissions={PERMISSIONS.PROPERTY_READ} />,
    path: relativePath(RoutesManager.deployment.propertiesHome.root.pattern),
    children: [
      // Properties list
      {
        element: <ProtectedRoute component={ApplicationsListContainer} permissions={PERMISSIONS.PROPERTY_READ} />,
        path: relativePath(RoutesManager.deployment.applications.root.pattern),
      },
      // Themes list
      {
        element: <ProtectedRoute component={ThemesV3ListContainer} permissions={PERMISSIONS.THEME_READ} />,
        path: relativePath(RoutesManager.deployment.themesV3.root.pattern),
      },
      // Deployment plans list
      {
        element: <ProtectedRoute component={DeploymentPlansListContainerV2} permissions={PERMISSIONS.DEPLOY_READ} />,
        path: relativePath(RoutesManager.deployment.deploymentPlansV2.root.pattern),
      },
    ],
  },

  // Properties
  {
    element: <ProtectedRoute component={ApplicationUpsertContainer} permissions={PERMISSIONS.PROPERTY_WRITE} />,
    path: relativePath(RoutesManager.deployment.applications.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={ApplicationViewContainer} permissions={PERMISSIONS.PROPERTY_READ} />,
    path: `${relativePath(RoutesManager.deployment.applications.view.root.pattern)}/*`,
  },

  // Themes
  {
    element: <ProtectedRoute component={ThemeV3View} permissions={PERMISSIONS.THEME_READ} />,
    path: `${relativePath(RoutesManager.deployment.themesV3.view.root.pattern)}/*`,
  },
  {
    element: <ProtectedRoute component={ThemeV3Upsert} permissions={PERMISSIONS.THEME_WRITE} />,
    path: relativePath(RoutesManager.deployment.themesV3.upsert.root.pattern),
  },

  // Deployment Plans
  {
    element: <ProtectedRoute component={DeploymentPlanUpsertContainerV2} permissions={PERMISSIONS.DEPLOY_WRITE} />,
    path: relativePath(RoutesManager.deployment.deploymentPlansV2.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={DeploymentPlanViewContainerV2} permissions={PERMISSIONS.DEPLOY_READ} />,
    path: `${relativePath(RoutesManager.deployment.deploymentPlansV2.view.root.pattern)}/*`,
  },
  {
    element: <ProtectedRoute component={DeploymentPlanDeployContainerV2} permissions={PERMISSIONS.DEPLOY_WRITE} />,
    path: relativePath(RoutesManager.deployment.deploymentPlansV2.deploy.root.pattern),
  },

  /**
   * Consent & Rights > Trackers
   */

  // Approved list
  {
    element: <ProtectedRoute component={ApprovedTrackers} permissions={PERMISSIONS.COOKIE_READ} />,
    path: RoutesManager.policyCenter.trackers.list.approvedTrackers.root.pattern,
  },
  // Settings
  {
    element: <ProtectedRoute component={TrackersSettings} permissions={PERMISSIONS.COOKIE_READ} />,
    path: `${RoutesManager.policyCenter.trackers.settings.root.pattern}/*`,
  },
  // Details
  {
    element: <ProtectedRoute component={TrackerDetails} permissions={PERMISSIONS.COOKIE_READ} />,
    path: `${RoutesManager.policyCenter.trackers.details.root.pattern}/*`,
  },

  /**
   * Consent & Rights > Rights
   */

  // Rights home
  {
    element: <ProtectedRoute component={RightsSection} permissions={PERMISSIONS.RTINVQ_READ} />,
    path: relativePath(RoutesManager.deployment.rightsHome.root.pattern),
    children: [
      // Requests list
      {
        element: <ProtectedRoute component={RightsQueueV2} permissions={PERMISSIONS.RTINVQ_READ} />,
        path: relativePath(RoutesManager.orchestration.rightsQueue.root.pattern),
      },
      // Workflows list
      {
        element: <ProtectedRoute component={WorkflowsListContainer} permissions={PERMISSIONS.WORKFLOW_READ} />,
        path: relativePath(RoutesManager.orchestration.workflows.root.pattern),
      },
      // Events list
      {
        element: <ProtectedRoute component={EventsListV2Container} permissions={PERMISSIONS.WORKFLOW_READ} />,
        path: relativePath(RoutesManager.orchestration.events.root.pattern),
      },
    ],
  },

  // Requests
  {
    element: <ProtectedRoute component={WorkflowStepDetails} permissions={PERMISSIONS.RTINVQ_READ} />,
    path: relativePath(RoutesManager.orchestration.rightsQueue.stepDetails.root.pattern),
  },
  {
    element: <ProtectedRoute component={RightsQueueViewV2} permissions={PERMISSIONS.RTINVQ_READ} />,
    path: relativePath(RoutesManager.orchestration.rightsQueue.view.root.pattern),
    children: [
      // overview
      {
        element: <ProtectedRoute component={RightsQueueViewV2} permissions={PERMISSIONS.RTINVQ_READ} />,
        path: relativePath(RoutesManager.orchestration.rightsQueue.view.overview.root.pattern),
      },
      // workflow
      {
        element: <ProtectedRoute component={RightsQueueViewV2} permissions={PERMISSIONS.RTINVQ_READ} />,
        path: relativePath(RoutesManager.orchestration.rightsQueue.view.workflow.root.pattern),
      },
      // subject-communication
      {
        element: <ProtectedRoute component={RightsQueueViewV2} permissions={PERMISSIONS.RTINVQ_READ} />,
        path: relativePath(RoutesManager.orchestration.rightsQueue.view.subjectCommunication.root.pattern),
      },
    ],
  },
  {
    element: <ProtectedRoute component={CreateRightInvocationContainer} permissions={PERMISSIONS.RTINVQ_WRITE} />,
    path: relativePath(RoutesManager.orchestration.rightsQueue.create.root.pattern),
  },
  {
    element: <ProtectedRoute component={Redaction} permissions={PERMISSIONS.REDACTION_WRITE} />,
    path: relativePath(RoutesManager.orchestration.rightsQueue.reviewData.root.pattern),
  },

  // Workflows
  {
    element: <ProtectedRoute component={WorkflowViewContainer} permissions={PERMISSIONS.WORKFLOW_READ} />,
    path: `${relativePath(RoutesManager.orchestration.workflows.view.root.pattern)}/*`,
  },
  {
    element: <ProtectedRoute component={EditWorkflowContainer} permissions={PERMISSIONS.WORKFLOW_WRITE} />,
    path: relativePath(RoutesManager.orchestration.workflows.upsert.root.pattern),
  },

  /**
   * Consent & Rights > Consent
   */

  // Consent home
  {
    element: <ProtectedRoute component={ConsentSection} permissions={PERMISSIONS.PERMIT_ORCH_READ} />,
    path: relativePath(RoutesManager.deployment.consentHome.root.pattern),
    children: [
      // Systems list
      {
        element: (
          <ProtectedRoute component={PermitPropagationListContainer} permissions={PERMISSIONS.PERMIT_ORCH_READ} />
        ),
        path: relativePath(RoutesManager.orchestration.consent.systems.root.pattern),
      },
      // Privacy protocols list
      {
        element: <ProtectedRoute component={PrivacyProtocols} permissions={PERMISSIONS.PERMIT_ORCH_READ} />,
        path: relativePath(RoutesManager.orchestration.consent.privacyProtocols.root.pattern),
      },
      // Tags list
      {
        element: (
          <ProtectedRoute
            component={isRelease(Release.TurboTags) ? TagsListContainerV2 : WebTagsListContainer}
            permissions={PERMISSIONS.WEB_TAGS_READ}
          />
        ),
        path: relativePath(RoutesManager.orchestration.webTags.root.pattern),
      },
    ],
  },

  // Systems
  {
    element: <ProtectedRoute component={PermitPropagationEditContainer} permissions={PERMISSIONS.PERMIT_ORCH_WRITE} />,
    path: relativePath(RoutesManager.orchestration.consent.systems.edit.root.pattern),
  },
  {
    element: <ProtectedRoute component={PermitPropagationViewContainer} permissions={PERMISSIONS.PERMIT_ORCH_READ} />,
    path: relativePath(RoutesManager.orchestration.consent.systems.view.root.pattern),
  },
  // Privacy Protocols
  {
    element: <ProtectedRoute component={PrivacyProtocolUpsert} permissions={PERMISSIONS.PERMIT_ORCH_WRITE} />,
    path: relativePath(RoutesManager.orchestration.consent.privacyProtocols.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={PrivacyProtocol} permissions={PERMISSIONS.PERMIT_ORCH_READ} />,
    path: relativePath(RoutesManager.orchestration.consent.privacyProtocols.view.root.pattern),
  },
  // Tags
  {
    element: <ProtectedRoute component={WebTagViewContainer} permissions={PERMISSIONS.WEB_TAGS_READ} />,
    path: `${relativePath(RoutesManager.orchestration.webTags.view.root.pattern)}/*`,
  },

  // OLD

  {
    element: <ProtectedRoute component={ExperiencesListContainer} permissions={PERMISSIONS.EXP_READ} />,
    path: relativePath(RoutesManager.deployment.experiences.root.pattern),
  },

  {
    element: <ProtectedRoute component={LanguagesListContainer} permissions={PERMISSIONS.TRANSLATIONS_READ} />,
    path: relativePath(RoutesManager.deployment.languages.root.pattern),
  },

  // Deployment Plans

  {
    element: <ProtectedRoute component={DeploymentPlansListContainer} permissions={PERMISSIONS.DEPLOY_READ} />,
    path: relativePath(RoutesManager.deployment.deploymentPlans.root.pattern),
  },

  // Deployment Plans V2

  // Themes V2

  {
    element: <ProtectedRoute component={ThemesV2ListContainer} permissions={PERMISSIONS.THEME_READ} />,
    path: relativePath(RoutesManager.deployment.themes.root.pattern),
  },

  // Themes V3

  {
    element: <ProtectedRoute component={PurposeStacksListContainer} permissions={PERMISSIONS.EXP_READ} />,
    path: relativePath(RoutesManager.deployment.purposeStacks.root.pattern),
  },

  // Experiences

  {
    element: <ProtectedRoute component={ExperienceUpsert} permissions={PERMISSIONS.EXP_WRITE} />,
    path: relativePath(RoutesManager.deployment.experiences.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={ExperienceViewContainer} permissions={PERMISSIONS.EXP_READ} />,
    path: `${relativePath(RoutesManager.deployment.experiences.view.root.pattern)}/*`,
  },
  {
    element: <ProtectedRoute component={ExperiencePreview} permissions={PERMISSIONS.EXP_READ} />,
    path: relativePath(RoutesManager.deployment.experiences.preview.root.pattern),
  },

  // Themes V2

  {
    element: <ProtectedRoute component={ThemesV2UpsertContainer} permissions={PERMISSIONS.THEME_WRITE} />,
    path: relativePath(RoutesManager.deployment.themes.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={ThemeV2ViewContainer} permissions={PERMISSIONS.THEME_READ} />,
    path: `${relativePath(RoutesManager.deployment.themes.view.root.pattern)}/*`,
  },
  {
    element: <ProtectedRoute component={ThemesV2PreviewContainer} permissions={PERMISSIONS.THEME_READ} />,
    path: relativePath(RoutesManager.deployment.themes.preview.root.pattern),
  },

  // Themes V3
  // {
  //   element: <ProtectedRoute component={ThemesV3PreviewContainer} permissions={PERMISSIONS.DEMO} />,
  //   path: relativePath(RoutesManager.deployment.themesV3.preview.root.pattern),
  // },

  // Deployment Plans

  {
    element: <ProtectedRoute component={DeploymentPlanUpsertContainer} permissions={PERMISSIONS.DEPLOY_WRITE} />,
    path: relativePath(RoutesManager.deployment.deploymentPlans.upsert.root.pattern),
  },
  {
    element: <ProtectedRoute component={DeploymentPlanViewContainer} permissions={PERMISSIONS.DEPLOY_READ} />,
    path: `${relativePath(RoutesManager.deployment.deploymentPlans.view.root.pattern)}/*`,
  },
  {
    element: <ProtectedRoute component={DeploymentPlanDeployContainer} permissions={PERMISSIONS.DEPLOY_WRITE} />,
    path: relativePath(RoutesManager.deployment.deploymentPlans.deploy.root.pattern),
  },
  {
    element: <ProtectedRoute component={DeploymentPlanPreview} permissions={PERMISSIONS.DEPLOY_READ} />,
    path: relativePath(RoutesManager.deployment.deploymentPlans.preview.root.pattern),
  },

  // Purpose Stacks

  {
    element: <ProtectedRoute component={PurposeStackViewContainer} permissions={PERMISSIONS.EXP_READ} />,
    path: `${relativePath(RoutesManager.deployment.purposeStacks.view.root.pattern)}/*`,
  },
  {
    element: <ProtectedRoute component={PurposeStacksUpsertContainer} permissions={PERMISSIONS.EXP_WRITE} />,
    path: relativePath(RoutesManager.deployment.purposeStacks.upsert.root.pattern),
  },
]
