import { APIListRequestParams, APIListResponse } from 'api/common/utils'
import { WebTagDTO } from 'interfaces/webtags/WebTags'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIListRequestParams<{
  organizationCode: string
  includeMetadata?: boolean
  includeIssues?: boolean
  q?: string
  appCode?: string
  appInstanceId?: string
  purposeId?: string
  enabled?: string
}>

type Response = APIListResponse<{ tags: WebTagDTO[] }>

export const fetchWebTags = ({
  organizationCode,
  includeMetadata,
  includeIssues,
  start,
  limit,
  q,
  appCode,
  appInstanceId,
  purposeId,
  enabled,
}: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/tags`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
          start,
          limit,
          ...(q && {
            q,
          }),
          ...(appCode && {
            appCode,
          }),
          ...(appInstanceId && {
            appInstanceID: appInstanceId,
          }),
          ...(purposeId && {
            purposeID: purposeId,
          }),
          ...(enabled && {
            enabled,
          }),
        },
      },
    }),
  )
