export enum ApiQueryKeys {
  assignedDataCategories = 'assignedDataCategories',

  authConfig = 'authConfig',

  auditLogsConsent = 'auditLogsConsent',
  auditLogsRightInvocationsPaginated = 'auditLogsRightInvocationsPaginated',
  auditLogsInteractionsPaginated = 'auditLogsInteractionsPaginated',

  authenticator = 'authenticator',
  authenticatorsPaginated = 'authenticatorsPaginated',

  application = 'application',
  applications = 'applications',
  applicationScript = 'applicationScript',
  applicationsPaginated = 'applicationsPaginated',
  applicationVersion = 'applicationVersion',
  applicationVersions = 'applicationVersions',

  appliances = 'appliances',
  appliance = 'appliance',
  applianceWorks = 'applianceWorks',

  assessmentCounts = 'assessmentCounts',
  assessmentQuestions = 'assessmentQuestions',
  assessmentQuestion = 'assessmentQuestion',
  assessmentQuestionPublished = 'assessmentQuestionPublished',
  assessmentTemplates = 'assessmentTemplates',
  assessmentTemplate = 'assessmentTemplate',
  assessmentTemplatePublished = 'assessmentTemplatePublished',
  assessments = 'assessments',
  assessment = 'assessment',

  businessFunctions = 'businessFunctions',
  businessFunction = 'businessFunction',

  cookie = 'cookie',
  cookies = 'cookies',
  cookiesPaginated = 'cookiesPaginated',
  cookiesInfinite = 'cookiesInfinite',
  cookieVersion = 'cookieVersion',
  cookieVersions = 'cookieVersions',

  chatToken = 'chatToken',
  customRightNameList = 'customRightNameList',
  customRightName = 'customRightName',
  deleteCustomRightName = 'deleteCustomRightName',

  appDataMap = 'appDataMap',

  dataCategories = 'dataCategories',
  dataCategoriesStats = 'dataCategoriesStats',

  dataMapPurposesInfinite = 'dataMapPurposesInfinite',
  dataMapPurposes = 'dataMapPurposes',
  dataMapPurpose = 'dataMapPurpose',
  dataMapRegions = 'dataMapRegions',
  dataMapLabels = 'dataMapLabels',
  dataMapDataSystems = 'dataMapDataSystems',
  dataMapDataSystem = 'dataMapDataSystem',
  dataMapVisualization = 'dataMapVisualization',
  dataReview = 'dataReview',
  dataSubjectTypes = 'dataSubjectTypes',
  dataSystems = 'dataSystems',
  dataSystemsGlobal = 'dataSystemsGlobal',

  dataSubjectTypesPaginated = 'dataSubjectTypesPaginated',
  dataSubjectType = 'dataSubjectType',
  dataSubjectTypeTemplates = 'dataSubjectTypeTemplates',

  deploymentPlan = 'deploymentPlan',
  deploymentPlans = 'deploymentPlans',
  deploymentPlansPaginated = 'deploymentPlansPaginated',
  deploymentPlanVersion = 'deploymentPlanVersion',
  deploymentPlanVersions = 'deploymentPlanVersions',
  deploymentPlanPreviewConfigurations = 'deploymentPlanPreviewConfigurations',

  dsrPolicies = 'dsrPolicies',

  dmlHooks = 'dmlHooks',
  dmlHook = 'dmlHook',

  experience = 'experience',
  experiences = 'experiences',
  experiencesPaginated = 'experiencesPaginated',
  experienceVersion = 'experienceVersion',
  experienceVersions = 'experienceVersions',

  executionResultsInfinite = 'executionResultsInfinite',

  globalVendorList = 'globalVendorList',
  globalBanner = 'globalBanner',

  identitySpace = 'identitySpace',
  identitySpaces = 'identitySpaces',
  identitySpacesPaginated = 'identitySpacesPaginated',
  identitySpaceVersion = 'identitySpaceVersion',
  identitySpaceVersions = 'identitySpaceVersions',
  identitySpacesCatalog = 'identitySpacesCatalog',

  jurisdictionV2 = 'jurisdictionV2',
  jurisdictionsV2 = 'jurisdictionsV2',

  label = 'label',
  labels = 'labels',
  labelsInfinite = 'labelsInfinite',
  labelsPaginated = 'labelsPaginated',

  language = 'language',
  languages = 'languages',
  languagesPaginated = 'languagesPaginated',
  organizationsLanguages = 'organizationsLanguages',
  organizationsLanguagesPaginated = 'organizationsLanguagesPaginated',

  legalBase = 'legalBase',
  legalBases = 'legalBases',
  legalBasesPaginated = 'legalBasesPaginated',
  legalBaseVersion = 'legalBaseVersion',
  legalBaseVersions = 'legalBaseVersions',

  organization = 'organization',
  organizations = 'organizations',
  organizationsPaginated = 'organizationsPaginated',
  organizationVersion = 'organizationVersion',
  organizationVersions = 'organizationVersions',

  controller = 'controller',
  controllers = 'controllers',
  controllersPaginated = 'controllersPaginated',

  policyDocument = 'policyDocument',
  policyDocumentContent = 'policyDocumentContent',
  policyDocuments = 'policyDocuments',
  policyDocumentsPaginated = 'policyDocumentsPaginated',
  policyDocumentsInfinite = 'policyDocumentsInfinite',
  policyDocumentVersion = 'policyDocumentVersion',
  policyDocumentVersions = 'policyDocumentVersions',

  policyScope = 'policyScope',
  policyScopes = 'policyScopes',
  policyScopesPaginated = 'policyScopesPaginated',
  policyScopeVersion = 'policyScopeVersion',
  policyScopeVersions = 'policyScopeVersions',
  policyScopeTemplates = 'policyScopeTemplates',

  jurisdictionPurposes = 'jurisdictionPurposes',
  jurisdictionPurposesPreview = 'jurisdictionPurposesPreview',

  privacyProtocol = 'privacyProtocol',
  privacyProtocols = 'privacyProtocols',

  purpose = 'purpose',
  purposes = 'purposes',
  purposesInfinite = 'purposesInfinite',
  purposeTemplates = 'purposeTemplates',
  purposesCanonical = 'purposesCanonical',
  purposesPaginated = 'purposesPaginated',
  purposeVersion = 'purposeVersion',
  purposeVersions = 'purposeVersions',

  purposeV2 = 'purposeV2',
  purposesV2 = 'purposesV2',

  region = 'region',
  regions = 'regions',
  regionsPaginated = 'regionsPaginated',
  regionVersion = 'regionVersion',
  regionVersions = 'regionVersions',

  regulation = 'regulation',
  regulations = 'regulations',
  regulationsPaginated = 'regulationsPaginated',
  regulationVersion = 'regulationVersion',
  regulationVersions = 'regulationVersions',

  event = 'event',
  events = 'events',
  eventsPaginated = 'eventsPaginated',
  eventsMapping = 'eventsMapping',

  workflow = 'workflow',
  workflowSummary = 'workflowSummary',
  workflows = 'workflows',
  workflowActivities = 'workflowActivities',
  workflowsPaginated = 'workflowsPaginated',
  workflowVersion = 'workflowVersion',
  workflowVersions = 'workflowVersions',

  workflowExecution = 'workflowExecution',
  workflowExecutionV2 = 'workflowExecutionV2',
  workflowExecutions = 'workflowExecutions',
  workflowExecutionStepDetails = 'workflowExecutionStepDetails',
  workflowExecutionStepDetailsV2 = 'workflowExecutionStepDetailsV2',
  workflowPreview = 'workflowPreview',

  right = 'right',
  rights = 'rights',
  canonicalRight = 'canonicalRight',
  rightsStatsReport = 'rightsStatsReport',
  rightsStatsPeriod = 'rightsStatsPeriod',
  rightsCanonical = 'rightsCanonical',
  rightsPaginated = 'rightsPaginated',
  rightVersion = 'rightVersion',
  rightVersions = 'rightVersions',

  stack = 'stack',
  stacks = 'stacks',

  team = 'team',
  teams = 'teams',
  teamAssignments = 'teamAssignments',
  teamDefaultAssignee = 'teamDefaultAssignee',

  theme = 'theme',
  themes = 'themes',
  themesPaginated = 'themesPaginated',
  themeVersion = 'themeVersion',
  themeVersions = 'themeVersions',

  user = 'user',
  userMe = 'userMe',
  users = 'users',
  usersByCredentials = 'usersByCredentials',
  userRoles = 'userRoles',
  usersPaginated = 'usersPaginated',
  userVersion = 'userVersion',
  userVersions = 'userVersions',
  userOrganizations = 'userOrganizations',

  webTag = 'webTag',
  webTags = 'webTags',
  webTagsPaginated = 'webTagsPaginated',
  webTagVersion = 'webTagVersion',
  webTagVersions = 'webTagVersions',

  webhook = 'webhook',
  webhooksPaginated = 'webhooksPaginated',
  webhookContext = 'webhookContext',

  app = 'app',
  appInstance = 'appInstance',
  appInstances = 'appInstances',
  apps = 'apps',
  appGalleries = 'appGalleries',
  appGalleryApps = 'appGalleryApps',
  appGalleryAppsPaginated = 'appGalleryAppsPaginated',
  appsInstancesPurposes = 'appsInstancesPurposes',
  appsInstanceUsages = 'appsInstanceUsages',

  notificationsCounter = 'notificationsCounter',
  notificationsUnreadCount = 'notificationsUnreadCount',
  notifications = 'notifications',
  notificationsInfinite = 'notificationsInfinite',
  notificationsPaginated = 'notificationsPaginated',

  tasks = 'tasks',
  tasksInfinite = 'tasksInfinite',

  rightInvocationsPaginated = 'rightInvocationsPaginated',
  rightInvocationsInfinite = 'rightInvocationsInfinite',
  rightInvocationsCount = 'rightInvocationsCount',
  rightInvocation = 'rightInvocation',

  rightInvocationsPaginatedV2 = 'rightInvocationsPaginatedV2',
  rightInvocationsInfiniteV2 = 'rightInvocationsInfiniteV2',
  rightInvocationsCountV2 = 'rightInvocationsCountV2',
  rightInvocationV2 = 'rightInvocationV2',

  file = 'file',
  filesInfo = 'filesInfo',
  filesList = 'filesList',

  formFieldsPaginated = 'formFieldsPaginated',
  formField = 'formField',

  formTemplatesPaginated = 'formTemplatesPaginated',
  formTemplate = 'formTemplate',
  formTemplatesPreviews = 'formTemplatesPreviews',

  authLink = 'authLink',
  entitlements = 'entitlements',
  featureFlags = 'featureFlags',
  permissions = 'permissions',

  products = 'products',

  // Admin part:

  account = 'account',
  accounts = 'accounts',
  accountsPaginated = 'accountsPaginated',

  adminAppGallery = 'adminAppGallery',
  adminAppGalleries = 'adminAppGalleries',
  adminAppGalleriesPaginated = 'adminAppGalleriesPaginated',

  relationshipsPaginated = 'relationshipsPaginated',
  relationshipsInfinite = 'relationshipsInfinite',
  relationshipsInfiniteV3 = 'relationshipsInfiniteV3',

  sku = 'sku',
  skus = 'skus',
  skusPaginated = 'skusPaginated',

  adminPermission = 'adminPermission',
  adminPermissions = 'adminPermissions',
  adminPermissionsPaginated = 'adminPermissionsPaginated',

  adminPermissionSet = 'adminPermissionSet',
  adminPermissionSets = 'adminPermissionSets',
  adminPermissionSetsPaginated = 'adminPermissionSetsPaginated',

  purchasePlans = 'purchasePlans',
  buildingStatus = 'buildingStatus',

  // API Keys

  apiKeysPaginated = 'apiKeysPaginated',
  apiKey = 'apiKey',

  dynamicDropdownValues = 'dynamicDropdownValues',

  policies = 'policies',
  policy = 'policy',
  policiesPaginated = 'policiesPaginated',
  previewConfig = 'previewConfig',
  resourcePoliciesPaginated = 'resourcePoliciesPaginated',

  resource = 'resource',
  resources = 'resources',
  resourcesInfinite = 'resourcesInfinite',
  relatedResourcesInfiniteV3 = 'relatedResourcesInfiniteV3',
  relatedResourcesInfiniteV2 = 'relatedResourcesInfiniteV2',
  resourcesInfiniteV3 = 'resourcesInfiniteV3',
  resourcesPaginated = 'resourcesPaginated',
  databasesPaginated = 'databasesPaginated',
  relatedResourcesPaginated = 'relatedResourcesPaginated',

  permitStatsOverall = 'permitStatsOverall',
  permitsStatsJurisdiction = 'permitsStatsJurisdiction',
  permitsStatsOverall = 'permitsStatsOverall',
  permitsStatsPurpose = 'permitsStatsPurpose',
  permitsStatsTrend = 'permitsStatsTrend',
  permitsStatsPerIdentitySpace = 'permitsStatsPerIdentitySpace',
  permitStatsPerJurisdiction = 'permitStatsPerJurisdiction',

  processingActivity = 'processingActivity',
  processingActivitiesInfinite = 'processingActivitiesInfinite',
  processingActivitiesPaginated = 'processingActivitiesPaginated',
  processingActivitiesSummary = 'processingActivitiesSummary',
  processingActivityVersion = 'processingActivityVersion',
  processingActivityVersions = 'processingActivityVersions',
  processingActivityFilters = 'processingActivityFilters',
  processingActivityChat = 'processingActivityChat',

  properties = 'properties',

  assetsStatistics = 'assetsStatistics',
  assetsDataSystems = 'assetsDataSystems',
  assetProcessingActivities = 'assetProcessingActivities',

  assetHierarchies = 'assetHierarchies',
  relatedDatasets = 'relatedDatasets',
  assetDsrConfig = 'assetDsrConfig',
  assetDsrConfigCount = 'assetDsrConfigCount',
  scenario = 'scenario',
  scenarios = 'scenarios',
  // orchestration events
  orchestrationEvents = 'orchestrationEvents',

  contextVariable = 'contextVariable',
  contextVariablesPaginated = 'contextVariablesPaginated',

  databaseDatasetSummariesPaginatedV2 = 'databaseDatasetSummariesPaginatedV2',
  databaseDatasetSummariesPaginatedV3 = 'databaseDatasetSummariesPaginatedV3',

  gatewayContext = 'gatewayContext',

  fetchSql = 'fetchSql',

  subscriptionTopicsPaginated = 'subscriptionTopicsPaginated',
  subscriptionTopic = 'subscriptionTopic',
  subscriptionTopicOrchestrations = 'subscriptionTopicOrchestrations',
  subscriptionControlsPaginated = 'subscriptionControlsPaginated',
  subscriptionControl = 'subscriptionControl',
  subscriptionControlOrchestrations = 'subscriptionControlOrchestrations',
  subscriptionContactMethodsPaginated = 'subscriptionContactMethodsPaginated',

  verifier = 'verifier',
  verifierUsage = 'verifierUsage',
  verifiersPaginated = 'verifiersPaginated',

  appConnections = 'appConnections',

  classificationsPaginatedV2 = 'classificationsPaginatedV2',
  classificationsPaginatedV3 = 'classificationsPaginatedV3',

  assetTablesPaginatedV3 = 'assetTablesPaginatedV3',

  installedDataSystem = 'installedDataSystem',
  installedDataSystems = 'installedDataSystems',
  installedDataSystemsStats = 'installedDataSystemsStats',
  installedDataSystemProcessingActivities = 'installedDataSystemProcessingActivities',
  connections = 'connections',
  connection = 'connection',
  assets = 'assets',

  jurisdictionRights = 'jurisdictionRights',
  jurisdictionsRights = 'jurisdictionsRights',
  jurisdictionsCanonicalRights = 'jurisdictionsCanonicalRights',
  dataPreview = 'dataPreview',
  relationshipMapping = 'relationshipMapping',
  databaseResources = 'databaseResources',
  tableResources = 'tableResources',
  columnResources = 'columnResources',
  customSql = 'customSql',
  resourceTypeDetails = 'resourceTypeDetails',

  onboardingProgressForCanduIntegration = 'onboardingProgressForCanduIntegration',

  approvedTrackersInfinite = 'approvedTrackersInfinite',
  unapprovedTrackersInfinite = 'unapprovedTrackersInfinite',
  notFoundTrackersInfinite = 'notFoundTrackersInfinite',
  trackerPropertiesInfinite = 'trackerPropertiesInfinite',
  approvedTrackerPropertiesInfinite = 'approvedTrackerPropertiesInfinite',
  unapprovedTrackerPropertiesInfinite = 'unapprovedTrackerPropertiesInfinite',
  notFoundTrackerPropertiesInfinite = 'notFoundTrackerPropertiesInfinite',
  newPropertyTrackerPropertiesInfinite = 'newPropertyTrackerPropertiesInfinite',
  trackerCategories = 'trackerCategories',
  trackerStatistics = 'trackerStatistics',
  trackerDetails = 'trackerDetails',
  trackerConfiguration = 'trackerConfiguration',
  trackerSystems = 'trackerSystems',
  trackersOnNewPropertiesInfinite = 'trackersOnNewPropertiesInfinite',

  orgPlan = 'orgPlan',
  orgUsage = 'orgUsage',
}

export enum ExternalApiQueryKeys {
  ipLocation = 'ipLocation',
}
