import React from 'react'

import { WebTagDTO } from 'interfaces/webtags/WebTags'
import { Box } from '@mui/material'
import { Chip, Status, StatusState, StatusVariant } from '@ketch-com/deck'

type Props = {
  webTag: WebTagDTO
}

export const WebTagViewDetails: React.FC<Props> = ({ webTag }) => {
  const { enabled, appName, appInstanceName } = webTag

  return (
    <Box display="flex" gap={1}>
      {appName && <Chip label={appName} size="small" />}

      {appInstanceName && <Chip label={appInstanceName} size="small" />}

      {enabled ? (
        <Status variant={StatusVariant.outlined} status={StatusState.success} label="Enabled" icon="Dot" />
      ) : (
        <Status variant={StatusVariant.outlined} status={StatusState.draft} label="Disabled" icon="Dot" />
      )}
    </Box>
  )
}
