import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { useTaskDetailsUtils } from './hooks'
import { DataSubjectVariables, OutcomeVariables } from './components'
import { useContext } from 'react'
import { TaskContext } from '../../context'
import { RightInvocationWorkflowStepDetailViewContext } from 'pages/orchestration/rightsQueue/stepDetails/context'
import { IdentitySpaces } from './components/IdentitySpaces/IdentitySpaces'
import { ContentGroup, InfoRow } from '@ketch-com/deck'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { InfoRowAssignUser } from '../../../../InfoRowAssignUser'
import { RightsQueueFormInfoRowTextField } from '../../../../FormInfoRowTextField'
import { InfoRowInlineUpload } from '../../../../InfoRowInlineUpload'

type Props = {}

export const TaskDetails: React.FC<Props> = () => {
  const { isTaskComplete } = useContext(RightInvocationWorkflowStepDetailViewContext)
  const { dsrResolutionAttachments, resolutionAttachments, canEdit, canReassign, isEditMode } = useContext(TaskContext)
  const shouldPreventEditOfCompletedTaskNotInEditMode = isTaskComplete && !isEditMode

  const {
    assigneeInfo,
    hasAttemptedToResolve,
    hasDataSubjectVariables,
    hasDsrResolutionAttachments,
    hasOutcomeVariables,
    hasResolutionAttachments,
    hasResolutionNotes,
    hasIdentitySpaces,
    isDsrResolutionAttachmentsRequired,
    isResolutionAttachmentsRequired,
    isResolutionNotesRequired,
    onUploadComplete,
    resolutionDetails,
    rightInvocation,
    showAttachmentsRequired,
    showDsrAttachmentsRequired,
    stepDetails,
    stepError,
    stepId,
    shouldShowOutcomesHeader,
    workflowExecutionId,
  } = useTaskDetailsUtils()

  const showDsrResolutionAttachmentsError =
    isDsrResolutionAttachmentsRequired && !dsrResolutionAttachments?.length && hasAttemptedToResolve

  return (
    <Box display="flex" flexDirection="column" gap={6} mb={6}>
      {/* Instructions */}

      <Box display="flex" flexDirection="column" gap={4}>
        <ContentGroup
          variant="inner-page-header"
          title="Instructions"
          titleVariant="h3"
          subTitle="Follow the instructions detailed in the Description section below."
        />

        {!!stepError && (
          <>
            <ContentGroup titleVariant="h3" variant="inner-page-header" title="Error" />
            {/* Description */}
            <InfoRow title="Error Details">
              {stepError ? (
                <Typography ml={0.5} color="darkDusk.main" variant="body">
                  {stepError}
                </Typography>
              ) : (
                <EmptyValueRenderer />
              )}
            </InfoRow>
          </>
        )}

        {/* Description */}

        <InfoRow title="Description">
          {stepDetails?.executionData?.manualTask?.details ? (
            <Typography color="darkDusk.main" variant="body">
              {stepDetails?.executionData?.manualTask?.details}
            </Typography>
          ) : (
            <EmptyValueRenderer />
          )}
        </InfoRow>

        {/* Assignees */}
        <InfoRowAssignUser
          infoRowTitle="Assignee"
          assigneeInfo={assigneeInfo || null}
          assignee={stepDetails.assignee}
          canEdit={canReassign}
          isComplete={isTaskComplete}
          workflowExecutionId={workflowExecutionId}
          workflowStepId={stepId}
        />
      </Box>

      {/* Outcomes Section */}

      <Box display="flex" flexDirection="column" gap={4}>
        {!!shouldShowOutcomesHeader && (
          <ContentGroup
            variant="inner-page-header"
            title="Outcomes"
            titleVariant="h3"
            subTitle="Complete each section listed below."
          />
        )}

        {/* Notes */}
        {hasResolutionNotes && (
          <RightsQueueFormInfoRowTextField
            infoRowTitle="Notes"
            showRequiredLabel={!!isResolutionNotesRequired}
            showOptionalLabel={!isResolutionNotesRequired}
            canEdit={canEdit && !shouldPreventEditOfCompletedTaskNotInEditMode}
            showValidation={hasAttemptedToResolve && isResolutionNotesRequired && !resolutionDetails}
            isRequired={hasResolutionNotes && isResolutionNotesRequired}
            name="resolutionDetails"
            placeholder="Add Notes"
            defaultValue={resolutionDetails || ''}
            inputId="resolutionDetails"
          />
        )}

        {/* Attachments */}
        {hasResolutionAttachments && (
          <InfoRowInlineUpload
            attachments={resolutionAttachments}
            canEdit={canEdit && !shouldPreventEditOfCompletedTaskNotInEditMode}
            disableInEditDownload
            infoRowTitle="Attachments"
            isError={isResolutionAttachmentsRequired && !resolutionAttachments?.length && hasAttemptedToResolve}
            isRequired={isResolutionAttachmentsRequired}
            name="resolutionAttachments"
            onDelete={() => {
              onUploadComplete([])
            }}
            onUploadComplete={onUploadComplete}
            showFileData={true}
            showOptionalLabel={!isResolutionAttachmentsRequired}
            showRequiredLabel={showAttachmentsRequired}
            showUploadSizes
            uploadContext={{
              version: 'resolution_attachments',
              folder: `${rightInvocation?.id}_${stepId}`,
              bucket: '',
            }}
          />
        )}

        {/* Attachments for Data Subject */}
        {hasDsrResolutionAttachments && (
          <InfoRowInlineUpload
            attachments={dsrResolutionAttachments}
            canEdit={canEdit && !shouldPreventEditOfCompletedTaskNotInEditMode}
            disableInEditDownload
            infoRowTitle="Attachments for Data Subject"
            isError={showDsrResolutionAttachmentsError}
            isRequired={isDsrResolutionAttachmentsRequired}
            name="dsrResolutionAttachments"
            onDelete={() => onUploadComplete([])}
            onUploadComplete={onUploadComplete}
            showFileData={true}
            showOptionalLabel={!isDsrResolutionAttachmentsRequired}
            showRequiredLabel={showDsrAttachmentsRequired}
            showUploadSizes
            uploadContext={{
              version: rightInvocation?.id,
              folder: stepId,
              bucket: '',
            }}
          />
        )}
      </Box>
      {/* Outcome Variables */}

      {hasOutcomeVariables && <OutcomeVariables />}

      {/* Data Subject Variables */}

      {hasDataSubjectVariables && <DataSubjectVariables />}

      {hasIdentitySpaces && <IdentitySpaces />}
    </Box>
  )
}
