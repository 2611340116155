import React, { memo, ReactNode, useState } from 'react'
import { Box, Typography, Tooltip } from '@mui/material'
import { showToast } from 'components/modals/AlertComponent'
import { Button, CustomIconName, Icon, Spinner, theme } from '@ketch-com/deck'

type ConfigIconButtonProps = {
  children: ReactNode
  handleClick: () => void
  iconName: CustomIconName
  tooltipMessage: string
  isLoading?: boolean
}

const ConfigItemIconButton: React.FC<ConfigIconButtonProps> = memo(
  ({ children, handleClick, iconName, tooltipMessage, isLoading = false }) => {
    return (
      <Tooltip title={tooltipMessage} arrow>
        <Button color="secondary" size="large" variant="link" disabled={isLoading} onClick={handleClick}>
          <Box display="flex" justifyContent="center">
            {children}
            <Icon name={iconName} width={20} height={20} />
          </Box>
        </Button>
      </Tooltip>
    )
  },
)

type ConfigItemDisplayType = 'api' | 'key' | 'link' | 'token'

const configItemDisplayTypeIcons: Record<ConfigItemDisplayType, CustomIconName> = {
  key: 'OKey',
  link: 'Link',
  api: 'OApi',
  token: 'FKey',
}

export type ConfigItemDisplayParams = {
  value: string
  name: string
  type: ConfigItemDisplayType
  alwaysVisible?: boolean
  onRegenerate?: () => void
  error?: boolean
  isLoading?: boolean
}

export const ConfigItemDisplay: React.FC<ConfigItemDisplayParams> = memo(
  ({ alwaysVisible = false, error = false, isLoading = false, value, name, type, onRegenerate }) => {
    const [isVisible, setIsVisible] = useState(alwaysVisible)

    const handleToggleVisibility = () => {
      setIsVisible(!isVisible)
    }

    const handleCopyToClipboard = () => {
      navigator.clipboard.writeText(value)
      showToast({ content: `${name} copied to clipboard`, type: 'success' })
    }

    const maskedValue = value.replace(/./g, '*')

    return (
      <Box
        sx={{
          borderRadius: '12px',
          padding: 1.5,
          backgroundColor: error ? theme.palette.Error.Disabled : theme.palette.Common.AppBackground,
          display: 'flex',
          gap: 1,
        }}
      >
        {isLoading ? (
          <Spinner size="24px" thickness={2} />
        ) : (
          <Icon
            name={configItemDisplayTypeIcons[type]}
            iconColor={error ? theme.palette.Error.Primary : undefined}
            width={24}
            height={24}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="label"
            sx={{
              color: error ? theme.palette.Error.Primary : undefined,
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              color: error ? theme.palette.Error.Primary : undefined,
              wordBreak: 'break-all',
              marginTop: 0.5,
            }}
          >
            {isVisible ? value : maskedValue}
          </Typography>
          <Box display="flex" alignItems="center" gap={1} marginTop={1}>
            {error && onRegenerate ? (
              <ConfigItemIconButton
                iconName="ORefresh"
                tooltipMessage="re-generate your token"
                isLoading={isLoading}
                handleClick={onRegenerate!}
              >
                Regenerate
              </ConfigItemIconButton>
            ) : (
              <>
                {!alwaysVisible && (
                  <ConfigItemIconButton
                    iconName={isVisible ? 'OEyeHide' : 'OEyeShow'}
                    tooltipMessage={isVisible ? 'Hide Artifactory Key' : 'Show Artifactory Key'}
                    handleClick={handleToggleVisibility}
                    isLoading={isLoading}
                  >
                    {isVisible ? 'Hide' : 'Show'}
                  </ConfigItemIconButton>
                )}

                <ConfigItemIconButton
                  iconName="OCopy"
                  tooltipMessage="Copy to clipboard"
                  handleClick={handleCopyToClipboard}
                  isLoading={isLoading}
                >
                  Copy
                </ConfigItemIconButton>
              </>
            )}
          </Box>
        </Box>
      </Box>
    )
  },
)
