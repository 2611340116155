import { MaybeNull } from 'interfaces/common'
import { ApplicationDTO } from 'interfaces/applications/application'
import { ApplicationEnvironment } from 'interfaces/applications/applicationEnvironment'
import { ApplicationFormSpecialValues, ApplicationFormValues } from 'interfaces/applications/ApplicationFormValues'

export const getEditApplicationInitialValues = ({
  application,
}: {
  application: MaybeNull<ApplicationDTO>
}): ApplicationFormValues => {
  let platformPayload = application?.platform || 1
  /* this is a FE hack to avoid need for a migration.
  Because there is now only 1 (web) or 2 (mobile), previously 2 was
  iOS and 3 was Android, but, now, that is captured in 2 (mobile) */
  if (platformPayload > 2 && platformPayload < 5) {
    platformPayload = 2
  }

  return {
    ID: application?.ID,
    code: application?.code || '',
    name: application?.name || '',
    managedIdentity: !!application?.managedIdentity,
    dataSubjectTypeCodes: (application?.dataSubjectTypes || []).map(dst => dst?.code || ''),
    platform: platformPayload,
    defaultPolicyScopeVariable: application?.defaultPolicyScopeVariable || '',
    customIdentitySpaces: !application?.managedIdentity || !!application?.identitySpaces?.length,
    customPolicyScope: !!application?.defaultPolicyScopeVariable,
    proxy: application?.proxy || '',
    supportProxy: !!application?.proxy,
    environments: application?.environments
      ? application.environments.map(environment => ({
          code: environment.code,
          domain: environment.domain,
          pattern: environment.pattern,
          themeID: environment.themeID,
        }))
      : [
          {
            code: ApplicationEnvironment.PRODUCTION,
            domain: '',
            pattern: '',
            themeID: '',
          },
        ],
    identitySpaces: application?.identitySpaces
      ? application.identitySpaces.map(applicationIdentitySpace => ({
          identitySpaceID: applicationIdentitySpace?.identitySpaceID || '',
          format: applicationIdentitySpace?.format || '',
          key: applicationIdentitySpace?.key || '',
          type: applicationIdentitySpace?.type || '',
          variable: applicationIdentitySpace?.variable || '',
          encoding: applicationIdentitySpace?.encoding || '',
        }))
      : [],
    propertyCookies: application?.propertyCookies || [],
    accessibilityButton: application?.accessibilityButton
      ? {
          enabled: true,
          ...application.accessibilityButton,
        }
      : {
          enabled: false,
          color: '#000000',
          icon: '',
          position: 'bottom-right',
        },
    privacyPolicyCode: application?.privacyPolicy?.code ?? '',
    termsOfServiceCode: application?.termsOfService?.code ?? ApplicationFormSpecialValues.NO_DOCUMENT,
  }
}
