import { Box, Typography } from '@mui/material'

type TooltipProps = {
  active?: boolean
  payload: any
  label: string
}

export const DistributionPerJurisdictionTooltip = (props: TooltipProps) => {
  const { active, payload } = props

  if (active && payload && payload.length) {
    const name = payload?.[0]?.payload?.name
    const totalRequests = payload?.[0]?.payload?.totalRequests
    const consentRequiredText = payload?.[0]?.payload?.consentRequiredText

    return (
      <Box
        sx={{
          background: ({ palette }) => palette.darkDusk.main,
          borderRadius: ({ spacing }) => spacing(0.75),
          p: 1.25,
          minWidth: 250,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="smallLabel" color="white.main" sx={{ mb: 0.75, fontWeight: 700 }}>
          {name}Name
        </Typography>

        <Typography variant="smallLabel" color="white.main" sx={{ mb: 0.75, fontWeight: 500 }}>
          {totalRequests}
        </Typography>

        <Typography variant="smallLabel" color="white.main" sx={{ fontWeight: 500 }}>
          {consentRequiredText}
        </Typography>
      </Box>
    )
  }

  return null
}
