import { InfoRow, InfoRowProps } from '@ketch-com/deck'
import { useEffect, useState } from 'react'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { OwnerSearchAutocomplete } from 'pages/dataSystems/DataSystem/components/OwnerSearchAutocomplete'
import { TeamChip } from 'components/chip/TeamChip'
import { TeamDTO } from '@ketch-com/figurehead'
import { UserAndTeamOptionType } from 'pages/dataSystems/DataSystem/types'

interface ChipContentTeamDropdownEditInfoRowProps extends InfoRowProps {
  setValues: (selectedChips?: string[]) => void
  values: UserAndTeamOptionType[]
  options: UserAndTeamOptionType[]
}

export default function ChipContentTeamDropdownEditInfoRow({
  options,
  setValues,
  values,
  ...rest
}: ChipContentTeamDropdownEditInfoRowProps) {
  const [localValues, setLocalValues] = useState<TeamDTO[]>([])
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    setLocalValues(values)
  }, [values])

  return (
    <InfoRow
      {...rest}
      isEditable
      isEditing={isEditing}
      onEditChange={() => {
        setIsEditing(true)
      }}
      onAcceptChange={() => {
        setIsEditing(false)
        setValues(localValues.map(i => i.id as string))
      }}
      onCancelChange={() => {
        setIsEditing(false)
        setLocalValues(values)
      }}
      isEmpty={localValues.length === 0}
    >
      {isEditing ? (
        <OwnerSearchAutocomplete options={options} values={values} updateValues={v => setLocalValues(v as TeamDTO[])} />
      ) : localValues.length > 0 ? (
        localValues.map(v => <TeamChip key={v.id} team={v} />)
      ) : (
        <EmptyValueRenderer />
      )}
    </InfoRow>
  )
}
