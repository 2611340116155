import React from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'

import { AssetDetailViewAssetListTable } from './components'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { Dropdown } from 'components/ui-kit/dropdown/Dropdown'
import { EmptyState } from 'components/ui-layouts/emptyState/EmptyState'
import { InfiniteScrollLoadingIndicator } from 'components/ui-layouts/table/components/infiniteScrollLoadingIndicator'
import { MaybeNull } from 'interfaces'
import { ReactComponent as EditIcon } from 'assets/icons/edit-pen-dark-dusk-faded.svg'
import { ReactComponent as EmptyStateIcon } from 'assets/icons/search.svg'
import { ReactComponent as TagIcon } from 'assets/icons/tag-dark-dusk-faded.svg'
import { SearchInput } from 'components/ui-kit/searchInput/SearchInput'
import { Switch } from 'components/ui-kit/switch/Switch'
import { Text } from 'components/ui-kit/typography/Text'
import { useAppDispatch } from 'store/hooks'
import { useAssetDetailViewRelatedAssetsUtils } from './hooks'
import { DataCategoriesBulkAssignModal } from './components'
import { mapValuesToPayload } from './components/DataCategoriesBulkAssignModal/utils'
import { setIsAssignDataCategoriesModalOpen } from 'store/assetDetailViewSlice'

const useStyles = makeStyles(
  ({ spacing, palette }) =>
    createStyles({
      searchInput: {
        maxWidth: 150,
      },
      searchInputFocused: {
        minWidth: 300,
      },
      showSelectedSwitch: {
        marginRight: spacing(1),
      },
      dropdown: {
        backgroundColor: palette.white.main,
        border: `1px solid ${palette.white.main}`,
      },
      dropdownIcon: {
        color: palette.darkDusk.main,
        marginRight: spacing(1),
        opacity: 0.25,
      },
    }),
  { name: 'AssetDetailViewRelatedAssets' },
)

type Props = {
  assetSummary: MaybeNull<AssetSummaryDTO>
}

export const AssetDetailViewRelatedAssets: React.FC<Props> = ({ assetSummary }) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const {
    assets,
    assetsToDisplayInTable,
    fetchNextPage,
    handleUpdateAssetLabels,
    hasNextPage,
    isAssignDataCategoriesModalOpen,
    isFetchingNextPage,
    isLoading,
    isShowOnlySelected,
    resourceTypeCodeUrlParam,
    searchString,
    selectedAssets,
    setIsShowOnlySelected,
    setSearchString,
    isUpdatingAssetLabels,
  } = useAssetDetailViewRelatedAssetsUtils({ assetSummary })

  return (
    <>
      <Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2} sx={{ minHeight: 44 }}>
          {/* Left  */}

          <Box display="flex" alignItems="center">
            <SearchInput
              className={classes.searchInput}
              isFocusedClassName={classes.searchInputFocused}
              size="small"
              onChange={nextSearchString => dispatch(setSearchString(nextSearchString))}
              onClear={() => dispatch(setSearchString(''))}
              value={searchString}
            />

            {/* Conditionally displayed bulk edit controls */}

            {selectedAssets.length > 0 ? (
              <>
                {/* Selected Count */}

                <Text size={14} weight={600} ml={4}>
                  {selectedAssets?.length}
                </Text>
                <Text size={14} weight={600} ml={1} mr={4} color="darkDuskFaded">
                  selected
                </Text>

                {/* Show only selected Switch */}

                <Switch
                  value={isShowOnlySelected}
                  className={classes.showSelectedSwitch}
                  onChange={(e, checked) => dispatch(setIsShowOnlySelected(checked))}
                />

                <Text size={12} weight={500} ml={1}>
                  Show only selected
                </Text>
              </>
            ) : null}
          </Box>

          {/* Right */}

          {selectedAssets.length > 0 ? (
            <Box display="flex" alignItems="center">
              <Dropdown
                className={classes.dropdown}
                value="one"
                items={[
                  {
                    content: 'Data Category',
                    onClick: () => dispatch(setIsAssignDataCategoriesModalOpen(true)),
                  },
                  {
                    content: 'Purpose',
                    // 'TODO:BAC - open purpose assign modal'
                    onClick: () => {},
                  },
                  {
                    content: 'Policy',
                    // 'TODO:BAC - open policy assign modal'
                    onClick: () => {},
                  },
                ]}
                disabled={false}
              >
                <Box display="flex" alignItems="center">
                  <TagIcon className={classes.dropdownIcon} />
                  Assign
                </Box>
              </Dropdown>

              <Dropdown
                className={classes.dropdown}
                value="one"
                items={[
                  {
                    content: 'Personal Data Type',
                    // 'TODO:BAC - open personal data type assign modal'
                    onClick: () => {},
                  },
                  {
                    content: 'Data Owner',
                    // 'TODO:BAC - open data owner assign modal'
                    onClick: () => {},
                  },
                ]}
                disabled={false}
              >
                <Box display="flex" alignItems="center">
                  <EditIcon className={classes.dropdownIcon} />
                  Edit
                </Box>
              </Dropdown>
            </Box>
          ) : null}
        </Box>

        <AssetDetailViewAssetListTable
          resourceTypeCode={resourceTypeCodeUrlParam}
          assets={assetsToDisplayInTable}
          isLoading={isLoading}
          fetchNextPage={fetchNextPage as any} // typescript story is unnecessarily complicated due to feature flag, using any for now
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
        />

        {!isLoading && !assets?.length ? (
          <EmptyState
            variant="page"
            bgVariant="white"
            icon={<EmptyStateIcon />}
            title={`No matches for ${searchString ? `"${searchString}"` : 'your search'}`}
            description="Please try another search."
          />
        ) : null}

        <InfiniteScrollLoadingIndicator isFetchingNextPage={isFetchingNextPage} />
      </Box>

      {isAssignDataCategoriesModalOpen ? (
        <DataCategoriesBulkAssignModal
          isUpdatingAssetLabels={isUpdatingAssetLabels}
          assetCode={assetSummary?.asset?.resource?.id || ''}
          onSubmit={labels => {
            const formData = mapValuesToPayload({
              labels,
            })
            handleUpdateAssetLabels({
              params: {
                assetCode: assetSummary?.asset?.resource?.id || '',
                formData,
              },
            })
          }}
          onCancel={() => {
            dispatch(setIsAssignDataCategoriesModalOpen(false))
          }}
        />
      ) : null}
    </>
  )
}
