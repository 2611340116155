import { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Grid } from '@mui/material'

import { useDataMapDataSystem } from 'api/dataMap/queries/useDataMapDataSystem'
import { useDataMapPurpose } from 'api/dataMap/queries/useDataMapPurpose'

import { ViewLayout } from 'components/ui-layouts/viewLayout/ViewLayout'
import { ViewLayoutContent } from 'components/ui-layouts/viewLayout/ViewLayoutContent'
import { ViewLayoutHeader } from 'components/ui-layouts/viewLayout/ViewLayoutHeader'
import { Tag } from 'components/ui-kit/tag/Tag'
import { ViewModeLayout } from 'components/ui-layouts/viewLayout/ViewModeLayout'
import { Text } from 'components/ui-kit/typography/Text'
import { LinkChip } from 'components/ui-kit/chip/LinkChip'
import { Chip } from 'components/ui-kit/chip/Chip'
import { Button } from '@ketch-com/deck'
import { ContactModal } from 'components/modals/contacts/ContactModal'

import { RoutesManager } from 'utils/routing/routesManager'
import { PurposeActions } from '../../../components/PurposeActions'
import { getDataSystemType } from '../../../utils/getDataSystemType'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { showToast } from 'components/ui-kit/toastr/Toastr'

const PREFIX = 'DataMapPurposeDataSystemView'

const classes = {
  chip: `${PREFIX}-chip`,
  dataResidency: `${PREFIX}-dataResidency`,
  inOtherPurposes: `${PREFIX}-inOtherPurposes`,
  chevron: `${PREFIX}-chevron`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme: { spacing, palette } }) => ({
  [`& .${classes.chip}`]: {
    marginRight: spacing(1),
    marginBottom: spacing(1),
  },

  [`& .${classes.dataResidency}`]: {
    alignSelf: 'stretch',
    border: `1px solid ${palette.iron.main}`,
    borderRadius: 11,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: spacing(0),
    marginRight: spacing(2),
    marginTop: spacing(3),
    padding: spacing(2, 2.25, 2.75, 2.25),
  },

  [`& .${classes.inOtherPurposes}`]: {
    position: 'relative',
    display: 'flex',
    margin: spacing(2, 0, 4, 0),
    padding: spacing(1.75),
    backgroundColor: palette.white.main,
    borderRadius: 11,
    width: 1280,
  },

  [`& .${classes.chevron}`]: {
    padding: 0,
    width: spacing(3),
    height: spacing(3),
  },
}))

const DataSystemIcon = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '64px',
  height: '64px',
  backgroundColor: palette.lighterGrey.main,
  borderRadius: '.875rem',
  padding: '8px',

  '& img': {
    width: '100%',
  },
}))

type Props = {}

export const DataMapPurposeDataSystemView: React.FC<Props> = () => {
  const { code, system } = useParams<{ code: string; system: string }>()
  const { data: purpose, isLoading: isPurposeLoading } = useDataMapPurpose({ params: { purposeCode: code } })
  const { data: dataSystem, isLoading: isDataSystemLoading } = useDataMapDataSystem({
    params: { purposeCode: code!, systemId: system },
    onError: () => {
      showToast({ content: 'Failed to fetch data map systems purpose details', type: 'error' })
    },
  })

  const [isOtherPurposesOpen, setIsOtherPurposesOpen] = useState(false)
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const navigate = useNavigate()

  const isLoading = isPurposeLoading || isDataSystemLoading

  const navigateToOtherPurpose = (purposeCode: string) =>
    navigate(RoutesManager.insights.dataMap.view.overview.root.getURL({ code: purposeCode }))

  const breadcrumbs = [
    { title: 'Reports', link: RoutesManager.insights.root.getURL() },
    { title: 'Data Map', link: RoutesManager.insights.dataMap.root.getURL() },
    { title: purpose?.name, link: RoutesManager.insights.dataMap.view.root.getURL({ code }) },
    {
      title: dataSystem?.name,
      link: RoutesManager.insights.dataMap.view.dataSystem.root.getURL({ code, system }),
    },
  ]

  const otherPurposeCount = dataSystem.otherPurposes?.length || 0
  const dataSystemType = getDataSystemType(dataSystem.type || 0)
  const lastUpdated =
    (purpose?.metadata?.updatedAt || 0) > (dataSystem?.metadata?.updatedAt || 0)
      ? purpose?.metadata?.updatedAt
      : dataSystem?.metadata?.updatedAt
  const handleDocumentClick = (code: string) =>
    navigate(RoutesManager.policyCenter.policyDocuments.view.root.getURL({ code }))

  const residencyRetentionTextRegEx = /([0] )\^{0,2}/g

  const contentRows = [
    {
      title: 'Contact',
      info: !!dataSystem.contact?.name?.replace(/\s+/g, '').length ? (
        <Box>
          <Text sx={{ display: 'block', marginBottom: '8px', fontWeight: 600 }} size={14}>
            {dataSystem?.contact?.name}
          </Text>
          <Text sx={{ display: 'block' }} size={14}>
            {dataSystem?.contact?.role}
          </Text>
          <Text sx={{ display: 'block' }} size={14}>
            {dataSystem?.contact?.email}
          </Text>
        </Box>
      ) : (
        <Text>None</Text>
      ),
    },
    {
      title: 'Documents',
      info: (
        <Box mb={-1}>
          {!dataSystem.documents?.length && <Text>None</Text>}
          {dataSystem.documents?.map((doc, key) => (
            // TODO: add icon once we have file types
            <Box key={key}>
              <LinkChip
                key={doc.name}
                text={doc.name}
                className={classes.chip}
                onClick={() => doc.code && handleDocumentClick(doc.code)}
              />
              <Text sx={{ display: 'block', margin: '0px 0 16px' }} size={11}>
                {doc.description} {doc.description && doc.metadata?.updatedAt ? ' | ' : ''}{' '}
                {doc.metadata?.updatedAt && (
                  <>
                    Last Updated <FormattedDateRenderer date={doc.metadata?.updatedAt} />
                  </>
                )}
              </Text>
            </Box>
          ))}
        </Box>
      ),
    },
    {
      title: 'Data Residencies',
      info: (
        <Grid container spacing={3}>
          {!dataSystem.dataResidencies?.length && (
            <Grid item xs={12}>
              <Text>None</Text>
            </Grid>
          )}
          {dataSystem.dataResidencies?.map(residency => (
            <Grid xs={3} item className={classes.dataResidency} key={residency?.location}>
              <Text
                sx={{
                  display: 'block',
                  marginBottom: '16px',
                  fontWeight: residency.location ? 600 : 400,
                  opacity: residency.location ? 1 : 0.5,
                }}
                size={14}
              >
                {residency.location ? residency.location : 'Country Not Specified'}
              </Text>

              <Text mb={0.5} color="darkDuskFaded" component="div" weight={600}>
                Security
              </Text>

              {!residency.security?.length && (
                <Text component="div" mb={0.25} sx={{ opacity: 0.5 }}>
                  None
                </Text>
              )}
              {residency.security?.map(security => (
                <Text component="div" mb={0.25} key={security}>
                  {security}
                </Text>
              ))}
              <Text mt={2.75} color="darkDuskFaded" mb={0.5} component="div" weight={600}>
                Retention
              </Text>
              <Text
                component="div"
                sx={{ opacity: (residency?.retention || '').match(residencyRetentionTextRegEx) ? 0.5 : 1 }}
              >
                {(residency?.retention || '').match(residencyRetentionTextRegEx) ? 'None' : residency.retention}
              </Text>
            </Grid>
          ))}
        </Grid>
      ),
    },
    {
      title: 'Personal Data Type',
      info: dataSystem.personalDataCategory ? <Chip>{dataSystem.personalDataCategory}</Chip> : <Text>None</Text>,
    },
    {
      title: 'Data Categories',
      info: (
        <Box mb={-1}>
          {dataSystem.dataCategories?.map(category => (
            <Chip className={classes.chip} key={category}>
              {category}
            </Chip>
          )) || <Text>None</Text>}
        </Box>
      ),
    },
  ]

  // only add data owner for assets
  if (dataSystemType === 'database') {
    // TODO: change to dataSystem.dataOwners[] once the BE is ready
    contentRows.splice(2, 0, {
      title: 'Data Owners',
      info: !!dataSystem?.dataOwners?.length ? (
        // TODO: add modal onclick handler once the API returns the data
        // onClick={() => setIsContactModalOpen(true)}
        <>
          {dataSystem?.dataOwners?.map(dataOwner => (
            <LinkChip deleteIcon={<></>} text={dataOwner} sx={{ marginRight: 0.5 }} />
          ))}
        </>
      ) : (
        <Text>None</Text>
      ),
    })
  }

  return (
    <Root>
      <ViewLayout breadcrumbs={breadcrumbs} ignoreFlex={true}>
        <ViewLayoutHeader
          title={dataSystem.name}
          icon={
            <DataSystemIcon>
              <img src={dataSystem?.systemInfo?.logoUrl} alt={dataSystem?.systemInfo?.systemName} />
            </DataSystemIcon>
          }
          actions={<PurposeActions lastUpdated={isLoading ? undefined : lastUpdated} />}
          details={isLoading ? undefined : <Tag text={dataSystemType} />}
        />
        <ViewLayoutContent hasBorderTop={false}>
          <ViewModeLayout
            title="Overview"
            subtitle={`Basic Information About ${dataSystem.name}`}
            isLoading={isLoading}
            contentRows={contentRows}
            shouldPadRows={true}
          />
        </ViewLayoutContent>
      </ViewLayout>
      <Box className={classes.inOtherPurposes}>
        <Box width={24} mr={2}>
          <Button
            className={classes.chevron}
            color="tertiary"
            onClick={() => setIsOtherPurposesOpen(!isOtherPurposesOpen)}
          >
            {isOtherPurposesOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </Button>
        </Box>
        <Box>
          <Box mb={0.75}>
            <Text size={20} weight={700} mr={0.5}>
              In Other Purposes
            </Text>
            <Text size={20} weight={700} color="grey">
              {otherPurposeCount}
            </Text>
          </Box>
          <Text component="div" color="grey" mb={isOtherPurposesOpen ? 3 : 0}>
            This asset is associated with {otherPurposeCount} other purposes.
          </Text>
          {isOtherPurposesOpen &&
            dataSystem.otherPurposes?.map(otherPurpose => (
              <Box mb={1.25} key={otherPurpose.code}>
                <LinkChip
                  text={otherPurpose.name || 'Untitled Purpose'}
                  icon={<></>}
                  onClick={() => otherPurpose.code && navigateToOtherPurpose(otherPurpose.code)}
                />
              </Box>
            ))}
        </Box>
      </Box>
      {isContactModalOpen && dataSystem.contact && (
        <ContactModal title="Data Owner" contact={dataSystem.contact} onClose={() => setIsContactModalOpen(false)} />
      )}
    </Root>
  )
}
