import * as React from 'react'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useFiltersV3 } from 'utils/hooks'
import { RightsQueueMegaFilterButtonPanelIds } from '../constants'
import { useDebounce } from 'react-use'
import { Checkbox, Icon, TextInput } from '@ketch-com/deck'
import { DataSubjectTypeDTO } from '@ketch-com/figurehead'

const panelId = RightsQueueMegaFilterButtonPanelIds.subjectTypes

type Props = {
  subjectTypes: DataSubjectTypeDTO[]
  handlePaginationReset: () => void
}

export const SubjectTypeFilter: React.FC<Props> = ({ subjectTypes, handlePaginationReset }) => {
  const [searchString, setSearchString] = React.useState<string>('')
  const [searchSubjectTypeValue, setSearchSubjectTypeValue] = React.useState<string>('')
  const { removeQueryParamFromList, replaceParams, clearAllEntriesForKey, getPanelParamValuesArrayByPanelId } =
    useFiltersV3()

  const panelParamValuesArray = getPanelParamValuesArrayByPanelId(panelId)

  const isAllSubjectTypesSelected = panelParamValuesArray.length === subjectTypes?.length

  useDebounce(
    () => {
      if (searchString) {
        setSearchSubjectTypeValue(searchString)
      } else {
        setSearchSubjectTypeValue('')
      }
    },
    500,
    [searchString],
  )

  const filteredSubjectTypes = subjectTypes.filter(subjectType => {
    const isChecked = !!panelParamValuesArray.find(param => param === subjectType?.code)
    return (
      (subjectType?.name || '').toLowerCase().includes(searchSubjectTypeValue.toLowerCase()) ||
      (subjectType?.code || '').toLowerCase().includes(searchSubjectTypeValue.toLowerCase()) ||
      isChecked // show checked rights even if they don't match the search string
    )
  })

  return (
    <Box display="flex" flexWrap="wrap" flexDirection="column" alignItems="center" width="100%">
      <Box pl={1.5} width="100%">
        {/* Search */}
        <TextInput
          sx={{ mb: 1 }}
          value={searchString}
          startIcon={<Icon name="OMag" />}
          endIcon={
            searchString && <Icon sx={{ cursor: 'pointer' }} name="OCross" onClick={() => setSearchString('')} />
          }
          size="small"
          fullWidth
          placeholder="Search Subject Types"
          onChange={event => setSearchString(event.target.value)}
        />
      </Box>

      {!subjectTypes.length || !filteredSubjectTypes.length ? (
        <Box height={34} width="100%" pl={1.5}>
          <Typography color="darkDuskFaded.main">No results found</Typography>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          sx={{ bgcolor: 'background.paper', maxHeight: 200, overflow: 'auto', width: '100%' }}
        >
          <Box display="flex" alignItems="center" padding="0px 3px">
            <Checkbox
              id="all-rights"
              name="all-rights"
              title="All"
              checked={isAllSubjectTypesSelected}
              onChange={() => {
                if (isAllSubjectTypesSelected) {
                  clearAllEntriesForKey(panelId)
                } else {
                  replaceParams({ [panelId]: subjectTypes.map(s => s.code).join(','), page: '0' })
                }
                handlePaginationReset()
              }}
            />
            <Typography>All</Typography>
          </Box>

          {filteredSubjectTypes.map(dst => {
            const isChecked = !!panelParamValuesArray.find(param => param === dst.code)
            return (
              <Box key={dst.code} display="flex" alignItems="center" flexWrap="wrap" padding="1px 3px">
                <Checkbox
                  id={dst.code}
                  name={dst.name}
                  checked={isChecked}
                  onChange={() => {
                    if (isChecked) {
                      removeQueryParamFromList({ key: panelId, value: dst.code || '' })
                    } else {
                      const paramValues = getPanelParamValuesArrayByPanelId(panelId)
                      replaceParams({ [panelId]: [...paramValues, dst.code || ''].join(','), page: '0' })
                    }
                    handlePaginationReset()
                  }}
                />

                <Box display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    alignItems="center"
                    maxWidth="200px"
                    sx={{
                      wordBreak: 'break-word',
                    }}
                  >
                    <Typography>{dst.name}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    maxWidth="200px"
                    sx={{
                      wordBreak: 'break-word',
                    }}
                  >
                    <Typography variant="footnote">{dst.code}</Typography>
                  </Box>
                </Box>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}
