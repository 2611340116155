import React, { useState } from 'react'

import { useWebTagsPaginatedPreservePagination } from 'api/webtags/queries/useWebTagsPaginatedPreservePagination'
import { useSyncWebTags } from 'api/webtags/mutations/useSyncWebTags'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { WebTagsList } from 'pages/consentAndRights/consent/webTags/list/WebTagsList'
import { useAppSelector } from 'store/hooks'
import { getWebTagsListFilterState } from 'store/webTagsListFilterSlice/selectors'

export const WebTagsListContainer: React.FC = () => {
  const { appCode, appInstanceId, enabled, purposeId, q: reduxSearchQuery } = useAppSelector(getWebTagsListFilterState)
  const [isBusy, setIsBusy] = useState(false)

  const {
    data: webTags,
    isLoading: isWebTagsListLoading,
    isFetching: isWebTagsListFetching,
    pagination,
  } = useWebTagsPaginatedPreservePagination({
    params: {
      includeIssues: true,
      includeMetadata: true,
      q: reduxSearchQuery,
      appCode,
      appInstanceId,
      purposeId,
      enabled,
    },
  })

  const hasFilters = appCode || appInstanceId || enabled || purposeId || reduxSearchQuery
  const emptyStateMessage = hasFilters
    ? 'There are no tags for entered search criteria'
    : 'There are no tags for this organization'

  const { mutateAsync: syncWebTags } = useSyncWebTags({
    onSuccess: () => {
      setIsBusy(false)
      showToast({ content: 'Tag sync initiated', type: 'success' })
    },
    onError: () => {
      setIsBusy(false)
      showToast({ content: 'Failed to initiate tag sync', type: 'error' })
    },
  })

  const handleSyncWebTags = async () => {
    setIsBusy(true)
    await syncWebTags({
      params: {
        appCode: appCode,
        appInstanceID: appInstanceId,
      },
    })
  }

  return (
    <WebTagsList
      isBusy={isBusy}
      isLoading={isWebTagsListLoading || isBusy}
      isFetching={isWebTagsListFetching}
      webTags={webTags}
      pagination={pagination}
      handleSyncWebTags={handleSyncWebTags}
      emptyStateMessage={emptyStateMessage}
    />
  )
}
