import React from 'react'
import { Box } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import { useLocation, useNavigate } from 'react-router-dom'

import { RoutesManager } from 'utils/routing/routesManager'
import { Text } from 'components/ui-kit/typography/Text'
import { Table } from 'components/ui-layouts/table/Table'
import { AssetSummaryDTO, GetAssetHierarchiesResponseBodyDTO, LabelDTO } from '@ketch-com/figurehead'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query'
import { TextCellRenderer } from 'components/ui-layouts/table/cellRenderers/TextCellRenderer'
import { Waypoint } from 'react-waypoint'
import { Badge } from 'components/ui-kit/badge/Badge'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import { AssetCheckboxCellRenderer, CheckAllAssetsCellRenderer, AssetsListTableActionsCellRenderer } from '.'
import { pushEntryOntoBackToUrlHistory, removeAssetById, selectAssetById } from 'store/assetDetailViewSlice'
import { getAssetDetailViewState } from 'store/assetDetailViewSlice/selectors'

const useStyles = makeStyles(
  ({ palette, spacing }) =>
    createStyles({
      tableRowSelected: {
        border: `1px solid ${palette.sphere.main}`,
      },
      assetsTable: {
        backgroundColor: palette.white.main,
        borderRadius: spacing(1),
      },
      tableAssetTypeIcon: {
        width: 22,
        height: 22,
        marginRight: spacing(1),
      },
      dataSetIcon: {
        marginRight: spacing(1),
      },
      labelsCountPill: {
        backgroundColor: palette.marine.main,
        borderRadius: '50%',
        padding: spacing(0.75, 0.75),
      },
      buttonOptions: {
        background: palette.white.main,
        padding: spacing(1, 1),
      },
    }),
  { name: 'SnowflakeDatasetTableWithCheckboxes' },
)

type Props = {
  assets: AssetSummaryDTO[]
  isLoading: boolean
  isFetchingNextPage?: boolean
  resourceTypeCode?: string
  hasNextPage?: boolean
  shouldShowOnlyColumnHeaders?: boolean
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<GetAssetHierarchiesResponseBodyDTO, unknown>>
}

export const SnowflakeDatasetTableWithCheckboxes: React.FC<Props> = ({
  assets,
  isLoading,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  shouldShowOnlyColumnHeaders = false,
}) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { selectedAssets } = useAppSelector(getAssetDetailViewState)

  return (
    <Table
      isSmallHeaderText
      items={shouldShowOnlyColumnHeaders ? [] : assets}
      pending={isLoading}
      className={classes.assetsTable}
      rowIdKey={assetSummary => {
        return assetSummary?.asset?.resource?.id as string
      }}
      cellSettings={{
        checkboxColumn: {
          width: 40,
          label: '',
          labelNodeRenderer: assetsAlt => (
            <CheckAllAssetsCellRenderer assets={shouldShowOnlyColumnHeaders ? assets : assetsAlt} />
          ),
          cellRenderer: assetSummary => {
            const isChecked = !!selectedAssets.includes(assetSummary?.asset?.resource?.id || '')
            return (
              <AssetCheckboxCellRenderer
                isChecked={isChecked}
                handleToggleSelectDatabase={assetId => {
                  if (isChecked) {
                    dispatch(removeAssetById(assetId))
                  } else {
                    dispatch(selectAssetById(assetId))
                  }
                }}
                code={assetSummary?.asset?.resource?.id || ''}
              />
            )
          },
        },
        name: {
          width: 415,
          label: 'Name',
          cellRenderer: assetSummary => (
            <TextCellRenderer
              onClick={e => {
                dispatch(pushEntryOntoBackToUrlHistory(location.pathname))
                navigate(
                  RoutesManager.assetManager.assets.detail.root.getURL({
                    code: assetSummary?.asset?.resource?.id || '',
                  }),
                )
              }}
              value={assetSummary?.asset?.resource?.name}
            />
          ),
        },
        personalDataType: {
          width: 147,
          label: 'Personal Data Type',
          cellRenderer: assetSummary => {
            const hasPersonalDataTypes = (assetSummary?.asset?.dataCategory?.length || 0) > 0
            return hasPersonalDataTypes ? (
              assetSummary?.asset?.dataCategory?.map((dataCategory: LabelDTO) => {
                return <Badge key={dataCategory?.name}>{dataCategory?.value}</Badge>
              })
            ) : (
              <EmptyValueRenderer color="darkDuskFaded" />
            )
          },
        },
        attributes: {
          width: 100,
          label: 'Attribute',
          cellRenderer: assetSummary => {
            const assignedLabels = assetSummary?.asset?.assignedLabels || []
            const inferredLabels = assetSummary?.asset?.inferredLabels || []
            const collectedLabels = [...assignedLabels, ...inferredLabels]
            return (
              <Box className={classes.labelsCountPill} display="flex" alignItems="center">
                <Text size={11} weight={500} color="persianBlue">
                  {collectedLabels?.length || 0}
                </Text>
              </Box>
            )
          },
        },
        labels: {
          width: 100,
          label: 'Classifications',
          cellRenderer: (assetSummary, index) => {
            const assignedLabels = assetSummary?.asset?.assignedLabels || []
            const inferredLabels = assetSummary?.asset?.inferredLabels || []
            const collectedLabels = [...assignedLabels, ...inferredLabels]
            return (
              <>
                <Box className={classes.labelsCountPill} display="flex" alignItems="center">
                  <Text size={11} weight={500} color="persianBlue">
                    {collectedLabels?.length || 0}
                  </Text>
                </Box>
                {(assets?.length || 0) - 5 === index ? (
                  <Waypoint
                    onEnter={() => {
                      if (!isFetchingNextPage && hasNextPage) fetchNextPage()
                    }}
                  />
                ) : null}
              </>
            )
          },
        },
        actions: {
          cellRenderer: assetSummary => {
            const shouldDisableEditAndViewLinks = false
            return (
              <AssetsListTableActionsCellRenderer
                isDisabled={shouldDisableEditAndViewLinks}
                buttonOptionsClassName={classes.buttonOptions}
                assetSummary={assetSummary}
              />
            )
          },
        },
      }}
    />
  )
}
