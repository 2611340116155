export enum PurposeDataSubjectRole {
  UNKNOWN_DATA_SUBJECT_ROLE = 0,
  CUSTOMER = 1,
  EMPLOYEE = 2,
}

export const PURPOSE_DATA_SUBJECT_ROLE_NAMES = {
  [PurposeDataSubjectRole.UNKNOWN_DATA_SUBJECT_ROLE]: 'Unknown',
  [PurposeDataSubjectRole.CUSTOMER]: 'Customer',
  [PurposeDataSubjectRole.EMPLOYEE]: 'Employee',
}

export const PURPOSE_DATA_SUBJECT_ROLE_OPTIONS = [
  {
    id: PurposeDataSubjectRole.CUSTOMER,
    name: PURPOSE_DATA_SUBJECT_ROLE_NAMES[PurposeDataSubjectRole.CUSTOMER],
  },
  {
    id: PurposeDataSubjectRole.EMPLOYEE,
    name: PURPOSE_DATA_SUBJECT_ROLE_NAMES[PurposeDataSubjectRole.EMPLOYEE],
  },
]
