import { FieldArray, Formik, useFormikContext } from 'formik'
import Box from '@mui/material/Box'

import { ICanvasStep, IWorkflowConfig } from 'pages/orchestration/workflows/edit/interfaces'

import { SidebarConfigsWrapper } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsWrapper'
import { SidebarConfigsHeader } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsHeader'
import { SidebarConfigsBody } from 'pages/orchestration/workflows/edit/components/sidebarConfigs/components/SidebarConfigsBody'
import { formatCodeFromName } from 'utils/formatters'
import Typography from '@mui/material/Typography'
import { AddVariableRow, ConfigSwitch, TaskRemovalWarningModal, VariableItem } from './components'
import { TaskActivityParamsKeys } from 'pages/orchestration/workflows/edit/interfaces/ITaskActivityParams'
import { useTaskFormUtils } from './hooks'
import {
  getIsTaskReferencedInDownstreamDecisionGateway,
  getTaskFormInitialValues,
  getTaskFormValidationSchema,
  getTaskRemovalWarningMessageConfig,
} from './utils'
import { WORKFLOW_TYPE_ENUM } from '../DsrTransponderForm/constants'
import { WarningModal } from '../../../../WorkflowCanvas/components'
import { useContext } from 'react'
import { EditWorkflowContainerContext } from '../../../../EditWorkflowFormContainer/context/EditWorkflowContainerContext'
import { FormInput } from 'components/form/FormInput'
import { ContentGroup } from '@ketch-com/deck'
import { FormError } from 'components/form/FormError'
import { FormAssigneeOrTeamSelector } from '../../../../../../../../../components/form/FormAssigneeOrTeamSelector'

interface ITaskFormProps {
  step: ICanvasStep
  steps: Array<ICanvasStep>
  handleClose: () => void
  handleSave: (params: { step: ICanvasStep }) => void
  handleRemove: (params: { step: ICanvasStep }) => void
}

export const TaskForm: React.FC<ITaskFormProps> = ({ step, steps, handleClose, handleRemove, handleSave }) => {
  const { values: formikValues } = useFormikContext<IWorkflowConfig>()
  const { canonicalRightCode } = useContext(EditWorkflowContainerContext)
  const {
    dataSubjectVariables,
    isLoadingAggregate,
    onSubmit,
    outcomeVariables,
    variablesWarning,
    selectedDataSubjectCodeForAddition,
    selectedOutcomeCodeForAddition,
    setVariablesWarning,
    setSelectedDataSubjectCodeForAddition,
    setSelectedOutcomeCodeForAddition,
    setUnAddedDataSubjectVariableWarning,
    setUnAddedOutcomeVariableWarning,
    unAddedDataSubjectVariableWarning,
    unAddedOutcomeVariableWarning,
    usedCodes,
    filteredDataSubjectVariables,
    setTaskRemovalWarningMessageConfig,
    taskRemovalWarningMessageConfig,
    identitySpaces,
    setSelectedIdentitySpaceCodeForAddition,
    selectedIdentitySpaceCodeForAddition,
    unAddedIdentitySpaceWarning,
    setUnAddedIdentitySpaceWarning,
  } = useTaskFormUtils({ step, steps, handleClose, handleRemove, handleSave })

  return (
    <SidebarConfigsWrapper isLoading={isLoadingAggregate} handleClose={handleClose}>
      <Formik
        validateOnMount
        initialValues={getTaskFormInitialValues({ step, canonicalRightCode })}
        validationSchema={getTaskFormValidationSchema({ usedCodes })}
        onSubmit={onSubmit}
      >
        {({ isValid, submitForm, setFieldValue, touched, values, errors }) => {
          return (
            <>
              <SidebarConfigsHeader
                isValid={isValid}
                steps={steps}
                step={step}
                handleSave={() => {
                  if (selectedDataSubjectCodeForAddition) {
                    setUnAddedDataSubjectVariableWarning(true)
                    return
                  }
                  if (selectedOutcomeCodeForAddition) {
                    setUnAddedOutcomeVariableWarning(true)
                    return
                  }

                  submitForm()
                }}
                handleRemove={() => {
                  const warningMessages = getTaskRemovalWarningMessageConfig({
                    values,
                    steps,
                  })

                  if (warningMessages.length > 0) {
                    setTaskRemovalWarningMessageConfig(warningMessages)
                    return
                  }

                  handleRemove({ step })
                }}
                handleClose={handleClose}
                copyable
              />

              <SidebarConfigsBody sx={{ px: 3 }}>
                {/* Description Label */}

                <Box mb={3}>
                  <FormInput
                    formPropertyName="description"
                    fullWidth
                    label="Description Label"
                    required
                    placeholder="Enter description"
                    onChange={e => setFieldValue(TaskActivityParamsKeys.code, formatCodeFromName(e.target.value))}
                    shouldUpdateDebounced
                  />
                </Box>

                <Box mb={5}>
                  <FormInput
                    formPropertyName="code"
                    fullWidth
                    label="Code"
                    placeholder="Enter code"
                    required
                    shouldUpdateDebounced
                  />
                </Box>

                {/* Instructions */}

                <Typography variant="h3" mb={3}>
                  Instructions
                </Typography>

                <Box mb={3}>
                  <FormInput
                    formPropertyName={TaskActivityParamsKeys.details}
                    fullWidth
                    label="Task Description"
                    placeholder="Enter task details"
                    required
                    rows={4}
                    multiline
                    shouldUpdateDebounced
                  />
                </Box>

                <FormAssigneeOrTeamSelector label="Assignee" name="assignee" />

                {/* Activity Timer */}
                <Box mb={5}>
                  <FormInput
                    type="number"
                    formPropertyName="timeToCompleteDays"
                    label="Time To Complete (Days)"
                    placeholder="0"
                    inputWidth="80px"
                    shouldUpdateDebounced
                  />
                </Box>

                {/* Outcomes */}

                <Box pb={3}>
                  <ContentGroup
                    title="Outcomes"
                    subTitle="Select details that should be provided by the Assignee."
                    titleVariant="h3"
                  />
                </Box>

                {/* Notes */}

                <ConfigSwitch
                  containerSx={{
                    mb: 0.5,
                  }}
                  title="Notes"
                  switchValue={values.hasResolutionNotes}
                  switchOnChange={e => {
                    setFieldValue(TaskActivityParamsKeys.hasResolutionNotes, e.target.checked)
                    if (!e.target.checked) {
                      setFieldValue(TaskActivityParamsKeys.isResolutionNotesRequired, false)
                    }
                  }}
                  // checkbox params
                  checkboxValue={values.isResolutionNotesRequired}
                  checkboxOnChange={e => {
                    setFieldValue(TaskActivityParamsKeys.isResolutionNotesRequired, e.target.checked)
                  }}
                />

                {/* Attachments */}

                <ConfigSwitch
                  containerSx={{
                    mb: 0.5,
                  }}
                  title="Attachments"
                  switchValue={values.hasResolutionAttachments}
                  switchOnChange={e => {
                    setFieldValue(TaskActivityParamsKeys.hasResolutionAttachments, e.target.checked)
                    if (!e.target.checked) {
                      setFieldValue(TaskActivityParamsKeys.isResolutionAttachmentsRequired, false)
                    }
                  }}
                  // checkbox params
                  checkboxValue={values.isResolutionAttachmentsRequired}
                  checkboxOnChange={e => {
                    setFieldValue(TaskActivityParamsKeys.isResolutionAttachmentsRequired, e.target.checked)
                  }}
                />

                {/* Attachments for Data Subject */}

                {formikValues?.canonicalRightCode === WORKFLOW_TYPE_ENUM.GET && (
                  <ConfigSwitch
                    containerSx={{
                      mb: 0.5,
                    }}
                    title="Attachments For Data Subject"
                    switchValue={values.hasDsrResolutionAttachments}
                    switchOnChange={e => {
                      setFieldValue(TaskActivityParamsKeys.hasDsrResolutionAttachments, e.target.checked)
                      if (!e.target.checked) {
                        setFieldValue(TaskActivityParamsKeys.isDsrResolutionAttachmentsRequired, false)
                      }
                    }}
                    // checkbox params
                    checkboxValue={values.isDsrResolutionAttachmentsRequired}
                    checkboxOnChange={e => {
                      setFieldValue(TaskActivityParamsKeys.isDsrResolutionAttachmentsRequired, e.target.checked)
                    }}
                  />
                )}

                {/* Data Subject Variables */}

                <ConfigSwitch
                  error={
                    errors?.dataSubjectVariables &&
                    touched?.dataSubjectVariables && <FormError msg={errors?.dataSubjectVariables as string} />
                  }
                  containerSx={{
                    mb: 0.5,
                  }}
                  title="Data Subject Variables"
                  switchValue={values.hasDataSubjectVariables}
                  switchOnChange={e => {
                    if (!e.target.checked) {
                      setFieldValue(TaskActivityParamsKeys.dataSubjectVariables, [])
                      setUnAddedDataSubjectVariableWarning(false)
                      setSelectedDataSubjectCodeForAddition('')
                    }

                    setFieldValue(TaskActivityParamsKeys.hasDataSubjectVariables, e.target.checked)
                  }}
                  disabled={!dataSubjectVariables?.length}
                  tooltipContent={!dataSubjectVariables?.length && 'No Data Subject Variables available'}
                  requisiteForm={
                    <FieldArray
                      name={TaskActivityParamsKeys.dataSubjectVariables}
                      render={({ push, remove }) => {
                        return (
                          <Box display="flex" flexDirection="column" px={1} pb={2}>
                            {(values?.dataSubjectVariables || []).map((dsv, index) => {
                              return (
                                <VariableItem
                                  key={dsv.code}
                                  option={dsv}
                                  remove={remove}
                                  selectedVariableCodeForAddition={selectedDataSubjectCodeForAddition}
                                  variableIndex={index}
                                  variableId={dsv.code}
                                  variableOptions={dataSubjectVariables}
                                  formikStateKey={TaskActivityParamsKeys.dataSubjectVariables}
                                  step={step}
                                  steps={steps}
                                  setVariablesWarning={setVariablesWarning}
                                />
                              )
                            })}

                            <AddVariableRow
                              push={push}
                              selectedVariableCodeForAddition={selectedDataSubjectCodeForAddition}
                              setSelectedDataSubjectCodeForAddition={setSelectedDataSubjectCodeForAddition}
                              variableOptions={filteredDataSubjectVariables}
                              currentlySelectedOptions={values?.dataSubjectVariables || []}
                              selectPlaceholder="Select Subject Variable"
                              setUnAddedVariableWarning={setUnAddedDataSubjectVariableWarning}
                              unAddedVariableWarning={
                                unAddedDataSubjectVariableWarning ? 'Please select "Add Variable" button.' : undefined
                              }
                            />
                          </Box>
                        )
                      }}
                    />
                  }
                />

                {/* Outcome Variables */}

                <ConfigSwitch
                  error={
                    errors?.outcomeVariables &&
                    touched?.outcomeVariables && <FormError msg={errors?.outcomeVariables as string} />
                  }
                  containerSx={{
                    mb: 0.5,
                  }}
                  title="Outcome Variables"
                  switchValue={values.hasOutcomeVariables}
                  switchOnChange={e => {
                    // need to determine if the task is referenced in a downstream decision gateway and prevent
                    // the user from de-selecting if so.
                    const { isReferenced, warningMessage } = getIsTaskReferencedInDownstreamDecisionGateway({
                      step,
                      steps,
                    })

                    if (isReferenced) {
                      setVariablesWarning(warningMessage)
                      return
                    }

                    if (!e.target.checked) {
                      setFieldValue(TaskActivityParamsKeys.outcomeVariables, [])
                      setUnAddedOutcomeVariableWarning(false)
                      setSelectedOutcomeCodeForAddition('')
                    }
                    setFieldValue(TaskActivityParamsKeys.hasOutcomeVariables, e.target.checked)
                  }}
                  disabled={!outcomeVariables?.length}
                  tooltipContent={!outcomeVariables?.length && 'No Outcome Variables available'}
                  requisiteForm={
                    <FieldArray
                      name={TaskActivityParamsKeys.outcomeVariables}
                      render={({ push, remove }) => {
                        return (
                          <Box display="flex" flexDirection="column" px={1} pb={2}>
                            {(values?.outcomeVariables || []).map((dsv, index) => {
                              return (
                                <VariableItem
                                  key={dsv.code}
                                  option={dsv}
                                  remove={remove}
                                  selectedVariableCodeForAddition={selectedOutcomeCodeForAddition}
                                  variableIndex={index}
                                  variableId={dsv.code}
                                  variableOptions={outcomeVariables}
                                  formikStateKey={TaskActivityParamsKeys.outcomeVariables}
                                  step={step}
                                  steps={steps}
                                  setVariablesWarning={setVariablesWarning}
                                />
                              )
                            })}

                            <AddVariableRow
                              push={push}
                              selectedVariableCodeForAddition={selectedOutcomeCodeForAddition}
                              setSelectedDataSubjectCodeForAddition={setSelectedOutcomeCodeForAddition}
                              variableOptions={outcomeVariables}
                              currentlySelectedOptions={values?.outcomeVariables || []}
                              selectPlaceholder="Select Outcome Variable"
                              setUnAddedVariableWarning={setUnAddedOutcomeVariableWarning}
                              unAddedVariableWarning={
                                unAddedOutcomeVariableWarning ? 'Please select "Add Variable" button.' : undefined
                              }
                            />
                          </Box>
                        )
                      }}
                    />
                  }
                />

                {/* Identifiers */}

                <ConfigSwitch
                  title="Identifiers"
                  switchOnChange={e => {
                    setFieldValue(TaskActivityParamsKeys.hasIdentitySpaces, e.target.checked)
                  }}
                  switchValue={values.hasIdentitySpaces}
                  disabled={!identitySpaces?.length}
                  containerSx={{
                    mb: 0.5,
                  }}
                  tooltipContent={!identitySpaces?.length && 'No Identifiers available'}
                  error={
                    errors?.identitySpaces &&
                    touched?.identitySpaces && <FormError msg={errors?.identitySpaces as string} />
                  }
                  requisiteForm={
                    <FieldArray
                      name={TaskActivityParamsKeys.identitySpaces}
                      render={({ push, remove }) => {
                        return (
                          <Box display="flex" flexDirection="column" px={1} pb={2}>
                            {(values?.identitySpaces || []).map((space, index) => {
                              return (
                                <VariableItem
                                  key={space.code}
                                  option={space}
                                  remove={remove}
                                  selectedVariableCodeForAddition={selectedIdentitySpaceCodeForAddition}
                                  variableIndex={index}
                                  variableId={space.code}
                                  variableOptions={identitySpaces}
                                  formikStateKey={TaskActivityParamsKeys.identitySpaces}
                                  step={step}
                                  steps={steps}
                                  setVariablesWarning={setVariablesWarning}
                                />
                              )
                            })}

                            <AddVariableRow
                              push={push}
                              selectedVariableCodeForAddition={selectedIdentitySpaceCodeForAddition}
                              setSelectedDataSubjectCodeForAddition={setSelectedIdentitySpaceCodeForAddition}
                              variableOptions={identitySpaces}
                              currentlySelectedOptions={values?.identitySpaces || []}
                              selectPlaceholder="Select Identifier"
                              setUnAddedVariableWarning={setUnAddedIdentitySpaceWarning}
                              unAddedVariableWarning={
                                unAddedIdentitySpaceWarning ? 'Please select "Add Variable" button.' : undefined
                              }
                            />
                          </Box>
                        )
                      }}
                    />
                  }
                />
              </SidebarConfigsBody>
            </>
          )
        }}
      </Formik>

      <WarningModal onClose={() => setVariablesWarning('')} message={variablesWarning} />

      <TaskRemovalWarningModal
        taskRemovalWarningMessageConfig={taskRemovalWarningMessageConfig}
        onClose={() => setTaskRemovalWarningMessageConfig([])}
        confirmRemove={() => handleRemove({ step })}
      />
    </SidebarConfigsWrapper>
  )
}
