import { getIn } from 'formik'
import { ExperienceBuilderSection } from '../components/builder/utils/enums'
import { errorSections, pageLabelFormKeys } from './useExperienceValidationSchema'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { EntitlementLayoutConfig, PreferenceCenterPage, QuickLinkAction, RightsFormMode } from '@ketch-sdk/ketch-types'
import { PreferencePageLabels } from './labels'
import { ExperienceSummary } from 'interfaces/experiences-v2/experienceSummary'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'
import { SubscriptionTopicDTO } from '@ketch-com/figurehead'
import { OptionType } from 'interfaces/optionType'
import { isUndefined } from 'lodash'
import { defaultExperienceContentEnglishConfig, defaultPreferenceExperienceV2DTO } from './defaults'
import { cloneDeep } from 'lodash'
/**
 * Return the form section corresponding to the first key from errorSections that is defined
 * in the errors array.
 */
export const getErrorSection = (errors: any): ExperienceBuilderSection => {
  const firstErrorFormKey = Object.keys(errorSections).find(errorFormKey => !!getIn(errors, errorFormKey))
  return errorSections[firstErrorFormKey || '']
}

export const getFormNameForLocale = (name: string, locale: string) => name.replace('.en.', `.${locale}.`)

const preferencePageNumbers = {
  [PreferenceCenterPage.Welcome]: '1. ',
  [PreferenceCenterPage.PrivacyPolicy]: '2. ',
  [PreferenceCenterPage.Purpose]: '2. ',
  [PreferenceCenterPage.Subscriptions]: '3. ',
  [PreferenceCenterPage.RequestsHome]: '4. ',
  [PreferenceCenterPage.RequestsForm]: '',
  [PreferenceCenterPage.RequestsSubmitted]: '',
}

export const renderPreferencePageLabel = (values: ExperienceV2DTO, page: PreferenceCenterPage) => {
  const label = pageLabelFormKeys[page] === '' ? PreferencePageLabels[page] : getIn(values, pageLabelFormKeys[page])
  return `${preferencePageNumbers[page]}${label}`
}

export const getExperienceSummary = (
  experience: ExperienceV2DTO,
  formTemplateOptions: OptionType[],
  subscriptionTopics: SubscriptionTopicDTO[],
): ExperienceSummary => {
  let uniqueFormIDs: string[] = []

  // Compute forms count
  const mode = experience.layout?.preference?.tabs?.requests?.home?.rights?.forms?.mode
  if (experience.type === ExperienceType.Preference) {
    if (mode === RightsFormMode.Single) {
      uniqueFormIDs.push(experience.layout?.preference?.tabs?.requests?.home?.rights?.forms?.singleFormID!)
    } else {
      const formDetails = experience.layout?.preference?.tabs?.requests?.home?.rights?.forms?.details

      // Get the number of unique default forms
      uniqueFormIDs.push(
        ...new Set(formDetails?.filter(right => !!right.defaultFormID).map(right => right.defaultFormID!) ?? []),
      )

      // Count the number of unique mapped forms
      formDetails?.forEach(right => {
        const uniqueMappingFormIDs = [
          ...new Set(
            right?.rightFormMappings
              ?.filter(form => form.formID && !uniqueFormIDs.includes(form.formID))
              .map(mapping => mapping.formID) ?? [],
          ),
        ]
        uniqueFormIDs.push(...uniqueMappingFormIDs)
      })
    }
  }

  // Compute subscription codes
  const subscriptionCodes =
    experience.layout?.preference?.tabs?.subscriptions?.list?.items.map(item => item.code || '') ?? []

  // Map IDs to the subscription and form objects
  const uniqueSubscriptions = subscriptionCodes
    .filter(code => subscriptionTopics.some(topic => topic.code === code))
    .map(code => subscriptionTopics.find(topic => topic.code === code)!.name)
  const uniqueForms = uniqueFormIDs
    .filter(formID => formTemplateOptions.some(formTemplate => formTemplate.value === formID))
    .map(formID => formTemplateOptions.find(formTemplate => formTemplate.value === formID)!.label || '')

  return { uniqueSubscriptions, uniqueForms }
}

export const withEntitlementInfo = (
  experience: ExperienceV2DTO,
  entitlementInfo: EntitlementLayoutConfig,
): ExperienceV2DTO => {
  // Return experience with entitlement info added
  return {
    ...experience,
    layout: {
      ...experience.layout,
      entitlementInfo,
    },
  }
}

const indexPages: PreferenceCenterPage[] = [
  PreferenceCenterPage.Welcome,
  PreferenceCenterPage.Purpose,
  PreferenceCenterPage.Subscriptions,
  PreferenceCenterPage.RequestsHome,
]

export const getPreferencePageLabel = (page: PreferenceCenterPage, index?: number) =>
  `${!isUndefined(index) && indexPages.includes(page) ? `${index + 1}. ` : ''}${PreferencePageLabels[page]}`

/**
 * This addresses a bug with the experience server migration where the quickLinks fields were incorrectly set, which
 * causes validation to fail.
 */

export const handleOldQuickLinksExperience = (experience: ExperienceV2DTO) => {
  const experienceDeepClone = cloneDeep(experience)
  if (experienceDeepClone?.layout?.preference?.tabs?.welcome?.quickLinks) {
    const experienceQuickLinksClone = cloneDeep(experienceDeepClone?.layout?.preference?.tabs?.welcome?.quickLinks)
    if (experienceQuickLinksClone.links) {
      const links = [...experienceQuickLinksClone.links]
      links.forEach((link, index) => {
        if (link.visible === true && !Object.keys(link).find(key => key === 'action')) {
          link.visible = false
          link.action = QuickLinkAction.OpenUrl
          if (index === 0) {
            experienceQuickLinksClone.visible = false
          }
        }
      })
      experienceQuickLinksClone.links = links
      experienceDeepClone.layout.preference.tabs.welcome.quickLinks = experienceQuickLinksClone
    }
  }

  return experienceDeepClone
}

export const handleMigratedExperience = (experience: ExperienceV2DTO) => {
  let experienceDeepClone = cloneDeep(experience)

  if (experienceDeepClone.layout?.preference?.tabs?.welcome?.quickLinks?.links === null) {
    experienceDeepClone.layout.preference.tabs.welcome.quickLinks.links =
      defaultPreferenceExperienceV2DTO.layout?.preference?.tabs?.welcome?.quickLinks?.links
  } else {
    experienceDeepClone = handleOldQuickLinksExperience(experienceDeepClone)
  }

  if (experienceDeepClone.translations) {
    Object.entries(experienceDeepClone.translations).forEach(([locale, content], i) => {
      if (content.preference?.tabs?.welcome?.quickLinks?.links === null) {
        content.preference.tabs.welcome.quickLinks.links =
          defaultExperienceContentEnglishConfig.preference?.tabs?.welcome?.quickLinks?.links
        experienceDeepClone.translations![locale] = content
      }
    })
  }

  return experienceDeepClone
}
