import { OnboardingProgressDTO } from '@ketch-com/figurehead'
import { useOnboardingProgress } from 'api/canduOnboardingProgress/queries/useOnboardingProgress'
import { usePermitStatsOverall } from 'api/permitStatistics/queries/usePermitsStatsOverall'
import { useTrackerStatistics } from 'api/trackers/queries/useTrackerStatistics'
import { addYears } from 'date-fns'
import moment from 'moment'
import { useEffect, useMemo } from 'react'

/**
 * The onboarding variables (traits) that we use in Candu are partially stored in Supercargo, and the rest are determined in the hook below.
 *
 * The complete list of variables are the keys of the 'CombinedOnboardingProgress' type.
 */

enum OnboardingProgressStatus {
  Done = 'done',
  Unspecified = 'unspecified',
}

export type CombinedOnboardingProgress =
  | OnboardingProgressDTO
  | {
      isTrackerScanComplete: string
      isTrackerApprovedOrDisclosed: string
      isFirstPermitReceived: string
    }

export const useCombinedOnboardingProgress: ({
  enabled,
  onSuccess,
  onError,
}: {
  enabled: boolean
  onSuccess: (data: CombinedOnboardingProgress) => void
  onError: () => void
}) => {
  isLoading: boolean
} = ({ enabled, onSuccess, onError }) => {
  // Get onboarding progress from supercargo
  const { data: onboardingProgress, isLoading: isLoadingOnboardingProgress } = useOnboardingProgress({
    enabled,
    refetchInterval: 6000, // 6 sec
    onError,
  })

  // Get trackers data
  const { data: trackerStats, isLoading: isLoadingTrackerStats } = useTrackerStatistics({
    enabled,
    refetchInterval: 6000, // 6 sec
    onError,
  })

  // Get permits data
  const currentDate = new Date()
  const { data: permitStats, isLoading: isLoadingOverallPermitStats } = usePermitStatsOverall({
    enabled: enabled && !!currentDate,
    params: {
      startDate: `${moment(addYears(currentDate, -1)).format('YYYY-MM-DD')}`,
      endDate: `${moment(currentDate).format('YYYY-MM-DD')}`,
    },
    refetchInterval: 6000, // 6 sec
    onError,
  })

  const isLoading = useMemo(
    () => isLoadingOnboardingProgress || isLoadingTrackerStats || isLoadingOverallPermitStats,
    [isLoadingOnboardingProgress, isLoadingTrackerStats, isLoadingOverallPermitStats],
  )

  useEffect(() => {
    // Only execute this block if we have all data AND it is either a) the first time we've had it all, or b) some of the data has changed
    if (!isLoading && onboardingProgress && trackerStats && permitStats) {
      // A scan has run if count or unReviewed is greater than 0
      const isTrackerScanComplete =
        (trackerStats?.count || 0) > 0 || (trackerStats?.unReviewed || 0) > 0
          ? OnboardingProgressStatus.Done
          : OnboardingProgressStatus.Unspecified

      // Customer has reviewed if count is greater than 0
      const isTrackerApprovedOrDisclosed =
        (trackerStats?.count || 0) > 0 ? OnboardingProgressStatus.Done : OnboardingProgressStatus.Unspecified

      // Permit has been received if consent count is greater than 0
      const isFirstPermitReceived =
        (permitStats?.consent || 0) > 0 ? OnboardingProgressStatus.Done : OnboardingProgressStatus.Unspecified

      // Assemble progress
      const combinedOnboardingProgress: CombinedOnboardingProgress = {
        isKetchTagInstalled: onboardingProgress?.isKetchTagInstalled,
        isCustomized: onboardingProgress?.isCustomized,
        isDeployed: onboardingProgress?.isDeployed,
        isTrackerScanComplete,
        isTrackerApprovedOrDisclosed,
        isFirstPermitReceived,
      }

      // Call the onSuccess function provided to this hook
      onSuccess(combinedOnboardingProgress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, onboardingProgress, trackerStats, permitStats])

  return { isLoading }
}
