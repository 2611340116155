import React, { useContext } from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'
import {
  OrgPlanIDs,
  OrgPlanMaxUniquesText,
  OrgPlanCosts,
  OrgPlanNames,
} from 'components/planAndBilling/common/constants/planAndBilling'
import { Button, Icon, theme, Status, StatusVariant, StatusState } from '@ketch-com/deck'

const freePlanOptions = [
  {
    title: OrgPlanMaxUniquesText.free,
    tooltip:
      'The number of unique visitors who will see the Ketch privacy experience on your website or mobile app each month.',
  },
  {
    title: 'Consent Management Platform (CMP)',
    tooltip:
      'Includes unlimited policy templates for privacy laws, customizable privacy experiences, website tracker scanning and blocking, and consent record storage.',
  },
  {
    title: '2 Integrations',
    tooltip:
      'Pass visitor consent signals to (2) business systems selected from our Integration Library (including Google Analytics, HubSpot, MailChimp, and more).',
  },
  {
    title: 'Limited Support',
    tooltip:
      'Limited assistance from the Ketch Customer Experience Team to configure the platform and understand its core functionality.',
  },
]

export const Free = () => {
  const { orgPlan } = useContext(PlanAndBillingContext)
  const isFreePlanActive = orgPlan?.planProductID === OrgPlanIDs?.free

  return (
    <Box
      flexBasis={0}
      flexGrow={1}
      pt={2}
      pb={4}
      sx={{
        border: `1px solid ${theme.palette.Common.Divider}`,
        borderRadius: 3,
      }}
    >
      <Box px={2}>
        <Box mb={2} display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="h3">{OrgPlanNames.free}</Typography>
          <Box display="flex" gap={0.5}>
            <Typography noWrap variant="h4">
              ${OrgPlanCosts.free}
            </Typography>
            <Typography variant="h4" color={theme.palette.Text.Secondary}>
              /Month
            </Typography>
          </Box>
          <Typography variant="body" color={theme.palette.Text.Secondary}>
            Per Organization
          </Typography>
        </Box>

        <Box my={2}>
          <Typography variant="body">Limited number of unique users per month.</Typography>
        </Box>

        {isFreePlanActive ? (
          <Box
            sx={{
              '& > div': {
                display: 'flex',
                padding: '9px 8px',
                fontSize: '14px',
                width: '100%',
              },
            }}
          >
            <Status
              icon="FCheckRound"
              label="You are on Free"
              variant={StatusVariant.outlined}
              status={StatusState.draft}
            />
          </Box>
        ) : (
          <Button fullWidth color="tertiary" size="large" onClick={() => {}}>
            Downgrade
          </Button>
        )}
      </Box>

      <Box
        mt={2.5}
        pt={0.5}
        px={2}
        display="flex"
        flexDirection="column"
        sx={{
          borderTop: `1px solid ${theme.palette.Common.Divider}`,
        }}
      >
        {freePlanOptions.map((planOption, index) => (
          <Box key={index} py="6px" display="flex" justifyContent="space-between" alignItems="center" gap={0.5}>
            <Typography variant={index === 0 ? 'label' : 'body'} pr={!planOption.tooltip ? 3 : 0}>
              {planOption.title}
            </Typography>
            {planOption.tooltip && (
              <Tooltip title={planOption.tooltip} placement="bottom">
                <Box display="inline-flex">
                  <Icon name="OInfo" iconColor={theme.palette.Black.o32} />
                </Box>
              </Tooltip>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
