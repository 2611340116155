import { APIRequestParams } from 'api/common/utils'
import { ApplianceDTO } from 'interfaces/appliances'
import { API } from 'utils/api/api'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  applianceId: string
}>

type Response = { appliance: ApplianceDTO }

export const deleteAppliance = ({ applianceId }: Params) =>
  API.put<Response>(
    formatRequestString({
      entityUrl: `/api/appliances/${applianceId}/ban`,
    }),
  )
