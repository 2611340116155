import React, { useContext, useState, useEffect } from 'react'
import { useDebounce } from 'react-use'
import { Box, Typography, Tooltip } from '@mui/material'
import { withUnapprovedTrackersContext, UnapprovedTrackersContext } from 'pages/policyCenter/trackers/context'
import {
  Spinner,
  EmptyState,
  DataGrid,
  TableCell,
  Icon,
  IconDictionary,
  ListLayout,
  Button,
  theme,
  TextInput,
} from '@ketch-com/deck'
import { GridRenderCellParams, GridColumnHeaderParams } from '@mui/x-data-grid-premium'
import { Tracker, ApproveTrackersBody } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import {
  Purposes,
  DisclosureAndBlocking,
  Categories,
  Properties,
} from 'pages/policyCenter/trackers/list/unapprovedTrackers/components'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { GetTrackerStatisticsResponseBody } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import pluralize from 'pluralize'
import { ReactComponent as DuckDuckGo } from 'assets/images/DuckDuckGo.svg'
import { ProvenanceType } from 'pages/policyCenter/trackers/interfaces'
import { ProvenanceVariant } from 'pages/policyCenter/trackers/components'

type Props = {
  trackerStatistics: GetTrackerStatisticsResponseBody
}

const UnapprovedTrackersWithContext: React.FC<Props> = ({ trackerStatistics }: Props) => {
  const [inputValue, setInputValue] = useState('')
  const { isPermitted: getIsPermitted } = useIsPermitted()
  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])
  const {
    isReady = false,
    unapprovedTrackers = [],
    numUnapprovedTrackers = 0,
    isFetchingUnapprovedTrackers,
    isFetchingNextUnapprovedTrackers,
    hasNextUnapprovedTrackers,
    fetchNextUnapprovedTrackers,
    selectedTrackers,
    setSelectedTrackers,
    approveTrackers,
    searchString,
    setSearchString,
    shouldBulkApproveAllTrackers,
    setShouldBulkApproveAllTrackers,
  } = useContext(UnapprovedTrackersContext)

  const noOfUnApprovedTrackers = unapprovedTrackers?.length || 0
  const noOfSelectedTrackers = selectedTrackers?.length || 0
  const selectedAllUnapprovedTrackers = Boolean(
    numUnapprovedTrackers && noOfSelectedTrackers && numUnapprovedTrackers === noOfSelectedTrackers,
  )

  useEffect(() => {
    if (shouldBulkApproveAllTrackers) {
      const trackersToSelect = unapprovedTrackers?.map(tracker => tracker?.key)
      setSelectedTrackers(trackersToSelect)
    }
  }, [shouldBulkApproveAllTrackers, unapprovedTrackers, setSelectedTrackers])

  useEffect(() => {
    if (!searchString) setInputValue('')
  }, [searchString])

  const rows = unapprovedTrackers?.map(tracker => tracker) || []
  const cols = [
    {
      width: 380,
      field: 'key',
      headerName: 'Tracker',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        const provenance = params?.row?.provenance
        return (
          <TableCell>
            <Box display="flex" alignItems="center" gap={1}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={36}
                height={36}
                borderRadius={1}
                sx={{ backgroundColor: ({ palette }) => palette.fadedGrey.main }}
              >
                <ProvenanceVariant provenance={provenance} type={ProvenanceType.Icon} />
              </Box>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography variant="label">{params?.row?.key}</Typography>

                {params?.row?.trackerInstances?.length ? (
                  <Box>
                    <Tooltip
                      title={
                        <Box>
                          {params?.row?.trackerInstances?.map((instances, index) => {
                            return (
                              <Box key={index} my={1}>
                                <Typography variant="label" color="white.main">
                                  {instances}
                                </Typography>
                              </Box>
                            )
                          })}
                        </Box>
                      }
                      placement="bottom"
                    >
                      <Typography variant="smallBody" color="darkDuskFaded.main">
                        {params?.row?.trackerInstances?.length || 0}{' '}
                        {pluralize('instance', params?.row?.trackerInstances?.length)}
                      </Typography>
                    </Tooltip>
                  </Box>
                ) : null}

                {params?.row?.dataSystem?.name || params?.row?.dataSystem?.logoURL ? (
                  <Box display="flex" alignItems="center" gap={0.5}>
                    {params?.row?.dataSystem?.logoURL ? (
                      <img
                        src={params?.row?.dataSystem?.logoURL}
                        alt={params?.row?.dataSystem?.name}
                        width={12}
                        height={12}
                      />
                    ) : null}
                    {params?.row?.dataSystem?.name ? (
                      <Typography variant="footnote" color="darkDusk.main">
                        {params?.row?.dataSystem?.name}
                      </Typography>
                    ) : null}
                  </Box>
                ) : (
                  <Typography variant="footnote" color="darkDuskFaded.main">
                    Unknown System
                  </Typography>
                )}
              </Box>
            </Box>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 160,
      field: 'categories',
      headerName: 'Category',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            {params?.row?.categories?.length ? (
              <Categories tracker={params?.row} />
            ) : (
              <Typography variant="body" color="darkDuskFaded.main">
                Unknown
              </Typography>
            )}
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 120,
      field: 'provenance',
      headerName: 'Provenance',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        const provenance = params?.row?.provenance || ''
        return (
          <TableCell>
            <Typography variant="body">
              <ProvenanceVariant provenance={provenance} type={ProvenanceType.Text} />
            </Typography>
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 160,
      field: 'id',
      headerName: 'Found on',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            {params?.row?.properties?.length ? (
              <Properties tracker={params?.row} />
            ) : (
              <Typography variant="body" color="darkDuskFaded.main">
                None
              </Typography>
            )}
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 160,
      field: 'purposes',
      headerName: 'Purposes',
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <TableCell>
            <Purposes tracker={params?.row} />
          </TableCell>
        )
      },
      sortable: false,
    },
    {
      width: 180,
      field: 'properties',
      renderHeader: (params: GridColumnHeaderParams) => (
        <Box display="flex" alignItems="center" gap={0.5}>
          <Typography variant="smallLabel">Disclosure</Typography>
          <Tooltip
            title={
              <Box display="flex" flexDirection="column" gap={1} width={180} p={0.5}>
                <Box display="flex" alignItems="center" gap={0.5}>
                  <Icon name={IconDictionary.OEyeShow} iconColor={theme.palette.white.main} width={20} height={20} />
                  <Icon name={IconDictionary.OEyeHide} iconColor={theme.palette.white.main} width={20} height={20} />
                </Box>
                <Box display="flex" flexDirection="column" gap={0.25}>
                  <Typography variant="label" color="white.main">
                    Disclosed or Hidden
                  </Typography>
                  <Typography variant="body" color="white.main">
                    Toggle tracker's visibility on privacy experiences.
                  </Typography>
                </Box>
              </Box>
            }
            placement="bottom"
          >
            <Box mt={0.5}>
              <Icon
                name={IconDictionary.FUnknown}
                iconColor={theme.palette.darkDuskFaded.main}
                width={16}
                height={16}
              />
            </Box>
          </Tooltip>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams<(typeof rows)[0]>) => {
        return (
          <Box>
            <DisclosureAndBlocking tracker={params?.row} />
          </Box>
        )
      },
      sortable: false,
    },
  ]

  useDebounce(
    () => {
      setSearchString(inputValue)
    },
    500,
    [inputValue],
  )

  if (!isReady && !searchString)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" my={4}>
        <Spinner size={35} thickness={3} />
      </Box>
    )

  if (!trackerStatistics?.unReviewed)
    return (
      <ListLayout headerTitle="New Trackers Found">
        <Box my={5}>
          <EmptyState
            size="medium"
            customIcon={<Icon name={IconDictionary.OCheckRound2} width={64} height={64} />}
            title="All Caught Up"
            subTitle="No trackers to review at the moment."
          />
        </Box>
      </ListLayout>
    )

  const Component = (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" my={3}>
        <Box display="flex" alignItems="center" gap={3}>
          <TextInput
            inputWidth="180px"
            size="small"
            placeholder="Search"
            startIcon={<Icon name={IconDictionary.OMag} iconColor={theme.palette.darkDuskFaded.main} />}
            rows={2}
            value={inputValue}
            onChange={e => {
              setInputValue(e?.currentTarget?.value)
            }}
          />

          {noOfSelectedTrackers ? (
            shouldBulkApproveAllTrackers || selectedAllUnapprovedTrackers ? (
              <Box display="flex" alignItems="center" gap={1}>
                <Box>
                  <Typography variant="body">
                    All <Typography variant="label">{numUnapprovedTrackers}</Typography>{' '}
                    {pluralize('Tracker', numUnapprovedTrackers)} selected
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="link"
                    color="secondary"
                    onClick={() => {
                      setSelectedTrackers([])
                      setShouldBulkApproveAllTrackers(false)
                    }}
                    size="large"
                  >
                    Clear
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box>
                <Typography variant="body">
                  <Typography variant="label">{noOfSelectedTrackers}</Typography>{' '}
                  {pluralize('Tracker', noOfSelectedTrackers)} Selected
                </Typography>
                {!selectedAllUnapprovedTrackers ? (
                  <>
                    {' '}
                    |{' '}
                    <Button
                      variant="link"
                      color="secondary"
                      onClick={() => setShouldBulkApproveAllTrackers(true)}
                      size="large"
                    >
                      Select All {numUnapprovedTrackers} {pluralize('Tracker', numUnapprovedTrackers)}
                    </Button>
                  </>
                ) : null}
              </Box>
            )
          ) : null}
        </Box>

        {isPermittedToWriteCookie ? (
          <Button
            color="primary"
            disabled={!isPermittedToWriteCookie || !noOfSelectedTrackers}
            onClick={() => {
              let requestBody: ApproveTrackersBody = {
                includedTrackers: selectedTrackers as string[],
              }

              if (shouldBulkApproveAllTrackers || selectedAllUnapprovedTrackers)
                requestBody = {
                  bulkApprove: true,
                  searchString,
                }
              approveTrackers({
                params: {
                  requestBody,
                },
              })
            }}
          >
            Approve{' '}
            {shouldBulkApproveAllTrackers ? numUnapprovedTrackers : noOfSelectedTrackers ? noOfSelectedTrackers : ''}
          </Button>
        ) : null}
      </Box>
      {noOfUnApprovedTrackers ? (
        <Box height="58vh">
          <DataGrid
            getRowHeight={() => 'auto'}
            getRowId={(row: Tracker) => row?.key}
            sx={{
              '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox):hover': {
                backgroundImage: 'unset',
                cursor: 'auto',
              },
            }}
            rows={rows}
            columns={cols}
            checkboxSelection={isPermittedToWriteCookie}
            hideFooter={false}
            hideFooterPagination={false}
            hideFooterRowCount
            disableBorder
            disableColumnMenu
            disableColumnPinning
            disableColumnReorder
            disableColumnResize
            disableChildrenSorting
            disableRowSelectionOnClick
            disableRowHoverStates
            loading={false}
            columnMenuProps={
              {
                slots: {
                  columnMenuFilterItem: null,
                  columnMenuAggregationItem: null,
                  columnMenuGroupingItem: null,
                },
              } as any
            }
            onRowsScrollEnd={() => {
              if (hasNextUnapprovedTrackers && !isFetchingUnapprovedTrackers && !isFetchingNextUnapprovedTrackers)
                fetchNextUnapprovedTrackers()
            }}
            rowSelectionModel={selectedTrackers}
            onRowSelectionModelChange={trackerKeys => {
              if (shouldBulkApproveAllTrackers && trackerKeys?.length !== noOfUnApprovedTrackers) {
                setShouldBulkApproveAllTrackers(false)
              }
              setSelectedTrackers(trackerKeys)
            }}
            paginationLoading={isFetchingNextUnapprovedTrackers}
            isPaginationLoading={isFetchingNextUnapprovedTrackers}
          />
        </Box>
      ) : null}

      {!noOfUnApprovedTrackers && searchString && isReady ? (
        <Box
          sx={{
            padding: '50px 0',
            borderRadius: '11px',
            backgroundColor: 'white.main',
          }}
        >
          <EmptyState
            iconName={IconDictionary.OFilter}
            title="No data matches the filter criteria"
            subTitle="Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try again."
            primaryButtonTitle="Reset to Defaults"
            primaryButtonProps={{
              onClick: () => {
                setSearchString('')
              },
              color: 'tertiary',
            }}
          />
        </Box>
      ) : null}

      {!noOfUnApprovedTrackers && searchString && !isReady ? (
        <Box display="flex" justifyContent="center" alignItems="center" my={4}>
          <Spinner size={35} thickness={3} />
        </Box>
      ) : null}
    </Box>
  )

  return (
    <ListLayout headerTitle="New Trackers Found">
      <Box mb={3} display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="label">
          {trackerStatistics?.unReviewed || 0} new {pluralize('tracker', trackerStatistics?.unReviewed)} found.
        </Typography>
        <Typography variant="body">
          Please review the list below, validate purpose assignment and toggle a visibility switch.
        </Typography>
      </Box>
      <Box>{Component}</Box>
      <Box display="flex" alignItems="center" gap={1} my={2} position="absolute" bottom={0} left={32}>
        <Typography variant="smallBody" color="darkDuskFaded.main">
          In partnership with
        </Typography>
        <DuckDuckGo />
      </Box>
    </ListLayout>
  )
}

export const UnapprovedTrackers = withUnapprovedTrackersContext(UnapprovedTrackersWithContext)
