import { APIRequestParams } from 'api/common/utils'
import { API } from 'utils/api/api'
import { WebTagDTO } from 'interfaces/webtags/WebTags'
import { formatRequestString } from 'utils/helpers/api/queryString'

type Params = APIRequestParams<{
  webTagId: string
  organizationCode: string
  version: string
  includeIssues?: boolean
}>

type Response = WebTagDTO

export const fetchWebTagVersion = ({ organizationCode, webTagId, version, includeMetadata, includeIssues }: Params) =>
  API.get<Response>(
    formatRequestString({
      entityUrl: `/api/organizations/${organizationCode}/tags/${webTagId}/versions/${version}`,
      params: {
        filters: {
          includeMetadata,
          includeIssues,
        },
      },
    }),
  )
