import React, { CSSProperties } from 'react'
import { Box } from '@mui/material'
import { SystemsList } from './SystemsList'
import { OverviewHeader } from './OverviewHeader'
import { Filters, ShowFilters } from 'pages/dataSystems/components/Filters'
import { withSystemsOverviewContext } from './Context/SystemsOverviewContext'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { RoutesManager } from 'utils/routing/routesManager'

interface Props {
  className?: string
  style?: CSSProperties
}

const OverviewWithContext: React.FC<Props> = ({ className, style }) => {
  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.systems.root.getURL() },
    { title: 'Systems', link: RoutesManager.systems.systemsHome.root.getURL() },
  ]

  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box className={className} style={style}>
        <OverviewHeader />

        <Box display="flex" mb={2.5}>
          <Filters show={[ShowFilters.SEARCH, ShowFilters.CAPABILITIES]} />
        </Box>

        <SystemsList />
      </Box>
    </>
  )
}

export const Overview = withSystemsOverviewContext(OverviewWithContext)
