import { ActionSheetItem, ActionSheetWrapper, Button, Icon, ListItemText } from '@ketch-com/deck'
import { Box } from '@mui/material'
import { useState } from 'react'
import { StatusFilters, useTransponderWorkFilters } from '../hooks/useTransponderWorkFilters'

const statusOptions: StatusFilters[] = ['All Statuses', 'QUEUED', 'IN-PROGRESS', 'COMPLETED', 'FAILED']

type FilterDropdownProps = {
  listItems: string[]
  setMenuItemCallback: (option: { [key: string]: string }) => void
  selectedMenuItem: string
  filterName: string
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  listItems,
  setMenuItemCallback,
  selectedMenuItem,
  filterName,
}) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleMenuItemClick = (_: React.MouseEvent<HTMLElement>, option: string) => {
    setMenuItemCallback({ [filterName]: option })
    setAnchorElement(null)
  }
  const handleMenuClose = () => setAnchorElement(null)

  return (
    <>
      <Button
        size="medium"
        color="tertiary"
        onClick={handleMenuOpen}
        endIcon={<Icon name={anchorElement ? 'OArrowCUp' : 'OArrowCDown'} />}
      >
        {selectedMenuItem}
      </Button>
      <ActionSheetWrapper anchorEl={anchorElement} open={!!anchorElement} onClose={handleMenuClose}>
        {listItems.map((option, index) => (
          <ActionSheetItem
            //   fine to use index here because it's a tuple
            key={index}
            selected={option === selectedMenuItem}
            onClick={event => handleMenuItemClick(event, option)}
          >
            <ListItemText selected={option === selectedMenuItem}>{option}</ListItemText>
          </ActionSheetItem>
        ))}
      </ActionSheetWrapper>
    </>
  )
}

type TransponderWorkListFilterProps = {
  inputSubjects: string[]
}

export const TransponderWorkListFilters: React.FC<TransponderWorkListFilterProps> = ({ inputSubjects }) => {
  const [{ status, inputSubject }, setFilters] = useTransponderWorkFilters()

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <FilterDropdown
        listItems={statusOptions}
        setMenuItemCallback={setFilters}
        selectedMenuItem={status}
        filterName="status"
      />
      <FilterDropdown
        listItems={inputSubjects}
        setMenuItemCallback={setFilters}
        selectedMenuItem={inputSubject}
        filterName="inputSubject"
      />
    </Box>
  )
}
