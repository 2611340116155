import {
  BannerButtonAction,
  BannerExperienceContentConfig,
  BannerExperienceLayoutConfig,
  CanonicalRightCode,
  ExperienceContentConfig,
  ExperienceLayoutConfig,
  ModalExperienceContentConfig,
  ModalExperienceLayoutConfig,
  PreferenceCenterPage,
  PreferenceExperienceContentConfig,
  PreferenceExperienceLayoutConfig,
  QuickLinkAction,
  RightsFormMode,
  SwitchButtonDisplay,
  UnsubscribeAllImpact,
  UnsubscribeFromAllPosition,
} from '@ketch-sdk/ketch-types'
import { ExperienceV2DTO } from 'interfaces/experiences-v2/experience'
import { ExperienceStatus } from 'interfaces/experiences-v2/experienceStatus'
import { ExperienceType } from 'interfaces/experiences-v2/experienceType'

const defaultBannerLayoutExperienceConfig: BannerExperienceLayoutConfig = {
  header: {
    visible: true,
    closeButtonVisible: false,
  },
  buttons: {
    primary: {
      action: BannerButtonAction.RejectAll,
      url: '',
    },
    secondary: {
      visible: true,
      action: BannerButtonAction.OpenModal,
      url: '',
    },
    tertiary: {
      visible: true,
      action: BannerButtonAction.AcceptAll,
      url: '',
    },
  },
  footer: {
    gpcBadgeVisible: false,
  },
}

const defaultModalLayoutExperienceConfig: ModalExperienceLayoutConfig = {
  header: {
    // NA
    visible: true,
    closeButtonVisible: false,
  },
  description: {
    title: {
      // NA
      visible: true,
    },
  },
  purposeListHeader: {
    visible: true,
    useDefaultText: true,
  },
  purposeList: {
    legalBasisVisible: false,
    purposeStacksDefaultExpanded: true,
    switchButtonLabels: {
      visible: false,
      display: SwitchButtonDisplay.Always,
      useDefaultText: true,
    },
  },
  footer: {
    gpcBadgeVisible: false,
  },
}

const defaultPreferenceLayoutExperienceConfig: PreferenceExperienceLayoutConfig = {
  gpcBadgeVisible: false,
  tabs: {
    welcome: {
      welcomeMsg: {
        visible: true,
      },
      quickLinks: {
        visible: false,
        links: [
          {
            action: QuickLinkAction.OpenUrl,
            page: PreferenceCenterPage.Subscriptions,
            url: '',
            visible: false,
          },
          {
            action: QuickLinkAction.OpenUrl,
            page: PreferenceCenterPage.RequestsHome,
            url: '',
            visible: false,
          },
          {
            action: QuickLinkAction.OpenUrl,
            page: PreferenceCenterPage.RequestsHome,
            url: '',
            visible: false,
          },
        ],
      },
      about: {
        visible: true,
      },
    },
    privacyPolicy: {
      visible: false,
      policyDocumentId: '',
    },
    purposes: {
      header: {
        title: {
          visible: true,
        },
      },
      purposeListHeader: {
        titleVisible: true,
        useDefaultText: true,
      },
      purposeList: {
        legalBasisVisible: false,
        purposeStacksDefaultExpanded: true,
        switchButtonLabels: {
          visible: false,
          display: SwitchButtonDisplay.Always,
          useDefaultText: true,
        },
      },
      actionButtonUseDefaultText: true,
    },
    subscriptions: {
      header: {
        title: {
          visible: true,
        },
      },
      unsubscribeAll: {
        visible: false,
        title: {
          useDefaultText: true,
        },
        description: {
          visible: true,
        },
        switchButton: {
          visible: false,
          useDefaultText: true,
        },
        impact: UnsubscribeAllImpact.Universal,
        position: UnsubscribeFromAllPosition.Top,
      },
      list: {
        items: [],
        switchButtons: {
          visible: false,
          useDefaultText: true,
        },
      },
      actionButtonUseDefaultText: true,
    },
    requests: {
      home: {
        header: {
          title: {
            visible: true,
          },
        },
        rights: {
          title: {
            useDefaultText: true,
          },
          forms: {
            mode: RightsFormMode.Single,
            singleFormID: '',
            details: [
              {
                canonicalRight: CanonicalRightCode.Get,
                defaultFormID: '',
                rightFormMappings: [],
              },
              {
                canonicalRight: CanonicalRightCode.Delete,
                defaultFormID: '',
                rightFormMappings: [],
              },
              {
                canonicalRight: CanonicalRightCode.Restrict,
                defaultFormID: '',
                rightFormMappings: [],
              },
              {
                canonicalRight: CanonicalRightCode.Update,
                defaultFormID: '',
                rightFormMappings: [],
              },
            ],
          },
        },
      },
      rightForm: {
        enableRecaptcha: true,
        actionButton: {
          useDefaultText: true,
        },
      },
      submitted: {
        actionButton: {
          visible: true,
          useDefaultText: true,
        },
      },
    },
  },
}

const defaultExperienceLayoutConfig: ExperienceLayoutConfig = {
  banner: defaultBannerLayoutExperienceConfig,
  modal: defaultModalLayoutExperienceConfig,
  preference: defaultPreferenceLayoutExperienceConfig,
  // These are calculated within figurehead on each submission
  entitlementInfo: {
    hasPurposesEntitlement: true,
    hasRightsEntitlement: true,
    hasSubscriptionsEntitlement: true,
  },
}

const defaultBannerEnglishExperienceConfig: BannerExperienceContentConfig = {
  header: {
    title: 'Your Privacy Choices',
  },
  description:
    '<p><span style="color: rgb(0, 0, 0);">We use online tracking and other technologies to provide a personalized experience, improve our offerings, monitor and record your engagement with us, and share limited personal information with third-party advertisers. To learn more about your privacy practices review our </span><a href="privacyPolicy" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 0, 0);" title="privacyPolicy">Privacy Policy</a><span style="color: rgb(0, 0, 0);">. Please confirm your choices.</span></p>',
  buttons: {
    primary: {
      text: 'Opt Out',
    },
    secondary: {
      text: 'Customize Settings',
    },
    tertiary: {
      text: 'Opt In',
    },
  },
}

const defaultModalEnglishExperienceConfig: ModalExperienceContentConfig = {
  header: {
    title: 'This Website Processes Personal Data',
  },
  description: {
    title: 'Your Privacy Choices',
    body: '<p><span style="color: rgb(0, 0, 0);">We use online tracking and other technologies to provide a personalized experience, improve our offerings, monitor and record your engagement with us, and share limited personal information with third-party advertisers. To learn more about your privacy practices review our </span><a href="https://sendit.ninja/deployment/experiences-v2/edit/consent/privacyPolicy" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 0, 0);">Privacy Policy</a><span style="color: rgb(0, 0, 0);">. Please confirm your choices.</span></p>',
  },
  purposeListHeader: {
    text: 'Purposes',
  },
  purposeList: {
    switchButtonLabels: {
      onText: 'Opted In',
      offText: 'Opted Out',
    },
  },
  footer: {
    actionButtonText: 'Confirm',
  },
}

const defaultPreferenceEnglishExperienceConfig: PreferenceExperienceContentConfig = {
  footer: {
    actionButtonText: 'Exit',
  },
  header: {
    title: 'Privacy Center',
  },
  navigation: {
    privacyPolicyTitle: 'Privacy Policy',
    purposesTitle: 'Purposes',
    requestsTitle: 'Requests',
    subscriptionsTitle: 'Subscriptions',
    welcomeTitle: 'Welcome',
  },
  tabs: {
    welcome: {
      about: {
        description:
          '<p>We prioritize and respect your privacy. We are committed to transparently managing your data,  using it only for necessary purposes with your explicit consent. Rigorous security measures are in place to protect your information,  and we never sell or share it for marketing without your permission. Your control over your data is important to us, and we continually update our practices to align with the latest standards.</p>',
        title: 'About Your Privacy',
      },
      quickLinks: {
        links: [
          {
            text: 'Manage Preferences',
          },
          {
            text: 'Submit Request',
          },
          {
            text: 'Manage Your Subscriptions',
          },
        ],
      },
      welcomeMsg: {
        subtitle:
          '<p><span style="color: rgb(0, 0, 0);">We use online tracking and other technologies to provide a personalized experience, improve our offerings, monitor and record your engagement with us, and share limited personal information with third-party advertisers. To learn more about your privacy practices review our </span><a href="https://sendit.ninja/deployment/experiences-v2/edit/consent/privacyPolicy" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 0, 0);">Privacy Policy</a><span style="color: rgb(0, 0, 0);">.</span></p>',
        title: 'Your Privacy Choices',
      },
    },
    purposes: {
      actionButtonText: 'Save',
      header: {
        description:
          '<p><span style="color: rgb(0, 0, 0);">We use online tracking and other technologies to provide a personalized experience, improve our offerings, monitor and record your engagement with us, and share limited personal information with third-party advertisers. To learn more about your privacy practices review our </span><a href="https://sendit.ninja/deployment/experiences-v2/edit/consent/privacyPolicy" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 0, 0);">Privacy Policy</a><span style="color: rgb(0, 0, 0);">. Please confirm your choices.</span></p>',
        title: 'Your Privacy Choices',
      },
      purposeList: {
        switchButtonLabels: {
          onText: 'Opted In',
          offText: 'Opted Out',
        },
      },
      purposeListHeader: {
        title: 'Purposes',
      },
    },
    subscriptions: {
      actionButtonText: 'Save',
      header: {
        description:
          "<p>Let us know what you are most interested in and how you'd like us to keep in touch. Unsubscribe any time by coming back here or by clicking on the manage subscriptions link in our emails.</p>",
        title: 'Your Subscriptions',
      },
      listSwitchLabels: {
        offText: 'Off',
        onText: 'On',
      },
      unsubscribeAll: {
        description:
          'You’ll always receive reservation details, account security updates, and changes to program terms and conditions.',
        switchLabels: {
          onText: 'On',
          offText: 'Off',
        },
        title: 'Unsubscribe From All',
      },
    },
    requests: {
      home: {
        dsrPortalLink: {
          description: 'Already submitted a request? Click here to view its status.',
          title: 'View My Requests',
        },
        header: {
          description:
            '<p>Applicable privacy laws give you certain rights to our collection, use, sharing, and storage of your information.  We welcome your exercise of those rights. For more details, please read our <a href="privacyPolicy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>.</p>',
          title: 'Exercise your rights',
        },
        rights: {
          title: 'Select a Request Type',
        },
      },
      rightForm: {
        actionButtonText: 'Submit',
        recaptchaFailureText:
          '<p>Unfortunately, we are unable to fulfill your request at this time due to suspected bot-like behavior, as indicated by our reCAPTCHA verification. If you believe this is incorrect, please try re-submitting your request.</p>',
      },
      submitted: {
        actionButtonText: 'Submit New Request',
        description:
          "<p>We've received your request. Certain requests require identity verification. For those requests, you will receive a second email with verification instructions. Please be sure to check your email. Where required, we will not be able to proceed without your timely verification.</p>",
        subtitle: 'Thank you!',
        title: 'Request was submitted',
      },
    },
  },
}

export const defaultExperienceContentEnglishConfig: ExperienceContentConfig = {
  banner: defaultBannerEnglishExperienceConfig,
  modal: defaultModalEnglishExperienceConfig,
  preference: defaultPreferenceEnglishExperienceConfig,
}

const defaultExperienceTranslationsConfig: { [locale: string]: ExperienceContentConfig } = {
  en: defaultExperienceContentEnglishConfig,
}

export const defaultExperienceV2DTO: ExperienceV2DTO = {
  name: '',
  type: ExperienceType.Consent,
  status: ExperienceStatus.Undeployed,
  layout: defaultExperienceLayoutConfig,
  translations: defaultExperienceTranslationsConfig,
}

export const defaultConsentExperienceV2DTO: ExperienceV2DTO = {
  ...defaultExperienceV2DTO,
  type: ExperienceType.Consent,
}

export const defaultPreferenceExperienceV2DTO: ExperienceV2DTO = {
  ...defaultExperienceV2DTO,
  type: ExperienceType.Preference,
}
