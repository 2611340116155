import React, { CSSProperties } from 'react'
import { InstalledDataSystemV2DTO } from '@ketch-com/figurehead'

import { Box } from '@mui/material'
import { ContentGroup } from '@ketch-com/deck'
import { RoutesManager } from 'utils/routing/routesManager'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import PredeterminedFields from 'pages/dataSystems/DataSystem/components/PredeterminedFields'

type Props = {
  appDescriptor: InstalledDataSystemV2DTO
  className?: string
  style?: CSSProperties
}

export const Overview: React.FC<Props> = ({ appDescriptor, className, style }) => {
  const breadcrumbs = [
    { title: 'Data Map', link: RoutesManager.systems.root.getURL() },
    { title: 'Systems', link: RoutesManager.systems.systems.root.getURL() },
    { title: appDescriptor.dataSystem?.name, link: RoutesManager.systems.id.root.getURL({ id: appDescriptor.id }) },
    { title: 'Overview' },
  ]
  return (
    <>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      <Box display="flex" flexDirection="column" gap={3} mb={6}>
        <ContentGroup
          title="Overview"
          subTitle="Basic information about the system and how it relates to your organization."
          titleVariant="h3"
        />
        <PredeterminedFields appDescriptor={appDescriptor} />
        {/*
          Extra fields are hidden: https://ketch-com.atlassian.net/browse/KD-9753
          Show the additional fields if Capabilities.INTROSPECTION_APP_CAPABILITY is present
          <Assets dataSystem={dataSystem} />
        */}
      </Box>
    </>
  )
}
