import { useCustomSqlUtils } from 'pages/assetManager/scenario/details/components/CustomSql/hooks'

export type CustomSqlUtils = ReturnType<typeof useCustomSqlUtils>

export interface CustomSqlFormData {
  identitySpaceCode: string
  name: string
  sqlQuery?: string
  sqlQueries?: string[]
  scriptType?: string
  id: string
}

export enum CustomSqlModalType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface CustomSqlModalData {
  mode: CustomSqlModalType
  customSqlId?: string
}
