import React from 'react'
import { Box, Typography } from '@mui/material'
import { Icon, IconDictionary, Button, theme } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { PropertySummary, UnapprovedTracker } from '@ketch-com/supercargo/dist/tracker_gen.schemas'
import { useUpsertTrackerPropertyDisclosure } from 'api/trackers/mutations/useUpsertTrackerPropertyDisclosure'
import { showToast } from 'components/ui-kit/toastr/Toastr'
import { useQueryClient } from 'react-query'
import { ApiQueryKeys } from 'api/common/queryKeys'

type Props = {
  tracker: UnapprovedTracker
  trackerPropertyItem: PropertySummary
  trackerPropertyItemIndex: number
}

export const PropertyDisclosureAndBlocking: React.FC<Props> = ({
  tracker = {} as UnapprovedTracker,
  trackerPropertyItem,
  trackerPropertyItemIndex,
}) => {
  const queryClient = useQueryClient()
  const trackerPropertiesItems = tracker?.properties
  const { isPermitted: getIsPermitted } = useIsPermitted()

  const isPermittedToWriteCookie = getIsPermitted([PERMISSIONS.COOKIE_WRITE])

  const {
    mutateAsync: upsertUnapprovedTrackerPropertyDisclosure,
    isLoading: isUpdatingUnapprovedTrackerPropertyDisclosure,
  } = useUpsertTrackerPropertyDisclosure({
    onSuccess: async () => {
      await queryClient.invalidateQueries([ApiQueryKeys.unapprovedTrackersInfinite])
      showToast({ content: 'Updated disclosure', type: 'success' })
    },
    onError: () => {
      showToast({ content: 'Failed to update disclosure', type: 'error' })
    },
  })

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        borderBottom: ({ palette }) =>
          trackerPropertiesItems?.length - 1 !== trackerPropertyItemIndex ? `1px solid ${palette.iron.main}` : 'none',
        paddingBottom: ({ spacing }) =>
          trackerPropertiesItems?.length - 1 !== trackerPropertyItemIndex ? spacing(1.5) : 0,
        marginBottom: ({ spacing }) =>
          trackerPropertiesItems?.length - 1 !== trackerPropertyItemIndex ? spacing(1.5) : 0,
      }}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="label">{trackerPropertyItem?.name}</Typography>
        <Typography variant="smallBody" color="darkDuskFaded.main">
          {trackerPropertyItem?.type}
        </Typography>
      </Box>
      <Box>
        <Button
          sx={{
            width: 32,
            height: 32,
            borderRadius: '100%',
            padding: 0.25,
          }}
          disabled={!isPermittedToWriteCookie || isUpdatingUnapprovedTrackerPropertyDisclosure}
          onClick={() => {
            upsertUnapprovedTrackerPropertyDisclosure({
              params: {
                data: {
                  isDisclosed: trackerPropertyItem?.isDisclosed ? false : true,
                },
                encodedTrackerKey: tracker?.encodedKey || '',
                propertyId: trackerPropertyItem?.id || '',
              },
            })
          }}
          variant={trackerPropertyItem?.isDisclosed ? 'contained' : 'iconCustomRounded'}
          color={trackerPropertyItem?.isDisclosed ? 'primary' : 'tertiary'}
          children={
            trackerPropertyItem?.isDisclosed ? (
              <Icon name={IconDictionary.OEyeShow} iconColor={theme.palette.white.main} />
            ) : (
              <Icon name={IconDictionary.OEyeHide} />
            )
          }
        />
      </Box>
    </Box>
  )
}
