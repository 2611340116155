import React from 'react'

import { AssetPolicyDTO } from '@ketch-com/figurehead'

type Props = {
  policy: AssetPolicyDTO
}

export const VersionsTab: React.FC<Props> = props => {
  return (
    <div>
      <h3>Hello VersionsTab</h3>
    </div>
  )
}
