export const transponderHelmInstructions = `
## Step 1 - Start Kubernetes 

> :warning: Transponder can only run on AMD64 (X86_64) architecture; it does not support ARM64. If your local machine is based on AMD64, you can use either Minikube or Lima. If your local machine is based on ARM64, you must use Lima to run AMD64 architecture.

### Run Minikube

Start Minikube. For details on how to use Minikube or any troubleshooting guides, refer to Minikube's [official guide](https://minikube.sigs.k8s.io/docs/).

\`\`\`shell
$ minikube start
😄  minikube v1.32.0 on Darwin 14.4 (arm64)
...
🏄  Done! kubectl is now configured to use "minikube" cluster and "default" namespace by default
$ kubectl config get-contexts
CURRENT   NAME       CLUSTER    AUTHINFO   NAMESPACE
*         minikube   minikube   minikube   default
$ kubectl get ns
NAME              STATUS   AGE
default           Active   3m59s
kube-node-lease   Active   3m59s
kube-public       Active   3m59s
kube-system       Active   3m59s
\`\`\`

### Run Lima

Start Lima with a Kubernetes template. For details on how to use Lima or any troubleshooting guides, refer to Lima's [official guide](https://lima-vm.io/docs/).

\`\`\`shell
$ limactl start template://k3s --arch x86_64
? Creating an instance "k3s"  [Use arrows to move, type to filter]
> Proceed with the current configuration
  Open an editor to review or modify the current configuration
  Choose another template (docker, podman, archlinux, fedora, ...)
  Exit
...
INFO[0133] READY. Run \`limactl shell k3s\` to open the shell.
INFO[0133] Message from the instance "k3s":
To run \`kubectl\` on the host (assumes kubectl is installed), run the following commands:
------
export KUBECONFIG="/Users/peter/.lima/k3s/copied-from-guest/kubeconfig.yaml"
kubectl ...
------
$ export KUBECONFIG="/Users/peter/.lima/k3s/copied-from-guest/kubeconfig.yaml"
$ kubectl config get-contexts
CURRENT   NAME      CLUSTER   AUTHINFO   NAMESPACE
*         default   default   default
$ kubectl get ns
NAME              STATUS   AGE
kube-system       Active   62s
kube-public       Active   62s
kube-node-lease   Active   62s
default           Active   62s
\`\`\`

## Step 2 - Create TLS certificates

For this tutorial, we need to create two different TLS certificates. We will create two self-signed certificates. However, please create certificates following your company's best practices for your production deployment.

### Transponder TLS certificate

Let's create a certificate for Transponder.

\`\`\`shell
$ openssl req -x509 -nodes -newkey rsa:2048 -keyout transponder_tls.key -out transponder_tls.crt -extensions SAN -config <(cat /etc/ssl/openssl.cnf <(printf "\n[SAN]\nsubjectAltName=DNS:transponder"))
# Follow the prompt
$ cat transponder_tls.crt | openssl x509 -noout -text
Certificate:
    Data:
        ...
        Subject: C=US, ST=California, L=San Francisco, O=Ketch, OU=Engineering, CN=transponder.ketch.com, emailAddress=infra-sec@ketch.com
        ...
        X509v3 extensions:
            X509v3 Subject Alternative Name:
                DNS:transponder
        ...
\`\`\`

With the above step, you now have a self-signed TLS certificate that is valid for the \`transponder\` domain name. This certificate will be valid for a month. We will be using \`transponder\` as the TLS domain name later when we deploy Transponder.

### Vault TLS certificate

Let's create a certificate for Hashicorp Vault. We will be using Vault for the secret provider below.

\`\`\`shell
$ openssl req -x509 -nodes -newkey rsa:2048 -keyout vault_tls.key -out vault_tls.crt -extensions SAN -config <(cat /etc/ssl/openssl.cnf <(printf "\n[SAN]\nsubjectAltName=DNS:vault"))
# Follow the prompt
$ cat vault_tls.crt | openssl x509 -noout -text
Certificate:
    Data:
        ...
        Subject: C=US, ST=California, L=San Francisco, O=Ketch, OU=Engineering, CN=vault.ketch.com, emailAddress=infra-sec@ketch.com
        ...
        X509v3 extensions:
            X509v3 Subject Alternative Name:
                DNS:vault
\`\`\`

Similar to the Transponder TLS certificate, you now have a self-signed TLS certificate that is valid for the \`vault\` domain name. This certificate will be valid for a month.

## Step 3 - Set up Vault

For this tutorial, we are using [Hashicorp Vault](https://www.vaultproject.io/) as the secret provider. Transponder supports multiple other secret providers. Please consult the Ketch support team for others.

We will create a single-pod Vault cluster. However, please create a Vault cluster following your company's best practices for the production deployment.

This part of the tutorial is largely based on Hashicorp's [official guide](https://developer.hashicorp.com/vault/tutorials/kubernetes/kubernetes-minikube-tls). Refer to that guide for details and troubleshooting tips.

### Helm repository

Add the official Hashicorp Helm repository.

\`\`\`shell
$ helm repo add hashicorp https://helm.releases.hashicorp.com
"hashicorp" has been added to your repositories
$ helm repo list
NAME      	URL
hashicorp 	https://helm.releases.hashicorp.com
\`\`\`

### Create a namespace

Create a dedicated namespace for Vault in our Kubernetes cluster.

\`\`\`shell
$ kubectl create namespace vault
namespace/vault created
$ kubectl get namespace vault
NAME              STATUS   AGE
vault             Active   7s
\`\`\`

### Create a Kubernetes secret for the TLS certificate

Create a Kubernetes secret containing the TLS certificate created above.

\`\`\`shell
$ kubectl -n vault create secret tls vault-tls --cert="vault_tls.crt" --key="vault_tls.key"
secret/vault-tls created
$ kubectl -n vault describe secret vault-tls
Name:         vault-tls
Namespace:    vault
Labels:       <none>
Annotations:  <none>

Type:  kubernetes.io/tls

Data
====
tls.key:  1704 bytes
tls.crt:  1448 bytes
\`\`\`

### Create an overrides file

Create a YAML file that contains our overrides for the default Vault values.

\`\`\`yaml
global:
  tlsDisable: false
injector:
  # This defaults to true, but we don't need it for this tutorial
  enabled: false
server:
  readinessProbe:
    enabled: true
  # Liveness probe fails if Vault is sealed. Because we are manually unsealing Vault,
  # this will force the pod to restart if we don't unseal quick enough.
  # Disable the probe for this tutorial. For the production Vault, setting up the
  # auto-unsealing and using the liveness probes are recommended.
  livenessProbe:
    enabled: false
  volumes:
    - name: tls
      secret:
        secretName: vault-tls
        items:
          - key: "tls.crt"
            path: "tls.crt"
          - key: "tls.key"
            path: "tls.key"
  volumeMounts:
    - name: tls
      mountPath: /tls
      readOnly: true
  standalone:
    enabled: true
    config: |
      ui = true

      listener "tcp" {
        tls_disable = 0
        address = "[::]:8200"
        cluster_address = "[::]:8201"
        # This needs to match the volumeMounts above
        tls_cert_file = "/tls/tls.crt"
        tls_key_file = "/tls/tls.key"
      }
      storage "file" {
        path = "/vault/data"
      }
\`\`\`

### Install Vault via Helm

Run the \`helm upgrade\` command to install Vault in our Kubernetes cluster.

\`\`\`shell
$ helm upgrade -n vault vault hashicorp/vault -f /path/to/the/vault/overrides.yaml --install
Release "vault" does not exist. Installing it now.
NAME: vault
...
STATUS: deployed
...
\`\`\`

Wait for the Vault pods to come up, then check the logs. Double-check that TLS is enabled.

\`\`\`shell
$ kubectl -n vault logs vault-0
==> Vault server configuration:

Administrative Namespace:
...
         Cluster Address: https://vault-0.vault-internal:8201
   Environment Variables: ...
              Go Version: go1.21.3
              Listener 1: tcp (addr: "[::]:8200", cluster address: "[::]:8201", max_request_duration: "1m30s", max_request_size: "33554432", tls: "enabled")
...

==> Vault server started! Log data will stream in below:
...
\`\`\`

### Unseal Vault

\`exec\` into the Vault pod

\`\`\`shell
$ kubectl -n vault exec -it pod/vault-0 -- sh
/ $
\`\`\`

Set \`VAULT_SKIP_VERIFY\` environment variable. Without this, you will run into a TLS verification error because the certificate is a self-signed certificate from an unknown authority and is not valid for 127.0.0.1.

\`\`\`shell
/ $ export VAULT_SKIP_VERIFY=true
\`\`\`

Initialize the Vault cluster.

\`\`\`shell
/ $ vault operator init
Unseal Key 1: SoMe
Unseal Key 2: V3ry
Unseal Key 3: C0mplicat1ng
Unseal Key 4: k3YS
Unseal Key 5: h3Re

Initial Root Token: v3ry.Dang3r0usT0k3n
\`\`\`

Unseal the vault cluster using the unseal keys above. You need to repeat this **three** times with three **different** unseal keys from the prior step.

\`\`\`shell Shell
/ $ vault operator unseal
Unseal Key (will be hidden): <ENTER ONE OF THE UNSEAL KEYS FROM ABOVE>
Key                Value
---                -----
Seal Type          shamir
Initialized        true
Sealed             true
...
Unseal Progress    1/3
...
/ $ vault operator unseal
Unseal Key (will be hidden): <ENTER ONE OF THE UNSEAL KEYS FROM ABOVE>
Key                Value
---                -----
Seal Type          shamir
Initialized        true
Sealed             true
...
Unseal Progress    2/3
...
/ $ vault operator unseal
Unseal Key (will be hidden): <ENTER ONE OF THE UNSEAL KEYS FROM ABOVE>
Key             Value
---             -----
Seal Type       shamir
Initialized     true
Sealed          false
...
\`\`\`

### Create a secret engine

Set up the \`VAULT_TOKEN\` environment variable. For this tutorial, we will use the root token. For the production deployment, please follow your company's best practices on which token to use.

\`\`\`shell
/ $ export VAULT_TOKEN=<ENTER THE ROOT TOKEN FROM ABOVE>
\`\`\`

Create a secrets engine that we can use. The Transponder only supports the KV v1 secrets engine.

\`\`\`shell
/ $ vault secrets list
# No secrets engine that we can use
/ $ vault secrets enable -path=secret kv-v1
Success! Enabled the kv-v1 secrets engine at: secret/
/ $ vault secrets list 
Path          Type         Accessor              Description
----          ----         --------              -----------
...
secret/       kv           kv_11e68c48           n/a
...
\`\`\`

## Step 4 - Install Transponder

### Helm repository

Add the Ketch Helm repository.

\`\`\`shell
$ helm repo add ketch-transponder-helm https://ketch.jfrog.io/artifactory/ketch-transponder-helm --username <INSERT USERNAME FROM PREREQUISITE>
Password: <ENTER PASSWORD FROM PREREQUISITE>
$ helm repo list
NAME      	            URL
ketch-transponder-helm	https://ketch.jfrog.io/artifactory/ketch-transponder-helm
\`\`\`

### Create a namespace

Create a dedicated namespace for Transponder in our Kubernetes cluster.

\`\`\`shell
$ kubectl create namespace transponder
namespace/transponder created
$ kubectl get namespace transponder
NAME          STATUS   AGE
transponder   Active   9s
\`\`\`

### Create an overrides file

Base 64 encode the Transponder TLS certificate and private key created above. Note these values down.

\`\`\`shell
$ cat transponder_tls.crt | base64
LS0t...
$ cat transponder_tls.key | base64
LS0t...
\`\`\`

Base 64 encode the Vault TLS certificate and private key created above. Note these values down.

\`\`\`shell
$ cat vault_tls.crt | base64
LS0t...
$ cat vault_tls.key | base64
LS0t...
\`\`\`

Create a YAML file that contains our overrides for the default Transponder values.

\`\`\`yaml
config:
  org: <INSERT ORGANIZATION CODE>
  id: transponder_tutorial
  transponder:
    # Lower number of replicas for this tutorial
    replica: 2
  tls:
    cert:
      content: <INSERT BASE64 ENCODED TRANSPONDER TLS CERTIFICATE FROM ABOVE>
    key:
      content: <INSERT BASE64 ENCODED TRANSPONDER TLS PRIVATE KEY FROM ABOVE>
  vault:
    address: https://vault.vault:8200
    # This needs to match the secret engine that we created above
    prefix: secret
    # For this tutorial, use the root token for the ease of use
    # For the production deployment, please follow your company's best practices
    # to provision an appropriate token
    token: <ENTER THE ROOT TOKEN>
    tls:
      cert:
        content: <INSERT BASE64 ENCODED VAULT TLS CERTIFICATE FROM ABOVE>
      # For this tutorial, enable insecure TLS traffic since we are using self-signed
      # certificates. For the production deployment, create certificates following
      # your company's best practices. 
      insecure: true
      key:
        content: <INSERT BASE64 ENCODED VAULT TLS PRIVATE KEY FROM ABOVE>
      override: vault
imageCredentials:
  username: <INSERT USERNAME FROM PREREQUISITE>
  password: <INSERT PASSWORD FROM PREREQUISITE>
ingress:
  # Transponder has a web UI. For this tutorial, do not provision any ingress.
  # For the production deployment, follow your company's best practices to expose the UI
  # using appropriate Kubernetes resources
  enabled: false
secret:
  provider: vault
\`\`\`

### Install Transponder via Helm

Run the \`helm upgrade\` command to install Vault in our Kubernetes cluster.

\`\`\`shell
$ helm upgrade --install -n transponder transponder ketch-transponder-helm/transponder -f /path/to/the/transponder/overrides.yaml
Release "transponder" does not exist. Installing it now.
NAME: transponder
...
STATUS: deployed
...
$ kubectl -n transponder get pods
NAME                                       READY   STATUS    RESTARTS   AGE
transponder-transponder-7d6cc9f57c-8wgkh   1/1     Running   0          109s
transponder-transponder-7d6cc9f57c-zlf5k   1/1     Running   0          103s
transponder-sonar-869867ddcb-rzhl8         1/1     Running   0          105s
\`\`\`

### Port forward to the web UI

Access the Transponder web UI by port forwarding to the Kubernetes service.

\`\`\`shell
$ kubectl -n transponder get services
NAME                      TYPE       CLUSTER-IP     EXTERNAL-IP   PORT(S)         AGE
transponder-transponder   NodePort   10.43.85.80    <none>        443:32719/TCP   14m
transponder-sonar         NodePort   10.43.184.59   <none>        443:32131/TCP   14m

$ kubectl -n transponder port-forward service/transponder-transponder 8443:443
Forwarding from 127.0.0.1:8443 -> 5000
Forwarding from [::1]:8443 -> 5000
\`\`\`

Visit [Transponder UI](https://localhost:8443/transponder/) from your browser. Because we are using a self-signed certificate, your browser will flag this page for an unverified TLS certificate, which is safe to ignore for this tutorial.

\`\`\`shell
$ curl -k "https://localhost:8443/transponder/"
<!doctype html><html lang="en"><head><meta charset="utf-8"/><link rel="icon" href="favicon.ico"/><meta name="viewport" content="width=device-width,initial-scale=1"/><meta name="theme-color" content="#000000"/><meta name="description" content="Ketch Transponder UI"/><title>Ketch Transponder</title><script defer="defer" src="./static/js/main.1f3c7fe3.js"></script><link href="./static/css/main.2043821a.css" rel="stylesheet"></head><body><noscript>You need to enable JavaScript to run this app.</noscript><div id="root"></div></body></html>
\`\`\`

You now have a working Transponder running locally!`
