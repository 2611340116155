import { FC, useContext } from 'react'
import { useMatches } from 'react-router-dom'
import { theme } from '@ketch-com/deck'
import { OrgPlanAndBillingBannerVariants } from 'components/planAndBilling/common/constants/planAndBilling'
import { Box } from '@mui/material'
import { RoutesManager } from 'utils/routing/routesManager'
import { PlanAndBillingBanners } from 'components/planAndBilling/components/banners/PlanAndBillingBanners'
import { OrgPlanIDs } from 'components/planAndBilling/common/constants/planAndBilling'
import { PlanStatus, BillingStatus } from '@ketch-com/harbormaster/dist/organization/plans.schemas'
import { isRelease } from 'utils/releases'
import { Release } from 'utils/releases/RELEASES'
import { PlanAndBillingContext } from 'components/planAndBilling/common/context/PlanAndBillingContext'

const ignoreRoutes = [RoutesManager.settings.planAndBilling.root.getURL()]

export const PlanAndBillingGlobalBanner: FC = () => {
  const { orgPlan } = useContext(PlanAndBillingContext)
  const matches = useMatches()
  const isOrgOnFreePlan = orgPlan?.planProductID === OrgPlanIDs?.free
  const isFreePlanInGrace = isOrgOnFreePlan && orgPlan?.planStatus === PlanStatus.in_grace_period
  const isFreePlanSuspended = isOrgOnFreePlan && orgPlan?.planStatus === PlanStatus.suspended
  const isOrgOnStarterPlan = orgPlan?.planProductID === OrgPlanIDs?.starter
  const isStarterPlanInGrace = isOrgOnStarterPlan && orgPlan?.planStatus === PlanStatus.in_grace_period
  const isStarterPlanSuspended = isOrgOnStarterPlan && orgPlan?.planStatus === PlanStatus.suspended
  const isBillingInGrace = orgPlan?.billingStatus === BillingStatus.in_grace_period
  const isBillingMissed = orgPlan?.billingStatus === BillingStatus.missed
  const isPlanSuspended = isFreePlanSuspended || isStarterPlanSuspended
  const isOrgOnPlgPlan = isOrgOnFreePlan || isOrgOnStarterPlan
  const isHiddenBanner =
    !isOrgOnPlgPlan || matches.some(match => ignoreRoutes.some(ignoreRoute => ignoreRoute === match.pathname))

  if (isHiddenBanner || !isRelease(Release.PlanAndBilling)) return null

  return (
    <Box mt={-3} mb={3} pt={0.5} sx={{ width: '100%', backgroundColor: theme.palette.common.white }}>
      {isFreePlanInGrace && !isBillingMissed ? (
        <PlanAndBillingBanners
          variant={OrgPlanAndBillingBannerVariants.freePlanInGrace}
          orgPlan={orgPlan}
          isGlobal={true}
        />
      ) : null}
      {isFreePlanSuspended ? (
        <PlanAndBillingBanners
          variant={OrgPlanAndBillingBannerVariants.freePlanSuspended}
          orgPlan={orgPlan}
          isGlobal={true}
        />
      ) : null}
      {isStarterPlanInGrace && !isBillingMissed ? (
        <PlanAndBillingBanners
          variant={OrgPlanAndBillingBannerVariants.starterPlanInGrace}
          orgPlan={orgPlan}
          isGlobal={true}
        />
      ) : null}
      {isStarterPlanSuspended ? (
        <PlanAndBillingBanners
          variant={OrgPlanAndBillingBannerVariants.starterPlanSuspended}
          orgPlan={orgPlan}
          isGlobal={true}
        />
      ) : null}
      {isBillingInGrace && !isPlanSuspended ? (
        <PlanAndBillingBanners
          variant={OrgPlanAndBillingBannerVariants.billingInGrace}
          orgPlan={orgPlan}
          isGlobal={true}
        />
      ) : null}
      {isBillingMissed ? (
        <PlanAndBillingBanners
          variant={OrgPlanAndBillingBannerVariants.billingMissed}
          orgPlan={orgPlan}
          isGlobal={true}
        />
      ) : null}
    </Box>
  )
}
