import { ListLayout, TabProps } from '@ketch-com/deck'
import { max } from 'lodash'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

const tabs: { tabProps: TabProps; path: string }[] = [
  {
    tabProps: {
      label: 'Systems',
    },
    path: RoutesManager.systems.systems.root.getURL(),
  },
  {
    tabProps: {
      label: 'Connections',
    },
    path: RoutesManager.systems.connections.root.getURL(),
  },
]

export const SystemsSection: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // Determine what tab is currently selected based on URL
  const tab = tabs.findIndex(t => pathname.includes(t.path))
  const tabItems = Object.values(tabs).map(t => t.tabProps)

  /* Redirect if base route is navigated to  */
  useEffect(() => {
    if (pathname === RoutesManager.systems.systemsHome.root.getURL()) {
      navigate(RoutesManager.systems.systems.root.getURL())
    }
  }, [pathname, navigate])

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    navigate(tabs[newTab].path)
  }

  return (
    <ListLayout
      headerTitle="Systems"
      tabsComponentProps={{ value: max([tab, 0]), onChange: handleTabChange, tabItems }}
    >
      <Outlet />
    </ListLayout>
  )
}
