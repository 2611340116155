import Box from '@mui/material/Box'
import { Text } from 'components/ui-kit/typography/Text'
import { useNavigate, useParams } from 'react-router-dom'
import { DataRiskDetailLayout } from 'pages/insights/dataRisks/components/layout/DataRiskDetailLayout'
import { ReactComponent as DataRiskSuggestionPie } from 'assets/icons/data_risk_suggestion_pie.svg'
import { ReactComponent as DataRiskSuggestionTrash } from 'assets/icons/data_risk_suggestion_trash.svg'
import { ReactComponent as PersonAvatarSmall } from 'assets/icons/person-avatar-small.svg'
import { Button } from '@ketch-com/deck'
import { ButtonOptions } from 'components/ui-kit/buttonOptions/ButtonOptions'
import { GreyBox } from 'pages/insights/dataRisks/components/layout/GreyBox'
import { LinkChip } from 'components/ui-kit/chip/LinkChip'
import { Chip } from 'components/ui-kit/chip/Chip'
import { useState } from 'react'
import { UnutilizedDataAssets } from 'pages/insights/dataRisks/__mocks__/unutilizedDataAssets'
import { formatLastUsedDate, formatNumberWithCommas, getRiskScoreDetails } from 'pages/insights/utils'
import { RoutesManager } from 'utils/routing/routesManager'
import { LineChart } from 'pages/insights/dataRisks/components/LineChart'
import { AssetTypeIcon } from 'pages/assetManager/components'
import { useStyles } from '../../components/layout/styles'
import { formatDateTimeFromUnix } from 'utils/helpers/dateTime'

type Props = {
  unutilizedDataAssets: UnutilizedDataAssets[]
}

export const UnutilizedDataDetails: React.FC<Props> = ({ unutilizedDataAssets }) => {
  const { code: assetId } = useParams<{ code?: string }>()
  const [isDataCategoriesOpen, setIsDataCategoriesOpen] = useState(false)
  const classes = useStyles()
  const navigate = useNavigate()
  const dataSystem = unutilizedDataAssets.find(asset => asset.id === assetId)
  const riskScoreDetails = getRiskScoreDetails(dataSystem?.dataRiskScore || 0)
  const lastUsedDate = new Date(dataSystem?.lastUsed?.timestamp || new Date().getTime())
  const lastUsedAgo = formatLastUsedDate(lastUsedDate)

  const actions = (
    <>
      <ButtonOptions size="big" items={[{ content: <Text>Export</Text> }, { content: <Text>Compare</Text> }]} />
      <Button size="large" color="secondary">
        View in Asset Manager
      </Button>
    </>
  )

  const whyContent = (
    <Box>
      <Text component="div" sx={{ mb: 0.75 }}>
        This database contains
      </Text>
      <Text component="div" sx={{ mb: 4 }} weight={700} size={20}>
        {formatNumberWithCommas(dataSystem?.recordCount)} PII records
      </Text>
      <Text component="div" sx={{ mb: 0.75 }}>
        Database last used
      </Text>
      <Text weight={700} size={20} color={riskScoreDetails.textColorName}>
        {lastUsedAgo}
      </Text>
    </Box>
  )

  const suggestionsContent = [
    {
      icon: <DataRiskSuggestionPie />,
      textContent: (
        <Text lineHeight="20px" component="div">
          If this database contains archived or obsolete records—consider deleting or restricting access for security
          reasons.
        </Text>
      ),
    },
    {
      icon: <DataRiskSuggestionTrash />,
      textContent: (
        <Text lineHeight="20px" component="div">
          This database contains PII records that are not used. If repurposed it can result in new revenue streams.
        </Text>
      ),
    },
  ]

  const lowerContentRows = [
    <GreyBox flexBasis="60%" heading="Data" key={1}>
      <>
        <Text color="grey" component="div" sx={{ mb: 1.5 }}>
          Personal Data Types
        </Text>
        {dataSystem?.personalDataTypes?.map((str, index) => (
          <Box key={index} mb={1}>
            <Chip>{str}</Chip>
          </Box>
        ))}
        <Text color="grey" component="div" sx={{ mb: 1.5, mt: 3 }}>
          Classifications
        </Text>
        <Box mb={1.5}>
          {isDataCategoriesOpen
            ? dataSystem?.dataCategories?.map((str, index) => (
                <Chip key={index} className={classes.chip}>
                  {str}
                </Chip>
              ))
            : dataSystem?.dataCategories?.slice(0, 9).map((str, index) => (
                <Chip key={index} className={classes.chip}>
                  {str}
                </Chip>
              ))}
        </Box>
        {(dataSystem?.dataCategories?.length || 0) > 10 && (
          <Text component="div" color="royalBlue" onClick={() => setIsDataCategoriesOpen(!isDataCategoriesOpen)}>
            {isDataCategoriesOpen ? 'Show less' : `View ${(dataSystem?.dataCategories?.length || 0) - 10} more`}
          </Text>
        )}
      </>
    </GreyBox>,
    <Box flexBasis="40%" display="flex" flexDirection="column" key={2}>
      <GreyBox heading="Last Used">
        <>
          <Box display="flex" gap={1} mb={1}>
            <PersonAvatarSmall />
            <Text weight={600}>{dataSystem?.lastUsed?.name}</Text>
          </Box>
          <Text component="div" lineHeight="20px">
            {lastUsedAgo} <br /> {formatDateTimeFromUnix(lastUsedDate.getTime() / 1000)}
          </Text>
        </>
      </GreyBox>
      <GreyBox heading="Purposes">
        <Box display="flex" flexDirection="column" gap={1}>
          {dataSystem?.purposes?.map((purpose, index) => (
            <Box key={index}>
              <LinkChip
                text={purpose.name}
                onClick={() => navigate(RoutesManager.policyCenter.purposes.view.root.getURL({ code: purpose.code }))}
              />
            </Box>
          ))}
        </Box>
      </GreyBox>
    </Box>,
  ]

  const mainContent = (
    <Box width={568} height={286}>
      <LineChart riskTrend={dataSystem?.riskTrend} />
    </Box>
  )

  return (
    <DataRiskDetailLayout
      heading={dataSystem?.name || ''}
      headingType={dataSystem?.assetType}
      headingTypeIcon={
        <AssetTypeIcon assetType={dataSystem?.canonicalResourceType || 0} className={classes.iconMedium} />
      }
      actions={actions}
      mainContent={mainContent}
      riskScoreDetails={riskScoreDetails}
      riskScore={dataSystem?.dataRiskScore}
      whyContent={whyContent}
      suggestionContent={suggestionsContent}
      lowerContentRows={lowerContentRows}
    />
  )
}
