import React from 'react'
import { TagsListProvider } from './context/TagsContext'
import { TagsListV2 } from './TagsListV2'

export const TagsListContainerV2: React.FC = () => {
  return (
    <TagsListProvider>
      <TagsListV2 />
    </TagsListProvider>
  )
}
