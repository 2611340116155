import React, { useContext } from 'react'
import { PrivacyProtocolTile } from './components/PrivacyProtocolTile'
import { RoutesManager } from 'utils/routing/routesManager'
import { FormMode } from 'interfaces/formModes/formMode'
import { getStatusRequestBody } from '../upsert/utils'
import { PrivacyProtocolsContext, withPrivacyProtocolsContext } from './context/PrivacyProtocolsContext'
import {
  PrivacyProtocol,
  PrivacyProtocolConfigurationResponseBodyStatus,
} from '@ketch-com/supercargo/dist/privacy_protocol_gen.schemas'
import { AppCrashView } from 'components/ui-routing/AppCrashView'
import { Box } from '@mui/material'
import { Spinner } from '@ketch-com/deck'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'

/**
 * View for the list of privacy protocols, which can each be viewed or configured
 */
const PrivacyProtocolsWithContext: React.FC = () => {
  const { navigate, protocols, refetchProtocols, updateStatus, isReady } = useContext(PrivacyProtocolsContext)

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Consent', link: RoutesManager.deployment.consentHome.root.getURL() },
    { title: 'Privacy Protocols', link: RoutesManager.orchestration.consent.privacyProtocols.root.getURL() },
  ]

  /**
   * Redirect to create screen when protocol enabled for the first time
   * @param protocolId ID of the protocol to configure
   */
  const handleEnableClick = (protocolId: string) => {
    navigate(
      RoutesManager.orchestration.consent.privacyProtocols.upsert.root.getURL({
        id: protocolId,
        formMode: FormMode.CREATE,
      }),
    )
  }

  /**
   * Flip the status of toggled protocol and update list
   * @param protocol Protocol for which status should be toggled
   * @param listIndex Index of the protocol in the protocols list
   */
  const handleEnableToggle = async (protocol: PrivacyProtocol, listIndex: number) => {
    const newStatus =
      protocol.config?.status === PrivacyProtocolConfigurationResponseBodyStatus.enabled
        ? PrivacyProtocolConfigurationResponseBodyStatus.disabled
        : PrivacyProtocolConfigurationResponseBodyStatus.enabled
    try {
      await updateStatus(protocol.id, { privacyProtocolConfig: getStatusRequestBody(newStatus) })
      await refetchProtocols()
    } catch {}
  }

  /**
   * Redirect to the view screen for a specific protocol
   * @param protocol protocol to view
   */
  const handleViewClick = (protocol: PrivacyProtocol) => {
    if (protocol.config?.status !== PrivacyProtocolConfigurationResponseBodyStatus.none) {
      navigate(
        RoutesManager.orchestration.consent.privacyProtocols.view.root.getURL({
          id: protocol.id,
        }),
      )
    }
  }

  return (
    <Box>
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      {!isReady ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" m={6}>
          <Spinner size="32px" thickness={2.5} />
        </Box>
      ) : protocols.length ? (
        protocols.map((p, i) => (
          <PrivacyProtocolTile
            key={p.id}
            protocol={p}
            onEnableClick={() => handleEnableClick(p.id)}
            onStatusToggle={() => handleEnableToggle(p, i)}
            onViewClick={() => handleViewClick(p)}
          />
        ))
      ) : (
        <AppCrashView />
      )}
    </Box>
  )
}

export const PrivacyProtocols = withPrivacyProtocolsContext(PrivacyProtocolsWithContext)
