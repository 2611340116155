import { getURLParams, IDParams, ReviewDataURLParams } from './utils'
import { FormMode } from '../../../interfaces/formModes/formMode'

export interface UpsertRouteProps {
  formMode: FormMode
  id?: string
}

export const orchestration = {
  root: {
    pattern: '/consent-and-rights',
    getURL: () => '/consent-and-rights',
  },

  // TODO:JB - Move to deployment.ts after nav 2.0 release
  events: {
    root: {
      pattern: '/events',
      getURL: () => '/consent-and-rights/rights-home/events',
    },

    create: {
      root: {
        pattern: '/rights-home/events/create',
        getURL: () => '/consent-and-rights/rights-home/events/create',
      },
    },
  },

  // TODO:JB - Move to deployment.ts after nav 2.0 release
  workflows: {
    root: {
      pattern: '/workflows',
      getURL: () => '/consent-and-rights/rights-home/workflows',
    },

    view: {
      root: {
        pattern: '/rights-home/workflows/view/:code',
        getURL: ({ code }: getURLParams) => `/consent-and-rights/rights-home/workflows/view/${code}`,
      },

      events: {
        root: {
          pattern: '/events',
          getURL: ({ code }: getURLParams) => `/consent-and-rights/rights-home/workflows/view/${code}/events`,
        },
      },

      versions: {
        root: {
          pattern: '/versions',
          getURL: ({ code }: getURLParams) => `/consent-and-rights/rights-home/workflows/view/${code}/versions`,
        },
      },
    },

    upsert: {
      root: {
        pattern: '/rights-home/workflows/edit/:code',
        getURL: ({ code }: getURLParams) => `/consent-and-rights/rights-home/workflows/edit/${code}`,
      },
    },
  },

  // TODO:JB - Move to deployment.ts after nav 2.0 release
  rightsQueue: {
    root: {
      pattern: '/rights-queue',
      getURL: () => '/consent-and-rights/rights-home/rights-queue',
    },

    reviewData: {
      root: {
        pattern: '/rights-home/rights-queue/:rightInvocationId/:executionId/data-review/:stepId',
        getURL: ({ rightInvocationId, stepId, executionId }: ReviewDataURLParams) =>
          `/consent-and-rights/rights-home/rights-queue/${rightInvocationId}/${executionId}/data-review/${stepId}`,
      },
    },

    view: {
      root: {
        pattern: '/rights-home/rights-queue/view/:id/*',
        getURL: ({ id }: IDParams) => `/consent-and-rights/rights-home/rights-queue/view/${id}/overview`,
      },

      overview: {
        root: {
          pattern: '/overview',
          getURL: ({ id }: IDParams) => `/consent-and-rights/rights-home/rights-queue/view/${id}/overview`,
        },
      },

      workflow: {
        root: {
          pattern: '/workflow',
          getURL: ({ id }: IDParams) => `/consent-and-rights/rights-home/rights-queue/view/${id}/workflow`,
        },
      },

      subjectCommunication: {
        root: {
          pattern: '/subject-communication',
          getURL: ({ id }: IDParams) => `/consent-and-rights/rights-home/rights-queue/view/${id}/subject-communication`,
        },
      },
    },

    stepDetails: {
      root: {
        pattern: '/rights-home/rights-queue/view/:id/executions/:workflowExecutionId/steps/:stepId',
        getURL: ({
          id,
          stepId,
          workflowExecutionId,
        }: {
          id?: string
          stepId?: string
          workflowExecutionId: string | undefined
        }) =>
          `/consent-and-rights/rights-home/rights-queue/view/${id}/executions/${workflowExecutionId}/steps/${stepId}`,
      },
    },

    create: {
      root: {
        pattern: '/rights-home/rights-queue/create',
        getURL: () => '/consent-and-rights/rights-home/rights-queue/create',
      },
    },
  },

  // Consent - tags
  webTags: {
    root: {
      pattern: '/tags',
      getURL: () => '/consent-and-rights/consent-home/tags',
    },

    view: {
      root: {
        pattern: '/consent-home/tags/view/:id',
        getURL: ({ id }: IDParams) => `/consent-and-rights/consent-home/tags/view/${id}`,
      },

      purposes: {
        root: {
          pattern: '/purposes',
          getURL: ({ id }: IDParams) => `/consent-and-rights/consent-home/tags/view/${id}/purposes`,
        },
      },

      versions: {
        root: {
          pattern: '/versions',
          getURL: ({ id }: IDParams) => `/consent-and-rights/consent-home/tags/view/${id}/versions`,
        },
      },
    },
  },

  // OLD - TODO:JB - Delete once nav 2.0 is released
  consent: {
    root: {
      pattern: '/consent',
      getURL: () => '/consent-and-rights/consent-home',
    },

    // Consent - systems
    systems: {
      root: {
        pattern: '/systems',
        getURL: () => '/consent-and-rights/consent-home/systems',
      },

      view: {
        root: {
          pattern: '/consent-home/systems/view/:id',
          getURL: ({ id }: IDParams) => `/consent-and-rights/consent-home/systems/view/${id}`,
        },
      },

      edit: {
        root: {
          pattern: '/consent-home/systems/edit/:id',
          getURL: ({ id }: IDParams) => `/consent-and-rights/consent-home/systems/edit/${id}`,
        },
      },
    },

    // Consent - privacy protocols
    privacyProtocols: {
      root: {
        pattern: '/privacy-protocols',
        getURL: () => '/consent-and-rights/consent-home/privacy-protocols',
      },

      view: {
        root: {
          pattern: '/consent-home/privacy-protocols/view/:id',
          getURL: ({ id }: getURLParams) => `/consent-and-rights/consent-home/privacy-protocols/view/${id}`,
        },
      },

      upsert: {
        root: {
          pattern: '/consent-home/privacy-protocols/:id/:formMode',
          getURL: ({ formMode, id }: UpsertRouteProps) =>
            `/consent-and-rights/consent-home/privacy-protocols/${id}/${formMode}`,
        },
      },
    },
  },
}
