import { Box, Typography } from '@mui/material'
import { Fragment, forwardRef, useRef, useEffect, useContext } from 'react'
import { Compose, Message } from 'pages/policyCenter/processingActivities/view/Comment/components'
import { useFormikContext } from 'formik'
import { ChatData } from 'pages/policyCenter/processingActivities/view/Comment/interfaces'
import { getConnectionStateText } from 'pages/policyCenter/processingActivities/view/Comment/utils'
import { Chip, Icon } from '@ketch-com/deck'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ProcessingActivityContext } from 'pages/policyCenter/processingActivities/view/context/ProcessingActivityContext'

export const Chat = forwardRef((props, ref) => {
  const { isPermitted } = useIsPermitted()
  const { processingActivityChatUsers } = useContext(ProcessingActivityContext)
  const { values } = useFormikContext<ChatData>()
  const { twilioMessages, twilioConnectionState } = values
  const connectionStateText = getConnectionStateText(twilioConnectionState)
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollRef.current) scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight)
  }, [values.twilioMessages])

  return (
    <Box ref={ref} p={2} display="flex" flexDirection="column" gap={2}>
      {twilioMessages && twilioMessages.length ? (
        <Box ref={scrollRef} maxHeight={432} overflow="auto">
          {twilioMessages.map((message, index) => {
            return (
              <Fragment key={message.sid}>
                <Message
                  message={message}
                  isLast={index === twilioMessages.length - 1}
                  users={processingActivityChatUsers}
                />
              </Fragment>
            )
          })}
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="label" color="darkDuskFaded.main">
            No comments yet
          </Typography>
          {isPermitted(PERMISSIONS.PROCESSING_ACTIVITY_WRITE) ? (
            <Typography variant="body" color="darkDuskFaded.main">
              Click below to add the first one!
            </Typography>
          ) : null}
        </Box>
      )}

      {isPermitted(PERMISSIONS.PROCESSING_ACTIVITY_WRITE) ? <Compose /> : null}

      {connectionStateText ? (
        <Box>
          <Chip size="small" icon={<Icon name="FWarning" />} label={connectionStateText} />
        </Box>
      ) : null}
    </Box>
  )
})
