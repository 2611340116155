import { Box, Typography } from '@mui/material'
import { AssetSummaryDTO } from '@ketch-com/figurehead'
import { ContentRow } from 'components/ui-layouts/viewLayout/ViewModeLayout'
import { EmptyValueRenderer } from 'components/ui-kit/typography/compositions/EmptyValueRenderer'
import * as Info from './components'
import { useInfoRowsConfig } from '../useInfoRowsConfig'
import { useInlineEditUtils } from './hooks'
import { UseInlineEditUtilsReturnType } from './hooks'
import { Link } from 'components/ui-kit/link/Link'
import { Info as InfoIcon } from '@mui/icons-material'
import { Tooltip } from 'components/ui-kit/tooltip/Tooltip'
import { GetDataPreviewResponseBody } from 'pages/assetManager/interfaces'

const getContentRowInfo = (
  assetSummary: AssetSummaryDTO,
  rowKey: string,
  inlineEditUtils: UseInlineEditUtilsReturnType,
  dataPreview?: GetDataPreviewResponseBody,
): React.ReactNode | null => {
  switch (rowKey) {
    case 'Description':
      return <Info.Description assetSummary={assetSummary} inlineEditUtils={inlineEditUtils} />
    case 'Technology':
      return <Info.Technology assetSummary={assetSummary} />
    case 'Personal Data Types':
      return <Info.PersonalDataTypes assetSummary={assetSummary} inlineEditUtils={inlineEditUtils} />
    case 'Identifier':
      return <Info.IdentitySpace assetSummary={assetSummary} inlineEditUtils={inlineEditUtils} />
    case 'Data Owners':
      return <Info.DataOwners assetSummary={assetSummary} inlineEditUtils={inlineEditUtils} />
    case 'Data Residencies':
      return <Info.DataResidencies assetSummary={assetSummary} />
    case 'Last Updated':
      return <Info.LastUpdated assetSummary={assetSummary} />
    case 'Deep Classification':
      return <Info.AutoDiscovery assetSummary={assetSummary} inlineEditUtils={inlineEditUtils} />
    case 'Data Preview':
      return (
        <Box display="flex" justifyContent="center" alignItems="center" gap={1} mt={-2}>
          <Link outer to={dataPreview?.previewURL || ''} target="_blank" variant="blue">
            Preview
          </Link>
          <Tooltip
            position="bottom"
            content={
              <Box display="flex" flexDirection="column" gap={1} p={1}>
                <Typography variant="smallBody">Preview needs access to transponder</Typography>
              </Box>
            }
          >
            <Box position="relative" top={3}>
              <InfoIcon sx={{ color: ({ palette }) => palette.darkDuskFaded.main }} />
            </Box>
          </Tooltip>
        </Box>
      )
    default:
      return <EmptyValueRenderer color="darkDuskFaded" />
  }
}

export const useAssetDetailContentRows = (assetSummary?: AssetSummaryDTO, dataPreview?: GetDataPreviewResponseBody) => {
  const infoRowsConfig = useInfoRowsConfig(assetSummary, dataPreview)
  const inlineEditUtils = useInlineEditUtils({ assetSummary })

  let contentRows: ContentRow[] = []
  infoRowsConfig?.forEach?.((rowKey: string) => {
    contentRows.push({
      title: rowKey,
      info: getContentRowInfo(assetSummary as AssetSummaryDTO, rowKey, inlineEditUtils, dataPreview),
    })
  })

  return contentRows
}
