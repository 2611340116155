import React from 'react'
import { Box, Typography } from '@mui/material'
import { Message } from '@twilio/conversations'
import { UserShortDTO } from 'interfaces/users/users'
import { UserAvatar } from 'components/avatar/UserAvatar'
import { getFullNameUserShortDTO } from 'utils/helpers/getFullNameUserShortDTO'

type Props = {
  message: Message
  bgColor?: string
  fgColor?: string
  author?: UserShortDTO
}

export const InternalMessage: React.FC<Props> = ({ author }) => {
  return (
    <Box alignItems="center" display="flex" gap={1}>
      <UserAvatar user={author} />
      <Typography variant="smallLabel">{getFullNameUserShortDTO(author || ({} as UserShortDTO))}</Typography>
    </Box>
  )
}
