import { useEffect, useState } from 'react'

import { useUserMe } from 'api/users/queries/useUserMe'
import { HeapClient } from 'interfaces/heap'

export const useHeapIdentify = () => {
  const [isMounted, setIsMounted] = useState(false)
  const { data: user, isLoading: isLoadingUserMe } = useUserMe()

  const isLoadingAggregate = isLoadingUserMe

  useEffect(() => {
    const heapClient = (window as any)?.heap as HeapClient
    const shouldCallHeapIdentify = !isMounted && !!heapClient && !!user && !isLoadingAggregate

    if (shouldCallHeapIdentify) {
      heapClient.identify(user.ID)

      const payload = {
        planProductId: user.organization?.planProductID || 'not_specified',
        code: user.organization.code,
        email: user.email,
        id: user.ID,
        name: user.organization.name,
      }

      heapClient.addUserProperties(payload)
      setIsMounted(true)
    }
  }, [setIsMounted, isMounted, user, isLoadingAggregate])
}
