import React, { useContext, useState } from 'react'
import { SidebarFormGroup } from '../SidebarFormGroup'
import { ExperienceServerDropdownButton } from '../ExperienceServerDropdownButton'
import { SidebarFormTranslations } from '../SidebarFormTranslations'
import { FormTextInput } from '../FormTextInput'
import { experienceUpsertFormKeys } from 'pages/consentAndRights/experiences-v2/upsert/utils/useExperienceValidationSchema'
import { PreferenceCenterPage } from '@ketch-sdk/ketch-types'
import { PreferencePageLabels } from 'pages/consentAndRights/experiences-v2/upsert/utils/labels'
import { ExperienceUpsertContext } from 'pages/consentAndRights/experiences-v2/upsert/context/ExperienceUpsertContext'
import { getPreferencePageLabel } from 'pages/consentAndRights/experiences-v2/upsert/utils/utils'

const pageFormKeys = {
  [PreferenceCenterPage.Welcome]: experienceUpsertFormKeys.preferenceWelcomeTabNavigationWelcomeTitle,
  [PreferenceCenterPage.PrivacyPolicy]: experienceUpsertFormKeys.preferenceWelcomeTabNavigationPrivacyPolicyTitle,
  [PreferenceCenterPage.Purpose]: experienceUpsertFormKeys.preferenceWelcomeTabNavigationPurposesTitle,
  [PreferenceCenterPage.Subscriptions]: experienceUpsertFormKeys.preferenceWelcomeTabNavigationSubscriptionsTitle,
  [PreferenceCenterPage.RequestsHome]: experienceUpsertFormKeys.preferenceWelcomeTabNavigationRequestsTitle,
  // These pages aren't in the nav menu
  [PreferenceCenterPage.RequestsForm]: '',
  [PreferenceCenterPage.RequestsSubmitted]: '',
}

export const PreferenceExperienceWelcomeTabNavigationForm: React.FC = () => {
  const { preferencePage, languages, isLanguagesLoading, entitledPreferencePages } = useContext(ExperienceUpsertContext)
  const hasLanguages = !isLanguagesLoading && !!languages.length
  const omitPages = [
    PreferenceCenterPage.RequestsForm,
    PreferenceCenterPage.RequestsSubmitted,
    PreferenceCenterPage.PrivacyPolicy,
  ]
  const [selectedPage, setSelectedPage] = useState(
    omitPages.includes(preferencePage) ? PreferenceCenterPage.Welcome : preferencePage,
  )

  return (
    <>
      <SidebarFormGroup sx={{ pt: 0, pb: '16px', mt: '-16px' }}>
        <ExperienceServerDropdownButton
          items={entitledPreferencePages.filter(page => !omitPages.includes(page))}
          value={selectedPage}
          renderItem={(p, index) => getPreferencePageLabel(p, index)}
          onChange={p => setSelectedPage(p)}
        />
      </SidebarFormGroup>
      <SidebarFormGroup hasBorderTop sx={{ pt: '24px' }} hasBorderBottom={hasLanguages}>
        <FormTextInput
          label={`"${PreferencePageLabels[selectedPage]}" Page Navigation Title`}
          name={pageFormKeys[selectedPage]}
          fullWidth
          charLimit={30}
        />
      </SidebarFormGroup>
      <SidebarFormTranslations
        title={`"${PreferencePageLabels[selectedPage]}" Page Navigation Title Translations`}
        name={pageFormKeys[selectedPage]}
        variant={'text'}
        charLimit={30}
      />
    </>
  )
}
