import React from 'react'
import { DataGrid, TableCell, Status, StatusVariant, StatusState, Chip, EmptyState, Button } from '@ketch-com/deck'
import { RegulationDTO } from 'interfaces/regulations/regulation'
import { RightDTO } from 'interfaces/rights/right'
import { PolicyScopeDTO } from 'interfaces/policyScopes/policyScope'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { PolicyScopeRegulations } from 'pages/policyCenter/policyScopes/components/PolicyScopeRegulations'
import { PolicyScopeRights } from 'pages/policyCenter/policyScopes/components/PolicyScopeRights'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

type Props = {
  versions: PolicyScopeDTO[]
  policyScope: PolicyScopeDTO
  rights: RightDTO[]
  regulations: RegulationDTO[]
}

export const PolicyScopeVersions: React.FC<Props> = ({ versions, policyScope, regulations, rights }) => {
  const { setQueryParam, removeQueryParam } = useQueryParams<{ version?: string }>()
  const latestVersion = versions[0]?.version

  type RowType = (typeof versions)[0]

  const gridColumns = [
    {
      field: 'Status',
      headerName: 'Status',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        const { version } = row
        return (
          <TableCell>
            {version === latestVersion && (
              <Status icon="Dot" label="Latest" status={StatusState.success} variant={StatusVariant.ghost} />
            )}
          </TableCell>
        )
      },
      sortable: false,
      width: 110,
    },
    {
      field: 'Version',
      headerName: 'Version',
      renderCell: ({ row: { version } }: GridRenderCellParams<RowType>) => {
        return (
          <TableCell>
            <Chip size="small" label={`v${version}`} />
          </TableCell>
        )
      },
      sortable: false,
      width: 160,
    },
    {
      field: 'Jurisdiction',
      headerName: 'Jurisdiction',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        const { name, code } = row
        return <TableCell title={name} subTitle={code} />
      },
      sortable: false,
      width: 160,
    },
    {
      field: 'Regulations',
      headerName: 'Regulations',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        const { regulationCodes } = row
        return (
          <TableCell>
            <PolicyScopeRegulations regulationCodes={regulationCodes} regulations={regulations} />
          </TableCell>
        )
      },
      sortable: false,
      width: 180,
    },
    {
      field: 'Rights',
      headerName: 'Rights',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        return (
          <TableCell>
            <PolicyScopeRights policyScope={row} regulations={regulations} rights={rights} />
          </TableCell>
        )
      },
      sortable: false,
      width: 180,
    },
    {
      field: 'Created',
      headerName: 'Created',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        const { metadata } = row
        return (
          <TableCell>
            <FormattedDateRenderer date={metadata?.createdAt} />
          </TableCell>
        )
      },
      sortable: false,
      width: 200,
    },
    {
      field: 'Actions',
      headerName: '',
      renderCell: ({ row }: GridRenderCellParams<RowType>) => {
        const { version } = row
        return (
          <TableCell>
            <Button
              disabled={version === policyScope.version}
              color="tertiary"
              onClick={() => {
                if (version === latestVersion) {
                  removeQueryParam('version')
                } else {
                  setQueryParam('version', version!)
                }
              }}
            >
              View
            </Button>
          </TableCell>
        )
      },
      sortable: false,
      width: 100,
    },
  ]

  return (
    <>
      {versions.length ? (
        <DataGrid
          rowCount={versions.length}
          rows={versions}
          columns={gridColumns}
          autosizeOnMount
          disableColumnMenu
          disableColumnPinning
          disableColumnReorder
          disableChildrenSorting
          disableColumnResize
          disableRowHoverStates
          hideFooter
          disableRowSelectionOnClick
          getRowId={({ version }) => version}
          sx={{
            padding: 0,
            '.MuiDataGrid-cell': { padding: '0' },
            '& .MuiDataGrid-columnHeader[role="columnheader"]:hover': {
              backgroundImage: 'unset',
            },
          }}
          getRowHeight={() => 'auto'}
        />
      ) : (
        <EmptyState
          iconName="FCopy"
          title="No versions exist for the selected jurisdiction"
          subTitle="As you make changes to your jurisdiction, you can view that history here"
        />
      )}
    </>
  )
}
